import React, { useState, useCallback, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { signOutUser } from 'src/redux/actions/authActions';
import Avatar from './Avatar';
import { PAGES } from 'src/constants/pages';
import dropdownArrow from 'src/assets/images/icons/dropdown_arrow.svg';
import { header } from 'src/assets/locales/en/common.json';
import { ReactComponent as KeyIcon } from 'src/assets/images/icons/key.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/images/icons/settings_privacy.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/images/icons/logout.svg';
import { ReactComponent as HelpIcon } from 'src/assets/images/icons/aside_menu/menu_help.svg';
import { DEFAULT_TRANSITION_TIMEOUT, DEFAULT_USER_NAME } from 'src/constants/globalConstants';
import { signOutUserHelper, clearAuthToken } from 'src/helpers/authHelpers';
import styles from './ProfileDropdown.module.scss';

interface ProfileDropdownProps {
  profileName: string | null;
  profileAvatar?: string;
}

const ProfileDropdown = ({ profileName, profileAvatar }: ProfileDropdownProps): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [isDropdownShown, setDropdownShown] = useState(false);

  const handleLogOut = async () => {
    try {
      await signOutUserHelper();
      clearAuthToken();
      dispatch(signOutUser());
      push(PAGES.SIGN_IN);
    } catch (error) {
      console.error('handleLogOut error: ', error);
    }
  };

  const onKeyPress = useCallback(
    ({ key }) => {
      if (isDropdownShown && key === 'Escape') setDropdownShown(false);
    },
    [isDropdownShown]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <div className={cn('nav-circle', styles.profileDropdownBlock)} onClick={() => setDropdownShown(true)}>
      <img src={dropdownArrow} alt={header.dropdownAlt} className={cn(styles.profileDropdownIcon, 'img-fluid')} />

      <CSSTransition
        in={isDropdownShown}
        unmountOnExit
        timeout={DEFAULT_TRANSITION_TIMEOUT}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone
        }}
      >
        <ClickAwayListener onClickAway={() => setDropdownShown(false)}>
          <div className={styles.dropdown}>
            <div className={styles.avatarBlock}>
              <Avatar avatar={profileAvatar} avatarWrapperClass={styles.avatarWrapper} />
              <div className={styles.profileInfo}>
                <h2 className={styles.profileName}>{profileName ?? DEFAULT_USER_NAME}</h2>
                <span className={styles.myProfileLink}>See your profile</span>
              </div>
            </div>

            {/* <NavLink
              className={cn(styles.menuLink, styles.menuItem)}
              activeClassName={styles.menuLinkActive}
              to={PAGES.SETTINGS_AND_PRIVACY}
            >
              <div className={styles.menuLinkContent}>
                <div className={styles.iconWrapper}>
                  <SettingsIcon className={cn(styles.icon, 'img-fluid')} />
                </div>

                <span className={styles.linkTitle}>Settings & privacy</span>
              </div>
            </NavLink> */}

            <span className={cn(styles.menuLink, styles.menuItem, styles.disableMenuItem)}>
              <div className={styles.menuLinkContent}>
                <div className={styles.iconWrapper}>
                  <SettingsIcon className={cn(styles.icon, 'img-fluid')} />
                </div>

                <span className={styles.linkTitle}>Settings & privacy</span>
              </div>
            </span>

            <NavLink
              className={cn(styles.menuLink, styles.menuItem)}
              activeClassName={styles.menuLinkActive}
              to={PAGES.API_SETTINGS}
            >
              <div className={styles.menuLinkContent}>
                <div className={styles.iconWrapper}>
                  <KeyIcon style={{ maxWidth: 25, minWidth: 25 }} className={cn(styles.icon, 'img-fluid')} />
                </div>

                <span className={styles.linkTitle}>API Settings</span>
              </div>
            </NavLink>

            <NavLink
              exact
              className={cn(styles.menuLink, styles.menuItem)}
              activeClassName={styles.menuLinkActive}
              to={PAGES.HELP_CENTER}
            >
              <div className={styles.menuLinkContent}>
                <div className={styles.iconWrapper}>
                  <HelpIcon className={cn(styles.icon, 'img-fluid')} />
                </div>

                <span className={styles.linkTitle}>Help Center</span>
              </div>
            </NavLink>

            <div className={styles.menuItem} onClick={handleLogOut}>
              <div className={styles.menuLinkContent}>
                <div className={styles.iconWrapper}>
                  <LogoutIcon className={cn(styles.icon, 'img-fluid')} />
                </div>

                <span className={styles.linkTitle}>Log Out</span>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </CSSTransition>
    </div>
  );
};

export default ProfileDropdown;
