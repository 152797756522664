import { InputEnums } from 'src/interfaces/RandomShapes';
import { SignUpFields as Fields } from 'src/interfaces/SignUpShapes';

export const TRANSITION_TIMEOUT = 150;
export const MAX_STEPS = 2;
export const INITIAL_STEP = 1;

export const fieldsArray = [
  {
    name: Fields.COMPANY_NAME,
    type: InputEnums.TEXT,
    placeholder: 'Company Name'
  },
  {
    name: Fields.COMPANY_NUMBER,
    type: InputEnums.TEXT,
    placeholder: 'Company Registration Number'
  },
  {
    name: Fields.FIRST_NAME,
    type: InputEnums.TEXT,
    placeholder: 'First name'
  },
  {
    name: Fields.LAST_NAME,
    type: InputEnums.TEXT,
    placeholder: 'Last name'
  },
  { name: Fields.EMAIL, type: InputEnums.TEXT, placeholder: 'Email' },
  {
    name: Fields.PASSWORD,
    type: InputEnums.PASSWORD,
    placeholder: 'Password'
  },
  {
    name: Fields.CONFIRM_PASSWORD,
    type: InputEnums.PASSWORD,
    placeholder: 'Confirm Password'
  }
];
