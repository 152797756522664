import React from 'react';
import cn from 'classnames';
import styles from './BlackBoxButton.module.scss';

interface BlackBoxButtonProps {
  onClick: (e) => void;
  btnClass?: string;
  label?: React.ReactNode;
  type?: 'button' | 'submit';
}

const BlackBoxButton = ({
  onClick,
  btnClass,
  label = 'Sign Up',
  type = 'button'
}: BlackBoxButtonProps): JSX.Element => {
  return (
    <button type={type} className={cn(styles.btn, { [btnClass]: btnClass })} onClick={onClick}>
      {label}
    </button>
  );
};

export default BlackBoxButton;
