export enum Fields {
  platform = 'platform',
  type = 'type',
  amount = 'amount',
  date = 'date'
}

export type Values = {
  [Fields.platform]: string;
  [Fields.type]: string;
  [Fields.amount]: string;
  [Fields.date]: string;
};
