import React, { memo, useCallback, useMemo, useState } from 'react';
import { getMonth, isBefore, add, format } from 'date-fns';

import { DatePicker } from 'src/components';
import { FieldNames } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';
import { PopperPlacementEnum } from 'src/components/DatePicker/DatePicker';
import styles from '../../TypeBlock.module.scss';

const DATE_FORMAT = 'dd/MM/yyyy';

type Props = {
  isViewMode: boolean;
  value: Date;
  onChange: (value: Date) => void;
  onBlur: () => void;
};

const StructureEndDate = memo<Props>(({ isViewMode, value, onChange, onBlur }) => {
  const tomorrow = useMemo(() => add(new Date(), { days: 1 }), []);
  const initialMonth = useMemo(() => getMonth(value ?? tomorrow), [value, tomorrow]);

  const [selectedMonth, setSelectedMonth] = useState<number>(initialMonth);
  const [open, setOpen] = useState(false);

  const handleDateChange = useCallback(
    (_: FieldNames, date: Date) => {
      onChange(date);

      if (getMonth(date) !== selectedMonth) setSelectedMonth(getMonth(date));
    },
    [onChange, selectedMonth]
  );

  const getClassNames = useCallback((date: Date) => (isBefore(date, tomorrow) ? [styles.disabledDay] : []), [tomorrow]);

  return (
    <div className={styles.typeBlock}>
      {isViewMode ? (
        <div className={styles.allocationViewInfo}>
          {value && (
            <React.Fragment>
              <span className={styles.allocationViewLabel}>Structure End Date</span>
              <span className={styles.allocationViewValue}>{value ? format(new Date(value), DATE_FORMAT) : null}</span>
            </React.Fragment>
          )}
        </div>
      ) : (
        <React.Fragment>
          <h3 className={styles.fieldLabelClass}>Structure End Date</h3>

          <DatePicker
            value={value}
            selectedMonth={selectedMonth}
            onSetSelectedMonth={(_, value) => setSelectedMonth(value)}
            onDateChange={handleDateChange}
            onBlur={onBlur}
            id={FieldNames.STRUCTURE_END_DATE}
            onOpenCalendar={() => setOpen(true)}
            isCalendarOpen={open}
            clearButtonLabel={value ? 'Clear Date' : null}
            popperPlacement={PopperPlacementEnum.BOTTOM_START}
            popperOffset={{ x: 0, y: 8 }}
            getClassNames={getClassNames}
            dateInputClassName={styles.dateInput}
            className={styles.datePicker}
            minDate={tomorrow}
            onCloseCalendar={(date?: Date) => {
              setOpen(false);
              setSelectedMonth(date ? getMonth(date) : initialMonth);
            }}
            onClearClick={() => {
              onChange(null);
              setOpen(false);
              setSelectedMonth(tomorrow.getMonth());
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
});

StructureEndDate.displayName = 'StructureEndDate';

export default StructureEndDate;
