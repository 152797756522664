import React, { useState, ReactNode, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import { Modal } from 'src/components';
import { TableHeaderColumns, TableColumn, TBodyCssClasses, ColumnAlignOptions } from 'src/interfaces/CustomTableShapes';
import styles from './TRow.module.scss';
import { SxProps, Theme } from '@mui/material';

const LINK_ALIGN_OPTIONS = {
  [ColumnAlignOptions.LEFT]: 'flex-start',
  [ColumnAlignOptions.CENTER]: 'center',
  [ColumnAlignOptions.RIGHT]: 'flex-end'
};

interface TRowProps {
  row: { id?: number | string; hasDetails?: boolean };
  columns: TableHeaderColumns;
  getSummaryContent: (colId: string, row) => ReactNode;
  detailsContent;
  isAsideMenuOpen: boolean;
  hasDetails?: boolean;
  classes?: TBodyCssClasses;
  guttersLeft?: number;
  guttersRight?: number;
  withModalDetails?: boolean;
  onGetDetailsPath?: (row) => string;
  onRowClick?: (row) => void;
  onSetCustomRowSx?: (row) => SxProps<Theme>;
}

const TRow = ({
  row,
  columns,
  getSummaryContent,
  detailsContent: DetailsContent,
  classes,
  guttersLeft,
  guttersRight,
  hasDetails,
  withModalDetails = false,
  isAsideMenuOpen,
  onGetDetailsPath,
  onRowClick,
  onSetCustomRowSx
}: TRowProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const onToggleCollapse = () => setOpen((prev) => !prev);

  const { customItemsCount, customWidthTotal } = useMemo(() => {
    const initialAcc = {
      customItemsCount: 0,
      customWidthTotal: 0
    };

    return columns.reduce(
      (acc, { width }) =>
        width ? { customItemsCount: acc.customItemsCount++, customWidthTotal: acc.customWidthTotal + width } : acc,
      initialAcc
    );
  }, [columns]);

  const rowDetailsPath = onGetDetailsPath ? onGetDetailsPath(row) : null;

  const onRowClickHandler = useCallback(() => {
    if (onRowClick) {
      onRowClick(row);
      return;
    }

    if (withModalDetails) onToggleCollapse();
  }, [onRowClick, row, withModalDetails]);
  return (
    <React.Fragment>
      <TableRow
        onClick={onRowClickHandler}
        sx={onSetCustomRowSx ? onSetCustomRowSx(row) : null}
        classes={{
          root: cn(styles.collapsibleRoot, {
            [styles.collapsibleRootHighlighted]: withModalDetails || !!rowDetailsPath,
            [classes?.tBodyRowClass]: classes?.tBodyRowClass
          })
        }}
      >
        {columns.map(({ id, align, width, isCheckbox }: TableColumn, idx, arr) => {
          const firstCol = idx === 0;
          const lastCol = idx === arr.length - 1;
          const currentWidth = width ? `${width}%` : `${(100 - customWidthTotal) / (arr.length - customItemsCount)}%`;

          const hasLinkButNotCheckbox = !isCheckbox && !!rowDetailsPath;
          return (
            <TableCell
              key={id}
              align={align}
              style={{
                width: currentWidth,
                paddingLeft: hasLinkButNotCheckbox ? null : firstCol && guttersLeft,
                paddingRight: hasLinkButNotCheckbox ? null : lastCol && guttersRight
              }}
              classes={{
                root: cn(styles.collapsibleCellRoot, {
                  [styles.cellLinkRoot]: hasLinkButNotCheckbox,
                  [classes?.tBodyCheckboxCellClass]: isCheckbox && classes?.tBodyCheckboxCellClass,
                  [classes?.tBodyCellClass]: classes?.tBodyCellClass
                })
              }}
            >
              {hasLinkButNotCheckbox ? (
                <Link
                  to={rowDetailsPath}
                  className={cn(styles.detailsPathLink, {
                    [classes?.detailsPathLinkClass]: classes?.detailsPathLinkClass
                  })}
                  style={{
                    justifyContent: LINK_ALIGN_OPTIONS[align],
                    paddingLeft: firstCol && guttersLeft,
                    paddingRight: lastCol && guttersRight
                  }}
                >
                  {getSummaryContent(id, row)}
                </Link>
              ) : (
                getSummaryContent(id, row)
              )}
            </TableCell>
          );
        })}
      </TableRow>

      {hasDetails && !withModalDetails && (
        <TableRow>
          <TableCell style={{ padding: 0, border: 0 }} colSpan={columns.length}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <div
                className={cn(styles.collapseContentWrapper, {
                  [classes?.detailsContentClass]: classes?.detailsContentClass
                })}
              >
                <DetailsContent {...row} />
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {hasDetails && withModalDetails && (
        <Modal
          isOpen={isOpen}
          onToggle={onToggleCollapse}
          stylesProps={{
            modal: {
              padding: isAsideMenuOpen ? '10% 0 10% 215px' : '10% 0 10% 84px',
              alignItems: 'center',
              transition: 'all 0.15s ease-in-out'
            }
          }}
        >
          <div className={styles.modalDetailsPaper}>
            <DetailsContent {...row} />
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TRow;
