import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import { includedZerosStringRegex } from 'src/helpers/regexHelpers';
import styles from './PercentageInput.module.scss';

interface PercentageInputShape {
  value?: number;
  className?: string;
  onChanged: (data: number) => void;
  onClick?: () => void;
  onBlur?: () => void;
  isAllowed?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  suffix?: string;
  thousandSeparator?: boolean;
  readOnly?: boolean;
}

const PercentageInput = ({
  value = 0,
  className,
  onChanged,
  isError,
  isAllowed,
  isDisabled,
  suffix,
  thousandSeparator = false,
  readOnly
}: PercentageInputShape): JSX.Element => {
  const [inputValue, setInputValue] = useState(value);

  const onChange = ({ value }: { value: string }) => {
    setInputValue((prev) => {
      const nonZeroCurrentValue = value.replace(includedZerosStringRegex, '');
      const prevValueIsZeroWithCurrentNonZero = prev === 0 && nonZeroCurrentValue;

      if (prevValueIsZeroWithCurrentNonZero) {
        onChanged(+nonZeroCurrentValue);
        return +nonZeroCurrentValue;
      }

      onChanged(+value);
      return +value;
    });
  };

  const withValueLimit = ({ value }) => value <= 100 && isAllowed && !value.startsWith('00');

  useEffect(() => {
    setInputValue(+value?.toFixed(2));
  }, [value]);

  return (
    <NumberFormat
      disabled={isDisabled}
      readOnly={readOnly}
      value={inputValue}
      allowNegative={false}
      thousandSeparator={thousandSeparator}
      suffix={suffix}
      decimalScale={2}
      onValueChange={onChange}
      className={cn(styles.input, { [styles.error]: isError, [className]: className })}
      isAllowed={withValueLimit}
    />
  );
};

export default PercentageInput;
