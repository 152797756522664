/* eslint-disable no-case-declarations */
import React from 'react';
import cn from 'classnames';
import { startOfDay, endOfDay, formatISO } from 'date-fns';
import { stringify } from 'query-string';
import NumberFormat from 'react-number-format';
import { CircleFlag } from 'react-circle-flags';
import { GetMarketplaceLoansParams, MarketplaceLoan } from 'src/interfaces/marketplaceReduxShapes';
import { formatDateFromJSON } from 'src/helpers/dateHelpers';
import { getCountryCode } from 'src/helpers/randomHelpers';
import { LOAN_TABLE_DATE_FORMAT, ExaloanRatings, LoanTypes } from 'src/constants/globalConstants';
import { MarketplaceColIDs, ProviderIds } from 'src/constants/marketplaceConstants';
import { formatLoanPurpose } from 'src/helpers/marketplaceHelpers';
import styles from './MarketplacePage.module.scss';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';

const { ID, ORIGINATION_DATE, LOAN_AMOUNT, INTEREST_RATE, GRADE, RELATIVE_VALUE, SECTOR, TERM, LOAN_PURPOSE } =
  MarketplaceColIDs;

export const renderSummaryContent = (
  id: MarketplaceColIDs,
  row: MarketplaceLoan,
  currencySymbol: string,
  loanPurpose: string
): JSX.Element => {
  switch (id) {
    case ID:
      return (
        <div className={styles.originatorBox}>
          <div className={styles.flagWrapper}>
            <CircleFlag
              countryCode={
                row?.source_platform_name === ProviderIds.EXALENDER
                  ? 'eu'
                  : getCountryCode(row?.country?.toLowerCase())?.toLowerCase()
              }
              onLoad={(e) => e.currentTarget.removeAttribute('title')}
              className={cn(styles.flag, 'img-fluid')}
            />
          </div>
          <span className={styles.textInfo}>{row.uniq}</span>
        </div>
      );

    case ORIGINATION_DATE:
      return (
        <span className={styles.textInfo}>{formatDateFromJSON(row.origination_date, LOAN_TABLE_DATE_FORMAT)}</span>
      );

    case LOAN_AMOUNT:
      return (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${currencySymbol} `}
          value={row?.loan_amount?.toFixed(2) || 0}
          className={styles.textInfo}
        />
      );

    case INTEREST_RATE:
      return <span className={styles.textInfo}>{(row.borrower_rate * 100).toFixed(2) + '%'}</span>;

    case GRADE:
      const scoreClassName = cn(styles.textInfo, {
        [styles.scoreA]: row.grade === ExaloanRatings.A,
        [styles.scoreB]: row.grade === ExaloanRatings.B,
        [styles.scoreC]: row.grade === ExaloanRatings.C,
        [styles.scoreD]: row.grade === ExaloanRatings.D,
        [styles.scoreE]: row.grade === ExaloanRatings.E,
        [styles.scoreF]: row.grade === ExaloanRatings.F
      });
      return <span className={scoreClassName}>{row.grade ?? ''}</span>;

    case RELATIVE_VALUE:
      return <span className={cn(styles.textInfo, styles.relativeValue)}>{row.relative_value ?? ''}</span>;

    case SECTOR:
      return row?.sector ? <span className={styles.textInfo}>{row?.sector}</span> : null;

    case TERM:
      return <span className={styles.textInfo}>{Math.round(row.term_original * 100) / 100}</span>;

    case LOAN_PURPOSE:
      const purposeClassName = cn(styles.textInfo, {
        [styles.loanPurpose]: row.loanType === LoanTypes.CONSUMER,
        [styles.smeLoanPurpose]: row.loanType === LoanTypes.SME
      });

      const loanPurposeLengthLimit = row.loanType === LoanTypes.CONSUMER ? 30 : 15;

      return <span className={purposeClassName}>{formatLoanPurpose(loanPurpose, loanPurposeLengthLimit)}</span>;

    default:
      return <></>;
  }
};

export const getLoanPurpose = ({ loanData, loanTypes, smePurposes }): string => {
  if (loanData.loanType === LoanTypes.CONSUMER) {
    return loanTypes.find((loanType) => loanType.value === loanData.loan_purpose)?.name;
  }

  return smePurposes.find((loanType) => loanType.value === loanData.loan_purpose)?.name;
};

export const getExportLoansUrl = (
  reqParams: GetMarketplaceLoansParams,
  currency: string,
  fileType: ExportFileTypes
): string => {
  const query = {
    currency,
    file_type: fileType,
    loan_type: reqParams?.type,
    origination_date_from: formatISO(startOfDay(reqParams.date.startDate)),
    origination_date_end: formatISO(endOfDay(reqParams.date.endDate)),
    platforms: reqParams?.platform?.join('|')
  };

  return `export/loans?${stringify(query, {
    skipEmptyString: true,
    skipNull: true
  })}`;
};
