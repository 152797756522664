import React from 'react';
import cn from 'classnames';
import { ShouldRender } from '../ShouldRender';
import { Field, ErrorMessage, useField } from 'formik';
import { ReactSVG } from 'react-svg';
import { CustomFieldProps } from './model';
import checkMark from 'src/assets/images/icons/checkMark.svg';
import styles from './FormikCustomField.module.scss';

const FormikCustomField = ({
  label,
  classes,
  isPassword,
  autoComplete,
  isError,
  onClearRequestError,
  required,
  ...props
}: CustomFieldProps): JSX.Element => {
  const [, meta] = useField(props);

  return (
    <div className={cn(styles.fieldWrapper, { [classes?.wrapperClassName]: classes?.wrapperClassName })}>
      <ShouldRender should={!!label}>
        <label
          htmlFor={props.id ?? props.name}
          className={cn(styles.label, { [classes?.labelClassName]: classes?.labelClassName })}
        >
          {label}
        </label>
      </ShouldRender>

      <Field
        {...props}
        className={cn(styles.input, {
          [styles.invalidInputData]: (meta.touched && meta.error) || isError,
          [classes?.inputClassName]: classes?.inputClassName
        })}
        autoComplete={autoComplete ? 'on' : 'off'}
        onClick={typeof onClearRequestError === 'function' ? onClearRequestError : null}
        placeholder={`${props.placeholder} ${required ? '*' : ''}`}
      />

      <ShouldRender should={isPassword && !!meta.value && !meta.error}>
        <ReactSVG src={checkMark} className={styles.validPasswordMark} />
      </ShouldRender>

      <ErrorMessage
        name={props.id ?? props.name}
        render={(msg) => (
          <div className={cn(styles.errorMessage, { [classes?.errorClass]: classes?.errorClass })}>{msg}</div>
        )}
      />
    </div>
  );
};

export default FormikCustomField;
