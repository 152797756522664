import React from 'react';
import cn from 'classnames';
import logo from 'src/assets/images/logo-no-title.png';
import { header } from 'src/assets/locales/en/common.json';
import styles from './NavLogo.module.scss';

interface NavLogoProps {
  isPrivatePage: boolean;
  onToggleAside?: () => void;
}

const NavLogo = ({ isPrivatePage, onToggleAside }: NavLogoProps): JSX.Element => (
  <div className={cn(styles.contentWrapper, { [styles.privatePageBehavior]: isPrivatePage })} onClick={onToggleAside}>
    <div className={styles.logoWrapper}>
      <img src={logo} alt={header.logoAlt} className={cn(styles.logo, 'img-fluid')} />
    </div>

    <h1 className={styles.logoTitle}>{header.logoTitle}</h1>
  </div>
);

export default NavLogo;
