import React from 'react';
import { v4 as uuid } from 'uuid';
import NumberFormat from 'react-number-format';
import { formatDateFromJSON } from 'src/helpers/dateHelpers';
import { LOAN_TABLE_DATE_FORMAT } from 'src/constants/globalConstants';
import { CashflowColIds, CashflowRow } from './constants';
import { ICashflowData } from 'src/interfaces';
import styles from './Cashflow.module.scss';

const {
  planned_payment_date,
  planned_principal_payment,
  planned_interest_payment,
  planned_total_payment,
  actual_payment_date,
  actual_interest_payment,
  actual_principal_payment,
  actual_total_payment
} = CashflowColIds;

export const renderRepaymentsRowContent = (colId: CashflowColIds, row: CashflowRow, currency: string): JSX.Element => {
  const value = row?.[colId] ?? null;

  switch (colId) {
    case planned_payment_date:
    case actual_payment_date:
      return value ? (
        <span className={styles.tableCellText}>{formatDateFromJSON(value as string, LOAN_TABLE_DATE_FORMAT, '-')}</span>
      ) : null;

    case planned_principal_payment:
    case planned_interest_payment:
    case planned_total_payment:
    case actual_interest_payment:
    case actual_principal_payment:
    case actual_total_payment:
      return value ? (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${currency} `}
          value={value}
          className={styles.tableCellText}
        />
      ) : null;

    default:
      return <span className={styles.tableCellText}>{value}</span>;
  }
};

export const getRepaymentsRowData = (repaymentsData: ICashflowData[]): CashflowRow[] => {
  return repaymentsData?.map(
    ({
      planned_payment_date,
      planned_principal_payment,
      planned_interest_payment,

      actual_payment_date,
      actual_interest_payment,
      actual_principal_payment
    }) => ({
      id: uuid(),
      [CashflowColIds.planned_payment_date]: planned_payment_date ?? null,
      [CashflowColIds.planned_interest_payment]: planned_interest_payment ? planned_interest_payment?.toFixed(2) : null,
      [CashflowColIds.planned_principal_payment]: planned_principal_payment
        ? planned_principal_payment?.toFixed(2)
        : null,
      [CashflowColIds.planned_total_payment]:
        planned_interest_payment + planned_principal_payment
          ? (planned_interest_payment + planned_principal_payment)?.toFixed(2)
          : null,

      [CashflowColIds.actual_payment_date]: actual_payment_date ?? null,
      [CashflowColIds.actual_principal_payment]: actual_principal_payment ? actual_principal_payment?.toFixed(2) : null,
      [CashflowColIds.actual_interest_payment]: actual_interest_payment ? actual_interest_payment?.toFixed(2) : null,
      [CashflowColIds.actual_total_payment]:
        actual_interest_payment + actual_principal_payment
          ? (actual_interest_payment + actual_principal_payment)?.toFixed(2)
          : null
    })
  );
};
