import React from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import styles from './CheckboxIcons.module.scss';
import checkMark from 'src/assets/images/icons/checkbox_checkmark.svg';

interface UncheckedProps {
  uncheckedBoxClass?: string;
}

export const CheckboxIconUnchecked = ({ uncheckedBoxClass }: UncheckedProps): JSX.Element => (
  <div className={cn(styles.box, { [uncheckedBoxClass]: uncheckedBoxClass })} />
);

interface CheckedProps {
  checkedBoxClass?: string;
  checkedIconClass?: string;
}

export const CheckboxIconChecked = ({ checkedBoxClass, checkedIconClass }: CheckedProps): JSX.Element => {
  return (
    <div className={cn(styles.boxChecked, { [checkedBoxClass]: checkedBoxClass })}>
      <ReactSVG src={checkMark} className={cn(styles.checkMarkIcon, { [checkedIconClass]: checkedIconClass })} />
    </div>
  );
};
