import React, { useMemo, useState, memo, FC } from 'react';
import cn from 'classnames';
import { getFormattedDateFromTimeStrByPeriod, statisticInvestmentSerializer } from 'src/helpers/statisticsHelpers';
import { ChartHeader, StatisticsColumnChart, StatementBox } from 'src/components';
import { GroupBy } from 'src/constants/statisticsConstants';

interface TotalInvestmentVolumeProps {
  data: Record<string, number>;
  currency?: string;
  wrapperClass?: string;
}

const TotalInvestmentVolume: FC<TotalInvestmentVolumeProps> = memo(({ data, currency, wrapperClass }): JSX.Element => {
  const [type, setType] = useState(GroupBy.DAY);

  const chartData = useMemo(() => {
    const totalInvestment: number = Object.values(data).reduce((a, b) => a + b, 0);

    const groups = Object.entries(data).reduce((acc, [dateString, value]) => {
      const key = getFormattedDateFromTimeStrByPeriod(type, dateString);

      if (!acc[key]) acc[key] = 0;
      if (acc[key] < value) acc[key] = value;

      return acc;
    }, {});

    return statisticInvestmentSerializer(groups, totalInvestment);
  }, [data, type]);

  return (
    <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
      <ChartHeader title="Total Investment Volume" type={type} onTypeChange={setType} />

      <StatisticsColumnChart
        id="total-investment"
        data={chartData}
        type={type}
        xLabelType="direct"
        yLabelType="money"
        currency={currency}
      />
    </StatementBox>
  );
});

TotalInvestmentVolume.displayName = 'TotalInvestmentVolume';

export default TotalInvestmentVolume;
