import React from 'react';
import cn from 'classnames';
import styles from '../NavAccountBlock.module.scss';

interface NationalityProps {
  icon: string;
  iconAlt: string;
}

const Nationality = ({ icon, iconAlt }: NationalityProps): JSX.Element => {
  return (
    <div className={styles.nationalityBlock}>
      <img src={icon} alt={iconAlt} className={cn('img-fluid', styles.nationalityImg)} />
    </div>
  );
};

export default Nationality;
