import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { baseReducerSelector } from 'src/redux/selectors';
import { onChangeAsideOpen } from 'src/redux/actions/baseActions';
import { Header, AsideNavigationMenu } from 'src/components';
import styles from './MainLayout.module.scss';

interface MainLayoutProps {
  children?: React.ReactNode;
  isCentered?: boolean;
  isPrivateInnerFull?: boolean;
  isPrivate?: boolean;
  isEmptySkeleton?: boolean;
  isInnerPadded?: boolean;
  classes?: {
    contentPaddingClass?: string;
    privateInnerClass?: string;
    privateContentClass?: string;
  };
}

const MainLayout = ({
  children,
  isPrivate = true,
  isCentered = true,
  isInnerPadded = true,
  isEmptySkeleton = false,
  isPrivateInnerFull,
  classes
}: MainLayoutProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isAsideMenuOpen } = useSelector(baseReducerSelector);

  const handleToggleAsideMenu = () => dispatch(onChangeAsideOpen());

  const getInnerContent = useCallback(() => {
    if (!isEmptySkeleton) {
      return (
        <div
          className={cn(styles.privateInner, {
            [styles.privateInnerFull]: isPrivateInnerFull,
            [styles.innerPadding]: isInnerPadded,
            [classes?.privateInnerClass]: classes?.privateInnerClass
          })}
        >
          <div className={cn(styles.contentPadding, { [classes?.contentPaddingClass]: classes?.contentPaddingClass })}>
            <div className={styles.contentWrapper}>{children}</div>
          </div>
        </div>
      );
    }

    return <div className={cn({ [styles.innerPadding]: isInnerPadded })}>{children}</div>;
  }, [children, classes, isEmptySkeleton, isInnerPadded, isPrivateInnerFull]);

  return (
    <div className={styles.pageWrapper}>
      <Header onToggleAside={handleToggleAsideMenu} isPrivatePage={isPrivate} />

      {isPrivate ? (
        <div className={styles.privateContentWrapper}>
          <AsideNavigationMenu isOpen={isAsideMenuOpen} />

          <main className={cn(styles.privateContent, { [classes?.privateContentClass]: classes?.privateContentClass })}>
            {getInnerContent()}
          </main>
        </div>
      ) : (
        <div className={styles.publicContentWrapper}>
          <main className={cn(styles.publicContent, { [styles.publicCentered]: isCentered })}>{children}</main>
        </div>
      )}
    </div>
  );
};

export default MainLayout;
