import React, { FC } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ShouldRender } from 'src/components';
import { DEFAULT_TRANSITION_TIMEOUT } from 'src/constants/globalConstants';
import Fade from 'src/components/reactTransitions/FadeTransition.module.css';

type Props = {
  isLoading: boolean;
  children: React.ReactElement | (() => React.ReactElement);
  loadingContent: React.ReactElement | (() => React.ReactElement);
  timeout?: number;
};

const WithLoading: FC<Props> = ({ isLoading, children, loadingContent, timeout = DEFAULT_TRANSITION_TIMEOUT }) => {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={isLoading ? 'loader' : 'content'} timeout={timeout} classNames={{ ...Fade }}>
        <>
          <ShouldRender should={isLoading}>{loadingContent}</ShouldRender>
          <ShouldRender should={!isLoading}>{children}</ShouldRender>
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default React.memo(WithLoading);
