import {
  GetReports,
  IClientReport,
  PutReports,
  ReportsLoadMoreRequestParams,
  ReportsRequestParams,
  GetMoreReportsByPlatform,
  PutMoreReportsPayload,
  PutMoreReportsByPlatform,
  GetTransactionsAndReportsAction,
  GetTransactionsAndReportsPayload,
  PutTransactionsAndReportsPayload,
  PutTransactionsAndReportsAction,
  PutMoreTransactionsPayload,
  PutMoreTransactionsAction,
  GetMoreTransactionsPayload,
  GetMoreTransactionsAction,
  IClientTransaction,
  PutTransactionsAction,
  TAddTransactionPayload,
  TAddTransactionAction,
  TDeleteTransactionAction,
  TDeleteTransactionPayload
} from 'src/interfaces/reportsReduxShapes';
import { reportTypes } from '../types';

export const getReports = (
  reqParams: ReportsRequestParams,
  onSuccess?: () => void,
  onError?: (err) => void
): GetReports => ({ type: reportTypes.GET_REPORTS, payload: { reqParams, onSuccess, onError } });

export const putReports = (payload: IClientReport[]): PutReports => ({ type: reportTypes.PUT_REPORTS, payload });

export const getMoreCashflowByPlatform = (
  reqParams: ReportsLoadMoreRequestParams,
  onSuccess?: () => void,
  onError?: (err) => void
): GetMoreReportsByPlatform => ({
  type: reportTypes.GET_MORE_REPORTS_BY_PLATFORM,
  payload: { reqParams, onSuccess, onError }
});

export const putMoreReportsByPlatform = (payload: PutMoreReportsPayload): PutMoreReportsByPlatform => ({
  type: reportTypes.PUT_MORE_REPORTS_BY_PLATFORM,
  payload
});

export const getTransactions = (payload: GetTransactionsAndReportsPayload): GetTransactionsAndReportsAction => ({
  type: reportTypes.GET_TRANSACTIONS,
  payload
});

export const putTransactions = (payload: IClientTransaction[]): PutTransactionsAction => ({
  type: reportTypes.PUT_TRANSACTIONS,
  payload
});

export const getMoreTransactionsByPlatform = (payload: GetMoreTransactionsPayload): GetMoreTransactionsAction => ({
  type: reportTypes.GET_MORE_TRANSACTIONS_BY_PLATFORM,
  payload
});

export const putMoreTransactionsByPlatform = (payload: PutMoreTransactionsPayload): PutMoreTransactionsAction => ({
  type: reportTypes.PUT_MORE_TRANSACTIONS_BY_PLATFORM,
  payload
});

export const getTransactionsAndReports = (
  payload: GetTransactionsAndReportsPayload
): GetTransactionsAndReportsAction => ({
  type: reportTypes.GET_TRANSACTIONS_AND_REPORTS,
  payload
});

export const putTransactionsAndReports = (
  payload: PutTransactionsAndReportsPayload
): PutTransactionsAndReportsAction => ({
  type: reportTypes.PUT_TRANSACTIONS_AND_REPORTS,
  payload
});

export const addTransaction = (payload: TAddTransactionPayload): TAddTransactionAction => ({
  type: reportTypes.ADD_TRANSACTION,
  payload
});

export const deleteTransaction = (payload: TDeleteTransactionPayload): TDeleteTransactionAction => ({
  type: reportTypes.DELETE_TRANSACTION,
  payload
});
