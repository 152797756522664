import React, { useState } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useDispatch, useSelector } from 'react-redux';
import { putUser, tempSaveUser } from 'src/redux/actions/authActions';
import { authSelector } from 'src/redux/selectors';
import {
  MainLayout,
  FormikCustomField as CustomField,
  AuthTitleBlock,
  RequestErrorMessage,
  ShouldRender,
  ConfirmButton
} from 'src/components';
import { PAGES } from 'src/constants/pages';
import { signInHelper, setAuthToken } from 'src/helpers/authHelpers';
import { validationSchema, validate, initialValues, defaultReqError } from './helpers';
import styles from './LoginPage.module.scss';

export interface Values {
  email: string;
  password: string;
}

export enum ErrorMessages {
  UserNotFoundException = "Sorry, we can't find an account with that email. Try again",
  NotAuthorizedException = 'The username or password you entered is incorrect',
  UserNotConfirmedException = 'Please confirm your email first'
}

export enum ChallengeNames {
  forceChangePassword = 'FORCE_CHANGE_PASSWORD',
  newPasswordRequired = 'NEW_PASSWORD_REQUIRED'
}

export type CognitoError = { code: string; name: string; message: string };

const LoginPage = (): JSX.Element => {
  const { push, replace } = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(authSelector);

  const [errorMessage, setErrorMessage] = useState('');
  const onClearRequestError = () => setErrorMessage('');

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isPrivate={false}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleBlock}>
          <AuthTitleBlock
            title="Log in to your account"
            description="Are you a new investor? Register"
            link={{ linkLabel: 'here', path: PAGES.SIGN_UP }}
            classes={{ blockClass: styles.innerTitleBlock }}
          />

          <ShouldRender should={!!errorMessage}>
            <RequestErrorMessage msg={errorMessage ?? defaultReqError} />
          </ShouldRender>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={validate}
          onSubmit={async ({ email, password }: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
            try {
              const user = await signInHelper(email, password);
              if (
                user?.challengeName === ChallengeNames.forceChangePassword ||
                user?.challengeName === ChallengeNames.newPasswordRequired
              ) {
                // If user has to force change password
                dispatch(tempSaveUser(user));
                return push(PAGES.UPDATE_PASSWORD);
              }

              setAuthToken(user.signInUserSession.accessToken.jwtToken);
              dispatch(putUser(user));
              replace(PAGES.DASHBOARD);
              resetForm();
            } catch (error) {
              const err = error as CognitoError;

              setErrorMessage(ErrorMessages[err.code] ?? err?.message);
            }
            setSubmitting(false);
          }}
        >
          {({ errors, values }) => {
            const isConfirmDisabled = some(values, (value) => !value) || !isEmpty(errors);

            return (
              <Form className={styles.form}>
                <CustomField
                  autoComplete
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                  classes={{ wrapperClassName: styles.emailFieldWrapper }}
                  onClearRequestError={onClearRequestError}
                  isError={!!errorMessage}
                />

                <CustomField
                  autoComplete
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Password"
                  classes={{ wrapperClassName: styles.passwordFieldWrapper }}
                  onClearRequestError={onClearRequestError}
                  isError={!!errorMessage}
                  isPassword
                />

                <ConfirmButton type="submit" isDisabled={isConfirmDisabled} btnClass={styles.confirmBtn} />
              </Form>
            );
          }}
        </Formik>

        <Link to={PAGES.PASSWORD_RECOVERY} className={styles.passwordLink}>
          Forgot Password?
        </Link>
      </div>
    </MainLayout>
  );
};

export default LoginPage;
