import React from 'react';
import cn from 'classnames';
import styles from './ButtonNext.module.scss';

interface ButtonNextProps {
  type: 'submit' | 'button' | 'reset';
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  beforeImg?: string;
  btnClass?: string;
  beforeImgClass?: string;
}

const ButtonNext = ({
  onClick,
  type,
  isDisabled,
  btnClass,
  beforeImg,
  beforeImgClass,
  label = 'Next >'
}: ButtonNextProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={cn(styles.bnt, { [btnClass]: !!btnClass, [styles.btnDisabled]: isDisabled })}
      disabled={isDisabled}
    >
      {beforeImg && (
        <img
          src={beforeImg}
          alt={label}
          className={cn(styles.beforeImg, 'img-fluid', { [beforeImgClass]: beforeImgClass })}
        />
      )}

      {label}
    </button>
  );
};

export default ButtonNext;
