import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  getAllocationDetails,
  clearAllocationDetails,
  getLoanConstraints,
  clearLoanConstraints
} from 'src/redux/actions/portfolioBuilderActions';
import { getRelativeValuesList } from 'src/constants/relativeValueFilters';
import { LoanTypes } from 'src/constants/globalConstants';
import {
  getGrades,
  getConsumerPurposes,
  getSmePurposes,
  getSmeSectors,
  getPlatforms
} from 'src/redux/actions/baseActions';
import {
  allocationDetailsSelector,
  builderConstraintsSelector,
  baseFiltersSelector,
  basePlatformsSelector
} from 'src/redux/selectors';
import { AllocationSummaryReq, CriteriaFilters, CriteriaNames, Platform, ServerToViewAllocation } from './model';
import { DEFAULT_CRITERIA_PERCENTAGE, PLATFORMS_LIST } from './constants';
import { PlatformShape } from 'src/interfaces';

export const useAllocationDetails = (): ServerToViewAllocation => {
  const params = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const allocationDetails = useSelector(allocationDetailsSelector);

  useEffect(() => {
    if (isNil(allocationDetails) && params?.id) dispatch(getAllocationDetails(params.id));

    return () => {
      if (allocationDetails) dispatch(clearAllocationDetails());
    };
  }, [dispatch, params?.id, allocationDetails]);

  return allocationDetails;
};

type ReturnBooleanParameterHook = [
  autoApproval: boolean,
  setAutoApproval: React.Dispatch<React.SetStateAction<boolean>>
];

export const useAutoApproval = (allocationDetails: ServerToViewAllocation): ReturnBooleanParameterHook => {
  const [autoApproval, setAutoApproval] = useState(false);

  useEffect(() => {
    if (allocationDetails) setAutoApproval(!!allocationDetails?.autoApproval);
  }, [allocationDetails]);

  return [autoApproval, setAutoApproval];
};

export const useCriteriaFilters = (
  allocationType: LoanTypes,
  setAllocationType: React.Dispatch<React.SetStateAction<LoanTypes>>,
  allocationDetails: ServerToViewAllocation,
  isNewMode: boolean
): CriteriaFilters => {
  const dispatch = useDispatch();

  const { grades, loanTypes, smePurposes, sectors } = useSelector(baseFiltersSelector);
  const constraints = useSelector(builderConstraintsSelector);

  const serverPlatforms = useSelector(basePlatformsSelector);
  const platformCurrencies = useMemo(
    () => uniq(serverPlatforms.map(({ currency }) => currency)).join('|'),
    [serverPlatforms]
  );

  const isSME = useMemo(() => allocationType === LoanTypes.SME, [allocationType]);

  useEffect(() => {
    dispatch(getGrades());

    return () => {
      dispatch(clearLoanConstraints());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!allocationType) return;

    if (isNewMode || allocationType === LoanTypes.CONSUMER) dispatch(getConsumerPurposes());
    if (isNewMode || allocationType === LoanTypes.SME) {
      dispatch(getSmePurposes());
      dispatch(getSmeSectors());
    }
  }, [dispatch, allocationType, isNewMode]);

  const cachedSummary = useRef<AllocationSummaryReq>();

  useEffect(() => {
    if (!platformCurrencies || !allocationType) return;

    const constraintsReq = { currency: platformCurrencies, type: allocationType };
    if (isEqual(cachedSummary.current?.currency, constraintsReq?.currency)) return;

    dispatch(getLoanConstraints(constraintsReq));

    cachedSummary.current = constraintsReq;
  }, [dispatch, allocationType, platformCurrencies]);

  useEffect(() => {
    if (!allocationDetails) return;

    setAllocationType(allocationDetails.loanType.value);
  }, [allocationDetails, setAllocationType]);

  return {
    ...(isSME ? { smePurposes, sectors } : { loanTypes }),
    grades,
    constraints,
    relative: getRelativeValuesList().map((item) => omit(item, 'id'))
  };
};

type UsePlatforms = { platformNames: string[]; serverPlatforms: PlatformShape[] };

export const usePlatforms = (loanType: LoanTypes): UsePlatforms => {
  const dispatch = useDispatch();
  const serverPlatforms = useSelector(basePlatformsSelector);
  const platformNames = useMemo(() => serverPlatforms.map(({ name }) => name), [serverPlatforms]);

  useEffect(() => {
    if (!loanType) return;
    dispatch(getPlatforms(loanType));
  }, [dispatch, loanType]);

  return { platformNames, serverPlatforms };
};

type UseManagePlatforms = [platforms: Platform[], setPlatforms: React.Dispatch<React.SetStateAction<Platform[]>>];

export const useManagePlatforms = (serverPlatforms: PlatformShape[]): UseManagePlatforms => {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    if (isEmpty(serverPlatforms)) return;
    const platformNames = serverPlatforms.map((p) => p.name);

    setPlatforms((prev) =>
      (isEmpty(prev)
        ? PLATFORMS_LIST.map((item) => ({
            ...item,
            currency: serverPlatforms.find((p) => p.name === item.id)?.currency
          }))
        : prev
      ).filter(({ id }) => platformNames.includes(id))
    );
  }, [serverPlatforms]);

  return [platforms, setPlatforms];
};

export const useChangePlatformsLoanType = (
  platformNames: string[],
  setInvestedPlatforms: React.Dispatch<React.SetStateAction<Platform[]>>,
  isNewPage: boolean
): void => {
  useEffect(() => {
    if (!isNewPage || isEmpty(platformNames)) return;

    setInvestedPlatforms([]);
  }, [isNewPage, platformNames, setInvestedPlatforms]);
};

export const useResetPurposeValueOnTypeChange = (
  type: LoanTypes,
  setInvestedPlatforms: React.Dispatch<React.SetStateAction<Platform[]>>,
  isNewPage: boolean
): void => {
  useEffect(() => {
    if (!type || !isNewPage) return;

    const isSME = type === LoanTypes.SME;

    setInvestedPlatforms((prev) =>
      prev.map((p) => {
        if (isSME && p.criteriaSelected.includes(CriteriaNames.LOAN_PURPOSES)) {
          return {
            ...p,
            loanTypes: p.loanTypes.map((purpose) => ({ ...purpose, percentage: DEFAULT_CRITERIA_PERCENTAGE }))
          };
        }

        if (!p.criteriaSelected.includes(CriteriaNames.SME_PURPOSES)) {
          return {
            ...p,
            smePurposes: p.smePurposes.map((purpose) => ({ ...purpose, percentage: DEFAULT_CRITERIA_PERCENTAGE }))
          };
        }

        return p;
      })
    );
  }, [type, isNewPage, setInvestedPlatforms]);
};
