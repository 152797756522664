export const chartColors = [
  '#F47853',
  '#E4BA89',
  '#9C9C9C',
  '#8A7C5A',
  '#8A9C5A',
  '#74A093',
  '#589BA4',
  '#4FA5B0',
  '#177D84'
];

export const pieCharColors = [
  '#24454C',
  '#077186',
  '#1093A7',
  '#589BA4',
  '#4FA5B0',
  '#8BC8CF',
  '#B0DADE',
  '#DEF2F3',
  '#162c30',
  '#05505e',
  '#16c6e0',
  '#84c6cf',
  '#528187',
  '#9be2eb',
  '#538b91',
  '#cbf0f2'
];
