import React from 'react';
import cn from 'classnames';
import styles from './RequestErrorMessage.module.scss';

interface Props {
  msg: string;
  customClass?: string;
}

const RequestErrorMessage = ({ msg, customClass }: Props): JSX.Element =>
  msg ? <span className={cn(styles.errorMessage, { [customClass]: !!customClass })}>{msg}</span> : null;

export default RequestErrorMessage;
