import { baseTypes } from '../types';
import * as Shapes from 'src/interfaces/baseReduxShapes';
import { LoanTypes } from 'src/constants/globalConstants';
import { LoanTypeShape } from 'src/interfaces';

export const getLoanFilters = (): { type: string } => ({
  type: baseTypes.GET_ALL_LOAN_FILTERS
});

export const putLoanFilters = (filters: Shapes.FiltersShape): Shapes.PutLoanFiltersAction => ({
  type: baseTypes.PUT_ALL_LOAN_FILTERS,
  payload: filters
});

export const getLoansSummary = (
  type: LoanTypes,
  currency: string,
  onSuccess?: (data) => void
): Shapes.GetLoansSummary => ({
  type: baseTypes.GET_LOANS_SUMMARY,
  payload: { type, currency, onSuccess }
});

export const putLoansSummary = (summary: Shapes.SummaryShape): Shapes.PutLoansSummaryAction => ({
  type: baseTypes.PUT_LOANS_SUMMARY,
  payload: summary
});

export const getCurrencies = (): { type: string } => ({
  type: baseTypes.GET_CURRENCIES
});

export const putCurrencies = (currencies: Array<string>): { type: string; payload: Array<string> } => ({
  type: baseTypes.PUT_CURRENCIES,
  payload: currencies
});

export const getRegions = (): { type: string } => ({
  type: baseTypes.GET_REGIONS
});

export const putRegions = (regions: Array<Shapes.RegionShape>): Shapes.PutRegions => ({
  type: baseTypes.PUT_REGIONS,
  payload: regions
});

export const getGrades = (): { type: string } => ({
  type: baseTypes.GET_GRADES
});

export const putGrades = (grades: Array<Shapes.GradeShape>): Shapes.PutGrades => ({
  type: baseTypes.PUT_GRADES,
  payload: grades
});

export const getConsumerPurposes = (): { type: string } => ({
  type: baseTypes.GET_CONSUMER_PURPOSES
});

export const putConsumerPurposes = (purposes: Array<LoanTypeShape>): Shapes.PutPurposes => ({
  type: baseTypes.PUT_CONSUMER_PURPOSES,
  payload: purposes
});

export const getSmePurposes = (): { type: string } => ({
  type: baseTypes.GET_SME_PURPOSES
});

export const putSmePurposes = (purposes: Array<LoanTypeShape>): Shapes.PutPurposes => ({
  type: baseTypes.PUT_SME_PURPOSES,
  payload: purposes
});

export const getSmeSectors = (): { type: string } => ({
  type: baseTypes.GET_SME_SECTORS
});

export const putSmeSectors = (purposes: Array<LoanTypeShape>): Shapes.PutPurposes => ({
  type: baseTypes.PUT_SME_SECTORS,
  payload: purposes
});

export const getPlatforms = (
  loanType?: string,
  onSuccess?: (data: Array<Shapes.PlatformShape>) => void
): Shapes.GetPlatforms => ({
  type: baseTypes.GET_PLATFORMS,
  payload: { loanType, onSuccess }
});

export const putPlatforms = (platforms: Array<Shapes.PlatformShape>): Shapes.PutPlatforms => ({
  type: baseTypes.PUT_PLATFORMS,
  payload: platforms
});

export const onChangeAsideOpen = (): { type: string } => ({
  type: baseTypes.HANDLE_CHANGE_ASIDE_MENU
});

export const onPresetAllocationId = (id: string) => ({
  type: baseTypes.SET_PRESELECTED_ALLOCATION_ID,
  payload: { id }
});
