import { marketplaceTypes, authActionTypes } from '../types';
import { MarketplaceReducer } from 'src/interfaces/marketplaceReduxShapes';
import { selectCurrencyOptions } from 'src/constants/globalConstants';
import { GET_LOANS_DEFAULT_PARAMS } from 'src/constants/marketplaceConstants';

const initialState = {
  loansData: { total: 0, loans: [], hasMore: true },
  currency: selectCurrencyOptions[1],
  reqParams: GET_LOANS_DEFAULT_PARAMS
};

const LIMIT_PER_PAGE = GET_LOANS_DEFAULT_PARAMS.limit;

export default (state = initialState, { type, payload }: { type: string; payload }): MarketplaceReducer => {
  switch (type) {
    case marketplaceTypes.PUT_MARKETPLACE_LOANS_DATA:
      return {
        ...state,
        loansData: {
          total: payload.total,
          hasMore: [...payload.data].length % LIMIT_PER_PAGE === 0,
          loans: [...payload.data]
        },
        reqParams: payload.reqParams
      };

    case marketplaceTypes.PUT_LOAD_MORE_MARKETPLACE_LOANS_DATA:
      return {
        ...state,
        loansData: {
          total: payload.total,
          hasMore: [...state.loansData.loans, ...payload.data].length % LIMIT_PER_PAGE === 0,
          loans: [...state.loansData.loans, ...payload.data]
        },
        reqParams: payload.reqParams
      };

    case marketplaceTypes.UPDATE_CURRENCY:
      return {
        ...state,
        reqParams: {
          ...state.reqParams,
          currency: payload
        },
        currency: payload
      };

    case marketplaceTypes.CLEAR_LOANS:
      return {
        ...state,
        loansData: { total: 0, loans: [], hasMore: false }
      };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
