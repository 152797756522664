import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencySelector } from 'src/redux/selectors';
import { useUserValues } from 'src/hooks';
import { CurrencySelect } from 'src/components';
import { header } from 'src/assets/locales/en/common.json';
import nationalityIcon from 'src/assets/images/mock/germany.png';
import { Nationality, Notifications, Avatar, ProfileName, ProfileDropdown } from './components';
import { selectCurrencyOptions } from 'src/constants/globalConstants';
import { updateCurrency } from 'src/redux/actions/marketplaceActions';
import styles from './NavAccountBlock.module.scss';

const NavAccountBlock = (): JSX.Element => {
  const dispatch = useDispatch();
  const currency = useSelector(currencySelector);
  const { userRole, profileName } = useUserValues();

  const onCurrencyChange = (currency) => dispatch(updateCurrency(currency));

  return (
    <div className={styles.wrapper}>
      <Nationality icon={nationalityIcon} iconAlt={header.nationalityAlt} />

      <div className={styles.currencySelectWrapper}>
        <CurrencySelect
          name="currencySelectName"
          value={currency}
          onChange={onCurrencyChange}
          options={selectCurrencyOptions}
          minMenuHeight={100}
          maxMenuHeight={500}
        />
      </div>

      <Notifications />
      <Avatar />
      <ProfileName profileName={profileName} />
      <ProfileDropdown profileName={userRole} />
    </div>
  );
};

export default NavAccountBlock;
