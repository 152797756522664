import axios, { AxiosResponse } from 'axios';
import endpoints from 'src/constants/apiEndpoints';

export const getLoanTypes = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_TYPES);
};

export const getSmePurposes = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.SME_LOAN_PURPOSES);
};

export const getLoanSectors = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.SME_LOAN_SECTORS);
};

export const getSmeSectors = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_SECTORS);
};

export const getFiltersSummary = (query: string): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_FILTERS_SUMMARY(query));
};

export const getCountries = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_FILTERS_COUNTRY);
};

export const getFiltersPlatform = (type?: string): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_FILTERS_PLATFORM(type));
};

export const getFiltersGrade = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_FILTERS_GRADE);
};

export const getCurrenciesHelper = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.CURRENCIES_URL);
};

export const getRegionsHelper = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.REGIONS_URL);
};
