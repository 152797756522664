import React, { useCallback } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearAuthToken, signOutUserHelper } from 'src/helpers/authHelpers';
import { signOutUser } from 'src/redux/actions/authActions';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { authorizationError } from 'src/redux/actions/authActions';
import { authSelector } from 'src/redux/selectors';
import Routes from './Routes';
import { store } from 'src/redux/store';
import { useLogoutByTimer, useRefreshAuthentication } from 'src/hooks';
import { PAGES } from './constants/pages';

Amplify.configure(awsconfig);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error?.response?.status === 401) store.dispatch(authorizationError());
    throw error;
  }
);

const App = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, isAuthenticating } = useSelector(authSelector);

  const onLogoutWithRedirect = useCallback(async () => {
    await signOutUserHelper();
    clearAuthToken();
    dispatch(signOutUser());
    push(PAGES.SIGN_IN);
  }, [dispatch, push]);

  useLogoutByTimer(onLogoutWithRedirect);
  useRefreshAuthentication(onLogoutWithRedirect);

  return !isAuthenticating && <Routes isAuth={isAuthenticated} />;
};

export default App;
