import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { startOfDay, endOfDay } from 'date-fns';
import endpoints from 'src/constants/apiEndpoints';
import * as Shapes from 'src/interfaces/investmentsReduxShapes';

export const getInvestmentsLoansHelper = (
  reqParams: Shapes.GetInvestmentsLoansParams
): Promise<AxiosResponse<{ data: Shapes.GetInvestmentsLoansResponse }>> => {
  const query = {
    limit: reqParams?.limit,
    page: reqParams?.page,
    currency: reqParams?.currency,
    sort: [reqParams?.sort?.type, reqParams?.sort?.order],
    funded_date_from: startOfDay(reqParams?.date?.startDate).toString(),
    funded_date_end: endOfDay(reqParams?.date?.endDate).toString(),
    statuses: reqParams?.statuses,
    allocations: reqParams?.allocations,
    relative: reqParams?.relative,
    grades: reqParams?.grade,
    platforms: reqParams?.platform
  };

  return axios.get(
    endpoints.GET_MY_INVESTMENTS(
      stringify(query, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
        skipEmptyString: true,
        skipNull: true
      })
    )
  );
};

export const getOutstandingBidsHelper = (
  reqParams: Shapes.GetInvestmentsLoansParams
): Promise<AxiosResponse<{ data: Shapes.GetInvestmentsLoansResponse }>> => {
  const query = {
    allocations: reqParams?.allocations,
    limit: reqParams?.limit,
    page: reqParams?.page,
    currency: reqParams?.currency,
    sort: [reqParams?.sort?.type, reqParams?.sort?.order],
    bidding_date_from: startOfDay(reqParams?.date?.startDate).toString(),
    bidding_date_end: endOfDay(reqParams?.date?.endDate).toString(),
    bid_statuses: reqParams?.statuses,
    relative: reqParams?.relative,
    grades: reqParams?.grade,
    platforms: reqParams?.platform
  };

  return axios.get(
    endpoints.GET_OUTSTANDING_BIDS(
      stringify(query, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
        skipEmptyString: true,
        skipNull: true
      })
    )
  );
};

export const getLoanTypes = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.LOAN_TYPES);
};

export const getAllocations = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_ALL_ALLOCATIONS);
};

export const getOpportunitiesAllocations = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_OPPORTUNITIES_ALLOCATIONS);
};

export const getTotalOpportunitiesReq = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_TOTAL_OPPORTUNITIES);
};

export const getFilteredOpportunitiesReq = (
  reqParams: Shapes.GetOpportunitiesParams
): Promise<AxiosResponse<{ data: Shapes.GetInvestmentsLoansResponse }>> => {
  const query = {
    currency: reqParams?.currency,
    limit: reqParams?.limit,
    page: reqParams?.page,
    sort: [reqParams?.sort?.type, reqParams?.sort?.order]
  };

  return axios.get(
    endpoints.GET_OPPORTUNITIES(
      reqParams.allocationId,
      stringify(query, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
        skipEmptyString: true,
        skipNull: true
      })
    )
  );
};

export const bidOpportunitiesReq = (data): Promise<AxiosResponse> => {
  return axios.post(endpoints.BID_OPPORTUNITIES, data);
};

export const getInvestmentHistoryReq = (id: string): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_INVESTMENT_HISTORY(id));
};

export const getInvestmentRepaymentsReq = ({
  id,
  currency
}: Shapes.IGetInvestmentRepaymentsReq): Promise<AxiosResponse> => {
  const query = stringify(
    { currency },
    {
      skipEmptyString: true,
      skipNull: true
    }
  );

  return axios.get(endpoints.GET_INVESTMENT_REPAYMENTS(id, query));
};

export const getEntityDetailsReq = (id: string, isLoan: boolean, currency: string): Promise<AxiosResponse> => {
  return axios.get(isLoan ? endpoints.GET_LOAN_DETAILS(id, currency) : endpoints.GET_INVESTMENT_DETAILS(id, currency));
};
