/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useMemo, useRef, memo } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import AddRounded from '@mui/icons-material/AddRounded';
import RemoveRounded from '@mui/icons-material/RemoveRounded';
import { currencySymbols } from 'src/constants/globalConstants';
import { ICurrency } from 'src/interfaces/RandomShapes';
import { Typography } from 'src/components';
import { useWindowDimensions } from 'src/hooks';
import { thousandsSeparator } from 'src/helpers';
import worldCountriesTopojson from 'src/assets/misc/world-countries-sans-antarctica.json';
import styles from './Map.module.scss';

type Props = {
  countries: Record<string, number>;
  currency: ICurrency;
};

const MIN_SCALE = 1;
const MAX_SCALE = 9;
const SCALE_STEP = 1;

const getDimensionSize = (width: number) => {
  if (width <= 1280) return 'xs';
  if (width <= 1366) return 'sm';
  if (width <= 1440) return 'md';
  if (width <= 1536) return 'lg';
  if (width <= 1920) return 'xl';
  return 'xxl';
};

const scaleMap = {
  xs: 120,
  sm: 120,
  md: 120,
  lg: 120,
  xl: 120,
  xxl: 90
};

const initialCoordinatesMap = {
  xs: [8, 30],
  sm: [8, 30],
  md: [8, 30],
  lg: [8, 30],
  xl: [8, 30],
  xxl: [8, -10]
};

const mapWidth = 800;
const mapHeight = 600;
const viewBoxMapHeight = 525;

const getInitialPosition = (dimensionSize) => ({ coordinates: initialCoordinatesMap[dimensionSize], zoom: 1 });

const Map: FC<Props> = ({ countries = {}, currency }) => {
  const mapBoxRef = useRef<HTMLDivElement>();
  const tooltipRef = useRef<any>();

  const { width } = useWindowDimensions();
  const dimensionSize = useMemo(() => getDimensionSize(width), [width]);

  const [position, setPosition] = useState(getInitialPosition(dimensionSize));
  const [isMouseDown, setMouseDown] = useState(false);

  const [tooltipContent, setTooltipContent] = useState('');
  const [showPointer, setShowPointer] = useState(false);

  const handleZoomIn = () => {
    if (position.zoom >= MAX_SCALE) return;

    setPosition((pos) => {
      const zoom = pos.zoom + SCALE_STEP;
      return { ...pos, zoom: zoom >= MAX_SCALE ? MAX_SCALE : zoom };
    });
  };

  const handleZoomOut = () => {
    if (position.zoom <= MIN_SCALE) return;

    setPosition((pos) => {
      const zoom = pos.zoom - SCALE_STEP;
      return zoom <= MIN_SCALE ? getInitialPosition(dimensionSize) : { ...pos, zoom };
    });
  };

  const handleMoveEnd = (position) => {
    setPosition(position);
    setMouseDown(false);
  };

  const countryKeys = useMemo(() => Object.keys(countries).map((item) => item.toLowerCase()), [countries]);

  return (
    <div className={styles.mapOuterWrapper}>
      <ZoomButtons
        disableZoomOut={position.zoom <= MIN_SCALE}
        disableZoomIn={position.zoom >= MAX_SCALE}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />

      <div
        ref={mapBoxRef}
        className={cn(styles.svgMapWrapper, {
          [styles.svgMapWrapperScrolling]: isMouseDown,
          [styles.svgMapWrapperDefault]: position.zoom === MIN_SCALE,
          [styles.svgMapWrapperPointer]: showPointer
        })}
        onMouseLeave={() => setMouseDown(false)}
        onMouseUp={() => setMouseDown(false)}
      >
        <ComposableMap
          //* is needed for tooltip
          viewBox={`0 0 ${mapWidth} ${viewBoxMapHeight}`}
          data-tip=""
          width={mapWidth}
          height={mapHeight}
          projection="geoMercator"
          projectionConfig={{
            rotate: [-12, 0, 0],
            scale: scaleMap[dimensionSize]
          }}
        >
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
            minZoom={MIN_SCALE}
            maxZoom={MAX_SCALE}
            filterZoomEvent={(e) => {
              if (e.type === 'wheel') return;
              if (position.zoom === MIN_SCALE && e.type === 'mousedown') return;

              if (e.type === 'mousedown') setMouseDown(true);

              return !!e;
            }}
            translateExtent={[
              [0, -mapHeight],
              [mapWidth, mapHeight]
            ]}
          >
            <Geographies geography={worldCountriesTopojson}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const match = countryKeys.includes(geo?.properties?.name.toLowerCase());

                  const value = Object.values(countries)?.[countryKeys.indexOf(geo?.properties?.name.toLowerCase())];

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        if (!match) return;

                        setShowPointer(true);
                        setTooltipContent(
                          `${geo.properties.name}: ${currencySymbols[currency.value]} ${thousandsSeparator(
                            +value?.toFixed(2)
                          )}`
                        );
                      }}
                      onMouseLeave={() => {
                        setShowPointer(false);
                        setTooltipContent('');
                      }}
                      fill={match ? '#4fa5b0' : '#ececec'}
                      stroke="#c2bcbc"
                      style={{
                        default: {
                          outline: 'none'
                        },
                        hover: {
                          outline: 'none'
                        },
                        pressed: {
                          outline: 'none'
                        }
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>

        <ReactTooltip
          ref={tooltipRef}
          className={styles.tooltip}
          overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
            const d = document.documentElement;
            const tooltipWidth = tooltipRef?.current?.tooltipRef?.clientWidth;
            const tooltipHeight = tooltipRef?.current?.tooltipRef?.clientHeight;

            left = Math.min(d.clientWidth - node.clientWidth, left);
            top = Math.min(d.clientHeight - node.clientHeight, top);
            left = Math.max(0, left) - (tooltipWidth / 2 + 6);
            top = Math.max(0, top) + tooltipHeight * 1.45;

            return { top, left };
          }}
        >
          {tooltipContent && <Typography>{tooltipContent}</Typography>}
        </ReactTooltip>
      </div>
    </div>
  );
};

const ZoomButtons = ({ onZoomIn, onZoomOut, disableZoomIn, disableZoomOut }) => (
  <div className={styles.zoomButtons}>
    <button
      type="button"
      disabled={disableZoomIn}
      onClick={onZoomIn}
      className={cn(styles.zoomButton, { [styles.zoomButtonDisabled]: disableZoomIn })}
    >
      <AddRounded className={styles.zoomIcon} />
    </button>

    <div className={styles.zoomDivider} />

    <button
      type="button"
      disabled={disableZoomOut}
      onClick={onZoomOut}
      className={cn(styles.zoomButton, { [styles.zoomButtonDisabled]: disableZoomOut })}
    >
      <RemoveRounded className={styles.zoomIcon} />
    </button>
  </div>
);

export default memo(Map);
