import React, { FC } from 'react';
import cn from 'classnames';
import { Fade } from '@mui/material';

type FadeWrapperProps = {
  show: boolean;
  children: React.ReactNode;
  unmountOnExit?: boolean;
  className?: string;
};

export const FadeWrapper: FC<FadeWrapperProps> = ({ children, show, unmountOnExit, className }) => {
  return (
    <Fade in={show} unmountOnExit={unmountOnExit}>
      <div className={cn({ [className]: className })}>{children}</div>
    </Fade>
  );
};
