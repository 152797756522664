import React from 'react';
import cn from 'classnames';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PuffLoader from 'react-spinners/PuffLoader';
import styles from './FakeRow.module.scss';

interface FakeRowProps {
  colSpan?: number;
  msg?: string | React.ReactNode;
  isError?: boolean;
  isLoader?: boolean;
  loaderSize?: number;
  loaderBoxClasses?: string;
}

const LOADER_SIZE = 80;
const DEFAULT_COL_SPAN = 1;

const FakeRow = ({
  msg,
  isError,
  isLoader,
  loaderSize = LOADER_SIZE,
  colSpan = DEFAULT_COL_SPAN,
  loaderBoxClasses
}: FakeRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell align="center" style={{ borderBottomColor: 'transparent' }} colSpan={colSpan}>
        <div className={cn(styles.loaderWrapper, { [loaderBoxClasses]: loaderBoxClasses })}>
          {isLoader ? (
            <PuffLoader loading size={loaderSize} color="#077186" />
          ) : (
            <p className={cn(styles.message, { [styles.errorMsg]: isError })}>{msg}</p>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default FakeRow;
