import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { startOfDay, endOfDay, formatISO } from 'date-fns';
import endpoints from 'src/constants/apiEndpoints';
import {
  ReportsRequestParams,
  ReportsLoadMoreRequestParams,
  CashflowTransactionsGroup,
  TAddTransactionBody
} from 'src/interfaces/reportsReduxShapes';
import { PlatformsIDs } from 'src/constants/globalConstants';

export const getReportsRequestQuery = (reqParams: ReportsRequestParams | ReportsLoadMoreRequestParams): string => {
  const query = {
    page: reqParams.page,
    limit: reqParams.limit,
    currency: reqParams.currency,
    platforms: reqParams.platforms,
    transaction_date_from: formatISO(startOfDay(reqParams.date.transaction_date_from)),
    transaction_date_to: formatISO(endOfDay(reqParams.date.transaction_date_to)),
    is_confirmed: false
  };

  return `${stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  })}`;
};

export const getReportsHelper = (
  reqParams: ReportsRequestParams | ReportsLoadMoreRequestParams
): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_REPORTS(getReportsRequestQuery(reqParams)));
};

export const getTransactionsRequestQuery = (
  reqParams: ReportsRequestParams | ReportsLoadMoreRequestParams,
  transactionType: CashflowTransactionsGroup
): string => {
  const query = {
    page: reqParams.page,
    limit: reqParams.limit,
    currency: reqParams.currency,
    platforms: reqParams.platforms,
    transaction_date_from: formatISO(startOfDay(reqParams.date.transaction_date_from)),
    transaction_date_to: formatISO(endOfDay(reqParams.date.transaction_date_to)),
    transactions_group: transactionType
  };

  return `${stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  })}`;
};

export const getTransactionsHelper = (
  reqParams: ReportsRequestParams | ReportsLoadMoreRequestParams,
  transactionType: CashflowTransactionsGroup
): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_TRANSACTIONS(getTransactionsRequestQuery(reqParams, transactionType)));
};

export const addTransaction = (body: TAddTransactionBody): Promise<AxiosResponse> => {
  return axios.post(endpoints.ADD_TRANSACTION, body);
};

export const deleteTransaction = (id: string): Promise<AxiosResponse> => {
  return axios.delete(endpoints.DELETE_TRANSACTION(id));
};
