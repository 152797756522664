import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { PlatformShape } from 'src/interfaces';
import { PlatformsIDs } from 'src/constants/globalConstants';
import { StatementBox, ModalWithAside, FadeWrapper } from 'src/components';
import { BlockTitle } from '../atoms';
import { Platform } from '../../model';
import { PlatformItem, ManagePlatform } from './components';
import styles from './PlatformsBlock.module.scss';

type Props = {
  isViewMode: boolean;
  allocationAmount: number;
  serverPlatforms: PlatformShape[];
  selectedCurrency: string;
  investedPlatforms: Platform[];
  onSetInvestedPlatforms: React.Dispatch<React.SetStateAction<Platform[]>>;
  selectedID: PlatformsIDs;
  onSetSelectedID: React.Dispatch<React.SetStateAction<PlatformsIDs>>;
  wrapperClass?: string;
};

const PlatformsBlock: FC<Props> = ({
  isViewMode,
  allocationAmount,
  serverPlatforms,
  selectedCurrency,
  investedPlatforms,
  onSetInvestedPlatforms,
  selectedID,
  onSetSelectedID,
  wrapperClass
}) => {
  const [showManage, setShowManage] = useState(false);

  const handleSelectPlatform = (id: PlatformsIDs) => onSetSelectedID(id);

  const onConfirmInvestProportion = (values: Platform[]) => {
    let newState: Platform[];

    onSetInvestedPlatforms((prev) => {
      const payload = values.map((value) => {
        const prevMatched = prev.find((item) => item.id === value.id);
        if (prevMatched) return { ...prevMatched, amount: value.amount, percentage: value.percentage };
        return value;
      });

      newState = payload;
      return payload;
    });

    if (newState.every(({ id }) => id !== selectedID)) onSetSelectedID(null);
  };

  useEffect(() => {
    if (isEmpty(investedPlatforms)) return;

    onSetInvestedPlatforms((prev) =>
      prev.map((item) => ({
        ...item,
        amount: item.percentage * (allocationAmount / 100)
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationAmount]);

  const hasPlatforms = !isEmpty(investedPlatforms);
  return (
    <StatementBox
      className={cn(styles.wrapper, { [styles.wrapperClean]: !hasPlatforms, [wrapperClass]: wrapperClass })}
    >
      <React.Fragment>
        <div className={cn(styles.titleBlock, { [styles.titleBlockSingle]: !hasPlatforms })}>
          <BlockTitle>Platforms</BlockTitle>

          <button type="button" onClick={() => setShowManage(true)} className={styles.manageButton}>
            {hasPlatforms ? 'Manage Platforms' : '+ Add Platforms'}
          </button>
        </div>

        <FadeWrapper show={hasPlatforms} unmountOnExit>
          <div className={styles.platformsBlock}>
            {investedPlatforms.map((p) => (
              <PlatformItem
                key={p.id}
                platform={p}
                isSelected={p.id === selectedID}
                onSelectPlatform={handleSelectPlatform}
              />
            ))}
          </div>
        </FadeWrapper>

        <ModalWithAside isOpen={showManage} onToggle={() => setShowManage((p) => !p)}>
          <ManagePlatform
            isViewMode={isViewMode}
            allocationAmount={allocationAmount}
            serverPlatforms={serverPlatforms}
            investedPlatforms={investedPlatforms}
            currency={selectedCurrency}
            onConfirm={(values) => {
              onConfirmInvestProportion(values);
              setShowManage(false);
            }}
          />
        </ModalWithAside>
      </React.Fragment>
    </StatementBox>
  );
};

export default PlatformsBlock;
