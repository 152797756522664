import React from 'react';
import { useHistory } from 'react-router-dom';
import { MainLayout, ErrorPageLayout, GreenBoxButton } from 'src/components';
import { PAGES } from 'src/constants/pages';
import styles from './ServerErrorPage.module.scss';

const ServerErrorPage = (): JSX.Element => {
  const { push } = useHistory();

  return (
    <MainLayout isCentered={false} isPrivate={false}>
      <ErrorPageLayout>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            <span>500. </span>
            Something went wrong...
          </h2>
          <p className={styles.description}>
            Whoops! The operation you have requested isn’t available right now. <span>app.loansweeper.com</span> is not
            able to handle this request.
          </p>

          <GreenBoxButton onClick={() => push(PAGES.SIGN_IN)} btnClass={styles.button} />
        </div>
      </ErrorPageLayout>
    </MainLayout>
  );
};

export default ServerErrorPage;
