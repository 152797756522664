/* eslint-disable no-case-declarations */
import React, { FC, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { scroller } from 'react-scroll';
import isNumber from 'lodash/isNumber';
import capitalize from 'lodash/capitalize';
import { formatDateFromJSON } from 'src/helpers/dateHelpers';
import { LOAN_TABLE_DATE_FORMAT, currencySymbols } from 'src/constants/globalConstants';
import { IconButton, Box } from '@mui/material';
import {
  IClientReport,
  IClientTransaction,
  IServerReport,
  IServerTransaction
} from 'src/interfaces/reportsReduxShapes';
import {
  CASHFLOW_ORIGINAL_CURRENCY_COLUMNS,
  CASHFLOW_REPORTS_CURRENCY_COLUMNS,
  CashflowColIds,
  TransactionsColIds
} from './constants';
import styles from './ReportsTableBlock.module.scss';
import { ReportCurrencyNames } from '../../constants';
import { PlatformShape, TableHeaderColumns } from 'src/interfaces';
import {
  DeleteTransactionIcon,
  ManualTransactionIcon,
  UnverifiedTransactionIcon,
  VerifiedTransactionIcon
} from 'src/components';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { thousandSeparatorFormatter } from 'src/helpers';
import { Provider } from 'src/constants/marketplaceConstants';

export const getHasMoreData = (dataItem: IClientReport | IClientTransaction): boolean => {
  return dataItem.data.length >= dataItem.clientSidePagination.page * dataItem.clientSidePagination.limit;
};

type TGetScrollElements = ({ platform, key }: { platform: string; key: string }) => {
  elementId: string;
  containerId: string;
};

export const getScrollElements: TGetScrollElements = ({ platform, key }) => {
  return {
    elementId: `${platform.toLowerCase()}_${key}_scroll_element`,
    containerId: `${platform.toLowerCase()}_${key}_scroll_container`
  };
};

type TOnScrollTableBottom = (payload: { elementId: string; containerId: string }) => void;

export const onScrollTableBottom: TOnScrollTableBottom = ({ elementId, containerId }): void => {
  const offsetHeight = scroller.get(elementId).clientHeight;

  scroller.scrollTo(elementId, {
    duration: 500,
    delay: 10,
    smooth: 'easeInOutQuint',
    containerId: containerId,
    offset: offsetHeight
  });
};

const {
  TRANSACTION_DATE,
  EXCHANGE_RATE,
  LOAN_ID,
  ORIGINAL_CURRENCY_LOAN_REPAYMENTS,
  ORIGINAL_CURRENCY_GROSS_INTEREST,
  ORIGINAL_CURRENCY_COMMISSION_PAID,
  REPORT_CURRENCY_LOAN_REPAYMENTS,
  REPORT_CURRENCY_GROSS_INTEREST,
  REPORT_CURRENCY_COMMISSION_PAID,
  TOTAL_PAYMENT
} = CashflowColIds;

const getTotalPayment = (row: IServerReport | IServerTransaction, reportCurrency: ReportCurrencyNames): number => {
  if (reportCurrency === ReportCurrencyNames.ORIGINAL) {
    return [
      row[ORIGINAL_CURRENCY_LOAN_REPAYMENTS],
      row[ORIGINAL_CURRENCY_GROSS_INTEREST],
      row[ORIGINAL_CURRENCY_COMMISSION_PAID]
    ].reduce((acc, cur) => (isNumber(+cur) ? acc + cur : acc), 0);
  }

  return [
    row[REPORT_CURRENCY_LOAN_REPAYMENTS],
    row[REPORT_CURRENCY_GROSS_INTEREST],
    row[REPORT_CURRENCY_COMMISSION_PAID]
  ].reduce((acc, cur) => (isNumber(+cur) ? acc + cur : acc), 0);
};

export const renderReportsRowContent = (
  colId: CashflowColIds,
  row: IServerReport,
  currencySymbol: string,
  reportCurrency: ReportCurrencyNames
): JSX.Element => {
  const value = row[colId];

  switch (colId) {
    case TRANSACTION_DATE:
      return (
        <span className={styles.tableTransactionDate}>
          {formatDateFromJSON(value as string, LOAN_TABLE_DATE_FORMAT, '-')}
        </span>
      );

    case EXCHANGE_RATE:
      return <>{Number(value)?.toFixed(4)}</>;

    case LOAN_ID:
      return <span className={styles.tableCellText}>{value}</span>;

    case TOTAL_PAYMENT:
      const prefixValue =
        reportCurrency === ReportCurrencyNames.ORIGINAL ? currencySymbols[row.original_currency] : currencySymbol;

      const totalValue = getTotalPayment(row, reportCurrency);
      const formattedValue = totalValue.toString().includes('.') ? totalValue?.toFixed(2) : totalValue;

      return totalValue ? (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${prefixValue} `}
          value={formattedValue}
          className={styles.tableCellText}
        />
      ) : (
        <>-</>
      );

    case ORIGINAL_CURRENCY_LOAN_REPAYMENTS:
    case ORIGINAL_CURRENCY_GROSS_INTEREST:
    case ORIGINAL_CURRENCY_COMMISSION_PAID:
      return value ? (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${currencySymbols[row.original_currency]} `}
          value={value.toString().includes('.') ? Number(value)?.toFixed(2) : value}
          className={styles.tableCellText}
        />
      ) : (
        <>-</>
      );

    case REPORT_CURRENCY_LOAN_REPAYMENTS:
    case REPORT_CURRENCY_GROSS_INTEREST:
    case REPORT_CURRENCY_COMMISSION_PAID:
      return value ? (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${currencySymbol} `}
          value={value.toString().includes('.') ? Number(value)?.toFixed(2) : value}
          className={styles.tableCellText}
        />
      ) : (
        <>-</>
      );

    default:
      return <>{value}</>;
  }
};

export const renderReportsTransactionAccountBalance = (
  platform: PlatformShape,
  reportCurrency: ReportCurrencyNames,
  reportingCurrency: string,
  transactionsItem: IClientTransaction
) => {
  let amount = transactionsItem?.actual_balance_reporting || 0;
  let currencySymbol = reportingCurrency;

  if (reportCurrency === ReportCurrencyNames.ORIGINAL) {
    amount = transactionsItem?.actual_balance_original || 0;
    currencySymbol = platform.currency;
  }

  return `${Provider[platform.name]} ${currencySymbol} ${thousandSeparatorFormatter(amount, 2)}`;
};

export const getReportColumns = (reportCurrency: ReportCurrencyNames): TableHeaderColumns => {
  return reportCurrency === ReportCurrencyNames.REPORTING
    ? CASHFLOW_REPORTS_CURRENCY_COLUMNS
    : CASHFLOW_ORIGINAL_CURRENCY_COLUMNS;
};

const {
  VERIFIED,
  SETTLEMENT_DATE,
  LOAN_ID: TRANSACTION_LOAN_ID,
  EXCHANGE_RATE: TRANSACTION_EXCHANGE_RATE,
  TRANSACTION_TYPE,
  TOTAL_PAYMENT: TRANSACTION_TOTAL_PAYMENT
} = TransactionsColIds;

type TVerifiedTransactionComponentProps = {
  row: IServerTransaction;
  onManualTransactionClick: (row: IServerTransaction) => void;
};

const classNames = {
  enter: styles.fadeEnter,
  enterActive: styles.fadeEnterActive,
  exit: styles.fadeExit,
  exitActive: styles.fadeExitActive
};

const VerifiedTransactionComponent: FC<TVerifiedTransactionComponentProps> = ({ row, onManualTransactionClick }) => {
  const [isHovered, setHovered] = useState(false);

  const deleteRef = useRef(null);
  const manualRef = useRef(null);

  const nodeRef = isHovered ? deleteRef : manualRef;
  return (
    <IconButton
      disableRipple
      onClick={(e) => {
        e.stopPropagation();
        onManualTransactionClick(row);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{ padding: '0', width: '100%' }}
    >
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={isHovered ? 'delete' : 'manual'}
          unmountOnExit
          nodeRef={nodeRef}
          addEndListener={(done) => {
            nodeRef.current.addEventListener('transitionend', done, false);
          }}
          classNames={{ ...classNames }}
        >
          <Box
            ref={nodeRef}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '1rem',
              marginTop: '-0.125rem'
            }}
          >
            {isHovered ? <DeleteTransactionIcon /> : <ManualTransactionIcon />}
          </Box>
        </CSSTransition>
      </SwitchTransition>
    </IconButton>
  );
};

export const renderTransactionRowContent = (
  colId: TransactionsColIds,
  row: IServerTransaction,
  currencySymbol: string,
  reportCurrency: ReportCurrencyNames,
  onManualTransactionClick: (row: IServerTransaction) => void
): JSX.Element => {
  const value = row?.[colId];

  switch (colId) {
    case VERIFIED:
      const Icon = row?.is_confirmed ? VerifiedTransactionIcon : UnverifiedTransactionIcon;

      return row?.is_manual ? (
        <VerifiedTransactionComponent onManualTransactionClick={onManualTransactionClick} row={row} />
      ) : (
        <Icon />
      );

    case SETTLEMENT_DATE:
      return (
        <span className={styles.tableTransactionDate}>
          {formatDateFromJSON(value as string, LOAN_TABLE_DATE_FORMAT, '-')}
        </span>
      );

    case TRANSACTION_LOAN_ID:
      return <span className={styles.tableCellText}>{row?.is_manual ? 'Custom' : value ?? '-'}</span>;

    case TRANSACTION_EXCHANGE_RATE:
      return <>{Number(value)?.toFixed(4)}</>;

    case TRANSACTION_TYPE:
      return value ? <span className={styles.tableCellText}>{capitalize(value)}</span> : <>-</>;

    case TRANSACTION_TOTAL_PAYMENT:
      const prefixValue =
        reportCurrency === ReportCurrencyNames.ORIGINAL ? currencySymbols[row.original_currency] : currencySymbol;

      const totalValue =
        reportCurrency === ReportCurrencyNames.ORIGINAL ? row.total_payment_original : row.total_payment_reporting;

      const formattedValue = totalValue.toString().includes('.') ? totalValue?.toFixed(2) : totalValue;

      return totalValue ? (
        <NumberFormat
          type="text"
          displayType="text"
          thousandSeparator
          prefix={`${prefixValue} `}
          value={formattedValue}
          className={styles.tableCellText}
        />
      ) : (
        <>-</>
      );

    default:
      return <>{value}</>;
  }
};
