import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { PAGES } from 'src/constants/pages';
import { GreenBoxButton } from 'src/atoms';
import { LoanTypes } from 'src/constants/globalConstants';
import styles from './LoanTypesBlock.module.scss';

type Props = {
  onChangeLoanType: (type: LoanTypes) => void;
  loanType: LoanTypes;
};

const LoanTypesBlock: FC<Props> = ({ onChangeLoanType, loanType }) => {
  return (
    <div className={styles.typesWrapper}>
      <div className={styles.typesBlock}>
        <span className={styles.label}>Loan Type:</span>

        <div className={styles.typesButtons}>
          <GreenBoxButton
            onClick={() => onChangeLoanType(LoanTypes.CONSUMER)}
            btnClass={cn({ [styles.activeTypeButton]: loanType === LoanTypes.CONSUMER })}
          >
            Consumer Loans
          </GreenBoxButton>

          <GreenBoxButton
            onClick={() => onChangeLoanType(LoanTypes.SME)}
            btnClass={cn({ [styles.activeTypeButton]: loanType === LoanTypes.SME })}
          >
            SME Loans
          </GreenBoxButton>

          <GreenBoxButton
            onClick={() => onChangeLoanType(LoanTypes.INVOICE_FINANCING)}
            btnClass={cn({ [styles.activeTypeButton]: loanType === LoanTypes.INVOICE_FINANCING })}
          >
            Invoice Financing
          </GreenBoxButton>
        </div>
      </div>

      <Link to={PAGES.NEW_ALLOCATION} className={styles.newAllocationLink}>
        Create New Allocation
      </Link>
    </div>
  );
};

export default LoanTypesBlock;
