import React, { useState, memo, useMemo } from 'react';
import { getFormattedDateFromTimeStrByPeriod, statisticInvestmentSerializer } from 'src/helpers/statisticsHelpers';
import { GroupBy } from 'src/constants/statisticsConstants';
import { ChartHeader, StatisticsColumnChart, StatementBox } from 'src/components';

interface NewInvestmentVolumeProps {
  data: Record<string, number>;
  currency?: string;
}

const NewInvestmentVolume = memo(({ data, currency }: NewInvestmentVolumeProps): JSX.Element => {
  const [type, setType] = useState<GroupBy>(GroupBy.DAY);

  const totalInvestment: number = Object.values(data).reduce((a, b) => a + b, 0);

  const groups = useMemo(
    () =>
      Object.entries(data).reduce((acc, [dateString, value]) => {
        const key = getFormattedDateFromTimeStrByPeriod(type, dateString);

        if (!acc[key]) acc[key] = 0;
        acc[key] += value;

        return acc;
      }, {}),
    [type, data]
  );

  const d = useMemo(() => statisticInvestmentSerializer(groups, totalInvestment), [groups, totalInvestment]);

  return (
    <StatementBox style={{ width: '100%' }}>
      <ChartHeader title="New Investment Volume" type={type} onTypeChange={setType} />

      <StatisticsColumnChart data={d} type={type} xLabelType="direct" yLabelType="money" currency={currency} />
    </StatementBox>
  );
});

NewInvestmentVolume.displayName = 'NewInvestmentVolume';

export default NewInvestmentVolume;
