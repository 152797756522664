import React, { useState, useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { StatementBox, CustomizedTable } from 'src/components';
import { IPortfolioProfitability } from 'src/interfaces';
import { SortOrders } from 'src/constants/globalConstants';
import { getRowContent } from './helpers';
import { portfolioProfitabilityCols, StatisticSortTypes, PortfolioProfitabilityColIds } from './constants';
import styles from './PortfolioProfitability.module.scss';

interface IPortfolioProfitabilityProps {
  data: IPortfolioProfitability[];
  currency?: string;
}

const PortfolioProfitability = ({ data, currency }: IPortfolioProfitabilityProps): JSX.Element => {
  const [sort, setSort] = useState({ type: StatisticSortTypes.RATING, order: SortOrders.ASC });

  const handleSortTable = (type: StatisticSortTypes, order: SortOrders) => setSort({ type, order });

  const getTableRowContent = useCallback(
    (colId: string, row: IPortfolioProfitability) => {
      return getRowContent(colId as PortfolioProfitabilityColIds, row, currency);
    },
    [currency]
  );

  const rows = useMemo(
    () =>
      data
        ?.map((d) => ({ ...d, id: uuid() }))
        .sort((a, b) => {
          if (sort.order === SortOrders.ASC) {
            if (a[sort.type] < b[sort.type]) return -1;
            if (a[sort.type] > b[sort.type]) return 1;

            return 0;
          } else {
            if (a[sort.type] > b[sort.type]) return -1;
            if (a[sort.type] < b[sort.type]) return 1;

            return 0;
          }
        }),
    [data, sort]
  );

  return (
    <StatementBox className={styles.portfolioProfitability}>
      <div className={styles.portfolioStructureHeader}>
        <h2>Portfolio Profitability</h2>
      </div>

      <CustomizedTable
        columns={portfolioProfitabilityCols}
        rows={rows}
        shouldShowList={!!rows.length}
        onSort={handleSortTable}
        getSummaryContent={getTableRowContent}
        tContainerClass={styles.tableContainer}
        isLoading={false}
        hasDetails={false}
        emptyRowsMsg={null}
        guttersRight={16}
        guttersLeft={16}
      />
    </StatementBox>
  );
};

export default PortfolioProfitability;
