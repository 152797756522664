interface ChartDataSchemaShape {
  title: string;
  field: string;
  value?: number;
  color?: string;
}

export const chartDataSchema: ChartDataSchemaShape[] = [
  {
    title: 'Repaid',
    field: 'Repaid'
  },
  {
    title: 'Performing',
    field: 'Performing'
  },
  {
    title: '1 - 7 days late',
    field: '1 - 7 days late'
  },
  {
    title: '8 - 15 days late',
    field: '8 - 15 days late'
  },
  {
    title: '16 - 30 days late',
    field: '16 - 30 days late'
  },
  {
    title: '31 - 60 days late',
    field: '31 - 60 days late'
  },
  {
    title: '61 - 90 days late',
    field: '61 - 90 days late'
  },
  {
    title: '91 - 120 days late',
    field: '91 - 120 days late'
  },
  {
    title: '120+ days late',
    field: '120+ days late'
  },
  {
    title: 'Defaulted',
    field: 'defaulted'
  }
];
