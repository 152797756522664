import axios, { AxiosResponse } from 'axios';
import { getAllocationsRequestQuery } from 'src/helpers/portfolioBuilderHelpers';
import endpoints from 'src/constants/apiEndpoints';
import { AllocationPayload } from 'src/interfaces/AllocationTypes';
import * as Shapes from 'src/interfaces/portfolioBuilderReduxShapes';
import { AllocationSummaryReq } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';

export const getAllocationsHelper = (reqParams: Shapes.GetAllocationsParams): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_PORTFOLIO_BUILDER_ALLOCATIONS(getAllocationsRequestQuery(reqParams)));
};

export const addAllocationHelper = (allocation: AllocationPayload): Promise<AxiosResponse> => {
  return axios.post(endpoints.ADD_ALLOCATION, allocation);
};

export const editAllocationHelper = (allocation: AllocationPayload, allocationId: string): Promise<AxiosResponse> => {
  return axios.put(endpoints.EDIT_ALLOCATION(allocationId), allocation);
};

export const deleteCurrentAllocationHelper = async (id: string | Array<string>): Promise<AxiosResponse> => {
  return axios.request({
    method: 'delete',
    url: endpoints.DELETE_ALLOCATION(id as string)
  });
};

export const deleteAllocationsGroupHelper = async (id: string[]): Promise<AxiosResponse> => {
  return axios.request({
    method: 'delete',
    url: endpoints.DELETE_ALLOCATION_GROUP,
    data: { id }
  });
};

export const getAllocationDetailsHelper = (id: string): Promise<AxiosResponse> => {
  return axios.get(endpoints.GET_ALLOCATION_DETAILS(id));
};

export const startAllocationHelpers = (id: string): Promise<AxiosResponse> => {
  return axios.put(endpoints.START_ALLOCATION(id));
};

export const stopAllocationHelpers = (id: string): Promise<AxiosResponse> => {
  return axios.put(endpoints.STOP_ALLOCATION(id));
};

export const getLoanConstraintsHelper = (reqParams: AllocationSummaryReq): Promise<AxiosResponse> => {
  if (reqParams?.currency && reqParams?.type) {
    return axios.get(endpoints.GET_LOAN_CONSTRAINTS_TYPE_CURRENCY(reqParams.currency, reqParams.type));
  }

  return axios.get(endpoints.GET_LOAN_CONSTRAINTS);
};
