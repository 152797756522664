import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';
import { MainLayout, PortfolioStructure, ContentHeadingBox } from 'src/components';
import { getAllocationHook, usePortfolioStructureOptions, useUserValues } from 'src/hooks';
import { getAllocations } from 'src/redux/actions/portfolioBuilderActions';
import { getStatistics } from 'src/redux/actions/statisticsActions';
import * as Shapes from 'src/interfaces/statisticsReduxShapes';
import { SortTypes } from 'src/constants/portfolioBuilderConstants';
import { SortOrders } from 'src/constants/globalConstants';
import {
  statisticsSelector,
  portfolioBuilderAllocationSelector,
  preselectedAllocationSelector
} from 'src/redux/selectors';
import { calculatePercentage, formatDateFromJSON, formatTitle } from 'src/helpers';
import { currencySelector } from 'src/redux/selectors';
import { exportFileApi } from 'src/utils/comon';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import {
  NewInvestmentVolume,
  TotalInvestmentVolume,
  CapitalDistributionBlock,
  LoanFiltersBlock,
  LoanStatusByVintage,
  StatisticWidget,
  PortfolioProfitability,
  MaturityChart,
  ReceivedPayments,
  NetReturnByChart,
  InterestRateDistribution
} from './components';
import { getWidgets, getExportStatisticsUrl } from './helpers';
import styles from './StatisticsPage.module.scss';
import { IAllocation } from 'src/interfaces';
import { onPresetAllocationId } from 'src/redux/actions/baseActions';
import isEmpty from 'lodash/isEmpty';
import { NET_RETURN_BY_KEYS } from 'src/constants/statisticsConstants';

const LOADER_SIZE = 80;
const DEFAULT_REQ_PARAMS = {
  page: 1,
  limit: 1000,
  sort: {
    type: SortTypes.CREATED,
    order: SortOrders.DESC
  }
};
const ALL_PORTFOLIOS_OPTION = { value: null, label: 'All Portfolios' };

const StatisticsPage = memo((): JSX.Element => {
  const dispatch = useDispatch();
  const { accessToken } = useUserValues();

  const statisticsData = useSelector(statisticsSelector);
  const allocations = useSelector(portfolioBuilderAllocationSelector);

  const [isLoadingState, setIsLoadingState] = useState({
    capitalDistribution: true
  });

  const currency = useSelector(currencySelector);

  const [portfolioStructureChartData, setPortfolioStructureChartData] = useState([]);

  const [portfolioStructureToggle, setPortfolioStructureToggle] = useState('loan_investment_by_purpose');

  const [statisticsRequestParams, setStatisticsRequestParams] = useState<Shapes.GetStatisticsParams>({
    allocation: null,
    currency: currency.value
  });

  const [statisticsLoading, setStatisticsLoading] = useState(false);

  useEffect(() => {
    setStatisticsRequestParams((prevState) => ({ ...prevState, currency: currency.value }));
  }, [currency.value]);

  const [isAllocationsFetching, setAllocationsFetching] = useState(true);

  useEffect(() => {
    dispatch(
      getAllocations(
        DEFAULT_REQ_PARAMS,
        () => setAllocationsFetching(false),
        () => setAllocationsFetching(false)
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (!statisticsData) return;

    const data = statisticsData[portfolioStructureToggle];

    if (!data) return;

    setPortfolioStructureChartData(
      Object.keys(data).map((key, idx) => ({
        title: formatTitle(key),
        percentage: calculatePercentage(Object.values(data), data[key]),
        id: `${idx}`
      }))
    );
  }, [statisticsData, portfolioStructureToggle]);

  const initializePortfolioStructureChartData = (val: string) => {
    setPortfolioStructureToggle(val);
  };

  const [selectedAllocation, setSelectedAllocation] = useState<IAllocation>(null);
  const structureOptions = usePortfolioStructureOptions({
    allocationType: selectedAllocation?.allocation_params?.loan_type,
    statisticsData
  });

  const allocationID = useSelector(preselectedAllocationSelector);

  const onAllocationChange = useCallback(
    (id: string) => {
      setStatisticsRequestParams((state) => ({
        ...state,
        allocation: id
      }));

      setSelectedAllocation(allocations.find((allocation) => allocation.id === id));

      setIsLoadingState((state) => ({
        ...state,
        capitalDistribution: true
      }));

      if (allocationID !== id) dispatch(onPresetAllocationId(id));
    },
    [allocations, allocationID, dispatch]
  );

  const {
    selectedAllocation: selectedAllocationOption,
    selectorOptions,
    onChangeValue
  } = getAllocationHook({
    allocations,
    onAllocationChange,
    allDataOption: ALL_PORTFOLIOS_OPTION
  });

  const [isAllocationPreset, setAllocationPreset] = useState(false);

  useEffect(() => {
    if (!isAllocationPreset) return;

    setStatisticsLoading(true);
    dispatch(
      getStatistics(
        statisticsRequestParams,
        () => setStatisticsLoading(false),
        () => setStatisticsLoading(false)
      )
    );
  }, [dispatch, statisticsRequestParams, isAllocationPreset]);

  useEffect(() => {
    if (isAllocationPreset || isEmpty(allocations) || isEmpty(selectorOptions)) return;

    const preselectOption = selectorOptions.find((item) => item.value === allocationID);
    onChangeValue(preselectOption);
    setAllocationPreset(true);
  }, [isAllocationPreset, allocations, allocationID, selectorOptions, onChangeValue]);

  useEffect(() => {
    setIsLoadingState((state) => ({
      ...state,
      capitalDistribution: false
    }));
  }, [statisticsData]);

  const [isExportReportError, setExportReportError] = useState(false);
  const onConfirmExport = useCallback(
    async (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => {
      setExportReportError(false);

      const fileName = `Exaloan statistics ${formatDateFromJSON(new Date(), 'MM/dd/yyyy')}`;
      const fileUrl = getExportStatisticsUrl(statisticsRequestParams, currency.value, fileType);

      await exportFileApi(fileUrl, fileName, fileType, accessToken);
      onDownloadedSuccess();
    },
    [accessToken, currency.value, statisticsRequestParams]
  );

  const widgets = useMemo(
    () => (statisticsData ? getWidgets(statisticsData, currency) : []),
    [statisticsData, currency]
  );

  const shouldRenderStatistics = !statisticsLoading && statisticsData;
  const shouldShowNoData = !isAllocationsFetching && !statisticsLoading && !statisticsData;
  const showNetReturnBy = useMemo(
    () => NET_RETURN_BY_KEYS.every((key) => !isEmpty(statisticsData?.[key])),
    [statisticsData]
  );

  return (
    <MainLayout isEmptySkeleton>
      <ContentHeadingBox wrapperClass={styles.contentHeadingBox}>
        <h2 className={styles.title}>Statistics</h2>

        <LoanFiltersBlock
          selectedAllocation={selectedAllocationOption}
          onChangeSelectedAllocation={onChangeValue}
          selectorOptions={selectorOptions}
          lastUpdatedDate={statisticsData?.created_at_date}
          lastRequestedDate={statisticsData?.last_requested_date}
          displayFilterButton={false}
          displayClearButton={false}
          onConfirmExport={onConfirmExport}
          blockExportCondition={!statisticsData}
          shouldRenderStatistics={!!shouldRenderStatistics}
          isPortfolioSelectError={!statisticsData && isExportReportError}
          onExportConditionBlocked={() => setExportReportError(true)}
        />
      </ContentHeadingBox>

      {isAllocationsFetching || statisticsLoading ? (
        <div className={styles.loaderWrapper}>
          <PuffLoader size={LOADER_SIZE} color="#077186" />
        </div>
      ) : null}

      {shouldShowNoData ? <h2 className={styles.noReport}>No data available at the moment</h2> : null}

      {shouldRenderStatistics ? (
        <div className={styles.widgetsWrapper}>
          <StatisticWidget widgets={widgets} />

          <PortfolioStructure
            chartData={portfolioStructureChartData}
            onChartDataChange={setPortfolioStructureChartData}
            toggleOptions={structureOptions}
            portfolioStructureToggle={portfolioStructureToggle}
            onPortfolioStrictureToggleChange={initializePortfolioStructureChartData}
          />

          <CapitalDistributionBlock
            data={statisticsData}
            title="My Capital Distribution Breakdown"
            isLoading={isLoadingState.capitalDistribution}
            currency={currency.value}
          />

          {!isEmpty(statisticsData?.portfolio_profitability) ? (
            <PortfolioProfitability data={statisticsData?.portfolio_profitability} currency={currency.value} />
          ) : null}

          {!isEmpty(statisticsData?.new_investment_volume_by_date) ? (
            <NewInvestmentVolume data={statisticsData?.new_investment_volume_by_date} currency={currency.value} />
          ) : null}

          {!isEmpty(statisticsData?.total_investment_volume_by_date) ? (
            <TotalInvestmentVolume
              data={statisticsData?.total_investment_volume_by_date}
              currency={currency.value}
              wrapperClass={styles.totalInvestmentBlock}
            />
          ) : null}

          {!isEmpty(statisticsData?.loan_status_by_vintage) ? (
            <LoanStatusByVintage
              data={statisticsData?.loan_status_by_vintage}
              currency={currency.value}
              wrapperClass={styles.statusByVintageBlock}
            />
          ) : null}

          {!isEmpty(statisticsData?.received_cashflow_chart) ? (
            <ReceivedPayments
              data={statisticsData?.received_cashflow_chart}
              currency={currency.value}
              wrapperClass={styles.statusByVintageBlock}
            />
          ) : null}

          {!isEmpty(statisticsData?.maturity_chart) ? (
            <MaturityChart
              data={statisticsData?.maturity_chart}
              currency={currency.value}
              wrapperClass={styles.maturityChartBlock}
            />
          ) : null}

          {showNetReturnBy ? (
            <NetReturnByChart
              netReturnByYear={statisticsData?.net_return_by_year}
              netReturnByMonth={statisticsData?.net_return_by_month}
              netReturnByWeek={statisticsData?.net_return_by_week}
              currency={currency.value}
            />
          ) : null}

          {!isEmpty(statisticsData?.interest_rate_bins_by_invested) ? (
            <InterestRateDistribution data={statisticsData?.interest_rate_bins_by_invested} currency={currency.value} />
          ) : null}
        </div>
      ) : null}
    </MainLayout>
  );
});

StatisticsPage.displayName = 'StatisticsPage';

export default StatisticsPage;
