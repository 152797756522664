export const callbackResolver = (callback: (value?) => void | undefined, value?: unknown): void => {
  if (typeof callback === 'function') return value ? callback(value) : callback();
};

export const loanHandler = (data) => {
  if (!data?.length) return data;

  return data.map((item) => ({
    ...item?.Loan,
    id: item?.id,
    loanId: item?.Loan?.id,
    invested_amount: item.invested_amount,
    latest_principal_outstanding: item?.latest_principal_outstanding,
    loan_country: item?.Loan?.country,
    loan_score: item?.Loan?.grade,
    loan_term: item?.Loan?.term_original,
    platform: item?.Loan?.source_platform_name,
    funded_date: item?.funded_date,
    matched_date: item?.matched_date
  }));
};

export const outstandingBidHandler = (data) => {
  if (!data?.length) return data;

  return data.map((item) => ({
    ...item?.Loan,
    id: item?.id,
    loanId: item?.Loan?.id,
    bid_statuses: item?.bid_statuses,
    bidding_date: item?.bidding_date,
    invested_amount: item.invested_amount,
    loan_country: item?.Loan?.country,
    loan_score: item?.Loan?.grade,
    loan_term: item?.Loan?.term_original,
    platform: item?.Loan?.source_platform_name
  }));
};
