import React, { FC, useCallback, useMemo } from 'react';
import { PuffLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  IClientReport,
  IClientTransaction,
  IServerReport,
  IServerTransaction
} from 'src/interfaces/reportsReduxShapes';
import styles from './ReportsTableBlock.module.scss';
import { currencySymbols } from 'src/constants/globalConstants';
import { CustomizedTable, ShouldRender } from 'src/components';
import {
  getReportColumns,
  renderReportsRowContent,
  getHasMoreData,
  renderTransactionRowContent,
  renderReportsTransactionAccountBalance
} from './helpers';
import {
  CashflowColIds,
  TransactionsColIds,
  TRANSACTIONS_CURRENCY_COLUMNS,
  MAX_TABLE_HEIGHT,
  SCROLL_AFTER_LENGTH,
  LOAD_MORE_LOADER_SIZE
} from './constants';
import { ReportCurrencyNames } from '../../constants';
import { useOnLoadMore, useScrollToBottom } from './hooks';
import { useHistory } from 'react-router-dom';
import { DYNAMIC_PAGES } from 'src/constants/pages';
import { basePlatformsSelector } from 'src/redux/selectors';

type ReportsTableBlockProps = {
  platform: string;
  cashflowItem: IClientReport;
  transactionsItem: IClientTransaction;
  currency: string;
  isAsideMenuOpen: boolean;
  onLoadMoreTransactions: (transactionsItem: IClientTransaction, onFinally: () => void) => void;
  onLoadMoreCashflow: (cashflowItem: IClientReport, onFinally: () => void) => void;
  reportCurrency: ReportCurrencyNames;
  onManualTransactionClick: (row: IServerTransaction) => void;
};

const ReportsTableBlock: FC<ReportsTableBlockProps> = ({
  platform,
  cashflowItem,
  transactionsItem,
  currency,
  isAsideMenuOpen,
  onLoadMoreTransactions,
  onLoadMoreCashflow,
  reportCurrency,
  onManualTransactionClick
}) => {
  const { push } = useHistory();

  const platformsData = useSelector(basePlatformsSelector);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    elementId: transactionScrollElementId,
    containerId: transactionScrollContainerId,
    onScrollToBottom: onTransactionScrollBottom
  } = useScrollToBottom({
    platform,
    key: 'transaction'
  });

  const { isLoading: isTransactionsLoading, onMore: handleTransactionsLoadMore } = useOnLoadMore<IClientTransaction>({
    data: transactionsItem,
    onLoadMore: onLoadMoreTransactions,
    onScrollBottom: onTransactionScrollBottom
  });

  const getTransactionRowContent = useCallback(
    (colId: string, row: IServerTransaction) =>
      renderTransactionRowContent(
        colId as TransactionsColIds,
        row,
        currencySymbols[currency],
        reportCurrency,
        onManualTransactionClick
      ),
    [currency, reportCurrency, onManualTransactionClick]
  );

  const hasMoreTransactions = useMemo(() => getHasMoreData(transactionsItem), [transactionsItem]);
  const isStickyHeaderTransactions = transactionsItem.data.length > SCROLL_AFTER_LENGTH;

  const {
    elementId: cashflowScrollElementId,
    containerId: cashflowScrollContainerId,
    onScrollToBottom: onScrollCashflowBottom
  } = useScrollToBottom({
    platform,
    key: 'cashflow'
  });

  const { isLoading: isCashflowLoading, onMore: handleCashflowLoadMore } = useOnLoadMore<IClientReport>({
    data: cashflowItem,
    onLoadMore: onLoadMoreCashflow,
    onScrollBottom: onScrollCashflowBottom
  });

  const getReportsRowContent = useCallback(
    (colId: string, row: IServerReport) =>
      renderReportsRowContent(colId as CashflowColIds, row, currencySymbols[currency], reportCurrency),
    [currency, reportCurrency]
  );

  const handleTransactionsRowClick = useCallback(
    (row: IServerTransaction) => {
      if (row?.is_manual || !row?.investment_key) return;

      push(DYNAMIC_PAGES.INVESTMENT_DETAILS(row?.investment_key));
    },
    [push]
  );

  const onSetTransactionRowSx = useCallback((row: IServerTransaction) => {
    return {
      cursor: row?.is_manual || !row?.investment_key ? 'default' : 'pointer'
    };
  }, []);

  const cashflowColumns = useMemo(() => getReportColumns(reportCurrency), [reportCurrency]);
  const hasMoreCashflow = useMemo(() => getHasMoreData(cashflowItem), [cashflowItem]);
  const isStickyHeaderCashflow = cashflowItem.data.length > SCROLL_AFTER_LENGTH;

  const title = useMemo(() => {
    const currentPlatformData = platformsData.find((item) => item.name === platform);

    return renderReportsTransactionAccountBalance(currentPlatformData, reportCurrency, currency, transactionsItem);
  }, [
    currency,
    platform,
    platformsData,
    reportCurrency,
    transactionsItem?.actual_balance_original,
    transactionsItem?.actual_balance_reporting
  ]);

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>

      <div className={styles.tableWrapper}>
        <h4 style={{ marginBottom: '1rem' }} className={styles.subtitle}>
          Transactions
        </h4>

        <CustomizedTable
          useUuIdBodyKeys
          columns={TRANSACTIONS_CURRENCY_COLUMNS}
          getSummaryContent={getTransactionRowContent}
          onRowClick={handleTransactionsRowClick}
          stickyHeader={isStickyHeaderTransactions}
          tableScrollWrapperContainerName={transactionScrollContainerId}
          tableScrollWrapperName={transactionScrollElementId}
          maxTableContainerHeight={MAX_TABLE_HEIGHT}
          rows={transactionsItem.data}
          guttersLeft={12}
          guttersRight={12}
          isLoading={false}
          hasDetails={false}
          emptyRowsMsg={'No transactions found'}
          onSort={null}
          tContainerClass={cn(styles.tableContainer, { [styles.tableContainerSticky]: isStickyHeaderTransactions })}
          noPaginationWrapperClass={styles.noPaginationWrapperClass}
          tHeadClasses={{
            tHeadClass: styles.tHeadClass,
            tHeadCellClass: cn(styles.tHeadCellClass, { [styles.tHeadCellClassSticky]: isStickyHeaderTransactions }),
            tHeadLabelClass: styles.tHeadLabelClass,
            tHeadTopColumnCellClass: cn(styles.tHeadTopColumnCellClass, {
              [styles.tHeadTopColumnCellWithAsideOpenClass]: isAsideMenuOpen
            })
          }}
          tBodyClasses={{
            tBodyCellClass: styles.tBodyCellClass,
            tBodyRowClass: styles.tBodyRowClass
          }}
          onSetCustomRowSx={onSetTransactionRowSx}
        />

        {isTransactionsLoading ? (
          <div className={styles.loaderWrapper}>
            <PuffLoader loading size={LOAD_MORE_LOADER_SIZE} color="#077186" />
          </div>
        ) : (
          <ShouldRender should={hasMoreTransactions}>
            <button type="button" className={styles.loadMoreButton} onClick={handleTransactionsLoadMore}>
              Load More
            </button>
          </ShouldRender>
        )}
      </div>

      <Accordion
        disableGutters
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{ boxShadow: 'none', marginTop: '1rem', '&:before': { display: 'none' } }}
      >
        <AccordionSummary sx={{ padding: 0, '.MuiAccordionSummary-content': { margin: '0.5rem 0 1rem 0' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <h4 className={styles.subtitle}>Unverified Cashflow</h4>

            <ExpandMoreIcon
              fontSize="large"
              sx={{
                transform: expanded === 'panel1' ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.15s cubic-bezier(0.4, 0, 0.6, 1)'
              }}
            />
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            padding: 0
          }}
        >
          <Box sx={{ width: '100%' }}>
            <CustomizedTable
              useUuIdBodyKeys
              stickyHeader={isStickyHeaderCashflow}
              tableScrollWrapperContainerName={cashflowScrollContainerId}
              tableScrollWrapperName={cashflowScrollElementId}
              maxTableContainerHeight={MAX_TABLE_HEIGHT}
              columns={cashflowColumns}
              rows={cashflowItem.data}
              getSummaryContent={getReportsRowContent}
              guttersLeft={12}
              guttersRight={12}
              isLoading={false}
              hasDetails={false}
              emptyRowsMsg={'No cashflow found'}
              onSort={null}
              tContainerClass={cn(styles.tableContainer, { [styles.tableContainerSticky]: isStickyHeaderCashflow })}
              noPaginationWrapperClass={styles.noPaginationWrapperClass}
              tHeadClasses={{
                tHeadClass: styles.tHeadClass,
                tHeadCellClass: cn(styles.tHeadCellClass, { [styles.tHeadCellClassSticky]: isStickyHeaderCashflow }),
                tHeadLabelClass: styles.tHeadLabelClass,
                tHeadTopColumnCellClass: cn(styles.tHeadTopColumnCellClass, {
                  [styles.tHeadTopColumnCellWithAsideOpenClass]: isAsideMenuOpen
                })
              }}
              tBodyClasses={{
                tBodyCellClass: styles.tBodyCellClass,
                tBodyRowClass: styles.tBodyRowClass
              }}
            />

            {isCashflowLoading ? (
              <div className={styles.loaderWrapper}>
                <PuffLoader loading size={LOAD_MORE_LOADER_SIZE} color="#077186" />
              </div>
            ) : (
              <ShouldRender should={hasMoreCashflow}>
                <button type="button" className={styles.loadMoreButton} onClick={handleCashflowLoadMore}>
                  Load More
                </button>
              </ShouldRender>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(ReportsTableBlock);
