import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import { statisticsTypes } from '../types';
import { callbackResolver } from 'src/utils/reduxHelpers';
import { putStatistics } from '../actions/statisticsActions';
import * as apiHelpers from './sagaHelpers/statisticsApiHelpers';
import * as Shapes from 'src/interfaces/statisticsReduxShapes';
import { statisticMapper } from 'src/helpers/statisticsHelpers';

function* getStatisticsWorker({ payload }: Shapes.GetStatisticsDataAction) {
  try {
    const { data } = yield call(apiHelpers.getStatisticsHelper, payload.reqParams);

    yield put(putStatistics(statisticMapper(data)));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    yield put(putStatistics(null));
  }
}

function* refreshStatisticsWorker({ payload }: Shapes.RefreshStatisticsAction) {
  try {
    yield call(apiHelpers.refreshStatisticsHelper);

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError, error);
  }
}

export function* statisticsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(statisticsTypes.GET_STATISTICS, getStatisticsWorker);
  yield takeEvery(statisticsTypes.REFRESH_STATISTICS, refreshStatisticsWorker);
}
