import { marketplaceTypes } from '../types';
import * as Shapes from 'src/interfaces/marketplaceReduxShapes';

export const updateCurrency = (currency: string) => ({
  type: marketplaceTypes.UPDATE_CURRENCY,
  payload: currency
});

export const getMarketplaceLoans = (
  reqParams: Shapes.GetMarketplaceLoansParams,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.GetMarketplaceLoansAction => ({
  type: marketplaceTypes.GET_MARKETPLACE_LOANS,
  payload: { reqParams, onSuccess, onError }
});

export const putMarketplaceLoans = (data: Shapes.PutMarketplaceLoansPayload): Shapes.PutMarketplaceLoansAction => ({
  type: marketplaceTypes.PUT_MARKETPLACE_LOANS_DATA,
  payload: data
});

export const putLoadMoreMarketplaceLoans = (
  data: Shapes.PutMarketplaceLoansPayload
): Shapes.PutMarketplaceLoansAction => ({
  type: marketplaceTypes.PUT_LOAD_MORE_MARKETPLACE_LOANS_DATA,
  payload: data
});
