import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import marketplaceReducer from './marketplaceReducer';
import userReducer from './userReducer';
import authReducer from './authReducer';
import investmentsReducer from './investmentsReducer';
import portfolioBuilderReducer from './portfolioBuilderReducer';
import baseReducer from './baseReducer';
import statisticsReducer from './statisticsReducer';
import apiReducer from './apiReducer';
import transactionsReducer from './transactionsReducer';

const persistConfig = {
  key: 'baseData',
  storage,
  whitelist: ['isAsideMenuOpen', 'preselectedAllocationId']
};

const persistedBaseReducer = persistReducer(persistConfig, baseReducer);

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  baseData: persistedBaseReducer,
  marketplace: marketplaceReducer,
  investments: investmentsReducer,
  portfolioBuilder: portfolioBuilderReducer,
  statistics: statisticsReducer,
  transactionsData: transactionsReducer,
  api: apiReducer
});
