import * as Yup from 'yup';

export type FormValues = {
  email: string;
};

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Wrong format. Please, enter the email as “your@email.com”')
    .required('Email cannot be blank')
});

export const initialValues: FormValues = {
  email: ''
};
