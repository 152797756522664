import React from 'react';
import MainLayout from 'src/components/MainLayout/MainLayout';
import styles from './HelpCenterPage.module.scss';

const HelpCenterPage = (): JSX.Element => {
  return (
    <MainLayout isPrivate>
      <h2 className={styles.helpCenterText}>
        Please call +49 69 989 570 15 or email us at info@exaloan.com for help. We are open everyday from 9-5pm.
      </h2>
    </MainLayout>
  );
};

export default HelpCenterPage;
