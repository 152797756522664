import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Modal, ModalLayout, CircularLoader, BlackBoxButton, FileTypeSelect } from 'src/components';
import {
  ExportFileTypes,
  EXPORT_FILE_ICONS,
  FILE_TYPES,
  FiletypeSelectPayload
} from 'src/constants/exportFilesConstants';
import { callbackResolver } from 'src/utils/reduxHelpers';
import { DEFAULT_TRANSITION_TIMEOUT } from 'src/constants/globalConstants';
import closeCross from 'src/assets/images/icons/close_cross.svg';
import checkMark from 'src/assets/images/icons/checkMark.svg';
import FadeTransition from '../reactTransitions/FadeTransition.module.css';
import styles from './ExportAllBlock.module.scss';

const ConfirmExportContent = ({ fileIcon, fileIconAlt, onConfirm, onCancel }) => {
  return (
    <React.Fragment>
      <div className={styles.fileIconWrapper}>
        <img src={fileIcon} alt={fileIconAlt} className="img-fluid" />
      </div>

      <p className={cn(styles.confirmDownloadText, styles.confirmText)}>Confirm Download</p>

      <div className={styles.btnBlock}>
        <BlackBoxButton onClick={onConfirm} label="Accept" btnClass={styles.confirmBtn} />
        <button type="button" onClick={onCancel} className={styles.cancelBtn}>
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

const DownloadComplete = ({ fileIcon, fileIconAlt, onClose }) => {
  return (
    <React.Fragment>
      <div className={styles.completeWrapper}>
        <button className={styles.closeBtn} type="button" onClick={onClose}>
          <img src={closeCross} alt="close btn" className={cn(styles.closeIcon, 'img-fluid')} />
        </button>
        <div className={styles.completeTitleBlock}>
          <img src={checkMark} alt="" className={styles.checkMark} />
          <h2 className={styles.completeTitle}>Please hold, the download will start shortly</h2>
        </div>
        <div className={styles.completeFiletypeWrapper}>
          <img src={fileIcon} alt={fileIconAlt} className={cn('img-fluid', styles.completeFileIcon)} />
        </div>
      </div>
    </React.Fragment>
  );
};

const DownloadingContent = ({ fileIcon, fileIconAlt }) => {
  return (
    <div className={styles.downloadingContent}>
      <img src={fileIcon} alt={fileIconAlt} className={cn('img-fluid', styles.downloadingFileIcon)} />
      <div className={styles.loaderWrapper}>
        <CircularLoader />
      </div>
    </div>
  );
};

interface ExportAllProps {
  onConfirmExport: (fileType: ExportFileTypes, onSuccess: () => void) => void;
  blockExportCondition?: boolean;
  onExportConditionBlocked?: () => void;
  selectWrapperClass?: string;
}

const ExportAllBlock = ({
  onConfirmExport,
  blockExportCondition,
  onExportConditionBlocked,
  selectWrapperClass
}: ExportAllProps): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSelectFileError, setSelectFileError] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<FiletypeSelectPayload | null>(null);

  const handleToggleModal = useCallback(() => {
    setModalOpen((state) => !state);

    if (isDownloaded) setDownloaded(false);
  }, [isDownloaded]);

  const onShowExportModal = useCallback(
    (value: FiletypeSelectPayload) => {
      if (!value) return setSelectFileError(true);
      if (value && blockExportCondition) {
        callbackResolver(onExportConditionBlocked);
        return;
      }

      handleToggleModal();
    },
    [handleToggleModal, blockExportCondition, onExportConditionBlocked]
  );

  const handleChangeFileType = useCallback(
    (value: FiletypeSelectPayload) => {
      onShowExportModal(value);
      setSelectedFileType(value);
    },
    [onShowExportModal]
  );

  const handleSelectFocus = () => {
    if (isSelectFileError) setSelectFileError(false);
  };

  const handleConfirmExport = () => {
    setDownloading(true);

    const onDownloadedSuccess = () => {
      setDownloaded(true);
      setDownloading(false);
    };

    onConfirmExport(selectedFileType.value, onDownloadedSuccess);
  };

  const selectedFileIcon = EXPORT_FILE_ICONS[selectedFileType?.value];
  const selectedFileIconAlt = selectedFileType?.label ?? 'file icon';
  return (
    <React.Fragment>
      <div className={styles.contentWrapper}>
        <FileTypeSelect
          value={selectedFileType}
          onChange={handleChangeFileType}
          options={FILE_TYPES}
          selectWrapperClass={cn({ [selectWrapperClass]: selectWrapperClass })}
          isError={isSelectFileError}
          onSelectFocus={handleSelectFocus}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onToggle={handleToggleModal}
        disableBackdropClick={isDownloading}
        disableEscapeKeyDown={isDownloading}
      >
        <ModalLayout wrapperClass={styles.modalLayoutWrapper}>
          <div className={styles.confirmContent}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={isDownloaded ? 'downloadComplete' : 'processDownloading'}
                timeout={DEFAULT_TRANSITION_TIMEOUT}
                classNames={{ ...FadeTransition }}
              >
                {isDownloaded ? (
                  <DownloadComplete
                    fileIcon={selectedFileIcon}
                    fileIconAlt={selectedFileIconAlt}
                    onClose={handleToggleModal}
                  />
                ) : (
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={isDownloading ? 'isDownloading' : 'confirmDownload'}
                      timeout={DEFAULT_TRANSITION_TIMEOUT}
                      classNames={{ ...FadeTransition }}
                    >
                      {isDownloading ? (
                        <DownloadingContent fileIcon={selectedFileIcon} fileIconAlt={selectedFileIconAlt} />
                      ) : (
                        <ConfirmExportContent
                          fileIcon={selectedFileIcon}
                          fileIconAlt={selectedFileIconAlt}
                          onConfirm={handleConfirmExport}
                          onCancel={handleToggleModal}
                        />
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                )}
              </CSSTransition>
            </SwitchTransition>
          </div>
        </ModalLayout>
      </Modal>
    </React.Fragment>
  );
};

export default ExportAllBlock;
