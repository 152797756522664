import { formatISO, startOfDay, endOfDay } from 'date-fns';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { stringify } from 'query-string';
import { GetMarketplaceLoansParams, LoanTypeShape } from 'src/interfaces';
import {
  EmploymentStatusesLabels,
  OccupationAreaTypesLabels,
  MarketplaceCheckboxEnums,
  marketplaceCheckboxBlockLabels,
  Provider
} from 'src/constants/marketplaceConstants';
import { LoanTypes } from 'src/constants/globalConstants';
import { getRelativeValuesList } from 'src/constants/relativeValueFilters';

export const getCurrentLoanTypeName = (
  loanTypeIdx: number,
  loanTypesArray: Array<LoanTypeShape>,
  withTrimming?: boolean
): string => {
  const targetLoanType = loanTypesArray.find((item) => item.value === loanTypeIdx);
  return targetLoanType.name.includes('/') && withTrimming ? targetLoanType.name.split('/')[0] : targetLoanType.name;
};

export const getEmploymentStatus = (employmentStatusIndex: number): string => {
  return EmploymentStatusesLabels[employmentStatusIndex] ?? 'Undefined';
};

export const getOccupationArea = (occupationAreaIndex: number): string => {
  return OccupationAreaTypesLabels[occupationAreaIndex] ?? 'Undefined';
};

export const formatLoanPurpose = (name: string, limit: number): string => {
  if (!name) {
    return '';
  }
  if (name.split('').length <= limit) {
    return name;
  }

  return `${name.slice(0, limit)}...`;
};

export const createFiltersArray = (filtersObject, isCheckedByDefault?: boolean | undefined) => {
  // Manually sort object properties
  const pickedFilters = pickBy(
    pick({ ...filtersObject, [MarketplaceCheckboxEnums.RELATIVE_VALUE]: getRelativeValuesList() }, [
      MarketplaceCheckboxEnums.COUNTRIES,
      MarketplaceCheckboxEnums.LOAN_TYPES,
      MarketplaceCheckboxEnums.SME_PURPOSES,
      MarketplaceCheckboxEnums.SME_SECTORS,
      MarketplaceCheckboxEnums.PLATFORMS,
      MarketplaceCheckboxEnums.GRADES,
      MarketplaceCheckboxEnums.RELATIVE_VALUE
    ]),
    isArray
  );

  return Object.keys(pickedFilters).reduce(
    (acc, key) => [
      ...acc,
      {
        id: key,
        blockLabel: marketplaceCheckboxBlockLabels[key],
        isAllChecked: !!isCheckedByDefault,
        list: pickedFilters[key].map((item) => ({
          ...item,
          name: key === MarketplaceCheckboxEnums.PLATFORMS ? Provider[item.name] : item.name,
          isChecked: !!isCheckedByDefault,
          id: item?._id ?? item.id
        }))
      }
    ],
    []
  );
};

export const getFiltersRequestObject = (filtersArray, isConsumer: boolean, emptyIfAllChecked?: boolean) => {
  return filtersArray.reduce((acc, item) => {
    const shouldSetEmpty = emptyIfAllChecked && item?.isAllChecked;
    return {
      ...acc,
      [item.id]: item.list.reduce((acc, listItem) => {
        switch (item.id) {
          case MarketplaceCheckboxEnums.GRADES:
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem.name] : acc;

          case MarketplaceCheckboxEnums.COUNTRIES:
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem.name.toLowerCase()] : acc;

          case MarketplaceCheckboxEnums.LOAN_TYPES:
          case MarketplaceCheckboxEnums.RELATIVE_VALUE:
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem.value] : acc;

          case MarketplaceCheckboxEnums.PLATFORMS:
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem?.name?.toUpperCase()] : acc;

          case MarketplaceCheckboxEnums.SME_SECTORS:
            if (isConsumer) return acc;
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem.name] : acc;

          case MarketplaceCheckboxEnums.SME_PURPOSES:
            if (isConsumer) return acc;
            return shouldSetEmpty ? [] : listItem.isChecked ? [...acc, listItem.value] : acc;

          default:
            return acc;
        }
      }, [])
    };
  }, {});
};

export const getMarketplaceLoansRequestQuery = (reqParams: GetMarketplaceLoansParams): string => {
  const query = {
    limit: reqParams?.limit,
    page: reqParams?.page,
    type: reqParams?.type,
    currency: reqParams?.currency,
    sort: [reqParams?.sort.type, reqParams?.sort.order],

    loan_amount_min: reqParams?.rangeFilters?.loanAmount?.min,
    loan_amount_max: reqParams?.rangeFilters?.loanAmount?.max,
    interest_rate_min: reqParams?.rangeFilters?.borrowerRate?.min
      ? reqParams?.rangeFilters?.borrowerRate?.min / 100
      : 0,
    interest_rate_max: reqParams?.rangeFilters?.borrowerRate?.max
      ? reqParams?.rangeFilters?.borrowerRate?.max / 100
      : null,
    term_min: reqParams?.rangeFilters?.term?.min,
    term_max: reqParams?.rangeFilters?.term?.max,

    origination_date_from: formatISO(startOfDay(reqParams?.date.startDate)),
    origination_date_end: formatISO(endOfDay(reqParams?.date.endDate)),

    countries: reqParams?.country,
    platforms: reqParams?.platform,
    grades: reqParams?.grade,
    sectors: reqParams?.sector,
    purposes: reqParams?.type === LoanTypes.SME ? reqParams?.smePurpose : reqParams?.loan_type,
    relative: reqParams?.relative
  };

  return stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  });
};
