import React from 'react';
import { NumericInput, ShouldRender } from 'src/components';
import cn from 'classnames';
import { comaRegex } from 'src/helpers/regexHelpers';
import styles from './RangeBar.module.scss';

enum InputsTypes {
  MIN = 'MIN',
  MAX = 'MAX'
}

export type RangeType = {
  min: number;
  max: number;
};

interface IRangeBarProps {
  label: string;
  values: RangeType;
  onRangeChange: (id, value: number[]) => void;
  valueLabels?: {
    isLabelInputs?: boolean;
    minThousandSeparator?: boolean;
    maxThousandSeparator?: boolean;
    after?: string;
  };
  id: string;
  disabled?: boolean;
  decimalScale?: number;
  isError?: boolean;
  labelClass?: string;
  afterClass?: string;
}

const RangeBar = ({
  label,
  values,
  onRangeChange,
  valueLabels,
  id,
  disabled = false,
  decimalScale = 2,
  isError,
  labelClass,
  afterClass
}: IRangeBarProps): JSX.Element => {
  const onLabelInputChange = (value: string, type: InputsTypes) => {
    const numericValue = +value.replace(comaRegex, '');
    const isMinField = type === InputsTypes.MIN;

    onRangeChange(id, isMinField ? [numericValue, +values.max] : [+values.min, numericValue]);
  };

  return (
    <div className={styles.rangeBarContainer}>
      <p className={cn({ [labelClass]: labelClass })}>{label}:&nbsp;</p>

      <NumericInput
        className={cn(styles.detailsValueBox, { [styles.error]: isError })}
        value={values.min}
        thousandSeparator={valueLabels?.minThousandSeparator}
        onChanged={(value) => onLabelInputChange(value, InputsTypes.MIN)}
        disabled={disabled}
        decimalScale={decimalScale}
      />

      <p>&nbsp;-&nbsp;</p>
      <NumericInput
        className={cn(styles.detailsValueBox, { [styles.error]: isError })}
        value={values.max}
        thousandSeparator={valueLabels?.minThousandSeparator}
        onChanged={(value) => onLabelInputChange(value, InputsTypes.MAX)}
        disabled={disabled}
        decimalScale={decimalScale}
      />

      <ShouldRender should={!!valueLabels?.after}>
        <p className={cn({ [afterClass]: afterClass })}>&nbsp;{valueLabels.after}</p>
      </ShouldRender>
    </div>
  );
};

export default RangeBar;
