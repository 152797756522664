import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import Error404Page from 'src/pages/Error404Page/Error404Page';
import { PAGES } from 'src/constants/pages';
import { AllocationPayload } from 'src/interfaces/AllocationTypes';
import { addAllocation, editAllocation } from 'src/redux/actions/portfolioBuilderActions';
import { PortfolioBuilderHomePage } from './PortfolioBuilderHomePage';
import { AllocationPage } from './AllocationPage';

const PortfolioBuilderPage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const onSubmitNewAllocation = useCallback(
    (values: AllocationPayload) => {
      dispatch(addAllocation(values as AllocationPayload, () => push(PAGES.PORTFOLIO_BUILDER)));
    },
    [dispatch, push]
  );

  const onSubmitEditAllocation = useCallback(
    (values: AllocationPayload, id: string) => {
      dispatch(editAllocation(values, id, () => push(PAGES.PORTFOLIO_BUILDER)));
    },
    [dispatch, push]
  );

  return (
    <Switch>
      <Route path={PAGES.PORTFOLIO_BUILDER} exact component={PortfolioBuilderHomePage} />

      <Route
        path={PAGES.NEW_ALLOCATION}
        render={(props) => <AllocationPage {...props} onConfirmAllocation={onSubmitNewAllocation} />}
      />

      <Route path={PAGES.VIEW_ALLOCATION} render={(props) => <AllocationPage {...props} isViewPage />} />

      <Route
        path={PAGES.EDIT_ALLOCATION}
        render={(props) => <AllocationPage {...props} isEditPage onConfirmAllocation={onSubmitEditAllocation} />}
      />

      <Route path={PAGES.ERROR_404} component={Error404Page} />
    </Switch>
  );
};

export default PortfolioBuilderPage;
