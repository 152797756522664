import { portfolioBuilderTypes } from '../types';
import { AllocationPayload } from 'src/interfaces/AllocationTypes';
import * as ReduxShapes from 'src/interfaces/portfolioBuilderReduxShapes';
import { AllocationSummaryReq } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';

export const getAllocations = (
  reqParams: ReduxShapes.GetAllocationsParams,
  onSuccess?: () => void,
  onError?: (err) => void
): ReduxShapes.GetAllocationsAction => ({
  type: portfolioBuilderTypes.GET_ALLOCATIONS,
  payload: { reqParams, onSuccess, onError }
});

export const putAllocationsData = (
  allocationsData: ReduxShapes.AllocationsData
): ReduxShapes.PutAllocationsDataAction => ({
  type: portfolioBuilderTypes.PUT_ALLOCATIONS,
  payload: allocationsData
});

export const addAllocation = (
  allocation: AllocationPayload,
  onSuccess?: () => void
): ReduxShapes.AddAllocationAction => ({
  type: portfolioBuilderTypes.ADD_ALLOCATION,
  payload: { allocation, onSuccess }
});

export const editAllocation = (
  allocation: AllocationPayload,
  allocationId: string,
  onSuccess?: () => void
): ReduxShapes.EditAllocationAction => ({
  type: portfolioBuilderTypes.EDIT_ALLOCATION,
  payload: { allocation, allocationId, onSuccess }
});

export const deleteCurrentAllocation = (
  id: string,
  onSuccess: () => void,
  onError: (err) => void
): ReduxShapes.DeleteCurrentAllocationAction => ({
  type: portfolioBuilderTypes.DELETE_CURRENT_ALLOCATION,
  payload: { id, onSuccess, onError }
});

export const deleteAllocationGroup = (
  allocationIds: string[],
  onSuccess: () => void,
  onError: (err) => void
): ReduxShapes.DeleteAllocationGroup => ({
  type: portfolioBuilderTypes.DELETE_GROUP_ALLOCATIONS,
  payload: { allocationIds, onSuccess, onError }
});

export const getAllocationDetails = (id: string, onSuccess?: () => void): ReduxShapes.GetAllocationDetails => ({
  type: portfolioBuilderTypes.GET_ALLOCATION_DETAILS,
  payload: { id, onSuccess }
});

export const clearAllocationDetails = (): { type: string } => ({
  type: portfolioBuilderTypes.CLEAR_ALLOCATION_DETAILS
});

export const putAllocationDetails = (
  allocation: ReduxShapes.AllocationDetailsShape
): ReduxShapes.PutAllocationDetailsAction => ({
  type: portfolioBuilderTypes.PUT_ALLOCATION_DETAILS,
  payload: allocation
});

export const startInvestingAllocation = (
  allocationID: string,
  onSuccess?: () => void
): ReduxShapes.StartInvestingAllocation => ({
  type: portfolioBuilderTypes.START_INVESTING,
  payload: { allocationID, onSuccess }
});

export const stopInvestingAllocation = (
  allocationID: string,
  onSuccess?: () => void
): ReduxShapes.StopInvestingAllocation => ({
  type: portfolioBuilderTypes.STOP_INVESTING,
  payload: { allocationID, onSuccess }
});

export const getLoanConstraints = (
  reqParams?: AllocationSummaryReq,
  onSuccess?: () => void
): ReduxShapes.GetLoanConstraints => ({
  type: portfolioBuilderTypes.GET_LOAN_CONSTRAINTS,
  payload: { reqParams, onSuccess }
});

export const putLoanConstraints = (
  constraintsList: Array<ReduxShapes.LoanConstraintShape>
): ReduxShapes.PutLoanConstraints => ({
  type: portfolioBuilderTypes.PUT_LOAN_CONSTRAINTS,
  payload: constraintsList
});

export const clearLoanConstraints = () => ({
  type: portfolioBuilderTypes.CLEAR_LOAN_CONSTRAINTS
});
