import { ColumnAlignOptions, TableHeaderColumns } from 'src/interfaces';

export const MAX_TABLE_HEIGHT = '796px';
export const SCROLL_AFTER_LENGTH = 20;
export const LOAD_MORE_LOADER_SIZE = 50;

export enum CashflowColIds {
  TRANSACTION_DATE = 'transaction_date',
  EXCHANGE_RATE = 'exchange_rate',
  LOAN_ID = 'uniq',

  TOTAL_PAYMENT = 'total_payment',

  ORIGINAL_CURRENCY_LOAN_REPAYMENTS = 'principal_payment_original',
  ORIGINAL_CURRENCY_GROSS_INTEREST = 'interest_payment_original',
  ORIGINAL_CURRENCY_COMMISSION_PAID = 'fees_original',

  REPORT_CURRENCY_LOAN_REPAYMENTS = 'principal_payment_reporting',
  REPORT_CURRENCY_GROSS_INTEREST = 'interest_payment_reporting',
  REPORT_CURRENCY_COMMISSION_PAID = 'fees_reporting'
}

export const CASHFLOW_REPORTS_CURRENCY_COLUMNS: TableHeaderColumns = [
  {
    id: CashflowColIds.TRANSACTION_DATE,
    label: 'Payment Date',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.LOAN_ID,
    label: 'Loan ID',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.EXCHANGE_RATE,
    label: 'Exchange rate',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.REPORT_CURRENCY_LOAN_REPAYMENTS,
    label: 'Principal Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.REPORT_CURRENCY_GROSS_INTEREST,
    label: 'Interest Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.REPORT_CURRENCY_COMMISSION_PAID,
    label: 'Fee Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.TOTAL_PAYMENT,
    label: 'Total payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export const CASHFLOW_ORIGINAL_CURRENCY_COLUMNS: TableHeaderColumns = [
  {
    id: CashflowColIds.TRANSACTION_DATE,
    label: 'Payment Date',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.LOAN_ID,
    label: 'Loan ID',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.EXCHANGE_RATE,
    label: 'Exchange rate',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.ORIGINAL_CURRENCY_LOAN_REPAYMENTS,
    label: 'Principal Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.ORIGINAL_CURRENCY_GROSS_INTEREST,
    label: 'Interest Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.ORIGINAL_CURRENCY_COMMISSION_PAID,
    label: 'Fee Payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: CashflowColIds.TOTAL_PAYMENT,
    label: 'Total payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export enum TransactionsColIds {
  VERIFIED = 'CUSTOM_VERIFIED',
  SETTLEMENT_DATE = 'transaction_date',
  LOAN_ID = 'uniq',
  EXCHANGE_RATE = 'exchange_rate',
  TRANSACTION_TYPE = 'transaction_type',
  TOTAL_PAYMENT = 'total_payment'
}

export const TRANSACTIONS_CURRENCY_COLUMNS: TableHeaderColumns = [
  {
    id: TransactionsColIds.VERIFIED,
    label: 'Verified',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER,
    width: 8
  },
  {
    id: TransactionsColIds.SETTLEMENT_DATE,
    label: 'Settlement Date',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: TransactionsColIds.LOAN_ID,
    label: 'Loan ID',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: TransactionsColIds.EXCHANGE_RATE,
    label: 'Exchange rate',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: TransactionsColIds.TRANSACTION_TYPE,
    label: 'Transaction Type',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: TransactionsColIds.TOTAL_PAYMENT,
    label: 'Total payment',
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];
