import React, { useState } from 'react';
import cn from 'classnames';
import { DatePicker } from '../DatePicker';
import { PopperPlacementEnum } from 'src/components/DatePicker/DatePicker';
import styles from './DatePickerBlock.module.scss';

export enum DatePickersNames {
  START_DATE = 'startDate',
  END_DATE = 'endDate'
}

interface DatePickerBlockProps {
  dates: { startDate: Date; endDate: Date };
  selectedMonths: { startDate: number; endDate: number };
  onSetSelectedMonth: (id, value: number) => void;
  onDateChange: (id, date) => void;
  label?: string;
  labelClass?: string;
}

const DatePickerBlock = ({
  dates,
  selectedMonths,
  onSetSelectedMonth,
  onDateChange,
  label = 'Listing date:',
  labelClass
}: DatePickerBlockProps): JSX.Element => {
  const [isDatePickerOpen, setDatePickerOpen] = useState({ startDate: false, endDate: false });

  const handleOpenCalendar = (name: DatePickersNames) => {
    setDatePickerOpen(
      name === DatePickersNames.START_DATE ? { startDate: true, endDate: false } : { startDate: false, endDate: true }
    );
  };

  const handleCloseCalendars = () => setDatePickerOpen({ startDate: false, endDate: false });

  const startDateClassName = (date: Date) => (date > dates.endDate ? [styles.disabledDay] : []);
  const endDateClassName = (date: Date) => (date < dates.startDate ? [styles.disabledDay] : []);

  return (
    <div className={styles.datePickerBlock}>
      <span className={cn(styles.dateLabel, styles.dateText, { [labelClass]: labelClass })}>{label}</span>
      <DatePicker
        value={dates.startDate}
        selectedMonth={selectedMonths.startDate}
        onSetSelectedMonth={onSetSelectedMonth}
        onDateChange={onDateChange}
        id={DatePickersNames.START_DATE}
        onOpenCalendar={() => handleOpenCalendar(DatePickersNames.START_DATE)}
        onCloseCalendar={(date?: Date) => {
          handleCloseCalendars();
          onSetSelectedMonth(DatePickersNames.START_DATE, date ? date.getMonth() : dates.startDate.getMonth());
        }}
        isCalendarOpen={isDatePickerOpen.startDate}
        calendarLabel="Start Date"
        popperPlacement={PopperPlacementEnum.TOP_START}
        popperOffset={{ x: -9 }}
        maxDate={dates.endDate}
        getClassNames={startDateClassName}
      />

      <span className={cn(styles.dateDivider, styles.dateText)}>-</span>

      <DatePicker
        value={dates.endDate}
        minDate={dates.startDate}
        selectedMonth={selectedMonths.endDate}
        onSetSelectedMonth={onSetSelectedMonth}
        onDateChange={onDateChange}
        id={DatePickersNames.END_DATE}
        onOpenCalendar={() => handleOpenCalendar(DatePickersNames.END_DATE)}
        onCloseCalendar={(date?: Date) => {
          handleCloseCalendars();
          onSetSelectedMonth(DatePickersNames.END_DATE, date ? date.getMonth() : dates.endDate.getMonth());
        }}
        isCalendarOpen={isDatePickerOpen.endDate}
        calendarLabel="End Date"
        popperPlacement={PopperPlacementEnum.TOP_END}
        popperOffset={{ x: 9 }}
        getClassNames={endDateClassName}
      />
    </div>
  );
};

export default DatePickerBlock;
