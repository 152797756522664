import React, { memo } from 'react';
import { FieldNames, SelectOption } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';
import styles from '../../TypeBlock.module.scss';
import { CustomizedSelect } from 'src/components';
import { LOAN_TYPE_OPTIONS } from 'src/pages/PortfolioBuilderPage/AllocationPage/constants';

type SelectTypeBlockProps = {
  isViewMode: boolean;
  isEditMode: boolean;
  value: SelectOption;
  onChange: (value) => void;
  onBlur: () => void;
};

const SelectTypeBlock = memo<SelectTypeBlockProps>(({ isViewMode, isEditMode, value, onChange, onBlur }) => {
  return (
    <div className={styles.typeBlock}>
      {isViewMode || isEditMode ? (
        <div className={styles.allocationViewInfo}>
          <span className={styles.allocationViewLabel}>Type of Loans</span>
          <span className={styles.allocationViewValue}>{value?.label ? `${value?.label + 's'}` : null}</span>
        </div>
      ) : (
        <React.Fragment>
          <h3 className={styles.fieldLabelClass}>Type of Loan</h3>
          <CustomizedSelect
            isDisabled={isViewMode}
            name={FieldNames.LOAN_TYPE}
            value={value}
            options={LOAN_TYPE_OPTIONS}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="Type of Loan"
            isSearchable={false}
            customStyles={{
              container: { width: 226, padding: '0 12px' },
              valueContainer: {
                display: 'flex',
                justifyContent: 'flex-start'
              }
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
});

SelectTypeBlock.displayName = 'SelectTypeBlock';

export default SelectTypeBlock;
