import React, { memo } from 'react';
import cn from 'classnames';
import { CheckboxWithLabel } from 'src/components';
import styles from '../../TypeBlock.module.scss';

type AutoInvestProps = {
  isViewMode: boolean;
  isAutoApproval: boolean;
  onToggleAuthApproval: () => void;
};

const AutoInvestBlock = memo<AutoInvestProps>(({ isAutoApproval, isViewMode, onToggleAuthApproval }) => {
  return (
    <div className={styles.autoInvestBlock}>
      <span className={styles.fieldLabelClass}>Autoinvest</span>

      <CheckboxWithLabel
        title="Automatically confirm all investment opportunities"
        isChecked={isAutoApproval}
        onChange={() => (isViewMode ? null : onToggleAuthApproval())}
        classes={{
          labelRootClass: cn(styles.labelRootClass, { [styles.labelRootViewClass]: isViewMode }),
          labelTextClass: styles.labelTextClass,
          checkboxRootClass: cn(styles.checkboxRootClass, { [styles.checkboxRootViewClass]: isViewMode })
        }}
        uncheckedIconProps={{ uncheckedBoxClass: styles.unCheckedIcon }}
        checkedIconProps={{
          checkedBoxClass: styles.checkedIconBox,
          checkedIconClass: styles.checkedIconClass
        }}
      />
    </div>
  );
});

AutoInvestBlock.displayName = 'AutoInvestBlock';

AutoInvestBlock.displayName = 'AutoInvestBlock';

export default AutoInvestBlock;
