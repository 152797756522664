import { PortfolioBuilderReducer } from 'src/interfaces/portfolioBuilderReduxShapes';
import { mapServerAllocationToView } from 'src/pages/PortfolioBuilderPage/AllocationPage/helpers';
import { portfolioBuilderTypes, authActionTypes } from '../types';

const initialState = {
  allocations: [],
  total: 0,
  allocationDetails: null,
  constraints: null
};

export default (state = initialState, { type, payload }: { type: string; payload }): PortfolioBuilderReducer => {
  switch (type) {
    case portfolioBuilderTypes.PUT_ALLOCATIONS:
      return { ...state, allocations: payload.data, total: payload.total };

    case portfolioBuilderTypes.PUT_ALLOCATION_DETAILS:
      return { ...state, allocationDetails: mapServerAllocationToView(payload) };

    case portfolioBuilderTypes.CLEAR_ALLOCATION_DETAILS:
      return { ...state, allocationDetails: null };

    case portfolioBuilderTypes.PUT_LOAN_CONSTRAINTS:
      return { ...state, constraints: payload };

    case portfolioBuilderTypes.CLEAR_LOAN_CONSTRAINTS:
      return { ...state, constraints: null };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
