import React, { FC } from 'react';
import { IAllocationOptions } from 'src/interfaces';
import { CustomizedSelect } from 'src/atoms';
import styles from './PortfolioAllocation.module.scss';

export const PortfolioAllocation: FC<IAllocationOptions> = ({
  selectedAllocation,
  selectorOptions,
  onChangeValue,
  isSearchable,
  isError
}) => {
  const errorStyles = isError
    ? {
        container: { border: '1px solid rgba(224, 57, 28, 0.46)' },
        placeholder: { color: 'rgba(224, 57, 28, 1)' }
      }
    : {};

  return (
    <div className={styles.allocationSelector}>
      <div className={styles.title}>Portfolio Allocation:</div>

      <CustomizedSelect
        isSearchable={isSearchable}
        value={selectedAllocation}
        onChange={onChangeValue}
        options={selectorOptions}
        customStyles={{
          container: {
            width: 460,
            minHeight: 35,
            height: 35,
            padding: '1px 10px 0px 6px',
            ...(errorStyles?.container ?? {})
          },
          placeholder: {
            ...(errorStyles?.placeholder ?? {})
          },
          menuList: {
            maxHeight: 195
          },
          option: {
            textAlign: 'left',
            justifyContent: 'flex-start',
            padding: '0.2em 0.3em'
          }
        }}
      />
    </div>
  );
};

export default PortfolioAllocation;
