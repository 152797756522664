import React from 'react';
import cn from 'classnames';
import styles from './GreenBoxButton.module.scss';

interface GreenBoxButtonProps {
  children?: React.ReactNode;
  onClick?: (e?) => void;
  btnClass?: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'transparent' | 'blue' | 'red';
  height?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disabled?: boolean;
}

const GreenBoxButton = ({
  children = 'Sign In',
  onClick,
  btnClass,
  type = 'submit',
  disabled = false,
  color = 'transparent',
  height = 'xs'
}: GreenBoxButtonProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={cn(styles.btn, {
        [btnClass]: btnClass,
        [styles[`btn_${color}`]]: color,
        [styles[`btn_height_${height}`]]: height
      })}
    >
      {children}
    </button>
  );
};

export default GreenBoxButton;
