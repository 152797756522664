import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { apiTypes } from '../types';
import { getTokens } from 'src/redux/sagas/sagaHelpers/apiSettingsHelpers';
import { setApiTokens } from '../actions/apiActions';

function* getApiTokensWorker() {
  try {
    const res = yield call(getTokens);
    yield put(setApiTokens(res));
  } catch (error) {
    console.error('getCurrenciesWorker', error);
  }
}

export function* apiTokensWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(apiTypes.GET_API_TOKENS, getApiTokensWorker);
}
