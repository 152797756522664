import React from 'react';
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import selectStyles from './CurrencySelectStyles';
import arrow from 'src/assets/images/icons/currency_arrow.svg';
import styles from './CurrencySelect.module.scss';

const DropdownIndicator = (): JSX.Element => <ReactSVG src={arrow} className={styles.arrow} />;

interface CurrencySelectProps {
  name: string;
  value: { value: string; label: string };
  onBlur?: () => void;
  onChange: (value: { value: string; label: string }) => void;
  options: Array<{ value: string; label: string }>;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  isDisabled?: boolean;
  isSearchable?: boolean;
}

const DEFAULT_MENU_HEIGHT = 145;

const CurrencySelect = ({
  name = null,
  value,
  options,
  onBlur,
  onChange,
  minMenuHeight = DEFAULT_MENU_HEIGHT,
  maxMenuHeight = DEFAULT_MENU_HEIGHT,
  isDisabled = false,
  isSearchable = false
}: CurrencySelectProps): JSX.Element => (
  <Select
    isSearchable={isSearchable}
    name={name}
    options={options}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    isDisabled={isDisabled}
    minMenuHeight={minMenuHeight}
    maxMenuHeight={maxMenuHeight}
    placeholder="Select"
    components={{ DropdownIndicator }}
    styles={selectStyles}
  />
);
export default CurrencySelect;
