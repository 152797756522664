import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './AuthTitleBlock.module.scss';

interface TitleBlockProps {
  title: string;
  description?: string;
  link?: {
    linkLabel: string;
    path;
  };
  classes?: {
    blockClass?: string;
    titleClass?: string;
    descriptionClass?: string;
    linkClass?: string;
  };
}

const AuthTitleBlock = ({ title, description = '', link, classes }: TitleBlockProps): JSX.Element => {
  const showDescription = description || (link?.path && link?.linkLabel);

  return (
    <div
      className={cn(styles.titleBlock, {
        [styles.extraMargin]: !showDescription,
        [classes?.blockClass]: classes?.blockClass
      })}
    >
      <h2
        className={cn(styles.title, {
          [styles.hideMargin]: !showDescription,
          [classes?.titleClass]: classes?.titleClass
        })}
      >
        {title}
      </h2>

      {showDescription && (
        <p className={cn(styles.description, { [classes?.descriptionClass]: classes?.descriptionClass })}>
          {description}
          {link?.path && link?.linkLabel && (
            <React.Fragment>
              &nbsp;
              <Link to={link.path} className={cn(styles.link, { [classes?.linkClass]: classes?.linkClass })}>
                {link.linkLabel}
              </Link>
            </React.Fragment>
          )}
        </p>
      )}
    </div>
  );
};

export default AuthTitleBlock;
