import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { formatDateFromJSON } from 'src/helpers';
import Loader from 'react-spinners/PuffLoader';
import { Fade } from '@mui/material';
import {
  Typography,
  CustomizedTable,
  PortfolioAllocation,
  ExportAllBlock,
  ContentHeadingBox,
  ContentBodyBox
} from 'src/components';
import { investmentsTypes } from 'src/redux/types';
import {
  currencySelector,
  investmentsFiltersSelector,
  opportunitiesSelector,
  totalOpportunitiesSelector
} from 'src/redux/selectors';
import {
  bidOpportunities,
  getFilteredOpportunities,
  getInvestmentsFilters
} from 'src/redux/actions/investmentsActions';
import {
  LoanSortTypes,
  OPPORTUNITIES_TABLE_HEADINGS_LIST,
  GET_OPPORTUNITIES_DEFAULT_PARAMS
} from 'src/constants/opportunitiesContants';
import { GetOpportunitiesParams, BidOpportunitiesReturnType } from 'src/interfaces';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { SortOrders } from 'src/constants/globalConstants';
import { PAGES, DYNAMIC_PAGES } from 'src/constants/pages';
import { getAllocationHook, useUserValues } from 'src/hooks';
import { exportFileApi } from 'src/utils/comon';
import OpportunitiesInvest from './OpportunitiesInvest';
import { getExportOpportunitiesUrl, renderSummaryContent } from './helpers';
import { ModePage, LoanChecked, DEFAULT_OPPORTUNITIES_PAGE } from './constants';
import { IAffected } from './model';
import styles from './OpportunitiesContent.module.scss';

const LOADER_SIZE = 80;

const OpportunitiesContent = (): JSX.Element => {
  const { push } = useHistory();
  const { accessToken } = useUserValues();

  const dispatch = useDispatch();
  const currency = useSelector(currencySelector);

  const totalOpportunities = useSelector(totalOpportunitiesSelector);

  const { allocationsWithOpportunities: allocations } = useSelector(investmentsFiltersSelector);
  const { data: opportunities, page, pagesCount } = useSelector(opportunitiesSelector);

  const [isAllocationsLoading, setAllocationsLoading] = useState(true);
  const [isOpportunitiesLoading, setOpportunitiesLoading] = useState(false);
  const [oppReqParams, setOppReqParams] = useState<GetOpportunitiesParams>(GET_OPPORTUNITIES_DEFAULT_PARAMS());
  const [isSelectAllChecked, setSelectAllChecked] = useState(true);
  const [isInvested, setIsInvested] = useState(false);
  const [opportunitiesChecked, setOpportunitiesChecked] = useState<Array<LoanChecked>>([]);
  const [mode, setMode] = useState(ModePage.INVEST_ALL);

  const [affectedInfo, setAffectedInfo] = useState<IAffected>(null);
  const onCloseResultModal = useCallback(() => {
    push(PAGES.OUTSTANDING_BIDS);
    setAffectedInfo(null);
    setIsInvested(false);
  }, [push]);

  const onAllocationChange = useCallback(
    (id: string) => {
      setMode(ModePage.INVEST_ALL);
      setOppReqParams({ ...GET_OPPORTUNITIES_DEFAULT_PARAMS(), allocationId: id, currency: currency.value });
    },
    [currency.value]
  );

  const { selectedAllocation, selectorOptions, onChangeValue } = getAllocationHook({
    allocations,
    onAllocationChange,
    isOpportunities: true
  });

  const loanType = useMemo(() => selectedAllocation?.type ?? null, [selectedAllocation]);

  const onOpportunitiesChecked = useCallback(
    ({ isChecked = true, isDisabled = false }: { isChecked?: boolean; isDisabled?: boolean }) => {
      setOpportunitiesChecked(() =>
        opportunities.reduce((arr, opp) => [...arr, { id: opp.id, isChecked, isDisabled }], [])
      );
    },
    [opportunities]
  );

  useMemo(() => {
    setSelectAllChecked(opportunitiesChecked.every(({ isChecked }) => isChecked));
  }, [opportunitiesChecked]);

  const handleChangeMode = (mode) => {
    setMode(mode);
    const isInvestAllMode = ModePage.INVEST_ALL === mode;
    onOpportunitiesChecked({ isChecked: isInvestAllMode, isDisabled: isInvestAllMode });
  };

  const handleSelectAllChange = useCallback(() => {
    setSelectAllChecked((state) => !state);
    onOpportunitiesChecked({ isChecked: !isSelectAllChecked });
  }, [isSelectAllChecked, onOpportunitiesChecked]);

  const handleCheckLoanChange = useCallback((id: string) => {
    setOpportunitiesChecked((opp) => opp.map((opp) => (opp.id === id ? { ...opp, isChecked: !opp.isChecked } : opp)));
  }, []);

  const handleChangePage = (e: React.ChangeEvent, page: number) => {
    setOppReqParams((state) => ({ ...state, page }));
  };

  const handleSortTable = (type: LoanSortTypes, order: SortOrders) => {
    setOppReqParams((prev) => ({ ...prev, sort: { type, order } }));
  };

  useEffect(() => {
    setOppReqParams((state) => ({ ...state, currency: currency.value, page: DEFAULT_OPPORTUNITIES_PAGE }));
  }, [currency.value]);

  useEffect(() => {
    if (!opportunities?.length) return;

    const isInvestAllMode = mode === ModePage.INVEST_ALL;
    onOpportunitiesChecked({ isChecked: isInvestAllMode, isDisabled: isInvestAllMode });
  }, [mode, onOpportunitiesChecked, opportunities]);

  useEffect(() => {
    dispatch(getInvestmentsFilters(true, () => (totalOpportunities ? null : setAllocationsLoading(false))));

    return () => {
      dispatch({ type: investmentsTypes.CLEAR_OPPORTUNITIES });
    };
  }, [dispatch, totalOpportunities]);

  useEffect(() => {
    if (!selectedAllocation || !oppReqParams?.allocationId) return;

    setOpportunitiesLoading(true);
    dispatch(
      getFilteredOpportunities(oppReqParams, () => {
        setAllocationsLoading(false);
        setOpportunitiesLoading(false);
      })
    );
  }, [selectedAllocation, oppReqParams, dispatch]);

  const handleInvest = useCallback(() => {
    const ids =
      ModePage.INVEST_ALL === mode
        ? opportunities.map(({ id }) => id)
        : opportunitiesChecked.reduce((acc, { isChecked, id }) => (isChecked ? [...acc, id] : acc), []);

    const reqData = {
      allocation: selectedAllocation?.value,
      ids
    };

    dispatch(
      bidOpportunities(reqData, ({ affected }: BidOpportunitiesReturnType) => {
        setAffectedInfo({ affected, failed: ids.length - affected });
        setIsInvested(true);
      })
    );
  }, [dispatch, mode, opportunitiesChecked, opportunities, selectedAllocation?.value]);

  const onConfirmExport = useCallback(
    async (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => {
      const fileName = `Exaloan opportunities ${formatDateFromJSON(new Date(), 'MM/dd/yyyy')}`;
      const fileUrl = getExportOpportunitiesUrl(oppReqParams?.allocationId, currency.value, fileType);

      await exportFileApi(fileUrl, fileName, fileType, accessToken);
      onDownloadedSuccess();
    },
    [accessToken, currency.value, oppReqParams?.allocationId]
  );

  const getSummaryContent = useCallback(
    (id, row) => {
      const targetChecked = opportunitiesChecked.find((item) => row.id === item.id);

      return renderSummaryContent(id, row, targetChecked, handleCheckLoanChange, currency.value);
    },
    [opportunitiesChecked, handleCheckLoanChange, currency]
  );

  const onGetDetailsPath = useCallback((row) => DYNAMIC_PAGES.OPPORTUNITY_DETAILS(row?.loanId), []);

  const paginationData = useMemo(
    () => ({
      currentPage: oppReqParams?.page,
      perPage: oppReqParams?.limit,
      onChangePage: handleChangePage,
      loadMorePagination: pagesCount > page
    }),
    [oppReqParams, pagesCount, page]
  );

  const countCheckedOpportunities = useMemo(
    () => opportunitiesChecked.filter((item) => item.isChecked)?.length,
    [opportunitiesChecked]
  );

  const showOpportunities = !!selectedAllocation && !!opportunities?.length;

  const showOuterLoader =
    (!!selectedAllocation && isAllocationsLoading && !opportunities?.length) ||
    (!selectedAllocation && isAllocationsLoading && !opportunities?.length);

  const showNoDataOuterMsg =
    (!!selectedAllocation && !isAllocationsLoading && !opportunities?.length && !isOpportunitiesLoading) ||
    (!selectedAllocation && !isAllocationsLoading && !opportunities?.length);

  const hasPagination = pagesCount > page;

  return (
    <React.Fragment>
      <ContentHeadingBox wrapperClass={styles.contentHeadingBox}>
        <div className={styles.contentHead}>
          <Typography variant="subtitle1">Confirm Investments</Typography>

          <PortfolioAllocation
            selectedAllocation={selectedAllocation}
            selectorOptions={selectorOptions}
            onChangeValue={onChangeValue}
          />
        </div>
      </ContentHeadingBox>

      <Fade in={showOpportunities}>
        <div>
          <ContentBodyBox
            wrapperClass={cn(styles.opportunities, {
              [styles.opportunitiesShown]: showOpportunities,
              [styles.opportunitiesWithLoadMore]: hasPagination
            })}
          >
            <div className={cn(styles.opportunities_control, styles.dateAndFilterWrapper)}>
              <div className={styles.opportunities_control_right}>
                <div className={styles.opportunities_control_selectedNumber}>
                  Selected Opportunities: {countCheckedOpportunities}
                </div>

                <OpportunitiesInvest
                  opportunities={countCheckedOpportunities}
                  onConfirmInvest={handleInvest}
                  isInvested={isInvested}
                  buttonText={ModePage.INVEST_ALL === mode ? 'Invest All' : 'Invest Selected'}
                  affectedInfo={affectedInfo}
                  onCloseResultModal={onCloseResultModal}
                />

                <button
                  type="button"
                  className={styles.clearFiltersBtn}
                  onClick={() =>
                    handleChangeMode(ModePage.INVEST_ALL === mode ? ModePage.INVEST_SELECTED : ModePage.INVEST_ALL)
                  }
                >
                  {ModePage.INVEST_ALL === mode ? 'Edit selection' : 'Select all'}
                </button>
              </div>

              <ExportAllBlock onConfirmExport={onConfirmExport} selectWrapperClass={styles.fileTypeSelectWrapper} />
            </div>

            <CustomizedTable
              columns={loanType ? OPPORTUNITIES_TABLE_HEADINGS_LIST(loanType) : []}
              rows={opportunities}
              shouldShowList={!!opportunities?.length}
              hasPagination={hasPagination}
              getSummaryContent={getSummaryContent}
              onGetDetailsPath={onGetDetailsPath}
              onSort={handleSortTable}
              paginationData={paginationData}
              isLoading={isAllocationsLoading || isOpportunitiesLoading}
              emptyRowsMsg="No data available at the moment"
              onSelectAllChange={handleSelectAllChange}
              isSelectAllChecked={isSelectAllChecked}
              isSelectAllDisabled={mode === ModePage.INVEST_ALL}
              guttersRight={16}
              guttersLeft={16}
            />
          </ContentBodyBox>
        </div>
      </Fade>

      {isAllocationsLoading ? (
        <Fade in={showOuterLoader} unmountOnExit>
          <div className={styles.loaderWrapper}>
            <Loader size={LOADER_SIZE} color="#077186" />
          </div>
        </Fade>
      ) : (
        <Fade in={!showOuterLoader && showNoDataOuterMsg} unmountOnExit>
          <h2 className={styles.noOpportunities}>No available opportunities</h2>
        </Fade>
      )}
    </React.Fragment>
  );
};

export default OpportunitiesContent;
