import { authActionTypes } from '../types';
import { UserData } from 'src/interfaces/authReduxShapes';

export default (state = null, { type, payload }: { type: string; payload }): UserData => {
  switch (type) {
    case authActionTypes.PUT_USER:
      return payload;

    case authActionTypes.SIGN_OUT:
      return null;

    default:
      return state;
  }
};
