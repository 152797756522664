import React, { FC } from 'react';
import cn from 'classnames';
import { StatementBox, RangeBar } from 'src/components';
import { RANGE_FILTERS } from 'src/constants/portfolioBuilderConstants';
import { InvestmentPercentage } from './components';
import { RangeFilters, RangeFiltersTypes } from '../../../model';
import styles from './GeneralConstraints.module.scss';
import { LoanTypes } from 'src/constants/globalConstants';

type Props = {
  loanType: LoanTypes;
  isViewPage: boolean;
  investmentPercentage: number;
  onInvestmentChange: (value: number) => void;
  investmentCurrency: string;
  rangeFilters: RangeFilters;
  onRangeChange: (id, value: number[]) => void;
};

export const MIN_INVESTMENT_PERCENTAGE = 1;

const getAfterClass = (id: RangeFiltersTypes): string => {
  return {
    [RangeFiltersTypes.LOAN_AMOUNT]: styles.loanAmountAfter,
    [RangeFiltersTypes.INTEREST_RATE]: styles.interestAfter,
    [RangeFiltersTypes.TERM]: styles.termAfter
  }[id];
};

const GeneralConstraints: FC<Props> = ({
  loanType,
  isViewPage,
  investmentPercentage,
  onInvestmentChange,
  investmentCurrency,
  rangeFilters,
  onRangeChange
}) => {
  const hasPercentageError = Math.floor(investmentPercentage) < MIN_INVESTMENT_PERCENTAGE;
  return (
    <StatementBox className={styles.wrapper}>
      <InvestmentPercentage
        isViewCase={isViewPage}
        onInvestmentChange={onInvestmentChange}
        investmentPercentage={investmentPercentage}
        isError={hasPercentageError}
        titleClass={styles.titleClass}
        investmentLabelClass={styles.investmentLabelClass}
        afterClass={styles.investmentAfterClass}
      />

      <div className={styles.rangeBlock}>
        {RANGE_FILTERS(investmentCurrency, loanType).map(({ id, label, valueLabels }) => {
          const currentValue = rangeFilters?.[id];
          const isError = currentValue.min > currentValue.max || (!currentValue.min && !currentValue.max);

          const isTerm = id === RangeFiltersTypes.TERM;
          const decimalScale = isTerm ? 0 : 2;

          return (
            <RangeBar
              key={label}
              id={id}
              label={label}
              values={currentValue}
              onRangeChange={onRangeChange}
              valueLabels={valueLabels}
              disabled={isViewPage}
              isError={isError}
              decimalScale={decimalScale}
              labelClass={styles.rangeLabel}
              afterClass={cn(styles.rangeAfter, getAfterClass(id))}
            />
          );
        })}
      </div>
    </StatementBox>
  );
};

export default GeneralConstraints;
