import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import styles from './FormRow.module.scss';

type TFormRowProps = {
  children: React.ReactNode;
  label: string;
};

const FormRow: FC<TFormRowProps> = memo(({ children, label }) => (
  <Box className={styles.formRow}>
    <span className={styles.formRowLabel}>{label}</span>

    <Box>{children}</Box>
  </Box>
));

FormRow.displayName = 'FormRow';

export default FormRow;
