import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateFromJSON } from 'src/helpers';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import { useHistory, Redirect } from 'react-router-dom';
import { MainLayout, CustomizedTable, ContentHeadingBox, ContentBodyBox } from 'src/components';
import { getMarketplaceLoans } from 'src/redux/actions/marketplaceActions';
import { getLoanFilters, getLoansSummary } from 'src/redux/actions/baseActions';
import {
  marketplaceLoansSelector,
  baseSummarySelector,
  baseFiltersSelector,
  currencySelector,
  baseReducerSelector
} from 'src/redux/selectors';
import { getTotalPagesCount } from 'src/helpers/tableHelpers';
import { createFiltersArray, getFiltersRequestObject } from 'src/helpers/marketplaceHelpers';
import {
  MARKETPLACE_COLUMNS,
  RangeFiltersTypes,
  LoanSortTypes,
  SET_RANGES_PARAMS_DEBOUNCE_TIMEOUT,
  GET_LOANS_DEFAULT_PARAMS,
  smeFilterIds,
  consumerFilterIds,
  subPagesByLoanTypes,
  MARKETPLACE_RANGE_FILTERS,
  invoiceFinancingFilterIds
} from 'src/constants/marketplaceConstants';
import { SortOrders, LoanTypes } from 'src/constants/globalConstants';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { currencySymbols } from 'src/constants/globalConstants';
import { GetMarketplaceLoansParams } from 'src/interfaces/marketplaceReduxShapes';
import { PAGES, DYNAMIC_PAGES } from 'src/constants/pages';
import { marketplaceTypes } from 'src/redux/types';
import { exportFileApi } from 'src/utils/comon';
import { useUserValues } from 'src/hooks';
import { LoanTypesBlock, FiltersBlock } from './components';
import { renderSummaryContent, getLoanPurpose, getExportLoansUrl } from './helpers';
import { REQUIRED_REQ_PARAMS } from './constants';
import styles from './MarketplacePage.module.scss';
import { usePickerDates } from './hooks';

export const MarketplaceGlobal = (): JSX.Element => <Redirect to={PAGES.MARKETPLACE_CONSUMER} />;

const MarketplacePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { accessToken } = useUserValues();

  const { loans, total, hasMore } = useSelector(marketplaceLoansSelector);
  const rawFilters = useSelector(baseFiltersSelector);

  const rangesData = useSelector(baseSummarySelector);
  const currency = useSelector(currencySelector);
  const { isFiltersLoading, isSummaryLoading } = useSelector(baseReducerSelector);

  const isInit = useRef(false);
  const isFiltered = useRef(false);

  const {
    push,
    location: { pathname }
  } = useHistory();

  const loanType = useMemo(
    () =>
      pathname
        .slice(1, pathname.length + 1)
        .split('/')[1]
        .replace(/-/g, '_')
        .toUpperCase() as LoanTypes,
    [pathname]
  );

  const isConsumer = useMemo(() => loanType === LoanTypes.CONSUMER, [loanType]);

  const [filters, setFilters] = useState(rawFilters);

  const [loansReqParams, setLoansReqParams] = useState<GetMarketplaceLoansParams>({
    ...GET_LOANS_DEFAULT_PARAMS,
    type: loanType
  });

  const [rangeFilters, setRangeFilters] = useState({ ...rangesData });

  const [isTableLoading, setTableLoading] = useState(true);

  const onDebouncedSerRangeParams = debounce((rangeFiltersValue) => {
    setLoansReqParams((state) => ({ ...state, rangeFilters: rangeFiltersValue, page: 1 }));
  }, SET_RANGES_PARAMS_DEBOUNCE_TIMEOUT);

  useEffect(() => {
    setFilters({
      ...rawFilters,
      platforms: rawFilters.platforms.filter((platform) => platform.loan_type.includes(loanType))
    });
  }, [rawFilters, loanType]);

  const handleChangePage = (e: React.ChangeEvent, page: number) => {
    setLoansReqParams((state) => ({ ...state, page }));
  };

  const handleChangePerPage = (perPage: { value: number }) => {
    setLoansReqParams((state) => ({ ...state, limit: perPage.value }));
  };

  const handleClearLoans = useCallback((): void => {
    setTableLoading(true);
    dispatch({ type: marketplaceTypes.CLEAR_LOANS });
  }, [dispatch]);

  const handleChangeLoanType = (type: LoanTypes) => {
    handleClearLoans();
    push(subPagesByLoanTypes[type]);
  };

  const handleSortTable = (type: LoanSortTypes, order: SortOrders) => {
    handleClearLoans();
    setLoansReqParams((prev) => ({ ...prev, sort: { type, order }, page: 1 }));
  };

  const handleRangeChange = useCallback(
    (id: RangeFiltersTypes, value: number[]) => {
      const [min, max] = value;
      let rangeParams;

      setRangeFilters((state) => {
        rangeParams = { ...state, [id]: { min, max } };
        return { ...state, [id]: { min, max } };
      });
      handleClearLoans();
      onDebouncedSerRangeParams(rangeParams);
    },
    [onDebouncedSerRangeParams, handleClearLoans]
  );

  const handleRangeSliderChange = useCallback((id: RangeFiltersTypes, value: number[]) => {
    const [min, max] = value;
    setRangeFilters((state) => ({ ...state, [id]: { min, max } }));
  }, []);

  const [filtersArray, setFiltersArray] = useState(undefined);

  const handleFilterChange = useCallback(
    (name: string, id: string) => {
      handleClearLoans();

      setFiltersArray((prevState) => {
        return prevState.map((block) => {
          if (block.id !== name) return block;

          const list = block.list.map((item) => ({
            ...item,
            isChecked: item.id === id ? !item.isChecked : item.isChecked
          }));

          const isAllChecked = list.every(({ isChecked }) => isChecked);

          return {
            ...block,
            isAllChecked,
            list
          };
        });
      });
    },
    [handleClearLoans]
  );

  const handleSelectAll = useCallback((name: string) => {
    setFiltersArray((state) =>
      state.map((block) =>
        block.id === name
          ? {
              ...block,
              isAllChecked: !block.isAllChecked,
              list: block.list.map((item) => ({ ...item, isChecked: !block.isAllChecked }))
            }
          : block
      )
    );
  }, []);

  useEffect(() => {
    const { sectors, smePurposes, platforms, loanTypes, grades, countries } = filters;
    const areFiltersSet = [sectors, smePurposes, platforms, loanTypes, grades, countries].every((v) => v.length);

    if (areFiltersSet) {
      setFiltersArray(createFiltersArray(filters, true));
      isFiltered.current = true;
    }
  }, [filters]);

  useEffect(() => {
    if (!isFiltered.current || !filtersArray) return;

    const {
      countries,
      grades,
      loanTypes,
      platforms,
      sectors,
      smePurposes,
      relative: relativeFilters
    } = getFiltersRequestObject(filtersArray, isConsumer);

    const { country, loan_type, platform, grade, smePurpose, sector, relative } = loansReqParams;

    if ([countries, loanTypes, platforms, grades, smePurposes, sectors, relativeFilters].every(isNil)) return;

    const isEqualWithReqParams = [
      [country, countries],
      [loan_type, loanTypes],
      [platform, platforms],
      [grade, grades],
      [smePurpose, smePurposes],
      [sector, sectors],
      [relative, relativeFilters]
    ].every((subArr) => isEqual(subArr[0], subArr[1]));

    if (isEqualWithReqParams) return;

    setLoansReqParams((state) => ({
      ...state,
      country: countries,
      loan_type: loanTypes,
      platform: platforms,
      grade: grades,
      sector: sectors,
      smePurpose: smePurposes,
      relative: relativeFilters,
      page: 1
    }));

    isInit.current = true;
  }, [loansReqParams, filtersArray, isConsumer]);

  useEffect(
    () => () => {
      handleClearLoans();
    },
    [handleClearLoans]
  );

  useEffect(() => {
    dispatch(getLoanFilters());
    dispatch(
      getLoansSummary(loanType, currency.value, (data) => {
        setLoansReqParams((prev) => ({ ...prev, rangeFilters: data }));
      })
    );
  }, [dispatch, loanType, currency?.value]);

  useEffect(() => {
    setRangeFilters(rangesData);
  }, [rangesData]);

  const paramsRef = useRef(null);

  useEffect(() => {
    const shouldRequest = REQUIRED_REQ_PARAMS.every((key) => Object.keys(loansReqParams).includes(key));
    if (isFiltersLoading || isSummaryLoading || !shouldRequest) return;
    if (!isFiltered.current || !isInit.current) return;

    setTableLoading(true);

    const {
      countries,
      grades,
      loanTypes,
      platforms,
      sectors,
      smePurposes,
      relative: relativeFilters
    } = getFiltersRequestObject(filtersArray, isConsumer, true);

    const params = {
      ...loansReqParams,
      country: countries,
      loan_type: loanTypes,
      platform: platforms,
      grade: grades,
      sector: sectors,
      smePurpose: smePurposes,
      relative: relativeFilters
    };

    if (isEqual(paramsRef.current, params)) return;

    paramsRef.current = params;

    dispatch(
      getMarketplaceLoans(
        { ...params, currency: currency.value },
        () => setTableLoading(false),
        () => setTableLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loansReqParams, currency, isFiltersLoading, isSummaryLoading]);

  const { selectedDates, selectedMonths, handleSetSelectedMonth, handleDateChange, onSetInitialDates } = usePickerDates(
    setLoansReqParams,
    handleClearLoans
  );

  const onClearFilters = useCallback(() => {
    const initialFiltersArray = createFiltersArray(filters, true);
    const { countries, grades, loanTypes, platforms, sectors, smePurposes, relative } = getFiltersRequestObject(
      initialFiltersArray,
      isConsumer
    );

    setLoansReqParams({
      ...GET_LOANS_DEFAULT_PARAMS,
      type: loanType,
      country: countries,
      loan_type: loanTypes,
      platform: platforms,
      grade: grades,
      sector: sectors,
      smePurpose: smePurposes,
      relative
    });

    setFiltersArray(initialFiltersArray);
    setRangeFilters({ ...rangesData });
    onSetInitialDates();
  }, [loanType, isConsumer, rangesData, filters, onSetInitialDates]);

  const onConfirmExport = useCallback(
    async (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => {
      const fileName = `Exaloan ${loanType.toLowerCase()} loans ${formatDateFromJSON(new Date(), 'MM/dd/yyyy')}`;
      const fileUrl = getExportLoansUrl(loansReqParams, currency.value, fileType);

      await exportFileApi(fileUrl, fileName, fileType, accessToken);
      onDownloadedSuccess();
    },
    [accessToken, loansReqParams, loanType, currency.value]
  );

  const columns = useMemo(
    () => MARKETPLACE_COLUMNS.filter((column) => column.loanTypes?.includes(loanType)),
    [loanType]
  );

  const getSummaryContent = useCallback(
    (id, row) => {
      const loanPurpose = getLoanPurpose({
        loanData: row,
        loanTypes: rawFilters.loanTypes,
        smePurposes: rawFilters.smePurposes
      });

      return renderSummaryContent(id, row, currencySymbols[currency.value], loanPurpose ?? row.loan_purpose);
    },
    [currency, rawFilters]
  );

  const onGetDetailsPath = useCallback((row) => DYNAMIC_PAGES.MARKETPLACE_DETAILS(row?.id, loanType), [loanType]);

  const pickerProps = useMemo(
    () => ({
      dates: selectedDates,
      selectedMonths,
      onDateChange: handleDateChange,
      onSetSelectedMonth: handleSetSelectedMonth
    }),
    [selectedDates, selectedMonths, handleDateChange, handleSetSelectedMonth]
  );

  const rangeProps = useMemo(
    () => ({
      onValueChange: handleRangeChange,
      onSliderChange: handleRangeSliderChange,
      rangeFilters,
      rangesData,
      list: MARKETPLACE_RANGE_FILTERS(currency.value, loanType),
      isWider: (id) => id === RangeFiltersTypes.LOAN_AMOUNT
    }),
    [rangeFilters, rangesData, handleRangeChange, handleRangeSliderChange, currency]
  );

  const filterProps = useMemo(() => {
    const actualFilters = filtersArray?.filter((val) => {
      switch (loanType) {
        case LoanTypes.CONSUMER:
          return consumerFilterIds.includes(val.id);
        case LoanTypes.SME:
          return smeFilterIds.includes(val.id);
        case LoanTypes.INVOICE_FINANCING:
          return invoiceFinancingFilterIds.includes(val.id);
      }
    });

    return {
      filters: actualFilters ?? [],
      onChangeFilters: handleFilterChange,
      onSelectAllFilters: handleSelectAll
    };
  }, [filtersArray, isConsumer, handleFilterChange, handleSelectAll]);

  const paginationData = useMemo(
    () => ({
      currentPage: loansReqParams.page,
      perPage: loansReqParams.limit,
      totalPages: getTotalPagesCount(total, loansReqParams.limit),
      onChangePage: handleChangePage,
      onChangePerPage: handleChangePerPage,
      loadMorePagination: hasMore
    }),
    [loansReqParams, hasMore, total]
  );

  const rows = useMemo(() => loans.map((val) => ({ ...val, loanType })), [loanType, loans]);
  return (
    <MainLayout isEmptySkeleton>
      <ContentHeadingBox>
        <LoanTypesBlock onChangeLoanType={handleChangeLoanType} loanType={loanType} />
      </ContentHeadingBox>

      <ContentBodyBox wrapperClass={cn({ [styles.contentBodyBox]: hasMore })}>
        <FiltersBlock
          onClearFilters={onClearFilters}
          onConfirmExport={onConfirmExport}
          pickerProps={pickerProps}
          rangeProps={rangeProps}
          filterProps={filterProps}
        />

        <CustomizedTable
          columns={columns}
          rows={rows}
          hasPagination={!!rows?.length}
          shouldShowList={!!rows?.length}
          getSummaryContent={getSummaryContent}
          onGetDetailsPath={onGetDetailsPath}
          onSort={handleSortTable}
          paginationData={paginationData}
          isLoading={isTableLoading || !isInit.current}
          emptyRowsMsg="No data available at the moment"
          guttersLeft={20}
          guttersRight={20}
        />
      </ContentBodyBox>
    </MainLayout>
  );
};

export default MarketplacePage;
