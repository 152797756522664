import React from 'react';
import cn from 'classnames';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px'
    }
  })
);

interface ModalLayoutProps {
  children: React.ReactNode;
  wrapperClass?: string;
}

const ModalLayout = ({ children, wrapperClass }: ModalLayoutProps): JSX.Element => {
  const { paper } = useStyles();
  return <div className={cn(paper, { [wrapperClass]: wrapperClass })}>{children}</div>;
};

export default ModalLayout;
