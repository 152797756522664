import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TrashBinIcon: FC<SvgIconProps> = ({ width = 11, height = 12 }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ fill: 'transparent', width, height }}
    >
      <path
        d="M1 3H10M2 3V10C2 10.5523 2.44771 11 3 11H8C8.5523 11 9 10.5523 9 10V3M3.5 3V2C3.5 1.44771 3.94771 1 4.5 1H6.5C7.0523 1 7.5 1.44771 7.5 2V3"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 5.5V8.5" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.49609 5.5V8.5" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const WorkBagIcon: FC<SvgIconProps> = ({ width = 12, height = 12 }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ fill: 'transparent', width, height }}
    >
      <path
        d="M1 4.5C1 3.94771 1.44771 3.5 2 3.5H10C10.5523 3.5 11 3.94771 11 4.5V10C11 10.5523 10.5523 11 10 11H2C1.44771 11 1 10.5523 1 10V4.5Z"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.5V2C8 1.44771 7.5523 1 7 1H5C4.44771 1 4 1.44771 4 2V3.5"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 6H1" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 6V7" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 6V7" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const LaunchLinkIcon: FC<SvgIconProps> = ({ width = 12, height = 12 }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ fill: 'transparent', width, height }}
    >
      <path d="M8.36719 1H10.9988V3.63158" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.4737 6.78953V9.94742C10.4737 10.5288 10.0024 11.0001 9.42105 11.0001H2.05263C1.47128 11.0001 1 10.5288 1 9.94742V2.579C1 1.99765 1.47128 1.52637 2.05263 1.52637H5.21053"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.26172 5.73687L10.7354 1.26318" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const ReportsSidebarIcon: FC<SvgIconProps> = ({ width = 15, height = 19 }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ fill: 'transparent', width, height }}
    >
      <path
        d="M15 5.81433H12V18.5643H15V5.81433ZM3 9.56433V18.5643H0V9.56433H3ZM9 18.5643H6V0.564331H9V18.5643Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export const AddManualTransactionIcon: FC<SvgIconProps> = ({ width = 18, height = 18 }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ fill: 'transparent', width, height }}
    >
      <path
        d="M9.9 10.8H11.7V8.1H14.4V6.3H11.7V3.6H9.9V6.3H7.2V8.1H9.9V10.8ZM5.4 14.4C4.905 14.4 4.48125 14.2238 4.12875 13.8713C3.77625 13.5188 3.6 13.095 3.6 12.6V1.8C3.6 1.305 3.77625 0.88125 4.12875 0.52875C4.48125 0.17625 4.905 0 5.4 0H16.2C16.695 0 17.1188 0.17625 17.4713 0.52875C17.8238 0.88125 18 1.305 18 1.8V12.6C18 13.095 17.8238 13.5188 17.4713 13.8713C17.1188 14.2238 16.695 14.4 16.2 14.4H5.4ZM5.4 12.6H16.2V1.8H5.4V12.6ZM1.8 18C1.305 18 0.88125 17.8238 0.52875 17.4713C0.17625 17.1188 0 16.695 0 16.2V3.6H1.8V16.2H14.4V18H1.8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export const ManualTransactionIcon: FC<SvgIconProps> = ({ width = 16, height = 16 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ fill: 'none', width, height }}>
      <path
        d="M1.77778 14.2222H3.04444L11.7333 5.53333L10.4667 4.26667L1.77778 12.9556V14.2222ZM0 16V12.2222L11.7333 0.511111C11.9111 0.348148 12.1074 0.222222 12.3222 0.133333C12.537 0.0444444 12.763 0 13 0C13.237 0 13.4667 0.0444444 13.6889 0.133333C13.9111 0.222222 14.1037 0.355556 14.2667 0.533333L15.4889 1.77778C15.6667 1.94074 15.7963 2.13333 15.8778 2.35556C15.9593 2.57778 16 2.8 16 3.02222C16 3.25926 15.9593 3.48519 15.8778 3.7C15.7963 3.91481 15.6667 4.11111 15.4889 4.28889L3.77778 16H0ZM11.0889 4.91111L10.4667 4.26667L11.7333 5.53333L11.0889 4.91111Z"
        fill="#000"
        fillOpacity="0.5"
      />
    </SvgIcon>
  );
};
export const DeleteTransactionIcon: FC<SvgIconProps> = ({ width = 15, height = 16 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ fill: 'none', width, height }}>
      <path
        d="M2.66667 16C2.17778 16 1.75926 15.8259 1.41111 15.4778C1.06296 15.1296 0.888889 14.7111 0.888889 14.2222V2.66667H0V0.888889H4.44444V0H9.77778V0.888889H14.2222V2.66667H13.3333V14.2222C13.3333 14.7111 13.1593 15.1296 12.8111 15.4778C12.463 15.8259 12.0444 16 11.5556 16H2.66667ZM11.5556 2.66667H2.66667V14.2222H11.5556V2.66667ZM4.44444 12.4444H6.22222V4.44444H4.44444V12.4444ZM8 12.4444H9.77778V4.44444H8V12.4444Z"
        fill="#000"
        fillOpacity="0.5"
      />
    </SvgIcon>
  );
};

export const UnverifiedTransactionIcon: FC<SvgIconProps> = ({ width = 17, height = 13 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ fill: 'none', width, height }}>
      <path d="M5.6263 12.025L0 6.325L1.40658 4.9L5.6263 9.175L14.6827 0L16.0893 1.425L5.6263 12.025Z" fill="#F4B400" />
    </SvgIcon>
  );
};

export const VerifiedTransactionIcon: FC<SvgIconProps> = ({ width = 22, height = 13 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ fill: 'none', width, height }}>
      <path
        d="M5.57695 12.025L0 6.375L1.40658 4.975L5.60163 9.225L6.98353 10.625L5.57695 12.025ZM11.1539 12.025L5.57695 6.375L6.95885 4.95L11.1539 9.2L20.235 0L21.6169 1.425L11.1539 12.025ZM11.1539 6.375L9.74733 4.975L14.6333 0.025L16.0399 1.425L11.1539 6.375Z"
        fill="#077186"
      />
    </SvgIcon>
  );
};
