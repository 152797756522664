import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from 'src/utils/ProtectedRoute';
import { ROUTE_OPTIONS } from './constants/routes';

type RoutesProps = {
  isAuth: boolean;
};

const Routes: FC<RoutesProps> = ({ isAuth }) => {
  return (
    <Switch>
      {ROUTE_OPTIONS.map((route) => {
        const { isPrivate, path, exact, component: Component } = route;

        return isPrivate ? (
          <ProtectedRoute
            key={path}
            isAuth={isAuth}
            path={path}
            exact={exact}
            component={Component}
            redirectTo={route?.redirectTo ?? null}
          />
        ) : (
          <Route key={path} path={path} exact={exact} component={Component} />
        );
      })}
    </Switch>
  );
};

export default Routes;
