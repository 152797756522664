import React, { FC } from 'react';
import { TableCell, TableRow, TableFooter } from '@mui/material';
import { WithLoading } from 'src/components';
import { PaginationProps } from 'src/interfaces/CustomTableShapes';
import { PaginationBlock } from '../PaginationBlock';
import { FakeRow } from '../rows/FakeRow';
import styles from './styles.module.scss';

export interface TFooterProps extends PaginationProps {
  isLoading: boolean;
  showLoadMorePagination: boolean;
  showDefaultPagination: boolean;
  colSpan: number;
  hasRows: boolean;
}

const LOAD_MORE_LOADER_SIZE = 55;

const TFooter: FC<TFooterProps> = ({
  isLoading,
  showLoadMorePagination,
  showDefaultPagination,
  colSpan,
  hasRows,
  ...rest
}) => {
  return (
    <TableFooter>
      <WithLoading
        isLoading={isLoading}
        loadingContent={
          <FakeRow
            isLoader={isLoading && hasRows}
            colSpan={colSpan}
            loaderBoxClasses={styles.ftrLoaderBox}
            loaderSize={LOAD_MORE_LOADER_SIZE}
          />
        }
      >
        <React.Fragment>
          {showLoadMorePagination ? (
            <SingleCellRow colSpan={colSpan}>
              <button
                type="button"
                className={styles.loadMoreButton}
                onClick={() => {
                  rest.onChangePage(undefined, rest.currentPage + 1);
                }}
              >
                Load More
              </button>
            </SingleCellRow>
          ) : null}

          {showDefaultPagination ? (
            <SingleCellRow colSpan={colSpan}>
              <PaginationBlock {...rest} />
            </SingleCellRow>
          ) : null}
        </React.Fragment>
      </WithLoading>
    </TableFooter>
  );
};

type SingleCellRowProps = {
  colSpan: number;
  children: React.ReactNode;
};

const SingleCellRow: FC<SingleCellRowProps> = ({ colSpan, children }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center" style={{ borderBottomColor: 'transparent', padding: 0 }}>
      {children}
    </TableCell>
  </TableRow>
);

export default TFooter;
