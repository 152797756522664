import React from 'react';
import cn from 'classnames';
import { getTotalOpportunitiesHook } from 'src/hooks';
import styles from './OpportunitiesSidebarIcon.module.scss';

const shouldBig = 100;

const OpportunitiesSidebarIcon = (): JSX.Element => {
  const { totalOpportunities } = getTotalOpportunitiesHook();

  return (
    <div
      className={cn(styles.countBox, {
        [styles.countBoxBig]: totalOpportunities >= shouldBig,
        [styles.noOpportunities]: !totalOpportunities
      })}
    >
      {totalOpportunities}
    </div>
  );
};

export default OpportunitiesSidebarIcon;
