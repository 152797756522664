import currencyjs from 'currency.js';
import isNumber from 'lodash/isNumber';
import capitalize from 'lodash/capitalize';
import { defaultCurrencyOpt, Currencies, currencySymbols } from 'src/constants/globalConstants';

export const formatPercentage = (x: number): string => {
  if (typeof x !== 'number') {
    return '';
  }

  return `${(x * 100).toFixed(2)}%`;
};

export const formatMoney = (x: number | string, currency?: string, opt = defaultCurrencyOpt, toFixed = 2): string => {
  if (typeof x !== 'number' && typeof x !== 'string') {
    return '';
  }

  const num = Number(x).toFixed(toFixed);

  return currencyjs(num || 0, {
    ...opt,
    symbol: currencySymbols[currency] || currencySymbols[Currencies.EUR]
  }).format();
};

export const formatNumber = (x: number, decimal = 1): number => {
  return Number.isInteger(x) ? x : Number(x.toFixed(decimal));
};

export const calculatePercentage = (total: number | number[], item: number): number => {
  const sumTotal = isNumber(total) ? total : total.reduce((a, b) => a + b, 0);

  return (item / sumTotal) * 100;
};

export const formatTitle = (str: string): string => {
  if (!str) {
    return '';
  }

  return capitalize(str?.includes('_') ? str.replace(/_/g, ' ') : str);
};

export const thousandSeparatorFormatter = (x: number, toFixed?: number): string => {
  return toFixed
    ? x.toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
