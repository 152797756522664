import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'src/redux/selectors';
import { MainLayout } from 'src/components';
import ButtonBack from 'src/atoms/buttons/buttonUnderlined/ButtonUnderlined';
import { PAGES } from 'src/constants/pages';
import styles from './ChangePasswordSuccessPage.module.scss';

const ChangePasswordSuccessPage = (): JSX.Element => {
  const { push } = useHistory();
  const { isAuthenticated } = useSelector(authSelector);

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isPrivate={false}>
      <div className={styles.content}>
        <div className={styles.passwordSuccessWrapper}>
          <div className={styles.titleBlock}>
            <h2 className={styles.title}>Password Change Success!</h2>
            <p className={styles.description}>
              Your have successfully changed your password! Please check your email to confirm your account.
            </p>
          </div>

          <ButtonBack onClick={() => push(PAGES.SIGN_IN)} label="Back to sign in" />
        </div>
      </div>
    </MainLayout>
  );
};

export default ChangePasswordSuccessPage;
