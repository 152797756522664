export type Steps = Array<{
  active: boolean;
}>;

export enum InputEnums {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  TEL = 'tel'
}

export type ICurrency = {
  value: string;
  label: string;
};

export type IError = {
  error: string;
  message: string | string[];
  statusCode: number;
};
