export const digitsRegex = /\D/g;
export const comaRegex = /[,]/g;
export const spacesRegex = /\s/g;
export const dashesRegex = /[-]/g;
export const includedZerosStringRegex = /[0]/g;

export const onlyDecimalDigitsRegex = /[^0-9.]/g;

export const onlyLatinAndUmlautRegex = /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØŠÙÚÛÜÝŸÞßàáâãäåæçèéêëìíîïðñòóôõöøšùúûüýÿþ]+$/;
export const onlyLatinAndUmlautWithSpaceRegex =
  /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØŠÙÚÛÜÝŸÞßàáâãäåæçèéêëìíîïðñòóôõöøšùúûüýÿþ ]+$/;
export const latinUmlautSpaceNumberDotRegex =
  /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØŠÙÚÛÜÝŸÞßàáâãäåæçèéêëìíîïðñòóôõöøšùúûüýÿþ\s.0-9]+$/;
export const latinUmlautNumbersSymbolRegex =
  /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØŠÙÚÛÜÝŸÞßàáâãäåæçèéêëìíîïðñòóôõöøšùúûüýÿþ ]+$/;

export const onlyLettersRgx = /^[a-zA-Z\- .']+$/;

export const lowLettersRgx = /[a-z]/g;
