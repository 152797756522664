import { stringify } from 'query-string';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { PercentagePieItemShape, GetAllocationsParams, LoanConstraintShape } from 'src/interfaces';
import { currencySymbols } from 'src/constants/globalConstants';
import { thousandsSeparator } from './numberHelpers';

export const getAllocationsRequestQuery = (reqParams: GetAllocationsParams): string => {
  const query = {
    limit: reqParams.limit,
    page: reqParams.page,
    sort: [reqParams.sort.type, reqParams.sort.order]
  };

  return stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  });
};

export const getDefaultLoanConstraintsData = (
  currency: string,
  constrains: LoanConstraintShape[]
): Array<{ title: string; value: number }> => {
  if (isNil(constrains)) return [];
  return constrains.map(({ value, min, max }) => ({
    title: max
      ? `${currencySymbols[currency]} ${thousandsSeparator(min) || 0} - ${thousandsSeparator(max)}`
      : `More than ${currencySymbols[currency]} ${thousandsSeparator(min)}`,
    value: value,
    percentage: 100 / constrains.length,
    isSelected: true
  }));
};

export const setDefaultPieChartPercentage = (list: PercentagePieItemShape[], execute?: boolean): boolean => {
  if (execute === false) {
    return;
  }
  const maxPercent = Math.max(
    0,
    list.reduce((acc, item) => acc - (item.isPreset ? item.percentage : 0), 100)
  );
  const fixedPercentCount = list.filter((item) => item.isPreset).length;
  const size = list.length - fixedPercentCount;

  for (const block of list) {
    if (!block.isPreset) {
      block.percentage = maxPercent / size;
    }
    block._value = block.percentage;
  }

  const sumPercent = list.reduce((acc, item) => acc + item._value, 0);

  return round(sumPercent, 1) === 100;
};

export const adjustPieChartPercentage = (list: PercentagePieItemShape[], value: number): void => {
  const preSetPercentage = list.reduce((previousValue, currentValue) => {
    if (!currentValue.isPreset) {
      return previousValue + currentValue.percentage;
    }
    return previousValue;
  }, 0);

  for (const block of list) {
    if (!block.isPreset) {
      const share = block.percentage / preSetPercentage;

      const percentage = block.percentage + value * share;

      block.percentage = Math.sign(percentage) === -1 || isNaN(percentage) ? 0 : percentage;
    }
  }
};
