import { sub, getMonth } from 'date-fns';

const GET_INITIAL_REPORTS_DATE_FROM = () => sub(new Date(), { months: 1 });
const GET_INITIAL_REPORTS_DATE_TO = () => new Date();

export const INITIAL_SELECTED_DATES = {
  startDate: GET_INITIAL_REPORTS_DATE_FROM(),
  endDate: GET_INITIAL_REPORTS_DATE_TO()
};

export const INITIAL_SELECTED_MONTH = {
  startDate: getMonth(GET_INITIAL_REPORTS_DATE_FROM()),
  endDate: getMonth(GET_INITIAL_REPORTS_DATE_TO())
};

export enum ReqParamKeys {
  page = 'page',
  limit = 'limit',
  sort = 'sort',
  date = 'date',
  currency = 'currency',
  loan_type = 'loan_type',
  country = 'country',
  platform = 'platform',
  grade = 'grade',
  sector = 'sector',
  smePurpose = 'smePurpose',
  relative = 'relative',
  type = 'type',
  rangeFilters = 'rangeFilters'
}

export const REQUIRED_REQ_PARAMS = Object.keys(ReqParamKeys);
