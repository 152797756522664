import * as Yup from 'yup';
import { SignUpFields, InputEnums } from 'src/interfaces';
import { latinUmlautNumbersSymbolRegex } from 'src/helpers';

export const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password can’t be blank')
    .min(8, 'Need at least 8 chars long')
    .matches(latinUmlautNumbersSymbolRegex, 'Please, use only Latin')
    .matches(/[a-z]/, 'Need at least 1 lowercase')
    .matches(/[A-Z]/, 'Need at least 1 uppercase')
    .matches(/[0-9]/, 'Need at least 1 digit')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/, 'Need at least 1 symbol character.'),

  confirmPassword: Yup.string()
    .required('Confirm password can’t be blank')
    .oneOf([Yup.ref('password')], 'Passwords mismatch'),

  [SignUpFields.USER_AGREEMENT]: Yup.boolean().required().oneOf([true], 'Please, read User Agreement')
});

export const formFields = [
  {
    name: 'password',
    id: 'password',
    type: InputEnums.PASSWORD,
    placeholder: 'New Password'
  },
  {
    name: 'confirmPassword',
    id: 'confirmPassword',
    type: InputEnums.PASSWORD,
    placeholder: 'Confirm Password'
  }
];
