import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { authSelector } from 'src/redux/selectors';
import MainLayout from 'src/components/MainLayout/MainLayout';
import Fade from 'src/components/reactTransitions/FadeTransition.module.css';
import { SignUpFields, ConfirmInformationFormValues } from 'src/interfaces/SignUpShapes';
import { PAGES } from 'src/constants/pages';
import RegisterForm from './components/RegisterForm';
import ThanksForSignUp from './components/ThanksForSignUp';
import { TRANSITION_TIMEOUT, MAX_STEPS, INITIAL_STEP } from './constants';
import styles from './RegisterPage.module.scss';

const getCurrentStep = (currentCase: number, props) => {
  switch (currentCase) {
    case 1:
      return <RegisterForm {...props} />;
    case 2:
      return <ThanksForSignUp />;

    default:
      break;
  }
};

const initialFormState = {
  [SignUpFields.COUNTRY_OF_RESIDENCE]: '',
  [SignUpFields.COMPANY_NAME]: '',
  [SignUpFields.COMPANY_NUMBER]: '',
  [SignUpFields.FIRST_NAME]: '',
  [SignUpFields.LAST_NAME]: '',
  [SignUpFields.EMAIL]: '',
  [SignUpFields.PASSWORD]: '',
  [SignUpFields.CONFIRM_PASSWORD]: '',
  [SignUpFields.USER_AGREEMENT]: false,
  [SignUpFields.NEWS_OFFERS]: false
};

interface SignUpState {
  country: { value: string; label: string } | string;
  companyName: string;
  companyNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreement: boolean;
  offers: boolean;
}

const RegisterPage = (): JSX.Element => {
  const { isAuthenticated } = useSelector(authSelector);
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);

  const [formState, setFormState] = useState<SignUpState>(initialFormState);

  const onSetFormState = (values: Partial<ConfirmInformationFormValues>) => {
    setFormState((state) => ({ ...state, ...values }));
  };

  const handleNext = () => {
    if (activeStep === MAX_STEPS) return;
    setActiveStep((prev) => prev + 1);
  };

  const isCentered = activeStep > 1;

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isCentered={isCentered} isPrivate={false}>
      <div
        className={cn(styles.contentWrapper, {
          [styles.additionalPadding]: !isCentered,
          [styles.thanksContent]: isCentered
        })}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition unmountOnExit key={activeStep} timeout={TRANSITION_TIMEOUT} classNames={{ ...Fade }}>
            {getCurrentStep(activeStep, {
              handleNext,
              formState,
              onSetFormState
            })}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </MainLayout>
  );
};

export default RegisterPage;
