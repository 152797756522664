import { authActionTypes } from '../types';
import * as Shapes from 'src/interfaces/authReduxShapes';

export const putUser = (user: Shapes.UserShape): Shapes.PutUserAction => ({
  type: authActionTypes.PUT_USER,
  payload: user
});

export const signOutUser = (): { type: string } => ({
  type: authActionTypes.SIGN_OUT
});

export const refreshAuthError = (): { type: string } => ({
  type: authActionTypes.REFRESH_ERROR
});

export const authorizationError = (): { type: string } => ({
  type: authActionTypes.UNAUTHORIZED_ERROR
});

export const tempSaveUser = (user) => ({
  type: authActionTypes.TEMP_SAVE_USER,
  payload: user
});

export const setAuthenticated = (isAuth: boolean): Shapes.SetAuthAction => ({
  type: authActionTypes.SET_AUTHENTICATED,
  payload: isAuth
});
