import React, { FC, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { PlatformShape } from 'src/interfaces';
import { PlatformsIDs } from 'src/constants/globalConstants';
import { ConfirmButton, NumericInput } from 'src/components';
import { comaRegex } from 'src/helpers/regexHelpers';
import { callbackResolver } from 'src/utils/reduxHelpers';
import { BlockTitle, DisplayFormatted } from '../../atoms';
import { Platform } from '../../../model';
import { useManagePlatforms } from '../../../hooks';
import styles from './styles.module.scss';

const percentage100 = 100;

type ManagePlatformProps = {
  isViewMode: boolean;
  allocationAmount: number;
  serverPlatforms: PlatformShape[];
  investedPlatforms: Platform[];
  currency: string;
  onConfirm: (values) => void;
};

export const ManagePlatform: FC<ManagePlatformProps> = ({
  isViewMode,
  allocationAmount,
  serverPlatforms,
  investedPlatforms,
  currency,
  onConfirm
}) => {
  const [platforms, setPlatforms] = useManagePlatforms(serverPlatforms);

  const onChangePlatforms = useCallback(
    (id: PlatformsIDs, field: 'percentage' | 'amount', value: string) => {
      const isPercentageField = field === 'percentage';
      const numericValue = +value.replace(comaRegex, '');

      const relatedFieldName = isPercentageField ? 'amount' : 'percentage';
      let relatedFieldValue: number;

      if (isPercentageField) relatedFieldValue = (allocationAmount / percentage100) * numericValue;
      if (!isPercentageField) relatedFieldValue = (numericValue / allocationAmount) * percentage100;

      setPlatforms((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, [field]: numericValue, [relatedFieldName]: relatedFieldValue } : item
        )
      );
    },
    [allocationAmount, setPlatforms]
  );

  useEffect(() => {
    if (isEmpty(investedPlatforms)) return;

    setPlatforms((prev) =>
      prev.map((platform) => {
        const matched = investedPlatforms.find((invested) => invested.id === platform.id);
        if (matched) return { ...platform, percentage: matched.percentage, amount: matched.amount };

        return platform;
      })
    );
  }, [investedPlatforms, setPlatforms]);

  const [selectedPlatformID, setSelectedPlatformID] = useState<PlatformsIDs>(null);
  const handleSelectPlatform = (id: PlatformsIDs) => setSelectedPlatformID(id);

  const handleConfirmInvestments = () => onConfirm(platforms.filter(({ percentage }) => !!percentage));

  const totalPercentage = platforms.reduce((acc, { percentage }) => acc + percentage, 0);
  const totalAmount = (allocationAmount / percentage100) * totalPercentage;

  const isError = totalPercentage > percentage100 || round(totalAmount) > allocationAmount;
  const disableConfirm = [
    isViewMode,
    totalPercentage < percentage100,
    round(totalAmount) < allocationAmount,
    isError
  ].some((v) => !!v);

  return (
    <form className={styles.managePlatformContent}>
      <div className={styles.manageTitleBlock}>
        <BlockTitle>Manage Platforms</BlockTitle>

        <div className={styles.allocationAmountBox}>
          <BlockTitle variant="sm">Allocation Amount</BlockTitle>
          <DisplayFormatted value={allocationAmount} after={currency} align="end" />
        </div>
      </div>

      <div className={styles.platformsList}>
        {isEmpty(platforms) ? (
          <BlockTitle variant="md" className={styles.noPlatformsMsg}>
            No platforms found for this type of loan
          </BlockTitle>
        ) : (
          platforms.map((p) => {
            const isSelected = selectedPlatformID === p.id;

            return (
              <PlatformRow
                key={p.id}
                platform={p}
                isSelected={isSelected}
                onSelectPlatform={handleSelectPlatform}
                onChangePlatforms={onChangePlatforms}
                isViewMode={isViewMode}
                currency={currency}
                allocationAmount={allocationAmount}
                isError={isError}
                selectedPlatformClass={styles.manageSelectedPlatform}
                disableSelectOnPlatform
              />
            );
          })
        )}
      </div>

      <div className={styles.totalRow}>
        <BlockTitle variant="sm" className={styles.total}>
          Total:
        </BlockTitle>

        <DisplayFormatted
          wrapperClass={styles.totalPercentageBox}
          className={styles.totalPercentage}
          value={totalPercentage.toFixed(2)}
          after="%"
        />

        <DisplayFormatted
          wrapperClass={styles.totalAmountBox}
          className={styles.totalAmount}
          value={totalAmount}
          decimalScale={0}
          after={currency}
          align="end"
        />
      </div>

      <ConfirmButton
        type="submit"
        isDisabled={disableConfirm}
        onClick={(e) => {
          e.preventDefault();
          handleConfirmInvestments();
        }}
        btnClass={cn(styles.confirmButton, { [styles.confirmButtonDisabled]: disableConfirm })}
      />
    </form>
  );
};

interface PlatformRowProps extends PlatformItemProps {
  isViewMode: boolean;
  onChangePlatforms: (id: PlatformsIDs, field: 'percentage' | 'amount', value: string) => void;
  currency: string;
  allocationAmount: number;
  disableSelectOnPlatform?: boolean;
  isError?: boolean;
  platformClass?: string;
  selectedPlatformClass?: string;
}

const DEFAULT_DECIMAL = 2;

const PlatformRow: FC<PlatformRowProps> = ({
  isViewMode,
  platform,
  onChangePlatforms,
  isSelected,
  onSelectPlatform,
  currency,
  allocationAmount,
  disableSelectOnPlatform,
  isError,
  platformClass,
  selectedPlatformClass
}) => {
  const isPlatformEmpty = !platform.percentage && !isSelected;
  const highlightError = isError && isSelected;

  return (
    <div className={styles.platformRow}>
      <PlatformItem
        platform={platform}
        isSelected={isSelected}
        onSelectPlatform={disableSelectOnPlatform ? null : onSelectPlatform}
        wrapperClass={cn(styles.platformItem, {
          [styles.platformEmpty]: isPlatformEmpty,
          [styles.platformCursorDefault]: isViewMode || disableSelectOnPlatform,
          [platformClass]: platformClass,
          [selectedPlatformClass]: selectedPlatformClass
        })}
      />

      <div className={styles.inputBox}>
        <NumericInput
          value={platform.percentage}
          isAllowed={({ value }) => +value <= percentage100}
          onChanged={(value) => onChangePlatforms(platform.id, 'percentage', value)}
          onClick={() => onSelectPlatform(platform.id)}
          onBlur={() => onSelectPlatform(null)}
          thousandSeparator={false}
          allowNegative={false}
          disabled={isViewMode}
          readOnly={isViewMode}
          decimalScale={DEFAULT_DECIMAL}
          className={cn(styles.detailsValueBox, {
            [styles.detailsValueEmpty]: isPlatformEmpty,
            [styles.error]: highlightError
          })}
        />
        <span className={styles.after}>%</span>
      </div>

      <div className={styles.inputBox}>
        <NumericInput
          value={platform.amount}
          isAllowed={({ value }) => +value <= allocationAmount}
          onChanged={(value) => onChangePlatforms(platform.id, 'amount', value)}
          onClick={() => onSelectPlatform(platform.id)}
          onBlur={() => onSelectPlatform(null)}
          thousandSeparator
          disabled={isViewMode}
          readOnly={isViewMode}
          decimalScale={0}
          className={cn(styles.detailsValueBox, {
            [styles.detailsValueEmpty]: isPlatformEmpty,
            [styles.error]: highlightError
          })}
        />

        <span className={styles.after}>{currency}</span>
      </div>
    </div>
  );
};

interface PlatformItemProps {
  platform: Platform;
  isSelected?: boolean;
  onSelectPlatform?: (id: PlatformsIDs) => void;
  wrapperClass?: string;
  selectedPlatformClass?: string;
}

export const PlatformItem: FC<PlatformItemProps> = ({
  isSelected,
  platform,
  onSelectPlatform,
  wrapperClass,
  selectedPlatformClass
}) => {
  return (
    <div
      className={cn(styles.platformBox, {
        [styles.platformBoxSelected]: isSelected,
        [wrapperClass]: wrapperClass,
        [selectedPlatformClass]: selectedPlatformClass && isSelected
      })}
      onClick={() => callbackResolver(onSelectPlatform, platform.id)}
    >
      <div className={styles.logoBox}>
        <img src={platform.logo} alt={platform.label} className="img-fluid" />
      </div>

      <span className={styles.platformTitle}>{platform.label}</span>
    </div>
  );
};
