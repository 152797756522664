import React from 'react';
import cn from 'classnames';
import defaultAvatar from 'src/assets/images/icons/default_avatar.svg';
import { header } from 'src/assets/locales/en/common.json';
import styles from '../NavAccountBlock.module.scss';

interface AvatarProps {
  avatar?: string;
  avatarWrapperClass?: string;
}

const Avatar = ({ avatar = defaultAvatar, avatarWrapperClass }: AvatarProps): JSX.Element => {
  return (
    <div className={cn('nav-circle', styles.avatarBlock, { [avatarWrapperClass]: avatarWrapperClass })}>
      <img src={avatar} alt={header.avatarAlt} className="img-fluid" />
    </div>
  );
};

export default Avatar;
