import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { ChartHeader, StatementBox } from 'src/components';
import { GroupBy } from 'src/constants/statisticsConstants';
import StatisticsColumnMappedChart from 'src/components/StatisticsColumnMappedChart';
import { TMaturityPaymentsData } from 'src/interfaces';

interface MaturityChartProps {
  data: TMaturityPaymentsData[];
  currency: string;
  wrapperClass?: string;
}

const MaturityChart = ({ data, currency, wrapperClass }: MaturityChartProps): JSX.Element => {
  const [type, setType] = useState(GroupBy.DAY);

  const chartData = useMemo(
    () =>
      data?.reduce(
        (acc, item) => ({
          planned_interest_payment: {
            ...acc?.planned_interest_payment,
            [item.planned_payment_date]: item.planned_interest_payment
          },
          planned_principal_payment: {
            ...acc?.planned_principal_payment,
            [item.planned_payment_date]: item.planned_principal_payment
          }
        }),
        { planned_interest_payment: {}, planned_principal_payment: {} }
      ),
    [data]
  );

  return (
    <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
      <ChartHeader title={'Expected Payments'} type={type} onTypeChange={setType} />

      <StatisticsColumnMappedChart
        type={type}
        currency={currency}
        data={chartData}
        yAxisType="linear"
        columnStackingType="normal"
      />
    </StatementBox>
  );
};

export default MaturityChart;
