import React from 'react';
import cn from 'classnames';
import { CustomizedCheckbox } from 'src/components';
import styles from './UserAgreementCheckbox.module.scss';

interface UserAgreementCheckboxProps {
  linkTitle?: string;
  onLinkClick: () => void;
  label: string;
  checkboxProps: {
    onChange: (e: React.ChangeEvent) => void;
    onBlur?: (e) => void;
    isChecked: boolean;
    name: string;
    id: string;
    size?: 'small' | 'medium';
    isDisabled?: boolean;
    icon?: JSX.Element;
    checkedIcon?: JSX.Element;
    checkedIconProps?: {
      checkedBoxClass?: string;
      checkedIconClass?: string;
    };
    uncheckedIconProps?: {
      uncheckedBoxClass?: string;
    };
    classes?: {
      checkboxRootClass: string;
      checkedIcon: string;
      colorPrimary: string;
      colorSecondary: string;
    };
  };
  classes?: {
    labelClass?: string;
    wrapperClass?: string;
  };
}

const UserAgreementCheckbox = ({
  linkTitle = 'User Agreement',
  label,
  onLinkClick,
  checkboxProps,
  classes
}: UserAgreementCheckboxProps): JSX.Element => {
  return (
    <div className={cn(styles.wrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      <CustomizedCheckbox {...checkboxProps} id={checkboxProps.id} />

      <div className={styles.labelWrapper}>
        <label htmlFor={checkboxProps.id} className={cn(styles.label, { [classes?.labelClass]: classes?.labelClass })}>
          {label}
        </label>

        <button className={styles.link} onClick={onLinkClick} type="button">
          {linkTitle}
        </button>
      </div>
    </div>
  );
};

export default UserAgreementCheckbox;
