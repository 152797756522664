import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PAGES } from 'src/constants/pages';

interface ProtectedRouteProps {
  component;
  isAuth: boolean;
  exact: boolean;
  path: string;
  redirectTo?;
}

const ProtectedRoute = ({
  component: Component,
  isAuth,
  path,
  exact,
  redirectTo,
  ...rest
}: ProtectedRouteProps): JSX.Element => {
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to={{ pathname: redirectTo ?? PAGES.SIGN_IN }} />
      }
    />
  );
};

export default ProtectedRoute;
