import { Currencies, PlatformsIDs } from 'src/constants/globalConstants';

export enum CashflowManualTransactionType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  FeePayment = 'PLATFORM_FEE'
}

export enum CashflowTransactionsGroup {
  All = 'all',
  Manual = 'manual',
  Unverified = 'unverified'
}

export interface ReportsBaseReqParams {
  page: number;
  limit: number;
  currency: string;
  date: { transaction_date_from: Date; transaction_date_to: Date };
  is_confirmed?: boolean;
}

export interface ReportsRequestParams extends ReportsBaseReqParams {
  platforms: PlatformsIDs[] | [];
}

export interface ReportsLoadMoreRequestParams extends ReportsBaseReqParams {
  platforms: PlatformsIDs;
}

export type IServerReport = {
  id: string;
  transaction_date: string;
  uniq: string;
  exchange_rate: number;
  allocation: string;
  original_currency: Currencies;
  source_platform_name: PlatformsIDs;
  invested_amount_original: number;
  principal_payment_original: number;
  interest_payment_original: number;
  fees_original: number;
  invested_amount_reporting: number;
  principal_payment_reporting: number;
  interest_payment_reporting: number;
  fees_reporting: number;
};

export type IClientReport = {
  platform: PlatformsIDs;
  data: IServerReport[];
  clientSidePagination: {
    page: number;
    limit: number;
  };
};

export type IServerTransaction = {
  deposit_original: number;
  deposit_reporting: number;
  exchange_rate: number;
  id: string;
  interest_payment_original: number;
  interest_payment_reporting: number;
  invested_amount_original: number;
  invested_amount_reporting: number;
  is_confirmed: false;
  is_manual: false;
  original_currency: string;
  principal_payment_original: number;
  principal_payment_reporting: number;
  source_platform_name: PlatformsIDs;
  total_payment_original: number;
  total_payment_reporting: number;
  transaction_date: string;
  transaction_type: string[];
  uniq: string;
  withdrawal_original: number;
  withdrawal_reporting: number;
  investment_key?: string;
};

export type IClientTransaction = {
  platform: PlatformsIDs;
  data: IServerTransaction[];
  actual_balance_original: number;
  actual_balance_reporting: number;
  clientSidePagination: {
    page: number;
    limit: number;
  };
};

export type TransactionsReducer = {
  transactions: IClientTransaction[];
  cashflow: IClientReport[];
};

export type GetTransactionsAndReportsPayload = {
  reqParams: ReportsRequestParams;
  transactionType: CashflowTransactionsGroup;
  onSuccess?: () => void;
  onError?: (err) => void;
};

export type GetTransactionsAndReportsAction = {
  type: string;
  payload: GetTransactionsAndReportsPayload;
};

export type PutTransactionsAndReportsPayload = {
  transactions: IClientTransaction[];
  cashflow: IClientReport[];
};

export type PutTransactionsAndReportsAction = {
  type: string;
  payload: PutTransactionsAndReportsPayload;
};

export type PutTransactionsAction = {
  type: string;
  payload: IClientTransaction[];
};

export type GetMoreTransactionsPayload = {
  reqParams: ReportsLoadMoreRequestParams;
  transactionType: CashflowTransactionsGroup;
  onSuccess?: () => void;
  onError?: (err) => void;
};

export type GetMoreTransactionsAction = {
  type: string;
  payload: GetMoreTransactionsPayload;
};

export type PutMoreTransactionsPayload = Omit<IClientTransaction, 'clientSidePagination'> & {
  page: number;
};

export type PutMoreTransactionsAction = {
  type: string;
  payload: PutMoreTransactionsPayload;
};

export type GetReports = {
  type: string;
  payload: {
    reqParams: ReportsRequestParams;
    onSuccess?: () => void;
    onError?: (err) => void;
  };
};

export type PutReports = {
  type: string;
  payload: IClientReport[];
};

export type GetMoreReportsByPlatform = {
  type: string;
  payload: { reqParams: ReportsLoadMoreRequestParams; onSuccess?: () => void; onError?: (err) => void };
};

export type PutMoreReportsPayload = {
  data: IServerReport[];
  platform: PlatformsIDs;
  page: number;
};

export type PutMoreReportsByPlatform = {
  type: string;
  payload: PutMoreReportsPayload;
};

export type TAddTransactionBody = {
  platform: PlatformsIDs;
  type: CashflowManualTransactionType;
  amount: number;
  date: string;
};

export type TAddTransactionPayload = {
  body: TAddTransactionBody;
  onSuccess?: () => void;
  onError?: (err) => void;
};

export type TAddTransactionAction = {
  type: string;
  payload: TAddTransactionPayload;
};
export type TDeleteTransactionPayload = {
  id: string;
  onSuccess?: () => void;
  onError?: (err) => void;
};

export type TDeleteTransactionAction = {
  type: string;
  payload: TDeleteTransactionPayload;
};
