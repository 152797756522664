export default {
  indicatorSeparator: (base): React.CSSProperties => ({
    ...base,
    display: 'none'
  }),
  container: (base): React.CSSProperties => ({
    ...base,
    minWidth: 88,
    width: 88,
    minHeight: 32,
    height: 32,
    padding: '0px 10px 0px 6px',
    background: '#fff',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    boxShadow: 'unset',
    border: '1px solid rgba(151, 151, 151, 0.46)',

    path: {
      stroke: 'rgb(15, 25, 66)',
      transition: 'all 0.3s ease-in-out'
    }
  }),
  control: (base): React.CSSProperties => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    minHeight: 32
  }),
  singleValue: (base): React.CSSProperties => ({
    ...base,
    margin: 0,
    fontFamily: 'Verlag, sanserif',
    fontSize: 22,
    letterSpacing: '-0.777778px',
    lineHeight: '26px',
    color: '#000'
  }),
  input: (base): React.CSSProperties => ({
    ...base,
    width: '100%',
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 22,
    letterSpacing: '-0.777778px',
    lineHeight: '26px',
    fontFamily: 'Verlag, sanserif',
    color: '#000'
  }),

  placeholder: (base: React.CSSProperties): React.CSSProperties => ({
    ...base,
    fontFamily: 'Verlag, sanserif',
    fontSize: 22,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, 0.53)',
    letterSpacing: '-0.9px',
    transition: 'all 0.3s ease-in-out'
  }),

  menu: (base): React.CSSProperties => ({
    ...base,
    zIndex: 10,
    marginTop: 1,
    marginBottom: 0,
    left: 0,
    minWidth: 88,
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 5px 5px'
  }),

  menuList: (base): React.CSSProperties => ({
    ...base,
    overflowX: 'hidden',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px'
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F2F3F6',
      borderRadius: '50px',
      width: '4px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D5D5D5',
      borderRadius: '3px',
      width: '6px'
    }
  }),

  option: (base, state): React.CSSProperties => ({
    ...base,
    maxHeight: 36,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Verlag, sanserif',
    transition: 'all 0.3s ease-in-out',
    border: state.isSelected ? '1px solid rgba(7, 113, 134, 0.03)' : '1px solid #fff',
    backgroundColor: state.isSelected ? 'rgba(7, 113, 134, 0.14)' : '#fff',
    color: '#000',
    fontSize: 22,
    letterSpacing: '-0.777778px',

    '&:last-child': {
      borderRadius: '0px 0px 5px 5px'
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: '#fff',
      boxShadow: ' 0px 3px 10px 0.7px rgba(0, 0, 0, 0.10)',
      transition: 'all 0.3s ease-in-out'
    },

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: '#fff',
      boxShadow: ' 0px 4px 10px 0.3px rgba(0, 0, 0, 0.03)',
      transition: 'all 0.3s ease-in-out'
    },

    '&:hover': {
      border: '1px solid rgba(7, 113, 134, 0.03)',
      backgroundColor: 'rgba(7, 113, 134, 0.14)',

      '&::after': {
        background: 'rgba(7, 113, 134, 0.01)'
      },

      '&::before': {
        background: 'rgba(7, 113, 134, 0.03)'
      }
    },
    '&:active': {
      backgroundColor: 'rgba(7, 113, 134, 0.1)'
    }
  }),

  valueContainer: (base): React.CSSProperties => ({
    ...base,
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: 1
  })
};
