import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import TableBody from '@mui/material/TableBody';
import { baseReducerSelector } from 'src/redux/selectors';
import { TRow, FakeRow } from '../rows';
import { TableColumn, TBodyCssClasses } from 'src/interfaces/CustomTableShapes';
import styles from './TBody.module.scss';
import { SxProps, Theme } from '@mui/material';

interface TBodyProps {
  isLoading: boolean;
  columns: Array<TableColumn>;
  rows: Array<{ id?: number | string }>;
  error?: null | string;
  emptyRowsMsg: string | React.ReactNode;
  getSummaryContent: (colId: string, row) => React.ReactNode;
  detailsContent?: React.ReactNode;
  rowsChecked?: Array<{ id: string; isChecked: boolean }>;
  onSetChecked?: (id: string) => void;
  classes?: TBodyCssClasses;
  shouldShowList?: boolean;
  guttersLeft?: number;
  guttersRight?: number;
  hasDetails?: boolean;
  withModalDetails?: boolean;
  onGetDetailsPath?: (row) => string;
  rowMappingUniqueKeys?: string | string[];
  useUuIdBodyKeys?: boolean;
  onRowClick?: (row) => void;
  onSetCustomRowSx?: (row) => SxProps<Theme>;
}

const TBody: FC<TBodyProps> = ({
  isLoading,
  columns,
  rows,
  error,
  emptyRowsMsg,
  getSummaryContent,
  detailsContent,
  classes,
  shouldShowList,
  guttersLeft,
  guttersRight,
  hasDetails,
  withModalDetails,
  onGetDetailsPath,
  rowMappingUniqueKeys = 'id',
  useUuIdBodyKeys,
  onRowClick,
  onSetCustomRowSx
}): JSX.Element => {
  const { isAsideMenuOpen } = useSelector(baseReducerSelector);

  const getTableContent = useCallback(() => {
    if (error) return <FakeRow isError msg={error} colSpan={columns.length} />;

    if (!shouldShowList && isLoading) return <FakeRow isLoader colSpan={columns.length} />;

    if (isEmpty(rows) && !isLoading) return <FakeRow msg={emptyRowsMsg} colSpan={columns.length} />;

    return rows?.map((item) => {
      let key = item?.id;

      if (useUuIdBodyKeys) key = uuid();

      if (!useUuIdBodyKeys && rowMappingUniqueKeys) {
        key = isArray(rowMappingUniqueKeys)
          ? [rowMappingUniqueKeys.reduce((acc, key) => acc + `${item?.[key]}`, ``)]
          : item?.[rowMappingUniqueKeys];
      }

      return (
        <TRow
          key={key}
          row={item}
          columns={columns}
          getSummaryContent={getSummaryContent}
          detailsContent={detailsContent}
          classes={classes}
          guttersLeft={guttersLeft}
          guttersRight={guttersRight}
          hasDetails={hasDetails}
          isAsideMenuOpen={isAsideMenuOpen}
          withModalDetails={withModalDetails}
          onGetDetailsPath={onGetDetailsPath}
          onRowClick={onRowClick}
          onSetCustomRowSx={onSetCustomRowSx}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    columns,
    rows,
    isLoading,
    emptyRowsMsg,
    getSummaryContent,
    detailsContent,
    classes,
    guttersLeft,
    guttersRight,
    hasDetails,
    shouldShowList,
    withModalDetails,
    isAsideMenuOpen,
    onGetDetailsPath,
    rowMappingUniqueKeys
  ]);

  return (
    <TableBody classes={{ root: cn(styles.tableBodyRoot, { [classes?.tBodyClass]: classes?.tBodyClass }) }}>
      <React.Fragment>{getTableContent()}</React.Fragment>
    </TableBody>
  );
};

export default TBody;
