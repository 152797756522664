import { sub, getMonth } from 'date-fns';
import { DatePickersNames } from 'src/components/DatePickerBlock/DatePickerBlock';
import { DEFAULT_REPORTS_REQ_PARAMS } from 'src/constants/reportsConstants';
import {
  ReportsRequestParams,
  CashflowTransactionsGroup,
  CashflowManualTransactionType
} from 'src/interfaces/reportsReduxShapes';
import { PlatformShape } from 'src/interfaces';
import { SelectedDates } from './model';

export const REQUIRED_REQUEST_KEYS = ['page', 'limit', 'platforms', 'currency', 'date'];

const GET_INITIAL_REPORTS_DATE_FROM = () => sub(new Date(), { years: 5 });
const GET_INITIAL_REPORTS_DATE_TO = () => new Date();

export const INITIAL_SELECTED_DATES = {
  startDate: GET_INITIAL_REPORTS_DATE_FROM(),
  endDate: GET_INITIAL_REPORTS_DATE_TO()
};

export const INITIAL_SELECTED_MONTH = {
  startDate: getMonth(GET_INITIAL_REPORTS_DATE_FROM()),
  endDate: getMonth(GET_INITIAL_REPORTS_DATE_TO())
};

export const DATE_PICKER_KEY_MAP = {
  [DatePickersNames.START_DATE]: 'transaction_date_from',
  [DatePickersNames.END_DATE]: 'transaction_date_to'
};

export const CHECKBOX_LABELS_TRUNCATE_WIDTH = 200;
export const DEFAULT_REPORTS_PAGE = 1;

export const LOADER_SIZE = 80;

export const GET_REPORTS_DEFAULT_PARAMS = (
  currency: string,
  platforms?: PlatformShape[],
  dates?: SelectedDates
): Partial<ReportsRequestParams> => {
  const params = {
    ...DEFAULT_REPORTS_REQ_PARAMS,
    currency
  };

  if (platforms) Object.assign(params, { ...params, platforms: platforms.map(({ name }) => name) });

  if (dates) {
    Object.assign(params, {
      ...params,
      date: {
        transaction_date_from: dates.startDate,
        transaction_date_to: dates.endDate
      }
    });
  }

  return params;
};

export const LOAD_MORE_PAGE_STEP = 1;
export const GET_REPORTS_INITIAL_PAGE = 1;
export const TRANSITION_TIMEOUT = 150;

export const transactionTypeOptions = [
  { value: CashflowTransactionsGroup.All, label: 'All' },
  { value: CashflowTransactionsGroup.Unverified, label: 'Unverified' },
  { value: CashflowTransactionsGroup.Manual, label: 'Manual' }
];

export enum ReportCurrencyNames {
  ORIGINAL = 'original',
  REPORTING = 'reporting'
}

export const currencyOptions = [
  { value: ReportCurrencyNames.REPORTING, label: 'Reporting currency' },
  { value: ReportCurrencyNames.ORIGINAL, label: 'Original currency' }
];

export const manualTransactionOptions = [
  { value: CashflowManualTransactionType.Deposit, label: 'Deposit' },
  { value: CashflowManualTransactionType.Withdrawal, label: 'Withdrawal' },
  { value: CashflowManualTransactionType.FeePayment, label: 'Fee payment' }
];
