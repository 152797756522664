export interface CustomStylesProps {
  indicatorSeparator?: Record<string, unknown>;
  container?: Record<string, unknown>;
  control?: Record<string, unknown>;
  singleValue?: Record<string, unknown>;
  input?: Record<string, unknown>;
  placeholder?: Record<string, unknown>;
  menu?: Record<string, unknown>;
  menuList?: Record<string, unknown>;
  option?: Record<string, unknown>;
  valueContainer?: Record<string, unknown>;
}

export default (customStyles?: CustomStylesProps): Record<string, unknown> => ({
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
    ...customStyles?.indicatorSeparator
  }),
  container: (base) => ({
    ...base,
    minWidth: 88,
    width: 88,
    minHeight: 32,
    height: 32,
    padding: '0px 10px 0px 6px',
    background: '#fff',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    boxShadow: 'unset',
    border: '1px solid rgba(151, 151, 151, 0.46)',
    ...customStyles?.container,

    path: {
      stroke: 'rgb(15, 25, 66)',
      transition: 'all 0.3s ease-in-out'
    }
  }),

  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    minHeight: 32,
    ...customStyles?.control
  }),

  valueContainer: (base) => ({
    ...base,
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: 1,
    overflow: 'visible',
    ...customStyles?.valueContainer
  }),

  singleValue: (base) => ({
    ...base,
    margin: 0,
    width: '100%',
    fontFamily: 'Verlag, sanserif',
    fontSize: 22,
    letterSpacing: '-0.777778px',
    lineHeight: '26px',
    color: '#000',
    overflow: 'visible',
    ...customStyles?.singleValue
  }),

  input: (base) => ({
    ...base,
    width: '100%',
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 22,
    letterSpacing: '-0.777778px',
    lineHeight: '26px',
    fontFamily: 'Verlag, sanserif',
    color: '#000',
    ...customStyles?.input
  }),

  placeholder: (base: React.CSSProperties) => ({
    ...base,
    fontFamily: 'Verlag, sanserif',
    fontSize: 22,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, 0.53)',
    letterSpacing: '-0.9px',
    transition: 'all 0.3s ease-in-out',
    ...customStyles?.placeholder
  }),

  menu: (base) => ({
    ...base,
    zIndex: 10,
    marginTop: 1,
    marginBottom: 0,
    left: 0,
    minWidth: 88,
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 5px 5px',
    ...customStyles?.menu
  }),

  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
    padding: 0,
    ...customStyles?.menuList,

    '&::-webkit-scrollbar': {
      width: '4px'
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F2F3F6',
      borderRadius: '50px',
      width: '4px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D5D5D5',
      borderRadius: '3px',
      width: '6px'
    }
  }),

  option: (base, state) => ({
    ...base,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Verlag, sanserif',
    transition: 'all 0.3s ease-in-out',
    border: state.isSelected ? '1px solid rgba(7, 113, 134, 0.03)' : '1px solid #fff',
    backgroundColor: state.isSelected ? 'rgba(7, 113, 134, 0.14)' : '#fff',
    color: '#000',
    fontSize: 22,
    padding: '0.2em',
    wordBreak: 'break-word',
    letterSpacing: '-0.777778px',
    ...customStyles?.option,

    '&:last-child': {
      borderRadius: '0px 0px 5px 5px'
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: '#fff',
      boxShadow: ' 0px 3px 10px 0.7px rgba(0, 0, 0, 0.10)',
      transition: 'all 0.3s ease-in-out'
    },

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: '#fff',
      boxShadow: ' 0px 4px 10px 0.3px rgba(0, 0, 0, 0.03)',
      transition: 'all 0.3s ease-in-out'
    },

    '&:hover': {
      border: '1px solid rgba(7, 113, 134, 0.03)',
      backgroundColor: 'rgba(7, 113, 134, 0.14)',

      '&::after': {
        background: 'rgba(7, 113, 134, 0.01)'
      },

      '&::before': {
        background: 'rgba(7, 113, 134, 0.03)'
      }
    },
    '&:active': {
      backgroundColor: 'rgba(7, 113, 134, 0.1)'
    }
  })
});
