import React, { FC } from 'react';
import { StatementBox } from 'src/components';
import { ConstraintsTitle } from '../atoms';
import { CriteriaRow } from '../..';
import { PortfolioRelativeValue } from '../../../model';
import styles from './RelativeValues.module.scss';

type Props = {
  isViewPage: boolean;
  relative: PortfolioRelativeValue[];
  onChange: (name: string, value: number) => void;
};

const RelativeValues: FC<Props> = ({ isViewPage, relative, onChange }) => {
  return (
    <StatementBox className={styles.box}>
      <ConstraintsTitle title="Relative value" />

      <div className={styles.content}>
        {relative?.map(({ name, percentage }) => (
          <CriteriaRow
            key={name}
            label={name}
            value={percentage}
            onChange={(value: number) => onChange(name, value)}
            readOnly={isViewPage}
          />
        ))}
      </div>
    </StatementBox>
  );
};

export default RelativeValues;
