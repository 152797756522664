import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import formatISO from 'date-fns/formatISO';
import Loader from 'react-spinners/PuffLoader';
import { refreshStatistics } from 'src/redux/actions/statisticsActions';
import { formatDateFromJSON } from 'src/helpers';
import { ButtonUnderlined } from '..';
import { ShouldRender } from '../ShouldRender';
import { WithLoading } from '../WithLoading';
import styles from './RefreshStatistics.module.scss';

const REFRESH_DATE_FORMAT = 'dd MMM yyyy HH:mm:ss';
const REFRESH_LOADER_SIZE = 15;

type RefreshStatisticsProps = {
  lastUpdatedDate: string;
  lastRequestedDate: string;
  wrapperClass?: string;
  refreshBtnBoxClass?: string;
  lastUpdatedClass?: string;
  shouldRevers?: boolean;
};

const RefreshStatistics: FC<RefreshStatisticsProps> = ({
  lastRequestedDate,
  lastUpdatedDate,
  wrapperClass,
  refreshBtnBoxClass,
  lastUpdatedClass,
  shouldRevers
}) => {
  const dispatch = useDispatch();

  const [isRefreshLoading, setRefreshLoading] = useState(false);
  const [lastRefreshedDate, setLastRefreshedDate] = useState(null);

  const handleRefreshAllocation = () => {
    setRefreshLoading(true);

    dispatch(
      refreshStatistics(
        () => {
          setLastRefreshedDate(formatISO(new Date()));
          setRefreshLoading(false);
        },
        () => setRefreshLoading(false)
      )
    );
  };

  const refreshDate = lastRefreshedDate ?? lastRequestedDate;
  const refreshBtnLabel = `Refresh${
    refreshDate ? ` (requested ${formatDateFromJSON(refreshDate, REFRESH_DATE_FORMAT)})` : ''
  }`;

  return (
    <div
      className={cn(styles.refreshAllocationBox, {
        [styles.refreshBoxReverse]: shouldRevers,
        [wrapperClass]: wrapperClass
      })}
    >
      <ShouldRender should={!!lastUpdatedDate}>
        <span className={cn(styles.lastUpdateDate, { [lastUpdatedClass]: lastUpdatedClass })}>
          Last update {formatDateFromJSON(lastUpdatedDate, REFRESH_DATE_FORMAT)}
        </span>
      </ShouldRender>

      <div className={cn(styles.refreshBtnBox, { [refreshBtnBoxClass]: refreshBtnBoxClass })}>
        <WithLoading
          isLoading={isRefreshLoading}
          loadingContent={
            <div className={styles.refreshLoaderBox}>
              <Loader size={REFRESH_LOADER_SIZE} color="#077186" />
            </div>
          }
        >
          <ButtonUnderlined
            classes={{
              btnClass: styles.refreshButton
            }}
            label={refreshBtnLabel}
            onClick={handleRefreshAllocation}
            prefix={null}
          />
        </WithLoading>
      </div>
    </div>
  );
};

export default RefreshStatistics;
