import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import { paginationSelectStyles, usePaginationStyles } from './PaginationBlockStyles';
import arrow from 'src/assets/images/icons/file_type_arrow.svg';
import styles from './PaginationBlock.module.scss';

const DEFAULT_PER_PAGE_OPTIONS = [
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 300, label: '300' }
];

const DEFAULT_PAGINATION_VALUE = 1;
const DEFAULT_MIN_MENU_HEIGHT = 178;
const DEFAULT_MAX_MENU_HEIGHT = 190;

const DropdownIndicator = (): JSX.Element => <ReactSVG src={arrow} />;

interface PaginationBlockProps {
  onChangePerPage?: (value) => void;
  perPage: number;
  options?: Array<{ value: number; label: string }>;
  selectLabel?: string;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  minMenuHeight?: number;
  maxMenuHeight?: number;
  selectStyles?: { menuMargin?: string; menuBoxShadow?: string };
  onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
  currentPage: number;
  totalPages?: number;
}

const PaginationBlock = ({
  onChangePerPage,
  perPage,
  options = DEFAULT_PER_PAGE_OPTIONS,
  selectLabel = 'Rows per page',
  menuPlacement = 'bottom',
  minMenuHeight = DEFAULT_MIN_MENU_HEIGHT,
  maxMenuHeight = DEFAULT_MAX_MENU_HEIGHT,
  selectStyles,
  onChangePage,
  currentPage,
  totalPages = DEFAULT_PAGINATION_VALUE
}: PaginationBlockProps): JSX.Element => {
  const { paginationRoot, paginationIcon, paginationItemPage } = usePaginationStyles();

  return (
    <div className={styles.contentWrapper}>
      {onChangePerPage ? (
        <div className={styles.selectBlock}>
          <Select
            options={options}
            value={{ value: perPage, label: perPage.toString() }}
            defaultValue={{ value: 100, label: '100' }}
            onChange={onChangePerPage}
            minMenuHeight={minMenuHeight}
            maxMenuHeight={maxMenuHeight}
            placeholder="Per Page"
            components={{ DropdownIndicator }}
            menuPlacement={menuPlacement}
            styles={paginationSelectStyles(selectStyles)}
          />
          <p className={styles.selectLabel}>{selectLabel}</p>
        </div>
      ) : null}

      {totalPages ? (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={onChangePage}
          defaultValue={DEFAULT_PAGINATION_VALUE}
          classes={{ root: paginationRoot }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                icon: paginationIcon,
                page: paginationItemPage
              }}
              {...item}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export default PaginationBlock;
