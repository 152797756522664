import React, { useState, useEffect, useMemo, useCallback, FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { investmentRootSelector, baseFiltersSelector } from 'src/redux/selectors';
import { getMonth } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { DatePickerBlock, CustomizedTable, ExportAllBlock, Filter, ContentBodyBox, ShouldRender } from 'src/components';
import { InvestmentsColIDs } from 'src/interfaces';
import { DatePickersNames } from 'src/components/DatePickerBlock/DatePickerBlock';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { INITIAL_DATE_PICKER_FROM, INITIAL_DATE_PICKER_TO } from 'src/constants/investmentsConstants';
import { SortOrders } from 'src/constants/globalConstants';
import { DYNAMIC_PAGES } from 'src/constants/pages';
import {
  renderSummaryContent,
  createInvestmentFiltersArray,
  getFiltersRequestObject,
  getStatuses,
  GET_INVESTMENT_LOANS_DEFAULT_PARAMS
} from './helpers';
import {
  CHECKBOX_LABELS_TRUNCATE_WIDTH,
  DEFAULT_INVESTMENTS_PAGE,
  InvestmentsCheckboxEnum,
  SUCCESSFUL_BID_STATUSES
} from '../constants';
import { INVESTMENT_COLUMNS, OUTSTANDING_BID_COLUMNS } from './columns';
import { HideUnsuccessfulToggle } from './components';
import { InvestmentRequestParams } from '../model';
import styles from './InvestmentContent.module.scss';

type InvestmentContentProps = {
  allocationIds: string[];
  pageTitle: string;
  isBidsPage: boolean;
  currency: string;
  loansReqParams: InvestmentRequestParams;
  onSetLoansReqParams: React.Dispatch<React.SetStateAction<InvestmentRequestParams>>;
  isLoading: boolean;
  onSetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmExport: (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => void;
  onTriggerClearFilters: () => void;
};

const InvestmentContent: FC<InvestmentContentProps> = ({
  allocationIds,
  pageTitle,
  isBidsPage,
  currency,
  loansReqParams,
  onSetLoansReqParams,
  isLoading,
  onSetLoading,
  onConfirmExport,
  onTriggerClearFilters
}): JSX.Element => {
  const { grades, platforms } = useSelector(baseFiltersSelector);

  const {
    investmentsData: { investments, hasMoreInvestments },
    outstandingBidsData: { outstandingBids, hasMoreBids }
  } = useSelector(investmentRootSelector);

  const handleChangePage = useCallback(
    (e: React.ChangeEvent, page: number) => {
      onSetLoansReqParams((state) => ({ ...state, page }));
    },
    [onSetLoansReqParams]
  );

  const handleSortTable = (type: InvestmentsColIDs, order: SortOrders) => {
    onSetLoansReqParams((prev) => ({ ...prev, sort: { type, order }, page: DEFAULT_INVESTMENTS_PAGE }));
  };

  const [filtersArray, setFiltersArray] = useState([]);

  useEffect(() => {
    if ([grades, platforms].some(isEmpty)) return;

    setFiltersArray(createInvestmentFiltersArray(grades, platforms, isBidsPage));
  }, [grades, platforms, isBidsPage]);

  const onChangeCheckboxFilters = useCallback(
    (filtersArray) => {
      const { status, relative: relativeFilters, platforms, grades } = getFiltersRequestObject(filtersArray);

      const { statuses, relative, platform, grade } = loansReqParams;

      if ([status].every(isNil)) return;

      const isEqualWithReqParams = [
        [status, statuses],
        [relativeFilters, relative],
        [platform, platforms],
        [grade, grades]
      ].every((value) => isEqual(value[0], value[1]));

      if (isEqualWithReqParams) return;

      onSetLoansReqParams((state) => ({
        ...state,
        statuses: status,
        relative: relativeFilters,
        platform: platforms,
        grade: grades
      }));
    },
    [loansReqParams, onSetLoansReqParams]
  );

  const [hideUnsuccessfulBids, setHideUnsuccessful] = useState(true);
  const onChangeHideUnsuccessfulBids = useCallback(
    (checked: boolean) => {
      setHideUnsuccessful(checked);

      const filters = filtersArray.map((block) => {
        if (block.id !== InvestmentsCheckboxEnum.STATUS) return block;

        const list = block.list.map((item) => ({
          ...item,
          isChecked: checked ? SUCCESSFUL_BID_STATUSES.includes(item.id) : true
        }));

        return {
          ...block,
          isAllChecked: !checked,
          list
        };
      });

      setFiltersArray(filters);
      onChangeCheckboxFilters(filters);
    },
    [filtersArray, onChangeCheckboxFilters]
  );

  const handleFilterChange = useCallback(
    (name: string, id: string) => {
      onSetLoansReqParams((prevState) => ({ ...prevState, page: DEFAULT_INVESTMENTS_PAGE }));
      onSetLoading(true);

      const filters = filtersArray.map((block) => {
        if (block.id !== name) return block;

        const list = block.list.map((item) => ({
          ...item,
          isChecked: item.id === id ? !item.isChecked : item.isChecked
        }));

        return {
          ...block,
          isAllChecked: list.every(({ isChecked }) => isChecked),
          list
        };
      });

      setFiltersArray(filters);
      onChangeCheckboxFilters(filters);

      const shouldUncheckHideUnsuccessfulBids =
        isBidsPage && hideUnsuccessfulBids && name === InvestmentsCheckboxEnum.STATUS;

      if (shouldUncheckHideUnsuccessfulBids) setHideUnsuccessful(false);
    },
    [filtersArray, isBidsPage, hideUnsuccessfulBids, onChangeCheckboxFilters, onSetLoading, onSetLoansReqParams]
  );

  const handleSelectAll = useCallback(
    (name: string) => {
      setFiltersArray((state) => {
        const newState = state.map((block) => {
          if (block.id !== name) return block;

          return {
            ...block,
            isAllChecked: !block.isAllChecked,
            list: block.list.map((item) => ({ ...item, isChecked: !block.isAllChecked }))
          };
        });

        onChangeCheckboxFilters(newState);
        return newState;
      });
    },
    [onChangeCheckboxFilters]
  );

  const [selectedDates, setSelectedDate] = useState({
    startDate: INITIAL_DATE_PICKER_FROM,
    endDate: INITIAL_DATE_PICKER_TO
  });

  const [selectedMonths, setSelectedMonths] = useState({
    startDate: getMonth(INITIAL_DATE_PICKER_FROM),
    endDate: getMonth(INITIAL_DATE_PICKER_TO)
  });

  const handleSetSelectedMonth = (id: DatePickersNames, value: number) => {
    setSelectedMonths((prev) => ({ ...prev, [id]: value }));
  };

  const handleDateChange = (id: DatePickersNames, date: Date) => {
    setSelectedDate((prevDates) => ({ ...prevDates, [id]: date }));

    if (getMonth(date) !== selectedMonths[id]) {
      setSelectedMonths((prevMonth) => ({ ...prevMonth, [id]: getMonth(date) }));
    }

    onSetLoansReqParams((prevParams) => ({ ...prevParams, date: { ...prevParams.date, [id]: date } }));
  };

  const onClearFilters = useCallback(() => {
    onSetLoansReqParams(GET_INVESTMENT_LOANS_DEFAULT_PARAMS(allocationIds, getStatuses(isBidsPage), isBidsPage));
    onTriggerClearFilters();

    setSelectedDate({
      startDate: INITIAL_DATE_PICKER_FROM,
      endDate: INITIAL_DATE_PICKER_TO
    });
    setSelectedMonths({
      startDate: getMonth(INITIAL_DATE_PICKER_FROM),
      endDate: getMonth(INITIAL_DATE_PICKER_TO)
    });

    setFiltersArray(createInvestmentFiltersArray(grades, platforms, isBidsPage));
  }, [allocationIds, isBidsPage, grades, platforms, onSetLoansReqParams, onTriggerClearFilters]);

  const getSummaryContent = useCallback(
    (id, row) => renderSummaryContent(id, row, currency, isBidsPage),
    [currency, isBidsPage]
  );

  const onGetDetailsPath = useCallback(
    (row) => (isBidsPage ? DYNAMIC_PAGES.BID_DETAILS(row?.id) : DYNAMIC_PAGES.INVESTMENT_DETAILS(row?.id)),
    [isBidsPage]
  );

  const hasMore = useMemo(
    () => (isBidsPage ? hasMoreBids : hasMoreInvestments),
    [hasMoreBids, hasMoreInvestments, isBidsPage]
  );

  const paginationData = useMemo(
    () => ({
      currentPage: loansReqParams?.page,
      perPage: loansReqParams?.limit,
      onChangePage: handleChangePage,
      loadMorePagination: hasMore
    }),
    [loansReqParams, handleChangePage, hasMore]
  );

  const columns = useMemo(() => (isBidsPage ? OUTSTANDING_BID_COLUMNS : INVESTMENT_COLUMNS), [isBidsPage]);
  const rows = useMemo(() => (isBidsPage ? outstandingBids : investments), [isBidsPage, outstandingBids, investments]);

  return (
    <ContentBodyBox wrapperClass={cn(styles.contentWrapper, { [styles.contentWrapperHasMore]: hasMore })}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.header}>{pageTitle}</h2>

        <div className={styles.dateAndFilterWrapper}>
          <button type="button" className={styles.clearFiltersBtn} onClick={onClearFilters}>
            Clear All Filters
          </button>

          <ExportAllBlock onConfirmExport={onConfirmExport} selectWrapperClass={styles.fileTypeWrapper} />

          <Filter
            classes={{ btnClass: styles.filterBtn, dropdownClass: styles.filterContainer }}
            filters={filtersArray}
            onChangeFilters={handleFilterChange}
            onSelectAllFilters={handleSelectAll}
            withTruncateLabels
            truncateWidth={CHECKBOX_LABELS_TRUNCATE_WIDTH}
          />
        </div>
      </div>

      <div className={cn(styles.downloadContractsWrapper, { [styles.downloadContractsBidsWrapper]: isBidsPage })}>
        <ShouldRender should={isBidsPage}>
          <HideUnsuccessfulToggle isChecked={hideUnsuccessfulBids} onChangeChecked={onChangeHideUnsuccessfulBids} />
        </ShouldRender>

        <DatePickerBlock
          dates={selectedDates}
          selectedMonths={selectedMonths}
          onDateChange={handleDateChange}
          onSetSelectedMonth={handleSetSelectedMonth}
          label={isBidsPage ? 'Bidding Date:' : 'Funding Date:'}
        />
      </div>

      <CustomizedTable
        columns={columns}
        rows={rows}
        hasPagination={!!rows?.length}
        shouldShowList={!!rows?.length}
        getSummaryContent={getSummaryContent}
        onGetDetailsPath={onGetDetailsPath}
        onSort={handleSortTable}
        paginationData={paginationData}
        isLoading={isLoading}
        emptyRowsMsg="No data available at the moment"
        guttersRight={16}
        guttersLeft={16}
        tHeadClasses={{ tHeadCellClass: styles.tHeadCellClass }}
        tBodyClasses={{
          tBodyCheckboxCellClass: styles.tBodyCheckboxCellClass,
          detailsPathLinkClass: styles.detailsPathLinkClass
        }}
      />
    </ContentBodyBox>
  );
};

export default InvestmentContent;
