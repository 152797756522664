import React, { FC } from 'react';
import cn from 'classnames';
import { SummaryShape } from 'src/interfaces';
import { Slider } from 'src/components';
import styles from './RangeSlidersBlock.module.scss';

export type IRangeFilter = {
  id;
  label: string;
  step: number;
  decimalScale?: number;
  valueLabels: {
    isLabelInputs: boolean;
    maxThousandSeparator?: boolean;
    minThousandSeparator?: boolean;
    minPrefix?: string;
    maxPrefix?: string;
    minAfter?: string;
    maxAfter?: string;
  };
};

export type RangeSlidersProps = {
  onValueChange: (id, value: number[]) => void;
  onSliderChange: (id, value: number[]) => void;
  isWider: (id) => boolean;
  list: IRangeFilter[];
  rangesData: SummaryShape;
  rangeFilters: SummaryShape;
  wrapperClasses?: string;
  sliderBoxClasses?: string;
};

const RangeSlidersBlock: FC<RangeSlidersProps> = ({
  onValueChange,
  onSliderChange,
  isWider,
  list,
  rangesData,
  rangeFilters,
  wrapperClasses,
  sliderBoxClasses
}) => {
  return (
    <div className={cn(styles.rangeBlock, { [wrapperClasses]: wrapperClasses })}>
      {list.map(({ id, label, ...props }) => {
        const { min, max } = rangesData[id];
        const currentValue = rangeFilters[id];

        return (
          <div key={label} className={cn(styles.rangeFilterBox, { [sliderBoxClasses]: sliderBoxClasses })}>
            <Slider
              minValue={min}
              maxValue={max}
              label={label}
              id={id}
              isWider={isWider(id)}
              onValueChange={onValueChange}
              onSliderChange={onSliderChange}
              sliderValue={[currentValue.min, currentValue.max]}
              labelsValue={{ min: currentValue.min, max: currentValue.max }}
              {...props}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RangeSlidersBlock;
