import React, { FC } from 'react';
import { StatementBox } from 'src/components';
import { currencySymbols } from 'src/constants/globalConstants';
import { ConstraintsTitle } from '../atoms';
import { CriteriaRow } from '../..';
import { Constraint } from '../../../model';
import styles from './LoanSizeConstraints.module.scss';

const thousandSeparator = (x: number) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

type Props = {
  isViewPage: boolean;
  constraints: Constraint[];
  onChange: (id: number, value: number) => void;
};

const LoanSizeConstraints: FC<Props> = ({ isViewPage, constraints, onChange }) => {
  return (
    <StatementBox className={styles.box}>
      <ConstraintsTitle title="Loan size constraints" />

      <div className={styles.content}>
        {constraints?.map(({ value: id, min, max, currency, percentage }) => {
          const label = max
            ? `${currencySymbols[currency]} ${thousandSeparator(min)}-${thousandSeparator(max)}`
            : `More than ${currencySymbols[currency]} ${thousandSeparator(min)}`;

          return (
            <CriteriaRow
              key={id}
              label={label}
              value={percentage}
              onChange={(value: number) => onChange(id, value)}
              readOnly={isViewPage}
            />
          );
        })}
      </div>
    </StatementBox>
  );
};

export default LoanSizeConstraints;
