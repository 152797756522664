import React, { FC } from 'react';
import cn from 'classnames';
import truncate from 'lodash/truncate';
import { Modal } from 'src/components';
import styles from './DeleteAllocationModal.module.scss';

const NAME_TRUNCATE_LENGTH = 25;

type DeleteAllocationModalProps = {
  name: string;
  isDeleteModalOpen: boolean;
  onToggleModal: () => void;
  isAsideMenuOpen: boolean;
  onDeleteAllocation: () => void;
};

const DeleteAllocationModal: FC<DeleteAllocationModalProps> = ({
  name,
  isDeleteModalOpen,
  onToggleModal,
  isAsideMenuOpen,
  onDeleteAllocation
}) => {
  return (
    <Modal
      isOpen={isDeleteModalOpen}
      onToggle={onToggleModal}
      stylesProps={{
        modal: {
          padding: isAsideMenuOpen ? '10% 0 10% 215px' : '10% 0 10% 84px',
          alignItems: 'center',
          transition: 'all 0.15s ease-in-out'
        }
      }}
    >
      <div className={styles.deleteGroupInfo}>
        <div className={styles.deleteInfoTextBlock}>
          <h2 className={styles.deletePopupTitle}>
            Are you sure you want to delete the {truncate(name, { length: NAME_TRUNCATE_LENGTH })} allocation?
          </h2>
          <p className={styles.deletePopupText}>
            Allocation will be deleted immediately, this action cannot be undone.
          </p>
        </div>

        <div className={styles.deletePopupBtnBlock}>
          <button className={cn(styles.deletePopupBtn, styles.cancelBtn)} onClick={onToggleModal}>
            Cancel
          </button>

          <button className={styles.deletePopupBtn} onClick={onDeleteAllocation}>
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAllocationModal;
