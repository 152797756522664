import React, { FC, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { Typography, ShouldRender } from '..';
import styles from './AsideNavigationMenu.module.scss';

const POPOVER_ID = 'side-menu-option-popover';

type SideMenuOptionProps = {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  path: string;
  exact: boolean;
  isDisabled?: boolean;
  isAsideOpen: boolean;
  popoverTitle: string;
  isActive?: boolean;
};

const SideMenuOption: FC<SideMenuOptionProps> = ({
  title,
  icon: Icon,
  path,
  exact,
  isDisabled,
  isAsideOpen,
  popoverTitle,
  isActive
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const open = !!anchorEl;

  return (
    <React.Fragment>
      <li
        className={cn(styles.menuItem, { [styles.menuItemDisabled]: isDisabled })}
        aria-owns={open ? POPOVER_ID : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <NavLink
          className={cn(styles.menuLink, {
            [styles.menuLinkMinified]: !isAsideOpen,
            [styles.menuLinkActive]: isActive
          })}
          activeClassName={styles.menuLinkActive}
          exact={exact}
          to={path}
        >
          <div className={styles.menuLinkContent}>
            <Icon className={styles.icon} />
            <span className={cn(styles.linkTitle, { [styles.linkTitleHidden]: !isAsideOpen })}>{title}</span>
          </div>
        </NavLink>
      </li>

      <ShouldRender should={!isAsideOpen}>
        <Popover
          id={POPOVER_ID}
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          classes={{
            root: styles.popoverRoot,
            paper: styles.popoverPaper
          }}
        >
          <Typography>{popoverTitle}</Typography>
        </Popover>
      </ShouldRender>
    </React.Fragment>
  );
};

export default SideMenuOption;
