/* eslint-disable no-case-declarations */
import React from 'react';
import cn from 'classnames';
import { stringify } from 'query-string';
import { CircleFlag } from 'react-circle-flags';
import NumberFormat from 'react-number-format';
import { IOpportunity } from 'src/interfaces';
import { CustomizedCheckbox } from 'src/components';
import { OpportunitiesColIDs } from 'src/constants/opportunitiesContants';
import { currencySymbols, LOAN_TABLE_DATE_FORMAT, LoanTypes } from 'src/constants/globalConstants';
import { Provider, ProviderIds } from 'src/constants/marketplaceConstants';
import { Purposes } from 'src/constants/statisticsConstants';
import { ExaloanRatings } from 'src/constants/globalConstants';
import { formatTitle, formatDateFromJSON, getCountryCode } from 'src/helpers';
import { LoanChecked } from './constants';
import styles from './OpportunitiesContent.module.scss';

const {
  CHECK_BOX,
  ORIGINATOR,
  MATCHED_DATE,
  LOAN_AMOUNT,
  MATURITY_DATE,
  GRADE,
  RELATIVE_VALUE,
  LOAN_PURPOSE,
  SECTOR,
  INTEREST_RATE,
  TERM
} = OpportunitiesColIDs;

export const renderSummaryContent = (
  id: OpportunitiesColIDs,
  row: IOpportunity,
  loanChecked: LoanChecked,
  onSetChecked: (id) => void,
  currency: string
): JSX.Element => {
  switch (id) {
    case CHECK_BOX:
      return (
        <CustomizedCheckbox
          isChecked={loanChecked?.isChecked ?? false}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSetChecked(loanChecked.id)}
          classes={{ checkboxRootClass: styles.checkboxRoot }}
          checkedIconProps={{ checkedBoxClass: styles.checkedBox, checkedIconClass: styles.checkedIcon }}
          uncheckedIconProps={{ uncheckedBoxClass: styles.uncheckedBox }}
          isDisabled={loanChecked?.isDisabled ?? false}
        />
      );

    case ORIGINATOR:
      return (
        <div className={styles.flagWrapper}>
          {row?.loan_country && (
            <CircleFlag
              countryCode={
                row?.platform === ProviderIds.EXALENDER
                  ? 'eu'
                  : getCountryCode(row?.loan_country?.toLowerCase())?.toLowerCase()
              }
              onLoad={(e) => e.currentTarget.removeAttribute('title')}
              className={cn(styles.flag, 'img-fluid')}
            />
          )}
          <span className={cn(styles.textInfo, styles.platformText)}> {Provider[row.platform]}</span>
        </div>
      );

    case INTEREST_RATE:
      return <span className={styles.textInfo}>{(row.borrower_rate * 100).toFixed(2) + '%'}</span>;

    case TERM:
      return <span className={styles.textInfo}>{Math.round(row.term_original * 100) / 100}</span>;

    case MATCHED_DATE:
      return <span className={styles.textInfo}>{formatDateFromJSON(row?.matched_date, LOAN_TABLE_DATE_FORMAT)}</span>;

    case LOAN_AMOUNT:
      return (
        <NumberFormat
          prefix={`${currencySymbols[currency]} `}
          displayType="text"
          value={row?.loan_amount?.toFixed(2) ?? 0}
          thousandSeparator
          type="text"
          className={styles.textInfo}
        />
      );

    case MATURITY_DATE:
      return (
        <span className={styles.textInfo}>
          {formatDateFromJSON(new Date(row?.maturity_date), LOAN_TABLE_DATE_FORMAT)}
        </span>
      );

    case GRADE:
      const scoreClassName = cn(styles.textInfo, {
        [styles.scoreA]: row.loan_score === ExaloanRatings.A,
        [styles.scoreB]: row.loan_score === ExaloanRatings.B,
        [styles.scoreC]: row.loan_score === ExaloanRatings.C,
        [styles.scoreD]: row.loan_score === ExaloanRatings.D,
        [styles.scoreE]: row.loan_score === ExaloanRatings.E,
        [styles.scoreF]: row.loan_score === ExaloanRatings.F
      });
      return <span className={scoreClassName}>{row.loan_score}</span>;

    case RELATIVE_VALUE:
      return <span className={cn(styles.textInfo, styles.relativeValue)}>{row?.relative_value ?? ''}</span>;

    case LOAN_PURPOSE:
      return <span className={styles.textInfo}>{Purposes[row.loan_purpose]}</span>;

    case SECTOR:
      return row.type === LoanTypes.SME && <span className={styles.textInfo}>{formatTitle(row?.sector)}</span>;

    default:
      return <></>;
  }
};

export const getExportOpportunitiesUrl = (allocationId: string, currency: string, fileType: string): string => {
  const query = {
    currency,
    file_type: fileType,
    allocation_id: allocationId
  };

  return `export/opportunities?${stringify(query)}`;
};
