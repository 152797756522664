import { apiTypes } from '../types';

export const setApiTokens = (tokens): { type: string; payload } => ({
  type: apiTypes.SET_API_TOKENS,
  payload: tokens
});

export const getApiTokens = () => ({
  type: apiTypes.GET_API_TOKENS
});
