import React from 'react';
import cn from 'classnames';
import RadioGroup from '@mui/material/RadioGroup';
import RadioWithLabel from 'src/atoms/radioButtons/radioWithLabel/RadioWithLabel';
import { RiskMeasureTypes } from 'src/constants/portfolioBuilderConstants';
import styles from './RiskMeasureBlock.module.scss';

const radiosList = [
  {
    value: RiskMeasureTypes.LOW,
    label: 'Low Risk'
  },
  {
    value: RiskMeasureTypes.BALANCED,
    label: 'Balanced'
  },
  {
    value: RiskMeasureTypes.RETURN_ORIENTED,
    label: 'Return-Oriented'
  }
];

interface RiskMeasureBlockProps {
  value: string | number;
  name?: string;
  isDisabled?: boolean;
  ariaLabel?: string;
  onRadioChange: (value) => void;
  classes?: {
    wrapperClass?: string;
    titleClass?: string;
    radioGroupClass?: string;
    radioLabelRootClass?: string;
    radioLabelClass?: string;
  };
}

const RiskMeasureBlock = ({
  onRadioChange,
  name,
  value,
  isDisabled,
  ariaLabel = 'risk measure',
  classes
}: RiskMeasureBlockProps): JSX.Element => {
  return (
    <div className={cn(styles.contentWrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      <h2 className={cn(styles.title, { [classes?.titleClass]: classes?.titleClass })}>Risk Measure</h2>

      <RadioGroup
        aria-label={ariaLabel}
        name={name}
        value={value}
        onChange={onRadioChange}
        classes={{ root: cn(styles.radioGroupRoot, { [classes?.radioGroupClass]: classes?.radioGroupClass }) }}
      >
        {radiosList.map(({ value, label }) => (
          <RadioWithLabel
            isDisabled={isDisabled}
            key={value}
            value={value}
            label={label}
            classes={{
              labelRootClass: cn(styles.labelRootClass, {
                [classes?.radioLabelRootClass]: classes?.radioLabelRootClass
              }),
              labelClass: cn({ [classes?.radioLabelClass]: classes?.radioLabelClass })
            }}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RiskMeasureBlock;
