import React, { FC } from 'react';
import cn from 'classnames';
import styles from './ContentHeadingBox.module.scss';

type ContentHeadingBoxProps = {
  children: React.ReactNode;
  wrapperClass?: string;
};

const ContentHeadingBox: FC<ContentHeadingBoxProps> = ({ children, wrapperClass }) => {
  return <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>{children}</div>;
};

export default ContentHeadingBox;
