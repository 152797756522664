import axios, { AxiosResponse } from 'axios';
import endpoints from 'src/constants/apiEndpoints';
import * as Shapes from 'src/interfaces/statisticsReduxShapes';

export const getStatisticsHelper = (
  params: Shapes.GetStatisticsParams
): Promise<AxiosResponse<{ data: Shapes.GetStatisticsResponse }>> => {
  return axios.get(endpoints.GET_STATISTICS(params.currency, params.allocation));
};

export const refreshStatisticsHelper = (): Promise<AxiosResponse> => {
  return axios.get(endpoints.REFRESH_STATISTICS);
};
