import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'src/redux/selectors';
import MainLayout from 'src/components/MainLayout/MainLayout';
import ButtonBack from 'src/atoms/buttons/buttonUnderlined/ButtonUnderlined';
import { PAGES } from 'src/constants/pages';
import styles from './AccountVerifiedPage.module.scss';

const AccountVerifiedPage = (): JSX.Element => {
  const { push } = useHistory();
  const { isAuthenticated } = useSelector(authSelector);

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isPrivate={false}>
      <div className={styles.content}>
        <div className={styles.passwordSuccessWrapper}>
          <div className={styles.titleBlock}>
            <h2 className={styles.title}>Your Account has been Verified!</h2>
            <p className={styles.description}>
              Your account has been confirmed! Please Sign in to access your account.
            </p>
          </div>

          <ButtonBack onClick={() => push(PAGES.SIGN_IN)} label="Back to sign in" />
        </div>
      </div>
    </MainLayout>
  );
};

export default AccountVerifiedPage;
