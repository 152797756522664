import React from 'react';
import cn from 'classnames';
import styles from './ToggleButton.module.scss';

interface ITogleButtonProps {
  options: Array<{
    value: string | number;
    label: string;
  }>;
  value: string;
  onChange: (option) => void;
  wrapperClassName?: string;
  itemClassName?: string;
}

const ToggleButton = ({
  options,
  value,
  onChange,
  wrapperClassName,
  itemClassName
}: ITogleButtonProps): JSX.Element => {
  return (
    <div className={cn(styles.toggleContainer, { [wrapperClassName]: wrapperClassName })}>
      {options.map((opt, idx) => (
        <button
          key={`${opt.label}_${idx}`}
          onClick={() => onChange(opt.value)}
          className={cn({
            [styles.active]: opt.value === value,
            [itemClassName]: itemClassName
          })}
        >
          {opt.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
