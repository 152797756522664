import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { PAGES } from 'src/constants/pages';
import { LoanTypes } from 'src/constants/globalConstants';
import { InvestmentHistory, ICashflowData, MarketplaceLoan } from 'src/interfaces';
import { getInvestmentHistory, getInvestmentRepayments } from 'src/redux/actions/investmentsActions';

interface EntityDetailsParams {
  id: string;
  isLoan: boolean;
  label: string;
  path: string;
  loanIdLabelType: string;
}

const ENTITY_TYPE_LABELS = {
  LOAN: 'Loan ID',
  BID: 'Loan ID',
  INVESTMENT: 'Loan ID',
  OPPORTUNITY: 'Loan ID'
};

export const getEntityDetailsParams = (pathname: string): EntityDetailsParams => {
  const isLoan = [PAGES.MARKETPLACE, PAGES.OPPORTUNITIES].some((page) => pathname.includes(page));
  const pathSplit = pathname.replace(/^\//, '').split('/');
  const id = pathSplit.slice(-1).join('');

  if (pathname.includes(PAGES.MARKETPLACE)) {
    const loanTypeFromPath = pathSplit[1].replace(/-/g, '_').toUpperCase();

    const mapping = {
      [LoanTypes.CONSUMER]: {
        label: 'Back to Marketplace Consumer',
        path: PAGES.MARKETPLACE_CONSUMER
      },
      [LoanTypes.SME]: {
        label: 'Back to Marketplace SME',
        path: PAGES.MARKETPLACE_SME
      },
      [LoanTypes.INVOICE_FINANCING]: {
        label: 'Back to Marketplace Invoice Financing',
        path: PAGES.MARKETPLACE_INVOICE_FINANCING
      }
    };

    return {
      id,
      isLoan,
      label: mapping[loanTypeFromPath].label,
      path: mapping[loanTypeFromPath].path,
      loanIdLabelType: ENTITY_TYPE_LABELS.LOAN
    };
  }

  if (pathname.includes(PAGES.INVESTMENTS)) {
    return {
      id,
      isLoan,
      label: 'Back to Investments',
      path: PAGES.INVESTMENTS,
      loanIdLabelType: ENTITY_TYPE_LABELS.INVESTMENT
    };
  }

  if (pathname.includes(PAGES.OUTSTANDING_BIDS)) {
    return {
      id,
      isLoan,
      label: 'Back To Outstanding Bids',
      path: PAGES.OUTSTANDING_BIDS,
      loanIdLabelType: ENTITY_TYPE_LABELS.BID
    };
  }

  if (pathname.includes(PAGES.OPPORTUNITIES)) {
    return {
      id,
      isLoan,
      label: 'Back to Opportunities',
      path: PAGES.OPPORTUNITIES,
      loanIdLabelType: ENTITY_TYPE_LABELS.OPPORTUNITY
    };
  }

  return {
    id,
    isLoan,
    label: 'Back to Marketplace',
    path: PAGES.MARKETPLACE,
    loanIdLabelType: ENTITY_TYPE_LABELS.LOAN
  };
};

interface UseEntityDetailsReturn extends EntityDetailsParams {
  push: (location, state?: unknown) => void;
  pathname: string;
  isBidsPage: boolean;
  isInvestmentsContent: boolean;
}

export const useEntityDetailsPathParams = (): UseEntityDetailsReturn => {
  const {
    push,
    location: { pathname }
  } = useHistory();

  const entityDetails = useMemo(() => getEntityDetailsParams(pathname), [pathname]);

  const isBidsPage = useMemo(() => pathname.includes(PAGES.OUTSTANDING_BIDS), [pathname]);
  const isInvestmentsContent = useMemo(
    () => [PAGES.INVESTMENTS, PAGES.OUTSTANDING_BIDS].some((page) => pathname.includes(page)),
    [pathname]
  );

  return useMemo(
    () => ({ push, ...entityDetails, pathname, isBidsPage, isInvestmentsContent }),
    [push, entityDetails, pathname, isBidsPage, isInvestmentsContent]
  );
};

type UseInvestmentHistoryReturn = [
  isHistoryLoading: boolean,
  historyData: InvestmentHistory[],
  shouldHideHistory: boolean
];

export const useInvestmentHistory = (dispatch, pathname: string, id: string): UseInvestmentHistoryReturn => {
  const [isHistoryLoading, setHistoryLoading] = useState(true);
  const [historyData, setHistoryData] = useState<InvestmentHistory[]>([]);

  const idRef = useRef(null);

  useEffect(() => {
    const shouldGetHistory =
      [PAGES.INVESTMENTS, PAGES.OUTSTANDING_BIDS].some((page) => pathname.includes(page)) && idRef.current !== id;

    if (shouldGetHistory) {
      dispatch(
        getInvestmentHistory(id, (data: InvestmentHistory[]) => {
          setHistoryData(data);
          setHistoryLoading(false);
        })
      );

      idRef.current = id;

      return;
    }

    setHistoryLoading(false);
  }, [dispatch, pathname, id, isHistoryLoading]);

  const shouldHideHistory = useMemo(
    () => [PAGES.MARKETPLACE, PAGES.OPPORTUNITIES].some((v) => pathname.includes(v)),
    [pathname]
  );

  return [isHistoryLoading, historyData, shouldHideHistory];
};

type UseInvestmentRepaymentsReturn = [
  isRepaymentsLoading: boolean,
  cashflowData: ICashflowData[],
  shouldShowRepayments: boolean
];

export const useInvestmentRepayments = (
  dispatch,
  pathname: string,
  loan: MarketplaceLoan,
  currency: string
): UseInvestmentRepaymentsReturn => {
  const [isRepaymentsLoading, setRepaymentsLoading] = useState(false);
  const [cashflowData, setCashflowData] = useState<ICashflowData[]>(null);

  const isRequested = useRef(null);

  const shouldGetRepayments = useMemo(
    () => (loan ? pathname.includes(PAGES.INVESTMENTS) && !isRepaymentsLoading && !isRequested.current : null),
    [pathname, loan, isRepaymentsLoading]
  );

  useEffect(() => {
    if (shouldGetRepayments) {
      isRequested.current = true;

      setRepaymentsLoading(true);

      dispatch(
        getInvestmentRepayments(
          { id: loan.id, currency },
          (data) => {
            setCashflowData(data);
            setRepaymentsLoading(false);

            isRequested.current = null;
          },
          () => {
            setRepaymentsLoading(false);
            isRequested.current = null;
          }
        )
      );
    }
  }, [dispatch, loan, currency, shouldGetRepayments]);

  const shouldShowRepayments = pathname.includes(PAGES.INVESTMENTS) && !isRepaymentsLoading && !isEmpty(cashflowData);

  return [isRepaymentsLoading, cashflowData, shouldShowRepayments];
};
