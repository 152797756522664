import { ApiReducer } from 'src/interfaces/apiReducerShapes';
import { apiTypes, authActionTypes } from '../types';

const initialState = {
  tokens: []
};

export default (state = initialState, { type, payload }: { type: string; payload }): ApiReducer => {
  switch (type) {
    case apiTypes.SET_API_TOKENS:
      return { tokens: [...payload.data] };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
