import React from 'react';
import { useHistory } from 'react-router-dom';
import { MainLayout, ErrorPageLayout, ConfirmButton } from 'src/components';
import { PAGES } from 'src/constants/pages';
import styles from './Error404Page.module.scss';

const errorReasons = [
  { text: '- We are doing some maintenance and the page will be back soon', newRow: '' },
  { text: '- The page no longer exists', newRow: '' },
  { text: '- One of of engineers tripped over a cable for the server', newRow: '(not again, James)' },
  { text: '- You like 404 Pages', newRow: '' }
];

const BTN_PADDING = 42;

const Error404Page = (): JSX.Element => {
  const { push } = useHistory();

  return (
    <MainLayout isCentered={false} isPrivate={false}>
      <ErrorPageLayout>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            <span>404. </span>
            Page Not Found.
          </h2>
          <p className={styles.description}>
            Whoops! That’s an error.
            <span>The page you have requested isn’t available right now.</span>
            <span>Perhaps you are here because:</span>
          </p>

          <ul className={styles.list}>
            {errorReasons.map(({ text, newRow }, idx) => (
              <li key={idx} className={styles.listItem}>
                <p className={styles.text}>
                  {text}
                  {!!newRow && <span>{newRow}</span>}
                </p>
              </li>
            ))}
          </ul>

          <ConfirmButton type="button" onClick={() => push(PAGES.DASHBOARD)} horizontalPadding={BTN_PADDING}>
            Return to Dashboard
          </ConfirmButton>
        </div>
      </ErrorPageLayout>
    </MainLayout>
  );
};

export default Error404Page;
