import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { MainLayout, PortfolioAllocation, TabsNavigationBlock, ContentHeadingBox } from 'src/components';
import { PAGES } from 'src/constants/pages';
import { getAllocationHook, useUserValues } from 'src/hooks';
import { currencySelector, investmentsFiltersSelector, preselectedAllocationSelector } from 'src/redux/selectors';
import { getInvestments, getInvestmentsFilters, getOutstandingBids } from 'src/redux/actions/investmentsActions';
import { getGrades, getPlatforms, onPresetAllocationId } from 'src/redux/actions/baseActions';
import { investmentsTypes } from 'src/redux/types';
import { GetInvestmentsLoansParams } from 'src/interfaces';
import { InvestmentContent } from './InvestmentContent';
import { pageTitleMap } from './constants';
import { InvestmentRequestParams } from './model';
import {
  getExportInvestmentsFileName,
  getExportInvestmentsUrl,
  getStatuses,
  GET_INVESTMENT_LOANS_DEFAULT_PARAMS
} from './InvestmentContent/helpers';
import { exportFileApi } from 'src/utils/comon';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import styles from './InvestmentsPage.module.scss';

const investmentsTabs = [
  { label: 'Investments', path: PAGES.INVESTMENTS },
  { label: 'Outstanding Bids', path: PAGES.OUTSTANDING_BIDS }
];

const ALL_PORTFOLIOS_OPTION = { value: null, label: 'All Portfolios' };

const InvestmentsPage = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    location: { pathname }
  } = useHistory();

  const { allocations } = useSelector(investmentsFiltersSelector);
  const allocationIds = useMemo(() => (!isEmpty(allocations) ? allocations?.map(({ id }) => id) : []), [allocations]);

  const [loansReqParams, setLoansReqParams] = useState<InvestmentRequestParams>(null);

  const onAllocationChange = useCallback(
    (id: string) => {
      dispatch(onPresetAllocationId(id));
      setLoansReqParams((prev) => ({ ...prev, allocations: id ? [id] : allocationIds }));
    },
    [dispatch, allocationIds]
  );

  const { selectedAllocation, selectorOptions, onChangeValue } = getAllocationHook({
    allocations,
    onAllocationChange,
    allDataOption: ALL_PORTFOLIOS_OPTION
  });

  const onTriggerClearFilters = useCallback(() => {
    onChangeValue(ALL_PORTFOLIOS_OPTION);
  }, [onChangeValue]);

  const isBidsPage = useMemo(() => pathname === PAGES.OUTSTANDING_BIDS, [pathname]);

  const [isAllocationPreset, setAllocationPreset] = useState(false);
  const allocationID = useSelector(preselectedAllocationSelector);

  const [isAllocationsFetching, setAllocationsFetching] = useState(true);

  useEffect(() => {
    if (
      isAllocationPreset ||
      (isEmpty(allocationIds) && isAllocationsFetching) ||
      (isEmpty(selectorOptions) && isAllocationsFetching)
    )
      return;

    const ids = allocationID ? [allocationID] : allocationIds;
    setLoansReqParams((prevState) => ({
      ...prevState,
      ...GET_INVESTMENT_LOANS_DEFAULT_PARAMS(ids, getStatuses(isBidsPage), isBidsPage)
    }));

    const preselectOption = selectorOptions.find((item) => item.value === allocationID);
    onChangeValue(preselectOption);

    setAllocationPreset(true);
  }, [
    isAllocationPreset,
    allocationIds,
    allocationID,
    isBidsPage,
    selectorOptions,
    onChangeValue,
    isAllocationsFetching
  ]);

  const onGetData = useCallback(
    (reqParams: GetInvestmentsLoansParams, onFinally: () => void) => {
      if (isBidsPage) {
        dispatch(getOutstandingBids(reqParams, onFinally, onFinally));
        return;
      }

      dispatch(getInvestments(reqParams, onFinally, onFinally));
    },
    [dispatch, isBidsPage]
  );

  const { accessToken } = useUserValues();
  const currency = useSelector(currencySelector);

  const onConfirmExport = useCallback(
    async (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => {
      const fileName = getExportInvestmentsFileName(isBidsPage);
      const fileUrl = getExportInvestmentsUrl(loansReqParams, currency.value, fileType, isBidsPage);

      await exportFileApi(fileUrl, fileName, fileType, accessToken);
      onDownloadedSuccess();
    },
    [accessToken, currency, isBidsPage, loansReqParams]
  );

  useEffect(() => {
    dispatch(getInvestmentsFilters(false, () => setAllocationsFetching(false)));
    dispatch(getGrades());
    dispatch(getPlatforms());

    return () => {
      dispatch({ type: investmentsTypes.CLEAR_INVESTMENTS });
      dispatch({ type: investmentsTypes.CLEAR_CACHED_REQ_PARAMS });
    };
  }, [dispatch]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!loansReqParams) return;

    setLoading(true);
    onGetData({ ...loansReqParams, currency: currency.value }, () => setLoading(false));
  }, [loansReqParams, currency, onGetData]);

  return (
    <MainLayout isEmptySkeleton>
      <ContentHeadingBox wrapperClass={styles.controlPanel}>
        <TabsNavigationBlock
          tabs={investmentsTabs}
          wrapperClass={styles.tabsWrapper}
          tabLinkClass={styles.tabLinkClass}
        />

        <PortfolioAllocation
          selectedAllocation={selectedAllocation}
          selectorOptions={selectorOptions}
          onChangeValue={onChangeValue}
          isSearchable={false}
        />
      </ContentHeadingBox>

      <InvestmentContent
        allocationIds={allocationIds}
        isBidsPage={isBidsPage}
        pageTitle={pageTitleMap[pathname]}
        currency={currency.value}
        loansReqParams={loansReqParams}
        onSetLoansReqParams={setLoansReqParams}
        isLoading={isLoading}
        onSetLoading={setLoading}
        onConfirmExport={onConfirmExport}
        onTriggerClearFilters={onTriggerClearFilters}
      />
    </MainLayout>
  );
};

export default InvestmentsPage;
