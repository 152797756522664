import React, { useMemo } from 'react';
import cn from 'classnames';
import { PercentagePieItemShape } from 'src/interfaces/PortfolioBuilderShapes';
import { PercentagePie, StatementBox, ToggleButton } from 'src/components';
import styles from './PortfolioStructure.module.scss';

interface IPortfolioStructureProps {
  chartData: Array<PercentagePieItemShape>;
  portfolioStructureToggle: string;
  onPortfolioStrictureToggleChange: (val) => void;
  onChartDataChange?: (data: PercentagePieItemShape[]) => void;
  toggleOptions: Array<{ value: string; label: string }>;
  wrapperClass?: string;
}

const PortfolioStructure = ({
  chartData,
  onChartDataChange,
  portfolioStructureToggle,
  onPortfolioStrictureToggleChange,
  toggleOptions,
  wrapperClass
}: IPortfolioStructureProps): JSX.Element => {
  const chartDataFormatted = useMemo(
    () => chartData.map((item) => ({ ...item, title: item.title.replaceAll('_', ' ') })),
    [chartData]
  );

  return (
    <StatementBox className={cn(styles.portfolioStructureWrapper, { [wrapperClass]: wrapperClass })}>
      <div className={styles.portfolioStructureHeader}>
        <h2>Portfolio Structure</h2>
      </div>

      <ToggleButton
        options={toggleOptions}
        value={portfolioStructureToggle}
        onChange={onPortfolioStrictureToggleChange}
        wrapperClassName={styles.toggleWrapper}
        itemClassName={styles.toggleItem}
      />

      <PercentagePie
        data={chartDataFormatted}
        isDefault
        readonly
        displayParagraph
        title=""
        onDataChanged={onChartDataChange}
        classes={{ pieChartWrapperClass: styles.pieWrapper, wrapperClass: styles.perncentageWrapper }}
      />
    </StatementBox>
  );
};

export default PortfolioStructure;
