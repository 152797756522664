import { call, put, takeLatest, takeLeading, takeEvery, ForkEffect, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { investmentCachedParamsSelector } from '../selectors';
import { investmentsTypes } from '../types';
import * as investmentsActions from '../actions/investmentsActions';
import * as apiHelpers from './sagaHelpers/investmentsApiHelpers';
import * as Shapes from 'src/interfaces/investmentsReduxShapes';
import { callbackResolver } from 'src/utils/reduxHelpers';
import { investmentLoansSelector, outstandingBidsSelector } from '../selectors';

function* getInvestmentsWorker({ payload }: Shapes.GetInvestmentsLoansAction) {
  const cachedParams = yield select(investmentCachedParamsSelector);

  if (isEqual(cachedParams, payload.reqParams)) {
    callbackResolver(payload?.onSuccess);

    return;
  }

  try {
    const { data } = yield call(apiHelpers.getInvestmentsLoansHelper, payload.reqParams);
    yield put(investmentsActions.putInvestmentsCachedParams(payload.reqParams));

    const { page }: Shapes.InvestmentLoansData = yield select(investmentLoansSelector);

    if (payload.reqParams.page > page) {
      yield put(investmentsActions.putMoreInvestmentsLoans(data.data, payload.reqParams.page));
      callbackResolver(payload?.onSuccess);

      return;
    }

    yield put(investmentsActions.putInvestmentsLoans(data.data, payload.reqParams.page));

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError, error);
    console.error('getInvestmentsWorker', error);
  }
}

function* getOutstandingBidsWorker({ payload }: Shapes.GetInvestmentsLoansAction) {
  const cachedParams = yield select(investmentCachedParamsSelector);

  if (isEqual(cachedParams, payload.reqParams)) {
    callbackResolver(payload?.onSuccess);

    return;
  }

  try {
    const { data } = yield call(apiHelpers.getOutstandingBidsHelper, payload.reqParams);
    yield put(investmentsActions.putInvestmentsCachedParams(payload.reqParams));

    const { page }: Shapes.OutstandingBidsData = yield select(outstandingBidsSelector);

    if (payload.reqParams.page > page) {
      yield put(investmentsActions.putMoreOutstandingBids(data.data, payload.reqParams.page));
      callbackResolver(payload?.onSuccess);

      return;
    }

    yield put(investmentsActions.putOutstandingBids(data.data, payload.reqParams.page));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError, error);
    console.error('getOutstandingBidsWorker', error);
  }
}

function* getInvestmentsFiltersWorker({ payload }: Shapes.GetInvestmentsFiltersAction) {
  try {
    const loanTypes = yield call(apiHelpers.getLoanTypes);
    let allocations, allocationsWithOpportunities;

    if (payload?.withOpportunities) {
      const { data } = yield call(apiHelpers.getOpportunitiesAllocations);
      allocationsWithOpportunities = data?.data;
    } else {
      const { data } = yield call(apiHelpers.getAllocations);
      allocations = data?.data;
    }

    yield put(
      investmentsActions.putInvestmentsFilters({
        loanTypes: loanTypes?.data?.data ?? [],
        allocations,
        allocationsWithOpportunities
      })
    );

    callbackResolver(payload?.onFinally);
  } catch (error) {
    console.error('getAllocationsWorker', error);
    callbackResolver(payload?.onFinally);
  }
}

function* getInvestmentsTotalOpportunitiesWorker() {
  try {
    const total = yield call(apiHelpers.getTotalOpportunitiesReq);

    yield put(investmentsActions.putTotalOpportunities(total?.data.amount));
  } catch (error) {
    console.error('getAllocationsWorker', error);
  }
}

function* getInvestmentsFilteredOpportunitiesWorker({ payload: { reqParams, onSuccess } }: Shapes.OpportunitiesAction) {
  try {
    const { data } = yield call(apiHelpers.getFilteredOpportunitiesReq, reqParams);

    if (reqParams.page === 1) {
      yield put(investmentsActions.putOpportunities(data));
    } else {
      yield put(investmentsActions.putMoreOpportunities(data));
    }

    callbackResolver(onSuccess);
  } catch (error) {
    console.error('getInvestmentsFilteredOpportunitiesWorker', error);
  }
}

function* bidInvestmentsOpportunitiesWorker({ payload }: Shapes.IBidOpportunitiesAction) {
  try {
    const { data } = yield call(apiHelpers.bidOpportunitiesReq, payload.data);

    callbackResolver(payload?.onSuccess, data);
  } catch (error) {
    console.error('bidInvestmentsOpportunitiesWorker', error);
  }
}

function* getInvestmentHistoryWorker({ payload }: Shapes.GetInvestmentHistory) {
  try {
    const { data } = yield call(apiHelpers.getInvestmentHistoryReq, payload.id);
    payload.onSuccess(data.data);
  } catch (error) {
    console.error('getInvestmentHistoryWorker', error);
  }
}

function* getInvestmentRepaymentsWorker({ payload }: Shapes.IGetInvestmentRepayments) {
  try {
    const { data } = yield call(apiHelpers.getInvestmentRepaymentsReq, payload.reqData);
    payload.onSuccess(data.data);
  } catch (error) {
    console.error('getInvestmentRepaymentsWorker', error);
  }
}

function* getEntityDetailsWorker({ payload }: Shapes.GetEntityDetails) {
  try {
    const { id, isLoan, currency } = payload;
    const { data } = yield call(apiHelpers.getEntityDetailsReq, id, isLoan, currency);

    payload.onSuccess(data.data);
  } catch (error) {
    callbackResolver(payload.onError, error);
    console.error('getInvestmentRepaymentsWorker', error);
  }
}

export function* investmentsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(investmentsTypes.GET_INVESTMENTS_LOANS, getInvestmentsWorker);
  yield takeEvery(investmentsTypes.GET_OUTSTANDING_BIDS, getOutstandingBidsWorker);
  yield takeLeading(investmentsTypes.GET_INVESTMENTS_FILTERS, getInvestmentsFiltersWorker);
  yield takeLatest(investmentsTypes.GET_TOTAL_OPPORTUNITIES, getInvestmentsTotalOpportunitiesWorker);
  yield takeLatest(investmentsTypes.BID_OPPORTUNITIES, bidInvestmentsOpportunitiesWorker);
  yield takeLatest(investmentsTypes.GET_FILTERED_OPPORTUNITIES, getInvestmentsFilteredOpportunitiesWorker);
  yield takeLatest(investmentsTypes.GET_INVESTMENT_HISTORY, getInvestmentHistoryWorker);
  yield takeLatest(investmentsTypes.GET_INVESTMENT_REPAYMENTS, getInvestmentRepaymentsWorker);
  yield takeLatest(investmentsTypes.GET_ENTITY_DETAILS, getEntityDetailsWorker);
}
