import croud4cashLogo from 'src/assets/images/crown4cash.png';
import flexFundingLogo from 'src/assets/images/flex_funding.png';
import bondoraLogo from 'src/assets/images/bondora.png';
import winwinLogo from 'src/assets/images/pinjamwinwin.png';
import exalenderLogo from 'src/assets/images/exalender.png';
import finbeeLogo from 'src/assets/images/finbee.png';
import vaurausLogo from 'src/assets/images/vauraus.png';
import acrediusLogo from 'src/assets/images/acredius.png';
import anoduLogo from 'src/assets/images/anodu.png';
import { LoanTypes, PlatformsIDs } from 'src/constants/globalConstants';
import { CriteriaNames, CriteriaOption, Platform } from './model';
import { InvestmentReinvestmentStrategy } from 'src/interfaces';

export const ALLOCATION_ACTIVE_STATUS = 2;

export const DEFAULT_INVESTMENT_PERCENTAGE = 100;
export const MIN_INVESTMENT_PERCENTAGE = 1;

export const DEFAULT_CRITERIA_PERCENTAGE = 100;

export const DEFAULT_RANGE_FILTERS = {
  borrowerRate: {
    min: 0,
    max: 50
  },
  term: {
    min: 0,
    max: 60
  },
  loanAmount: {
    min: 1,
    max: 10000000
  }
};

export const PLATFORMS_LOGO_MAP = {
  [PlatformsIDs.CROWD4CASH]: croud4cashLogo,
  [PlatformsIDs.FLEXFUNDING]: flexFundingLogo,
  [PlatformsIDs.BONDORA]: bondoraLogo,
  [PlatformsIDs.PINJAMWINWIN]: winwinLogo,
  [PlatformsIDs.EXALENDER]: exalenderLogo,
  [PlatformsIDs.FINBEE]: finbeeLogo,
  [PlatformsIDs.VAURAUS]: vaurausLogo,
  [PlatformsIDs.ACREDIUS]: acrediusLogo,
  [PlatformsIDs.ANODU]: anoduLogo
};

export const PLATFORMS_LABEL_MAP = {
  [PlatformsIDs.CROWD4CASH]: 'Crowd4Cash',
  [PlatformsIDs.FLEXFUNDING]: 'Flexfunding',
  [PlatformsIDs.BONDORA]: 'Bondora',
  [PlatformsIDs.PINJAMWINWIN]: 'PinJamWinWin',
  [PlatformsIDs.EXALENDER]: 'Exalender',
  [PlatformsIDs.FINBEE]: 'Finbee',
  [PlatformsIDs.VAURAUS]: 'Vauraus',
  [PlatformsIDs.ACREDIUS]: 'Acredius',
  [PlatformsIDs.ANODU]: 'Anodu'
};

const { LOAN_PURPOSES, RELATIVE_VALUE, GRADE, LOAN_SIZE, GENERAL, SME_SECTORS } = CriteriaNames;

export const PLATFORMS_LIST: Platform[] = [
  {
    id: PlatformsIDs.CROWD4CASH,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.CROWD4CASH],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.CROWD4CASH],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.FINBEE,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.FINBEE],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.FINBEE],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.ACREDIUS,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.ACREDIUS],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.ACREDIUS],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.VAURAUS,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.VAURAUS],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.VAURAUS],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.FLEXFUNDING,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.FLEXFUNDING],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.FLEXFUNDING],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.BONDORA,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.BONDORA],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.BONDORA],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.PINJAMWINWIN,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.PINJAMWINWIN],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.PINJAMWINWIN],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.EXALENDER,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.EXALENDER],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.EXALENDER],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  },
  {
    id: PlatformsIDs.ANODU,
    label: PLATFORMS_LABEL_MAP[PlatformsIDs.ANODU],
    logo: PLATFORMS_LOGO_MAP[PlatformsIDs.ANODU],
    percentage: 0,
    amount: 0,
    criteriaSelected: [],
    investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE,
    general: DEFAULT_RANGE_FILTERS,
    constraints: [],
    loanTypes: [],
    relative: [],
    grades: [],
    smePurposes: [],
    sectors: [],
    currency: null
  }
];

export const LOAN_TYPE_OPTIONS = [
  { value: LoanTypes.CONSUMER, label: 'Consumer Loan' },
  { value: LoanTypes.SME, label: 'SME Loan' },
  { value: LoanTypes.INVOICE_FINANCING, label: 'Invoice Financing' }
];

export const CRITERIA_OPTIONS: CriteriaOption[] = [
  {
    name: 'Loan Purposes',
    id: LOAN_PURPOSES,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME]
  },
  {
    name: 'Relative Value',
    id: RELATIVE_VALUE,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    name: 'Exaloan Score',
    id: GRADE,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    name: 'Loan Size Constraints',
    id: LOAN_SIZE,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    name: 'General Constrains',
    id: GENERAL,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    name: 'SME Sectors',
    id: SME_SECTORS,
    loanTypes: [LoanTypes.SME]
  }
];

export const REINVESTMENT_STRATEGY_DEFAULT_OPTION = {
  value: InvestmentReinvestmentStrategy.DoNotReinvest,
  label: 'Do Not Reinvest'
};

export const REINVESTMENT_STRATEGY_OPTIONS = [
  REINVESTMENT_STRATEGY_DEFAULT_OPTION,
  { value: InvestmentReinvestmentStrategy.ReinvestPrincipal, label: 'Reinvest Principal' },
  { value: InvestmentReinvestmentStrategy.ReinvestAll, label: 'Reinvest All' }
];
