import React, { FC, useCallback } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { onlyDecimalDigitsRegex } from 'src/helpers/regexHelpers';
import { callbackResolver } from 'src/utils/reduxHelpers';

interface Props extends NumberFormatProps {
  isPercentageField?: boolean;
  onChanged: (value: string) => void;
}

const defaultMaxPercentage = 100;
const defaultPercentageDecimal = 2;

const NumericInput: FC<Props> = ({
  value,
  onChanged,
  isAllowed,
  onClick,
  isPercentageField,
  decimalScale,
  suffix,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => {
      const newValue: string = e.target.value.replace(onlyDecimalDigitsRegex, '');
      const isZeroBaseValue = !+value && newValue.length >= 2 && !newValue.includes('.');

      if (isZeroBaseValue) {
        onChanged(newValue.replace(/0/g, ''));
        return;
      }

      onChanged(newValue);
    },
    [onChanged, value]
  );

  return (
    <NumberFormat
      {...props}
      value={value}
      onChange={handleChange}
      suffix={suffix}
      decimalScale={isPercentageField ? defaultPercentageDecimal : decimalScale}
      isAllowed={(value) => {
        if (isPercentageField) return +value.value <= defaultMaxPercentage;
        if (typeof isAllowed === 'function') return isAllowed(value);
        return true;
      }}
      onClick={(e) => {
        const numericValue = Math.floor(+e.target.value.replace(onlyDecimalDigitsRegex, ''));

        if (numericValue === 0 && e.target.selectionStart === 1) {
          e.target.selectionStart = e.target.selectionStart - e.target.value.length;
          e.target.selectionEnd = e.target.selectionEnd - e.target.value.length;
        }

        callbackResolver(onClick, e);
      }}
    />
  );
};

export default NumericInput;
