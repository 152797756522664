import React from 'react';
import cn from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles, createStyles } from '@mui/styles';
import { CheckboxIconUnchecked, CheckboxIconChecked } from './adds/CheckboxIcons';

const useCheckboxStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      padding: '3px 9px 3px 0',
      '&:hover': { backgroundColor: 'transparent' }
    },
    checked: {
      '&:hover': { backgroundColor: 'transparent' }
    },
    colorSecondary: {
      color: '#9DAFBD',

      '&.Mui-checked': {
        color: '#077186',

        '&:hover': { backgroundColor: 'transparent' }
      }
    }
  })
);

interface CustomizedCheckboxProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent) => void;
  onClick?: (e) => void;
  onBlur?: (e) => void;
  name?: string;
  id?: string;
  size?: 'small' | 'medium';
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  uncheckedIconProps?: {
    uncheckedBoxClass?: string;
  };
  checkedIconProps?: {
    checkedBoxClass?: string;
    checkedIconClass?: string;
  };
  classes?: {
    checkboxRootClass?: string;
    checkedIcon?: string;
    colorPrimary?: string;
    colorSecondary?: string;
  };
}

const CustomizedCheckbox = ({
  onChange,
  onClick,
  onBlur,
  isDisabled,
  isChecked,
  name,
  classes,
  size,
  id,
  icon,
  checkedIcon,
  uncheckedIconProps,
  checkedIconProps
}: CustomizedCheckboxProps): JSX.Element => {
  const { root, checked, colorSecondary } = useCheckboxStyles();

  return (
    <Checkbox
      disabled={isDisabled}
      icon={icon ?? <CheckboxIconUnchecked {...uncheckedIconProps} />}
      checkedIcon={checkedIcon ?? <CheckboxIconChecked {...checkedIconProps} />}
      size={size ? size : 'small'}
      disableRipple
      checked={isChecked}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      name={name}
      id={id ?? name}
      value={isChecked}
      classes={{
        root: cn(root, { [classes?.checkboxRootClass]: classes?.checkboxRootClass }),
        checked: cn(checked, { [classes?.checkedIcon]: classes?.checkedIcon }),
        colorPrimary: cn({ [classes?.colorPrimary]: classes?.colorPrimary }),
        colorSecondary: cn(colorSecondary, { [classes?.colorSecondary]: classes?.colorSecondary })
      }}
    />
  );
};

export default CustomizedCheckbox;
