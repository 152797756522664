import axios, { AxiosResponse } from 'axios';
import endpoints from 'src/constants/apiEndpoints';
import { getMarketplaceLoansRequestQuery } from 'src/helpers/marketplaceHelpers';
import * as Shapes from 'src/interfaces/marketplaceReduxShapes';

export const getMarketplaceLoansHelper = (
  reqParams: Shapes.GetMarketplaceLoansParams
): Promise<AxiosResponse<{ data: Shapes.GetMarketplaceLoansResponse }>> => {
  return axios.get(endpoints.GET_LOANS(getMarketplaceLoansRequestQuery(reqParams)));
};
