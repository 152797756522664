import React, { useMemo, useState, memo, FC } from 'react';
import cn from 'classnames';
import { ChartHeader, StatementBox } from 'src/components';
import { GroupBy } from 'src/constants/statisticsConstants';
import { TReceivedPaymentsData } from 'src/interfaces';
import StatisticsColumnMappedChart from 'src/components/StatisticsColumnMappedChart';

interface TotalInvestmentVolumeProps {
  data: TReceivedPaymentsData[];
  currency?: string;
  wrapperClass?: string;
}

const ReceivedPayments: FC<TotalInvestmentVolumeProps> = memo(({ data, currency, wrapperClass }): JSX.Element => {
  const [type, setType] = useState(GroupBy.DAY);

  const chartData = useMemo(
    () =>
      data?.reduce(
        (acc, item) => ({
          actual_interest_payment: {
            ...acc?.actual_interest_payment,
            [item.actual_payment_date]: item.actual_interest_payment
          },
          actual_principal_payment: {
            ...acc?.actual_principal_payment,
            [item.actual_payment_date]: item.actual_principal_payment
          }
        }),
        { actual_interest_payment: {}, actual_principal_payment: {} }
      ),
    [data]
  );

  return (
    <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
      <ChartHeader title="Received Payments" type={type} onTypeChange={setType} />

      <StatisticsColumnMappedChart
        type={type}
        currency={currency}
        data={chartData}
        yAxisType="linear"
        columnStackingType="normal"
      />
    </StatementBox>
  );
});

ReceivedPayments.displayName = 'ReceivedPayments';

export default ReceivedPayments;
