import React, { memo } from 'react';
import NumberFormat from 'react-number-format';
import { FormikFormatField as FormatField } from 'src/components';
import cn from 'classnames';

import { FieldNames, SelectOption } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';
import styles from '../../TypeBlock.module.scss';
import { onlyDecimalDigitsRegex } from 'src/helpers';

type MaxBidSizeProps = {
  isViewMode: boolean;
  isEditMode: boolean;
  currencyValue: SelectOption;
  maxBixSizeValue: string;
};

const MAX_BID_SIZE_VALUE = 100 * 1000 * 1000;

const MaxBidSizeBlock = memo<MaxBidSizeProps>(({ isViewMode, isEditMode, currencyValue, maxBixSizeValue }) => {
  const isNewMode = !isViewMode && !isEditMode;

  return (
    <div className={styles.amountBlock}>
      <label
        htmlFor={FieldNames.MAX_BID_SIZE}
        className={cn(styles.fieldLabelClass, { [styles.viewLabel]: isViewMode })}
      >
        Maximum Bid Size
      </label>

      <div className={styles.maxBidSizeInner}>
        <span className={cn(styles.allocationViewCurrency, { [styles.maxBidGreySizeCurrency]: isNewMode })}>
          {currencyValue.value}
        </span>

        {isViewMode ? (
          <NumberFormat
            displayType="text"
            value={maxBixSizeValue}
            thousandSeparator
            type="text"
            renderText={(value) => (
              <div className={styles.allocationViewInfo}>
                <span className={styles.allocationViewValue}>
                  {value?.replace(onlyDecimalDigitsRegex, '') ? value : 'No Limit'}
                </span>
              </div>
            )}
          />
        ) : (
          <FormatField
            id={FieldNames.MAX_BID_SIZE}
            name={FieldNames.MAX_BID_SIZE}
            placeholder="Amount for e.g. 10,000"
            thousandSeparator
            isNumericString
            allowNegative={false}
            autoComplete="off"
            maxNumericValue={MAX_BID_SIZE_VALUE}
            classes={{
              inputClassName: styles.inputClass,
              wrapperClassName: styles.amountWrapperClass,
              errorClass: styles.errorClass
            }}
          />
        )}
      </div>
    </div>
  );
});

MaxBidSizeBlock.displayName = 'MaxBidSizeBlock';

export default MaxBidSizeBlock;
