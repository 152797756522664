import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { formatInTimeZone } from 'date-fns-tz';
import { Currencies, selectCurrencyOptions as currenciesOptions, LoanTypes } from 'src/constants/globalConstants';
import { AllocationDetailsShape, AllocationParams, AllocationPayload, InvestmentCriterias } from 'src/interfaces';
import apiEndpoints from 'src/constants/apiEndpoints';
import { onlyDecimalDigitsRegex } from 'src/helpers/regexHelpers';
import {
  LOAN_TYPE_OPTIONS,
  DEFAULT_CRITERIA_PERCENTAGE,
  PLATFORMS_LOGO_MAP,
  PLATFORMS_LABEL_MAP,
  DEFAULT_RANGE_FILTERS,
  DEFAULT_INVESTMENT_PERCENTAGE,
  REINVESTMENT_STRATEGY_OPTIONS,
  REINVESTMENT_STRATEGY_DEFAULT_OPTION
} from './constants';
import {
  TypeBlockValues,
  TypeBlockErrors,
  AllocationTypeData,
  RangeFilters,
  CriteriaNames,
  PortfolioRelativeValue as PortfolioRelative,
  PortfolioGrade,
  Constraint,
  PortfolioLoanType,
  CriteriaFilters,
  ExtendedCriteriaFilters,
  Platform,
  CriteriaNamesServer,
  CRITERIA_SERVER_TO_VIEW_KEYS,
  ServerAllocationPayload,
  ServerToViewAllocation
} from './model';

export const getAllocationTitle = (isView: boolean, isEdit: boolean): string => {
  if (isView) return 'View Allocation';
  if (isEdit) return 'Edit Allocation';
  return 'New Allocation';
};

const MAX_ALLOCATION_AMOUNT = 999999999;

export const validationSchema = Yup.object({
  name: Yup.string()
    .required('Name field should be 1-120 chars long')
    .min(1, 'Name field should be 1-120 chars long')
    .max(120, 'Name field should be 1-120 chars long'),
  amount: Yup.string()
    .required(`Loan amount can't be zero`)
    .test('amount', `Loan amount can't be zero`, (value) => !!+value?.replace(onlyDecimalDigitsRegex, ''))
    .test(
      'amount',
      `Loan amount can't be more than 999,999,999`,
      (value) => +value?.replace(onlyDecimalDigitsRegex, '') < MAX_ALLOCATION_AMOUNT
    ),
  maxBidSize: Yup.string()
    .notRequired()
    .test('maxBidSize', `This value can not be 0`, (value) => +value !== 0)
});

const checkAllocationName = async (name: string): Promise<boolean> => {
  try {
    const { data } = await axios.post(apiEndpoints.CHECK_NAME, {
      name
    });
    return data?.exists;
  } catch (e) {
    console.error('checkAllocationName error', e);
  }
};

const CHECK_NAMES_TIMEOUT = 1000;
const debouncedCheckName = debounce((name) => checkAllocationName(name), CHECK_NAMES_TIMEOUT);
const forbiddenCharactersRegex = /["'`/\\|?*&@#$%^()]/g;
const allowedNameCharacters = /[a-zA-Z0-9 ]/g;

export const validateTypeBlock = async ({ name }: TypeBlockValues, prevName: string): Promise<TypeBlockErrors> => {
  const errors: TypeBlockErrors = {};

  if (!name.trim()) {
    Object.assign(errors, { name: 'Name field should be 1-120 chars long' });
    return errors;
  }

  if (name?.replace(allowedNameCharacters, '')) {
    Object.assign(errors, { name: 'Please, use only Latin characters or numeric symbols' });
    return errors;
  }

  if (name?.match(forbiddenCharactersRegex)) {
    Object.assign(errors, { name: 'In portfolio name field, there should be no special symbols' });
    return errors;
  }

  if (name && prevName !== name) {
    debouncedCheckName?.cancel();

    const alreadyExists = await debouncedCheckName(name.trim());
    if (alreadyExists) Object.assign(errors, { name: 'Allocation with given name already exists' });
  }

  return errors;
};

export const mapAllocationTypeView = (allocation: ServerToViewAllocation): AllocationTypeData => {
  return {
    name: allocation?.name,
    currency: allocation?.currency ?? currenciesOptions[1],
    amount: allocation?.amount?.toString(),
    loanType: allocation?.loanType ?? null,
    maxBidSize: allocation?.maxBidSize?.toString(),
    reinvestmentStrategy: allocation?.reinvestmentStrategy ?? null,
    structureEndDate: allocation?.structureEndDate ?? null
  };
};

export const getInitialTypeValues = (allocationTypeData: AllocationTypeData): TypeBlockValues => ({
  name: allocationTypeData?.name ?? '',
  currency: allocationTypeData?.currency,
  amount: allocationTypeData?.amount ?? '',
  loanType: allocationTypeData.loanType ?? null,
  maxBidSize: allocationTypeData?.maxBidSize ?? '',
  reinvestmentStrategy: allocationTypeData?.reinvestmentStrategy ?? REINVESTMENT_STRATEGY_DEFAULT_OPTION,
  structureEndDate: allocationTypeData?.structureEndDate ?? null
});

export const rangeFiltersToView = (allocation: AllocationDetailsShape): RangeFilters => ({
  borrowerRate: {
    min: allocation.custom_allocation_params.interest_rate_min,
    max: allocation.custom_allocation_params.interest_rate_max
  },
  loanAmount: {
    min: allocation.custom_allocation_params.loan_amount_min,
    max: allocation.custom_allocation_params.loan_amount_max
  },
  term: {
    min: allocation.custom_allocation_params.term_min,
    max: allocation.custom_allocation_params.term_max
  }
});

export const getRelatedCriteriaItem = (
  criteriaName: string,
  criteriaList: Constraint[] | PortfolioLoanType[] | PortfolioRelative[] | PortfolioGrade[],
  id: string | number,
  percentage: number
): Constraint[] | PortfolioLoanType[] | PortfolioRelative[] | PortfolioGrade[] => {
  switch (criteriaName) {
    case CriteriaNames.RELATIVE_VALUE:
    case CriteriaNames.GRADE:
      return criteriaList.map((item) => (item?.name === id ? { ...item, percentage } : item)) as PortfolioGrade[];

    case CriteriaNames.SME_PURPOSES:
    case CriteriaNames.LOAN_PURPOSES:
    case CriteriaNames.SME_SECTORS:
      return criteriaList.map((item) => (item.id === id ? { ...item, percentage } : item)) as PortfolioLoanType[];

    case CriteriaNames.LOAN_SIZE:
      return criteriaList.map((item) => (item.value === id ? { ...item, percentage } : item)) as Constraint[];

    default:
      return criteriaList;
  }
};

export const getExtendedCriteriaFilters = (criteriaFilters: CriteriaFilters): ExtendedCriteriaFilters => {
  return Object.keys({ ...criteriaFilters }).reduce(
    (acc, key) => ({
      ...acc,
      [key]: criteriaFilters[key].map((item) => ({ ...item, percentage: DEFAULT_CRITERIA_PERCENTAGE }))
    }),
    {} as ExtendedCriteriaFilters
  );
};

const percentage100 = 100;

export const getAllocationPayload = (
  { name, amount, currency, loanType, maxBidSize, reinvestmentStrategy, structureEndDate }: TypeBlockValues,
  investedPlatforms: Platform[],
  autoApproval: boolean
): AllocationPayload => {
  const isSME = loanType.value === LoanTypes.SME;
  const maxBidSizeAmount = +maxBidSize.replace(onlyDecimalDigitsRegex, '');

  const allocationParams: AllocationParams = {
    loan_type: loanType.value,
    max_bid_amount: maxBidSizeAmount > 0 ? maxBidSizeAmount : null,
    reinvestment_strategy: reinvestmentStrategy.value,
    structure_end_date: structureEndDate ? structureEndDate.toISOString() : null,
    platforms: investedPlatforms.map(
      ({
        id: platform,
        percentage,
        general,
        investmentPercentage,
        criteriaSelected,
        grades,
        constraints,
        relative,
        smePurposes,
        sectors,
        loanTypes
      }) => {
        const investmentCriterias = {} as InvestmentCriterias;

        if (criteriaSelected?.includes(CriteriaNames.GENERAL)) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            general: {
              loan_amount_min: general?.loanAmount?.min,
              loan_amount_max: general?.loanAmount?.max,
              interest_rate_min: general?.borrowerRate?.min / percentage100,
              interest_rate_max: general?.borrowerRate?.max / percentage100,
              term_min: general?.term?.min,
              term_max: general?.term?.max,
              investment_per_loan: investmentPercentage / percentage100
            }
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.GRADE)) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            grades: grades.reduce((acc, { name, percentage }) => ({ ...acc, [name]: percentage / percentage100 }), {})
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.LOAN_SIZE)) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            loan_size_constraints: constraints.reduce(
              (acc, { value, percentage }) => ({ ...acc, [value]: percentage / percentage100 }),
              {}
            )
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.RELATIVE_VALUE)) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            relative_value: relative.reduce(
              (acc, { value, percentage }) => ({ ...acc, [value]: percentage / percentage100 }),
              {}
            )
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.LOAN_PURPOSES) && !isSME) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            purposes: loanTypes.reduce(
              (acc, { value, percentage }) => ({ ...acc, [value]: percentage / percentage100 }),
              {}
            )
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.LOAN_PURPOSES) && isSME) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            purposes: smePurposes.reduce(
              (acc, { value, percentage }) => ({ ...acc, [value]: percentage / percentage100 }),
              {}
            )
          });
        }

        if (criteriaSelected?.includes(CriteriaNames.SME_SECTORS) && isSME) {
          Object.assign(investmentCriterias, {
            ...investmentCriterias,
            sectors: sectors.reduce(
              (acc, { value, percentage }) => ({ ...acc, [value]: percentage / percentage100 }),
              {}
            )
          });
        }

        return {
          platform,
          percentage: percentage / percentage100,
          investment_criterias: investmentCriterias
        };
      }
    )
  };

  const payload: AllocationPayload = {
    name,
    amount: +amount.replace(onlyDecimalDigitsRegex, ''),
    currency: currency.value as Currencies,
    auto_approval: autoApproval,
    include_previous_loans: false,
    allocation_params: allocationParams
  };

  return payload;
};

export const mapServerAllocationToView = ({
  name,
  amount,
  currency,
  auto_approval,
  allocation_params: { loan_type, platforms, ...restAllocationParams },
  status
}: ServerAllocationPayload): ServerToViewAllocation => {
  const investedPlatforms: Partial<Platform>[] = platforms.map(({ platform, percentage, investment_criterias }) => {
    const criteriaSelected = Object.keys(investment_criterias);

    const pickPlatform: Partial<Platform> = {
      id: platform,
      percentage: percentage * percentage100,
      logo: PLATFORMS_LOGO_MAP[platform],
      label: PLATFORMS_LABEL_MAP[platform],
      amount: amount * percentage,
      criteriaSelected: criteriaSelected.map((key) => CRITERIA_SERVER_TO_VIEW_KEYS[key])
    };

    if (investment_criterias?.general) {
      Object.assign(pickPlatform, {
        ...pickPlatform,
        investmentPercentage: investment_criterias.general.investment_per_loan * percentage100,
        [CriteriaNames.GENERAL]: {
          loanAmount: {
            min: investment_criterias.general.loan_amount_min,
            max: investment_criterias.general.loan_amount_max
          },
          term: {
            min: investment_criterias.general.term_min,
            max: investment_criterias.general.term_max
          },
          borrowerRate: {
            min: investment_criterias.general.interest_rate_min * percentage100,
            max: investment_criterias.general.interest_rate_max * percentage100
          }
        } as RangeFilters
      });
    }

    const criteriasFilters = criteriaSelected?.reduce((acc, blockKey) => {
      if (blockKey === CriteriaNamesServer.GENERAL) return acc;

      if (investment_criterias?.[blockKey]) {
        return {
          ...acc,
          [CRITERIA_SERVER_TO_VIEW_KEYS[blockKey]]: Object.keys(investment_criterias?.[blockKey]).map((criteriaKey) => {
            const percentage = investment_criterias?.[blockKey]?.[criteriaKey] * percentage100;

            switch (blockKey) {
              case CriteriaNamesServer.GRADE:
                return { name: criteriaKey, percentage };

              case CriteriaNamesServer.LOAN_SIZE:
              case CriteriaNamesServer.LOAN_PURPOSES:
              case CriteriaNamesServer.RELATIVE_VALUE:
              case CriteriaNamesServer.SME_SECTORS:
                return { value: criteriaKey, percentage };

              default:
                return { value: criteriaKey, percentage };
            }
          })
        };
      }

      return acc;
    }, {});

    return { ...pickPlatform, ...criteriasFilters };
  });

  return {
    name,
    amount: amount.toString(),
    currency: { label: currency, value: currency },
    loanType: LOAN_TYPE_OPTIONS.find(({ value }) => value === loan_type),
    autoApproval: auto_approval,
    maxBidSize: restAllocationParams?.max_bid_amount ? restAllocationParams.max_bid_amount?.toString() : '',
    reinvestmentStrategy: restAllocationParams?.reinvestment_strategy
      ? REINVESTMENT_STRATEGY_OPTIONS.find(({ value }) => value === restAllocationParams?.reinvestment_strategy)
      : REINVESTMENT_STRATEGY_DEFAULT_OPTION,
    structureEndDate: restAllocationParams?.structure_end_date
      ? new Date(restAllocationParams?.structure_end_date)
      : null,
    investedPlatforms,
    status
  } as ServerToViewAllocation;
};

export const getPlatformDetailsByCriteriaFilters = (
  allocationDetails: ServerToViewAllocation,
  criteriaFilters: CriteriaFilters
): Platform[] => {
  const isSME = allocationDetails.loanType.value === LoanTypes.SME;
  const { grades, relative, constraints, sectors, smePurposes, loanTypes } = criteriaFilters;

  return allocationDetails.investedPlatforms.map(({ criteriaSelected, ...platform }) => {
    const criterias = {};

    if (criteriaSelected.includes(CriteriaNames.GRADE)) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.GRADE]: platform.grades.map((grade) => ({
          ...grade,
          ...grades.find(({ name }) => name === grade.name)
        }))
      });
    }

    if (criteriaSelected.includes(CriteriaNames.LOAN_PURPOSES) && !isSME) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.LOAN_PURPOSES]: platform.loanTypes.map((purpose) => ({
          ...purpose,
          ...loanTypes.find(({ value }) => value === purpose.value)
        }))
      });
    }

    if (criteriaSelected.includes(CriteriaNames.LOAN_PURPOSES) && isSME) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.SME_PURPOSES]: platform.loanTypes.map((purpose) => ({
          ...purpose,
          ...smePurposes.find(({ value }) => value === purpose.value)
        }))
      });
    }

    if (criteriaSelected.includes(CriteriaNames.SME_SECTORS) && isSME) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.SME_SECTORS]: platform.sectors.map((sector) => ({
          ...sector,
          ...sectors.find(({ value }) => value === +sector.value)
        }))
      });
    }

    if (criteriaSelected.includes(CriteriaNames.LOAN_SIZE)) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.LOAN_SIZE]: platform.constraints.map((constraint) => ({
          ...constraint,
          ...constraints.find(({ value }) => +value === +constraint.value)
        }))
      });
    }

    if (criteriaSelected.includes(CriteriaNames.RELATIVE_VALUE)) {
      Object.assign(criterias, {
        ...criterias,
        [CriteriaNames.RELATIVE_VALUE]: platform.relative.map((rel) => ({
          ...rel,
          ...relative.find(({ value }) => value === rel.value)
        }))
      });
    }

    return {
      ...platform,
      criteriaSelected,
      ...criterias
    };
  }) as Platform[];
};

export const getGeneralCriteriaHasErrors = (platforms: Platform[]): boolean => {
  const platformErrors: boolean[] = platforms.map(({ criteriaSelected, ...platform }) => {
    if (!criteriaSelected.includes(CriteriaNames.GENERAL)) return;

    if (!platform?.investmentPercentage) return true;

    const general = platform.general;

    const errors = Object.keys(general).reduce((acc, key) => {
      const hasError = general[key].min > general[key].max || (!general[key].min && !general[key].max);
      return { ...acc, [key]: hasError };
    }, {});

    return some(errors, (v) => !!v);
  });

  return platformErrors.some((e) => !!e);
};

export const setExtendedCriteriaToPlatform = (
  platform: Platform,
  criteriaSelected: string[],
  criteriaExtended: ExtendedCriteriaFilters,
  platformCurrency: Currencies
): Platform => {
  const updated = { ...platform, criteriaSelected };

  const hasGeneralCriteriaSelected = criteriaSelected.includes(CriteriaNames.GENERAL);
  const hasNoGeneralFieldInPlatform = !Object.keys(platform).some((key) => key === CriteriaNames.GENERAL);

  if (hasGeneralCriteriaSelected && hasNoGeneralFieldInPlatform) {
    Object.assign(updated, {
      ...updated,
      general: DEFAULT_RANGE_FILTERS,
      investmentPercentage: DEFAULT_INVESTMENT_PERCENTAGE
    });
  }

  Object.keys(criteriaExtended).map((key) => {
    if (isEmpty(updated[key])) {
      Object.assign(updated, {
        ...updated,
        [key]:
          key === CriteriaNames.LOAN_SIZE
            ? criteriaExtended[key].filter(
                (constraint) => constraint.currency === platform.currency || constraint.currency === platformCurrency
              )
            : criteriaExtended[key]
      });
    }
  });

  return updated;
};
