import React, { useCallback } from 'react';

type Props = {
  should: boolean;
  children: React.ReactElement | (() => React.ReactElement);
};

const ShouldRender = ({ should, children }: Props): JSX.Element => {
  const renderChild = useCallback(() => {
    return typeof children === 'function' ? children() : children;
  }, [children]);

  return should ? renderChild() : null;
};

export default ShouldRender;
