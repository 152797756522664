import React from 'react';
import cn from 'classnames';
import { Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import { ShouldRender } from '../ShouldRender';
import { FormatFieldProps } from './model';
import styles from './FormikCustomField.module.scss';
import { onlyDecimalDigitsRegex } from 'src/helpers';

const FormikFormatField = ({
  label,
  placeholder,
  inputType,
  readOnly,
  format = null,
  mask = '',
  prefix = '',
  maxLength = null,
  allowLeadingZeros = false,
  allowEmptyFormatting,
  thousandSeparator,
  allowNegative = false,
  onClearRequestError,
  classes,
  disabled = false,
  decimalScale = 2,
  isNumericString = false,
  autoComplete,
  isAllowed = () => true,
  maxNumericValue,
  hideErrorState,
  ...props
}: FormatFieldProps): JSX.Element => (
  <div className={cn(styles.fieldWrapper, { [classes?.wrapperClassName]: classes?.wrapperClassName })}>
    <ShouldRender should={!!label}>
      <label
        htmlFor={props.id ?? props.name}
        className={cn(styles.label, { [classes?.labelClassName]: classes?.labelClassName })}
      >
        {label}
      </label>
    </ShouldRender>

    <Field {...props}>
      {({ field: { onBlur, onChange, value, name }, meta }) => (
        <NumberFormat
          id={props.id}
          name={name}
          isNumericString={isNumericString}
          value={value}
          disabled={disabled}
          decimalScale={decimalScale}
          defaultValue={value}
          onChange={(e) => {
            const numericValue = +(e.target.value as string).replace(onlyDecimalDigitsRegex, '');

            if (maxNumericValue && numericValue > maxNumericValue) return;

            onChange(e);
          }}
          onBlur={onBlur}
          type={inputType}
          displayType="input"
          autoComplete={autoComplete}
          placeholder={placeholder}
          thousandSeparator={thousandSeparator}
          allowNegative={allowNegative}
          readOnly={readOnly}
          maxLength={maxLength}
          prefix={prefix}
          isAllowed={isAllowed}
          mask={mask}
          format={format}
          allowLeadingZeros={allowLeadingZeros}
          allowEmptyFormatting={allowEmptyFormatting}
          onClick={typeof onClearRequestError === 'function' ? onClearRequestError : null}
          className={cn(styles.input, {
            [classes?.inputClassName]: classes?.inputClassName,
            [styles.invalidInputData]: meta.touched && meta.error && !hideErrorState
          })}
          onValueChange={(values) => {
            if (isNumericString) {
              onChange({
                target: {
                  name: 'floatValue',
                  value: values.floatValue
                }
              });
            }
          }}
        />
      )}
    </Field>

    {!hideErrorState && (
      <ErrorMessage
        name={props.id ?? props.name}
        render={(msg) => (
          <div className={cn(styles.errorMessage, { [classes?.errorClass]: classes?.errorClass })}>{msg}</div>
        )}
      />
    )}
  </div>
);
export default FormikFormatField;
