import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CheckboxBlock, BlackBoxButton, StatementBox, ModalWithAside } from 'src/components';
import { CriteriaOption } from '../../../../model';
import styles from './CriteriaSelection.module.scss';

type Props = {
  isViewPage: boolean;
  criteriaOptions: CriteriaOption[];
  criteriaSelected: string[];
  onApply: (arr: string[]) => void;
  open: boolean;
  onClose: () => void;
};

const CriteriaSelection = ({
  isViewPage,
  criteriaOptions,
  criteriaSelected = [],
  onApply,
  open,
  onClose
}: Props): JSX.Element => {
  const [criteriaData, setCriteriaData] = useState([]);

  const onChange = (_, id, isChecked: boolean) => {
    setCriteriaData((prev) => prev.map((val) => (val.id === id ? { ...val, isChecked: !isChecked } : val)));
  };

  const handleApply = () => {
    onApply(criteriaData.filter((val) => val.isChecked).map((val) => val.id));
    onClose();
  };

  useEffect(() => {
    setCriteriaData(
      criteriaOptions.map((val) => ({
        ...val,
        isChecked: criteriaSelected.includes(val.id)
      }))
    );
  }, [criteriaOptions, criteriaSelected]);

  return (
    <React.Fragment>
      <Fade in={open} unmountOnExit>
        <div>
          <ClickAwayListener onClickAway={onClose}>
            <div>
              <StatementBox className={styles.criteriasWrapper}>
                <CheckboxBlock
                  list={criteriaData}
                  blockLabel="Filters"
                  onChange={isViewPage ? null : onChange}
                  className={cn(styles.criteriasCheckBoxWrapper, { [styles.checkboxCursorDefault]: isViewPage })}
                  checkboxRootClass={cn({ [styles.checkboxCursorDefault]: isViewPage })}
                  labelRootClass={cn({ [styles.checkboxCursorDefault]: isViewPage })}
                  blockId="#"
                  isAllChecked={false}
                  isDisabled={false}
                  onSelectAll={() => false}
                  displaySelectAll={false}
                />

                <BlackBoxButton
                  btnClass={styles.activeTypeButton}
                  onClick={isViewPage ? onClose : handleApply}
                  label={isViewPage ? 'Close' : 'Apply'}
                />
              </StatementBox>
            </div>
          </ClickAwayListener>
        </div>
      </Fade>
      <ModalWithAside isOpen={open} onToggle={onClose} />
    </React.Fragment>
  );
};

export default CriteriaSelection;
