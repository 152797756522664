import React, { useMemo, memo, FC } from 'react';
import cn from 'classnames';
import { statisticInvestmentSerializer } from 'src/helpers/statisticsHelpers';
import { ChartHeader, StatisticsColumnChart, StatementBox } from 'src/components';

interface InterestRateDistributionProps {
  data: Record<string, number>;
  currency?: string;
  wrapperClass?: string;
}

const InterestRateDistribution: FC<InterestRateDistributionProps> = memo(
  ({ data, currency, wrapperClass }): JSX.Element => {
    const chartData = useMemo(() => {
      const totalInvestment: number = Object.values(data).reduce((a, b) => a + b, 0);

      const groups = Object.entries(data).reduce((acc, [key, value]) => {
        if (!acc[key]) acc[key] = 0;
        if (acc[key] < value) acc[key] = value;

        return acc;
      }, {});

      return statisticInvestmentSerializer(groups, totalInvestment);
    }, [data]);

    return (
      <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
        <ChartHeader title="Interest Rate Distribution" />
        <StatisticsColumnChart
          data={chartData}
          type={undefined}
          xLabelType="direct"
          yLabelType="money"
          currency={currency}
        />
      </StatementBox>
    );
  }
);

InterestRateDistribution.displayName = 'InterestRateDistribution';

export default InterestRateDistribution;
