import React from 'react';
import cn from 'classnames';
import truncate from 'lodash/truncate';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles, createStyles } from '@mui/styles';
import CustomizedCheckbox from 'src/atoms/CustomizedCheckbox/CustomizedCheckbox';

const useStyles = makeStyles(() =>
  createStyles({
    formControlRoot: {
      textAlign: 'left',
      alignItems: 'center',
      width: '100%',
      marginLeft: 0,
      marginRight: 0
    },

    label: {
      fontFamily: 'Verlag, sans-serif',
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#111a3f',
      whiteSpace: 'nowrap'
    }
  })
);

interface CheckboxProps {
  onChange: (e: React.ChangeEvent) => void;
  onBlur?: (e) => void;
  isChecked: boolean;
  isDisabled?: boolean;
  title: string | JSX.Element;
  name?: string;
  size?: 'small' | 'medium';
  id?: string;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  uncheckedIconProps?: {
    uncheckedBoxClass?: string;
  };
  checkedIconProps?: {
    checkedBoxClass?: string;
    checkedIconClass?: string;
  };
  classes?: {
    labelTextClass?: string;
    labelRootClass?: string;
    checkboxRootClass?: string;
    checkedIcon?: string;
    colorPrimary?: string;
    colorSecondary?: string;
  };
  withTruncate?: boolean;
  truncateWidth?: number;
}

const DEFAULT_TRUNCATE_WIDTH = 100;

const CheckboxWithLabel = ({
  onChange,
  onBlur,
  isChecked,
  isDisabled,
  title,
  name,
  classes,
  size = 'small',
  id = name,
  icon,
  checkedIcon,
  uncheckedIconProps,
  checkedIconProps,
  withTruncate,
  truncateWidth
}: CheckboxProps): JSX.Element => {
  const { formControlRoot, label } = useStyles();

  const currentLabel =
    withTruncate && typeof title === 'string'
      ? truncate(title, { length: truncateWidth ?? DEFAULT_TRUNCATE_WIDTH }).trim()
      : title;

  return (
    <FormControlLabel
      disabled={isDisabled}
      control={
        <CustomizedCheckbox
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={isDisabled}
          icon={icon}
          uncheckedIconProps={uncheckedIconProps}
          checkedIcon={checkedIcon}
          checkedIconProps={checkedIconProps}
          isChecked={isChecked}
          name={name}
          id={id}
          size={size}
          classes={{
            checkboxRootClass: classes?.checkboxRootClass,
            checkedIcon: classes?.checkedIcon,
            colorPrimary: classes?.colorPrimary,
            colorSecondary: classes?.colorSecondary
          }}
        />
      }
      label={currentLabel}
      classes={{
        root: cn(formControlRoot, { [classes?.labelRootClass]: classes?.labelRootClass }),
        label: cn(label, { [classes?.labelTextClass]: classes?.labelTextClass })
      }}
    />
  );
};

export default CheckboxWithLabel;
