import { authActionTypes, baseTypes, marketplaceTypes } from '../types';
import { BaseReducer } from 'src/interfaces/baseReduxShapes';

const initialLoanSummary = {
  borrowerRate: {
    min: 0,
    max: 0
  },
  term: {
    min: 0,
    max: 0
  },
  loanAmount: {
    min: 0,
    max: 0
  }
};

const initialLoanFilters = {
  countries: [],
  loanTypes: [],
  sectors: [],
  platforms: [],
  smePurposes: [],
  grades: []
};

const initialState: BaseReducer = {
  preselectedAllocationId: null,
  loanPurposes: [],
  loansSummary: initialLoanSummary,
  loansFilters: initialLoanFilters,
  currencies: [],
  regions: [],
  isFiltersLoading: false,
  isSummaryLoading: false,
  isAsideMenuOpen: true
};

export default (state = initialState, { type, payload }: { type: string; payload }): BaseReducer => {
  switch (type) {
    case marketplaceTypes.UPDATE_CURRENCY:
      return { ...state, isFiltersLoading: true, isSummaryLoading: true };

    case baseTypes.PUT_LOANS_SUMMARY:
      return {
        ...state,
        loansSummary: payload,
        isSummaryLoading: false
      };

    case baseTypes.PUT_ALL_LOAN_FILTERS:
      return { ...state, loansFilters: { ...payload }, isFiltersLoading: false };

    case baseTypes.PUT_CURRENCIES:
      return { ...state, currencies: payload };

    case baseTypes.PUT_REGIONS:
      return { ...state, regions: payload };

    case baseTypes.PUT_GRADES:
      return { ...state, loansFilters: { ...state.loansFilters, grades: payload } };

    case baseTypes.PUT_CONSUMER_PURPOSES:
      return { ...state, loansFilters: { ...state.loansFilters, loanTypes: payload } };

    case baseTypes.PUT_SME_PURPOSES:
      return { ...state, loansFilters: { ...state.loansFilters, smePurposes: payload } };

    case baseTypes.PUT_SME_SECTORS:
      return { ...state, loansFilters: { ...state.loansFilters, sectors: payload } };

    case baseTypes.PUT_PLATFORMS:
      return { ...state, loansFilters: { ...state.loansFilters, platforms: payload } };

    case baseTypes.HANDLE_CHANGE_ASIDE_MENU:
      return { ...state, isAsideMenuOpen: !state.isAsideMenuOpen };

    case baseTypes.SET_PRESELECTED_ALLOCATION_ID:
      return { ...state, preselectedAllocationId: payload.id };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
