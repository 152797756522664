import React, { FC } from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import { ReactSVG } from 'react-svg';
import defaultSelectStyles, { CustomStylesProps } from './CustomizedSelectStyles';
import arrow from 'src/assets/images/icons/currency_arrow.svg';
import styles from './CustomizedSelect.module.scss';

type TDropdownIndicatorProps = {
  className?: string;
};

export const DropdownIndicator: FC<TDropdownIndicatorProps> = ({ className }): JSX.Element => (
  <ReactSVG src={arrow} className={cn(styles.arrow, { [className]: className })} />
);

export interface CustomizedSelectProps {
  value: { value; label: string } | null;
  options: Array<{ value; label: string }>;
  onChange: (value) => void;
  onBlur?: () => void;
  name?: string;
  placeholder?: string;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  customStyles?: CustomStylesProps;
  closeMenuOnSelect?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  CustomDropdownIndicator?: () => JSX.Element;
  CustomOption?: (props) => JSX.Element;
}

const DEFAULT_MENU_HEIGHT = 145;

const CustomizedSelect = ({
  name = null,
  value,
  options,
  onBlur = null,
  onChange,
  placeholder = 'Select',
  closeMenuOnSelect = true,
  isSearchable = true,
  minMenuHeight = DEFAULT_MENU_HEIGHT,
  maxMenuHeight = DEFAULT_MENU_HEIGHT,
  customStyles,
  isDisabled,
  CustomDropdownIndicator = DropdownIndicator as unknown as () => JSX.Element,
  CustomOption
}: CustomizedSelectProps): JSX.Element => (
  <Select
    isDisabled={isDisabled}
    name={name}
    options={options}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    closeMenuOnSelect={closeMenuOnSelect}
    isSearchable={isSearchable}
    minMenuHeight={minMenuHeight}
    maxMenuHeight={maxMenuHeight}
    placeholder={placeholder}
    components={{
      DropdownIndicator: CustomDropdownIndicator,
      Option: CustomOption ?? components.Option
    }}
    styles={defaultSelectStyles(customStyles)}
  />
);

export default CustomizedSelect;
