import { OutstandingBidStatuses, InvestmentStatuses } from 'src/constants/investmentsConstants';
import { PAGES } from 'src/constants/pages';

export enum Statuses {
  CURRENT = 'current',
  LATE = 'late',
  COMPLETED = 'completed',
  DEFAULTED = 'defaulted',
  CANCELED = 'CANCELED'
}

export enum LoanTypeShorts {
  CONSUMER = 'Consumer',
  SME = 'SME'
}

export const investmentsStatuses = [
  {
    name: 'Completed',
    id: InvestmentStatuses.COMPLETED
  },
  {
    name: 'Prepaid',
    id: InvestmentStatuses.PREPAID
  },
  {
    name: 'Current',
    id: InvestmentStatuses.CURRENT
  },
  {
    name: 'Late',
    id: InvestmentStatuses.LATE
  },
  {
    name: 'Defaulted',
    id: InvestmentStatuses.DEFAULTED
  }
];

export enum InvestmentsCheckboxEnum {
  STATUS = 'status',
  RELATIVE_VALUE = 'relative',
  PLATFORMS = 'platforms',
  GRADES = 'grades'
}

export const outstandingBidLabels = {
  [OutstandingBidStatuses.SENT]: 'Bid Sent',
  [OutstandingBidStatuses.EXECUTED]: 'Bid Accepted',
  [OutstandingBidStatuses.REJECTED]: 'Bid Rejected',
  [OutstandingBidStatuses.WAITING_FOR_DISBURSEMENT]: 'Waiting For Disbursement',
  [OutstandingBidStatuses.NOT_FUNDED]: 'Not Funded'
};

export const outstandingBidStatuses = [
  {
    name: outstandingBidLabels[OutstandingBidStatuses.SENT],
    id: OutstandingBidStatuses.SENT
  },
  {
    name: outstandingBidLabels[OutstandingBidStatuses.EXECUTED],
    id: OutstandingBidStatuses.EXECUTED
  },
  {
    name: outstandingBidLabels[OutstandingBidStatuses.REJECTED],
    id: OutstandingBidStatuses.REJECTED
  },
  {
    name: outstandingBidLabels[OutstandingBidStatuses.WAITING_FOR_DISBURSEMENT],
    id: OutstandingBidStatuses.WAITING_FOR_DISBURSEMENT
  },
  {
    name: outstandingBidLabels[OutstandingBidStatuses.NOT_FUNDED],
    id: OutstandingBidStatuses.NOT_FUNDED
  }
];

export const CHECKBOX_LABELS_TRUNCATE_WIDTH = 200;
export const DEFAULT_INVESTMENTS_PAGE = 1;

export const pageTitleMap = {
  [PAGES.INVESTMENTS]: 'Investments',
  [PAGES.OUTSTANDING_BIDS]: 'Outstanding Bids'
};

export const SUCCESSFUL_BID_STATUSES = [
  OutstandingBidStatuses.SENT,
  OutstandingBidStatuses.EXECUTED,
  OutstandingBidStatuses.WAITING_FOR_DISBURSEMENT
];
