import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  FormikCustomField as CustomField,
  ConfirmButton,
  CountriesSelect,
  CheckboxWithLabel,
  AuthTitleBlock,
  ButtonUnderlined as ButtonBack,
  Modal,
  RequestErrorMessage,
  ModalLayout,
  UserAgreementCheckbox
} from 'src/components';
import UserAgreement from './components/UserAgreement';
import { signUpThreeValidationSchema } from '../validationsSchemas';
import { signUpHelper } from 'src/helpers/authHelpers';
import { InputEnums } from 'src/interfaces/RandomShapes';
import {
  ConfirmInformationFormValues as Values,
  SignUpFields as Fields,
  ConfirmInformationStep
} from 'src/interfaces/SignUpShapes';
import { PAGES } from 'src/constants/pages';
import { fieldsArray } from '../constants';
import styles from '../RegisterPage.module.scss';

const RegisterForm: FC<ConfirmInformationStep> = ({ handleNext, formState }): JSX.Element => {
  const { push } = useHistory();

  const [requestError, setRequestError] = useState<{ message?: string } | null>(null);
  const onClearRequestError = () => setRequestError(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const handleToggleModal = () => setModalOpen((prev) => !prev);

  return (
    <React.Fragment>
      <AuthTitleBlock
        title="Sign up with a new account"
        classes={{
          blockClass: styles.titleBlock
        }}
      />

      <Formik
        initialValues={{
          [Fields.COUNTRY_OF_RESIDENCE]: formState.country,
          [Fields.COMPANY_NAME]: formState.companyName,
          [Fields.COMPANY_NUMBER]: formState.companyNumber,
          [Fields.FIRST_NAME]: formState.firstName,
          [Fields.LAST_NAME]: formState.lastName,
          [Fields.EMAIL]: formState.email,
          [Fields.PASSWORD]: formState.password,
          [Fields.CONFIRM_PASSWORD]: formState.confirmPassword,
          [Fields.USER_AGREEMENT]: formState.agreement,
          [Fields.NEWS_OFFERS]: formState.offers
        }}
        validationSchema={signUpThreeValidationSchema}
        onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
          const { companyName, companyNumber, password, country, email, firstName, lastName, agreement, offers } =
            values;

          try {
            await signUpHelper({
              username: email.trim(),
              password: password,
              attributes: {
                email: email.trim(),
                'custom:company_name': companyName.trim(),
                'custom:company_number': companyNumber.trim(),
                'custom:country': country.value,
                'custom:first_name': firstName.trim(),
                'custom:last_name': lastName.trim(),
                'custom:user_agreement': agreement ? '1' : '0',
                'custom:exaloan_offers': offers ? '1' : '0'
              }
            });
            handleNext();
          } catch (error) {
            setRequestError(error);
          }
          setSubmitting(false);
        }}
      >
        {({ errors, values, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => {
          const isSomeValuesFalsy = Object.keys(values).some((key) => {
            if (key === Fields.NEWS_OFFERS) return;
            return !values[key];
          });
          const isSelectError = !isNil(errors[Fields.COUNTRY_OF_RESIDENCE]) && !!touched[Fields.COUNTRY_OF_RESIDENCE];
          const errorMsg = isSelectError ? errors[Fields.COUNTRY_OF_RESIDENCE]?.value : null;

          return (
            <Form className={styles.signUpForm}>
              <CountriesSelect
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                value={values[Fields.COUNTRY_OF_RESIDENCE]}
                name={Fields.COUNTRY_OF_RESIDENCE}
                id={Fields.COUNTRY_OF_RESIDENCE}
                placeholder="Country of Residence"
                customClass={styles.confirmInfoSelectInput}
                isError={isSelectError}
                errorMsg={errorMsg}
              />

              {fieldsArray.map(({ name, type, placeholder }) => (
                <CustomField
                  key={name}
                  name={name}
                  id={name}
                  type={type}
                  placeholder={placeholder}
                  classes={{ wrapperClassName: styles.confirmInfoField }}
                  isPassword={type === InputEnums.PASSWORD}
                  isError={requestError && name === Fields.EMAIL}
                  onClearRequestError={onClearRequestError}
                />
              ))}

              <UserAgreementCheckbox
                onLinkClick={handleToggleModal}
                label="I confirm that I have read and agree to the "
                checkboxProps={{
                  isChecked: values.agreement,
                  name: Fields.USER_AGREEMENT,
                  id: Fields.USER_AGREEMENT,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
              />

              <Modal isOpen={isModalOpen} onToggle={handleToggleModal}>
                <ModalLayout>
                  <UserAgreement
                    onAccept={() => {
                      setFieldValue(Fields.USER_AGREEMENT, true);
                      handleToggleModal();
                    }}
                    onCancel={() => {
                      setFieldValue(Fields.USER_AGREEMENT, false);
                      handleToggleModal();
                    }}
                  />
                </ModalLayout>
              </Modal>

              <CheckboxWithLabel
                isChecked={values.offers}
                name={Fields.NEWS_OFFERS}
                id={Fields.NEWS_OFFERS}
                title="I confirm to receive Exaloan news and offers."
                onChange={handleChange}
                onBlur={handleBlur}
                classes={{ labelRootClass: styles.offersRoot }}
              />

              {requestError && (
                <RequestErrorMessage
                  customClass={styles.requestErrorMsg}
                  msg={requestError?.message ?? 'Something went wrong... Please try again.'}
                />
              )}

              <ConfirmButton
                type="submit"
                isDisabled={isSomeValuesFalsy || !isEmpty(errors)}
                btnClass={styles.submitBtn}
              />
              <ButtonBack onClick={() => push(PAGES.SIGN_IN)} label="Back to sign in" />
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default RegisterForm;
