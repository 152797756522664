import React from 'react';
import MainLayout from 'src/components/MainLayout/MainLayout';
import { OpportunitiesContent } from './OpportunitiesContent';

const OpportunitiesPage = (): JSX.Element => {
  return (
    <MainLayout isEmptySkeleton>
      <OpportunitiesContent />
    </MainLayout>
  );
};

export default OpportunitiesPage;
