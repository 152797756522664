import React from 'react';
import cn from 'classnames';
import styles from './ConfirmButton.module.scss';

interface ConfirmButtonProps {
  type: 'button' | 'submit' | 'reset';
  onClick?: (e) => void;
  isDisabled?: boolean;
  children?: React.ReactNode;
  horizontalPadding?: number;
  btnClass?: string;
  style?: React.CSSProperties;
}

const DEFAULT_PADDING = 50;

const ConfirmButton = ({
  onClick,
  horizontalPadding,
  type,
  isDisabled,
  btnClass,
  children = 'Confirm',
  style
}: ConfirmButtonProps): JSX.Element => {
  const padding = horizontalPadding ?? DEFAULT_PADDING;
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={cn(styles.btn, { [styles.btnDisabled]: isDisabled, [btnClass]: btnClass })}
      onClick={onClick}
      style={{ padding: `0px ${padding}px`, ...style }}
    >
      {children}
    </button>
  );
};

export default ConfirmButton;
