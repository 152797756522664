export const hexToRgba = (hex: string, opacity?: number): string => {
  const parsedHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const { r, g, b } = {
    r: parseInt(parsedHex[1], 16),
    g: parseInt(parsedHex[2], 16),
    b: parseInt(parsedHex[3], 16)
  };

  return opacity ? `rgba(${r}, ${g}, ${b}, ${opacity})` : `rgba(${r}, ${g}, ${b})`;
};
