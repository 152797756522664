import omit from 'lodash/omit';
import { IEntityDetails, InvestmentData } from './model';

export const investmentDataHandler = (data: InvestmentData): IEntityDetails => ({
  executed_date: data?.executed_date,
  funded_date: data?.funded_date,
  id: data?.id,
  invested_amount: data?.invested_amount,
  loanId: data?.Loan?.id,
  platform: data?.Loan?.source_platform_name,
  term: data?.Loan?.term_original,
  provider: data?.Loan?.type,
  ...omit(data?.Loan, ['id'])
});

export const outstandingBidDataHandler = (data: InvestmentData): IEntityDetails => ({
  executed_date: data?.executed_date,
  funded_date: data?.funded_date,
  id: data?.id,
  invested_amount: data?.invested_amount,
  loanId: data?.Loan?.id,
  platform: data?.Loan?.source_platform_name,
  term: data?.Loan?.term_original,
  provider: data?.Loan?.type,
  bid_statuses: data?.bid_statuses,
  ...omit(data?.Loan, ['id'])
});
