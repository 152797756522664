import { RootReducer } from 'src/interfaces/rootShapes';
import { UserData, AuthReducer } from 'src/interfaces/authReduxShapes';
import { MarketplaceLoansData, GetMarketplaceLoansParams } from 'src/interfaces/marketplaceReduxShapes';
import {
  InvestmentsReducer,
  InvestmentLoansData,
  InvestmentsFiltersShape,
  GetInvestmentsLoansParams,
  OutstandingBidsData
} from 'src/interfaces/investmentsReduxShapes';
import { InvestmentStatistic, LoanTypeShape, IAllocation } from 'src/interfaces';
import * as BaseShapes from 'src/interfaces/baseReduxShapes';
import { PortfolioBuilderReducer, LoanConstraintShape } from 'src/interfaces/portfolioBuilderReduxShapes';
import { TransactionsReducer } from 'src/interfaces/reportsReduxShapes';
import { ICurrency } from 'src/interfaces/RandomShapes';
import { ServerToViewAllocation } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';

export const marketplaceLoansSelector = (store: RootReducer): MarketplaceLoansData => store.marketplace.loansData;
export const marketplaceReqParamsSelector = (store: RootReducer): GetMarketplaceLoansParams =>
  store.marketplace.reqParams;

export const userSelector = (store: RootReducer): UserData => store?.user;
export const authSelector = (store: RootReducer): AuthReducer => store?.auth;

export const investmentRootSelector = (store: RootReducer): InvestmentsReducer => store.investments;
export const investmentLoansSelector = (store: RootReducer): InvestmentLoansData => store.investments.investmentsData;
export const outstandingBidsSelector = (store: RootReducer): OutstandingBidsData =>
  store.investments.outstandingBidsData;

export const investmentCachedParamsSelector = (store: RootReducer): null | GetInvestmentsLoansParams =>
  store.investments.cachedParams;

export const loanTypesSelector = (store: RootReducer): LoanTypeShape[] => store.investments.loansFilters.loanTypes;
export const investmentsFiltersSelector = (store: RootReducer): InvestmentsFiltersShape =>
  store.investments.loansFilters;

export const portfolioAllocationsDataSelector = (store: RootReducer): PortfolioBuilderReducer =>
  store?.portfolioBuilder;
export const allocationDetailsSelector = (store: RootReducer): ServerToViewAllocation =>
  store?.portfolioBuilder?.allocationDetails;

export const portfolioBuilderAllocationSelector = (store: RootReducer): IAllocation[] =>
  store?.portfolioBuilder?.allocations;

export const builderConstraintsSelector = (store: RootReducer): LoanConstraintShape[] =>
  store?.portfolioBuilder?.constraints;

export const baseReducerSelector = (store: RootReducer): BaseShapes.BaseReducer => store.baseData;
export const baseSummarySelector = (store: RootReducer): BaseShapes.SummaryShape => store.baseData.loansSummary;
export const baseFiltersSelector = (store: RootReducer): BaseShapes.FiltersShape => store.baseData.loansFilters;
export const baseCurrenciesSelector = (store: RootReducer): Array<string> => store.baseData.currencies;
export const preselectedAllocationSelector = (store: RootReducer): string => store.baseData.preselectedAllocationId;
export const baseRegionsSelector = (store: RootReducer): Array<BaseShapes.RegionShape> | [] => store.baseData.regions;

export const baseGradesSelector = (store: RootReducer): Array<BaseShapes.GradeShape> =>
  store.baseData.loansFilters.grades;
export const basePlatformsSelector = (store: RootReducer): Array<BaseShapes.PlatformShape> =>
  store.baseData.loansFilters.platforms;

export const statisticsSelector = (store: RootReducer): InvestmentStatistic | null => store?.statistics;

export const apiTokensSelector = (store: RootReducer) => store?.api.tokens;

export const currencySelector = (store: RootReducer): ICurrency => store.marketplace.currency;

export const totalOpportunitiesSelector = (store: RootReducer) => store.investments.totalOpportunities;

export const opportunitiesSelector = (store: RootReducer) => store.investments.opportunities;

export const reportsSelector = (store: RootReducer): TransactionsReducer => store.transactionsData;
