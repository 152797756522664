import { useMemo } from 'react';
import { TNetReturnByMonth, TNetReturnByWeek, TNetReturnByYear } from 'src/interfaces';
import { IGraphData } from 'src/components/StatisticsColumnChart/StatisticsColumnChart';
import { getMonthName } from './helpers';
import { GroupBy } from 'src/constants/statisticsConstants';

type TUseNetReturnChartData = (payload: {
  dataMap: Record<GroupBy.WEEK, Array<TNetReturnByYear | TNetReturnByMonth | TNetReturnByWeek>>;
  type: GroupBy;
}) => IGraphData[];

const MULTIPLY_FACTOR = 100;

export const useNetReturnChartData: TUseNetReturnChartData = ({ dataMap, type }): IGraphData[] => {
  return useMemo(() => {
    const data: Array<TNetReturnByYear | TNetReturnByMonth | TNetReturnByWeek> = dataMap[type];

    const groups = data.reduce((acc, item) => {
      switch (type) {
        case GroupBy.WEEK:
          return { ...acc, [`Week ${(item as TNetReturnByWeek).week} ${item.year}`]: item.net_return };

        case GroupBy.MONTH:
          return { ...acc, [`${getMonthName((item as TNetReturnByMonth).month)} ${item.year}`]: item.net_return };

        case GroupBy.YEAR:
          return { ...acc, [item.year]: item.net_return };

        default:
          return {};
      }
    }, {});

    const resultData: IGraphData[] = Object.keys(groups).map((key) => ({
      name: (groups[key] * MULTIPLY_FACTOR).toFixed(2),
      y: +(groups[key] * MULTIPLY_FACTOR).toFixed(2),
      date: key,
      type: 'column'
    }));

    return resultData;
  }, [dataMap, type]);
};
