import * as Yup from 'yup';
import { SignUpFields as Fields } from 'src/interfaces/SignUpShapes';
import {
  latinUmlautNumbersSymbolRegex,
  onlyLatinAndUmlautWithSpaceRegex,
  latinUmlautSpaceNumberDotRegex
} from 'src/helpers/regexHelpers';

export const signUpThreeValidationSchema = Yup.object({
  [Fields.COUNTRY_OF_RESIDENCE]: Yup.object().shape({
    value: Yup.string().required('Country of residence cannot be blank')
  }),

  [Fields.COMPANY_NAME]: Yup.string()
    .required('Company Name cannot be blank')
    .matches(latinUmlautSpaceNumberDotRegex, 'Please, use only Latin, numbers and dots')
    .trim()
    .min(3, 'Name field should be 3-255 chars long')
    .max(255, 'Name field should be 3-255 chars long'),

  [Fields.COMPANY_NUMBER]: Yup.string()
    .required('Registration number cannot be blank')
    .trim()
    .min(3, 'Name field should be 3-255 chars long')
    .max(255, 'Name field should be 3-255 chars long'),

  [Fields.FIRST_NAME]: Yup.string()
    .required('First Name cannot be blank')
    .matches(onlyLatinAndUmlautWithSpaceRegex, 'Please, use only Latin')
    .trim()
    .min(2, 'Name field should be 2-255 chars long')
    .max(255, 'Name field should be 2-255 chars long'),

  [Fields.LAST_NAME]: Yup.string()
    .required('Last Name cannot be blank')
    .matches(onlyLatinAndUmlautWithSpaceRegex, 'Please, use only Latin')
    .trim()
    .min(2, 'Name field should be 2-255 chars long')
    .max(255, 'Name field should be 2-255 chars long'),

  [Fields.EMAIL]: Yup.string()
    .email('Wrong format. Please, enter the email as “your@email.com”')
    .required('Email cannot be blank'),

  [Fields.PASSWORD]: Yup.string()
    .required('Password can’t be blank')
    .matches(latinUmlautNumbersSymbolRegex, 'Please, use only Latin')
    .min(8, 'Need at least 8 chars long')
    .matches(/[a-z]/, 'Need at least 1 lowercase')
    .matches(/[A-Z]/, 'Need at least 1 uppercase')
    .matches(/[0-9]/, 'Need at least 1 digit')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~].*$/, 'Need at least 1 symbol character.'),
  [Fields.CONFIRM_PASSWORD]: Yup.string()
    .required('Confirm password can’t be blank')
    .oneOf([Yup.ref('password')], 'Passwords mismatch'),

  [Fields.USER_AGREEMENT]: Yup.boolean().required().oneOf([true], 'Please, read User Agreement')
});
