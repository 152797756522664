import React, { FC, useState, memo } from 'react';
import cn from 'classnames';
import { ChartHeader, StatementBox } from 'src/components';
import { GroupBy } from 'src/constants/statisticsConstants';
import { ILoanStatusByVintageData } from 'src/interfaces';
import StatisticsColumnMappedChart from 'src/components/StatisticsColumnMappedChart';

interface LoanStatusByVintageProps {
  data: ILoanStatusByVintageData;
  currency: string;
  wrapperClass?: string;
}

const LoanStatusByVintage: FC<LoanStatusByVintageProps> = memo(({ data, currency, wrapperClass }): JSX.Element => {
  const [type, setType] = useState(GroupBy.DAY);

  return (
    <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
      <ChartHeader title={'Loan Status By Vintage'} type={type} onTypeChange={setType} />
      <StatisticsColumnMappedChart
        data={data}
        type={type}
        currency={currency}
        columnStackingType="percent"
        colors={['#E0391C', '#077186', '#8A9C5A']}
        externalChartOptions={{ plotOptions: { series: { minPointLength: 0 } } as Highcharts.PlotOptions }}
      />
    </StatementBox>
  );
});

LoanStatusByVintage.displayName = 'LoanStatusByVintage';

export default LoanStatusByVintage;
