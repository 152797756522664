import * as Yup from 'yup';
import { latinUmlautNumbersSymbolRegex } from 'src/helpers/regexHelpers';
import { emailPrefixCutter } from 'src/helpers/formHelpers';
import { Values } from './LoginPage';

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Wrong format. Please, enter the email as “your@email.com”')
    .required('Email cannot be blank'),
  password: Yup.string()
    .required('Password can’t be blank')
    .matches(latinUmlautNumbersSymbolRegex, 'Please, use only Latin')
    .min(8, 'Need at least 8 chars long')
    .matches(/[a-z]/, 'Need at least 1 lowercase')
    .matches(/[A-Z]/, 'Need at least 1 uppercase')
    .matches(/[0-9]/, 'Need at least 1 digit')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/, 'Need at least 1 symbol character.')
});

type ValidateErrors = { email?: string; password?: string };

export const validate = (values: Values): ValidateErrors => {
  const errors: { email?: string; password?: string } = {};
  const emailPrefix = emailPrefixCutter(values.email);

  if (emailPrefix && values.password.includes(emailPrefix)) {
    errors.password = 'The password must not contain the part of login.';
  }

  return errors;
};

export const initialValues: Values = {
  email: '',
  password: ''
};

export const defaultReqError = 'One or more fields have an error, please check again.';
