import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from 'src/redux/selectors';
import { signOutUser } from 'src/redux/actions/authActions';
import { signOutUserHelper, clearAuthToken } from 'src/helpers/authHelpers';
import { MainLayout, GreenBoxButton, BlackBoxButton } from 'src/components';
import logo from 'src/assets/images/logo-no-title.png';
import { header } from 'src/assets/locales/en/common.json';
import { PAGES } from 'src/constants/pages';
import styles from './HomePage.module.scss';

const HomePage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const handleSignInAsDifferentUser = async () => {
    await signOutUserHelper();
    clearAuthToken();
    dispatch(signOutUser());
    push(PAGES.SIGN_IN);
  };

  return (
    <MainLayout isPrivate={false}>
      {user ? (
        <div className={styles.signInAsPreviousWrapper}>
          <GreenBoxButton
            onClick={() => push(PAGES.DASHBOARD)}
            btnClass={styles.signInAsPreviousBtn}
          >{`Sign in as ${user?.attributes?.email}`}</GreenBoxButton>

          <button className={styles.signInAsDifferentBtn} type="button" onClick={handleSignInAsDifferentUser}>
            Sign in as a different user?
          </button>
        </div>
      ) : (
        <div className={styles.homeContent}>
          <div className={styles.logoBlock}>
            <div className={styles.logoWrapper}>
              <img src={logo} alt={header.logoAlt} className={cn(styles.logo, 'img-fluid')} />
            </div>
            <h1 className={styles.logoTitle}>{header.logoTitle}</h1>
          </div>
          <h2 className={styles.title}>Welcome To Our Platform</h2>
          <div className={styles.btnBlock}>
            <GreenBoxButton onClick={() => push(PAGES.SIGN_IN)} />
            <BlackBoxButton onClick={() => push(PAGES.SIGN_UP)} />
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default HomePage;
