import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { currencySymbols } from 'src/constants/globalConstants';
import { ICurrency } from 'src/interfaces';
import styles from '../DashboardPage.module.scss';

type Props = {
  currency: ICurrency;
  activeInvestments: number;
  activeLoans: number;
  totalInvestments: number;
  totalLoans: number;
  totalInterest: number;
};

const Benefits: FC<Props> = ({
  currency,
  activeInvestments,
  activeLoans = 0,
  totalInvestments,
  totalLoans = 0,
  totalInterest
}) => {
  return (
    <div className={styles.benefits}>
      <div className={styles.block}>
        <div className={styles.benefits_item}>
          <NumberFormat
            type="text"
            displayType="text"
            thousandSeparator
            prefix={`${currencySymbols[currency.value]} `}
            value={activeInvestments.toFixed(2)}
            className={styles.benefits_item_value}
          />
          <div className={styles.benefits_item_label}>Active Investments</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.benefits_item}>
          <NumberFormat
            type="text"
            displayType="text"
            thousandSeparator
            value={activeLoans}
            className={styles.benefits_item_value}
          />
          <div className={styles.benefits_item_label}>Active Loans</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.benefits_item}>
          <NumberFormat
            type="text"
            displayType="text"
            thousandSeparator
            prefix={`${currencySymbols[currency.value]} `}
            value={totalInvestments.toFixed(2)}
            className={styles.benefits_item_value}
          />
          <div className={styles.benefits_item_label}>Total Investments</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.benefits_item}>
          <NumberFormat
            type="text"
            displayType="text"
            thousandSeparator
            value={totalLoans}
            className={styles.benefits_item_value}
          />
          <div className={styles.benefits_item_label}>Total Loans</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.benefits_item}>
          <div className={styles.benefits_item_value}>
            <NumberFormat
              displayType="text"
              prefix={`${currencySymbols[currency.value]} `}
              value={totalInterest.toFixed(2)}
              thousandSeparator
              type="text"
              className={styles.benefits_item_value}
            />
          </div>
          <div className={styles.benefits_item_label}>Total Interest received</div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
