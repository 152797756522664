import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import isEmpty from 'lodash/isEmpty';
import PuffLoader from 'react-spinners/PuffLoader';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { DEFAULT_REPORTS_REQ_PARAMS } from 'src/constants/reportsConstants';
import { useUserValues } from 'src/hooks';
import { exportFileApi } from 'src/utils/comon';
import {
  addTransaction,
  deleteTransaction,
  getMoreCashflowByPlatform,
  getMoreTransactionsByPlatform,
  getTransactions,
  getTransactionsAndReports
} from 'src/redux/actions/reportsActions';
import {
  IClientReport,
  ReportsRequestParams,
  ReportsLoadMoreRequestParams,
  CashflowTransactionsGroup,
  IClientTransaction,
  TAddTransactionBody,
  IServerTransaction
} from 'src/interfaces/reportsReduxShapes';
import { currencySelector, baseFiltersSelector, reportsSelector, baseReducerSelector } from 'src/redux/selectors';
import {
  ContentHeadingBox,
  DatePickerBlock,
  ExportAllBlock,
  MainLayout,
  Filter,
  AddManualTransactionIcon
} from 'src/components';
import {
  CHECKBOX_LABELS_TRUNCATE_WIDTH,
  GET_REPORTS_DEFAULT_PARAMS,
  LOADER_SIZE,
  REQUIRED_REQUEST_KEYS,
  LOAD_MORE_PAGE_STEP,
  TRANSITION_TIMEOUT,
  GET_REPORTS_INITIAL_PAGE,
  ReportCurrencyNames,
  transactionTypeOptions,
  currencyOptions
} from './constants';
import { createReportsFiltersArray, getExportReportsFileName, getExportReportsUrl } from './helpers';
import {
  useFiltersArrayWithInitialFetch,
  useHandleFiltersChange,
  useHandleFiltersSelectAll,
  useOnChangeFilters,
  usePickerDates,
  useReportsCurrencyChanged
} from './hooks';
import { ReportsTableBlock } from './components/ReportsTableBlock';
import { ContentTypes } from './model';
import styles from './ReportsPage.module.scss';
import FadeTransition from 'src/components/reactTransitions/FadeTransition.module.css';
import { getPlatforms } from 'src/redux/actions/baseActions';
import { PlatformShape } from 'src/interfaces';
import { AddTransactionModal } from './components/AddTransactionModal';
import { ReportsStyledSelect } from './components/ReportsStyledSelect';
import { DeleteTransactionModal } from './components/DeleteTransactionModal';

const ReportsPage: FC = () => {
  const dispatch = useDispatch();
  const { isAsideMenuOpen } = useSelector(baseReducerSelector);

  const { cashflow, transactions } = useSelector(reportsSelector);
  const { platforms } = useSelector(baseFiltersSelector);

  const { accessToken } = useUserValues();
  const currency = useSelector(currencySelector);

  const [isLoading, setLoading] = useState(true);

  const [reqParams, setReqParams] = useState<ReportsRequestParams>({
    ...DEFAULT_REPORTS_REQ_PARAMS,
    currency: currency.value
  } as ReportsRequestParams);

  useReportsCurrencyChanged(currency.value, reqParams.currency, setReqParams, setLoading);

  const { selectedDates, selectedMonths, handleSetSelectedMonth, handleDateChange, onSetInitialDates } =
    usePickerDates(setReqParams);

  const handleLoadMoreCashflowByPlatform = useCallback(
    (report: IClientReport, onFinally: () => void) => {
      const params: ReportsLoadMoreRequestParams = {
        ...reqParams,
        page: report.clientSidePagination.page + LOAD_MORE_PAGE_STEP,
        platforms: report.platform
      };

      dispatch(getMoreCashflowByPlatform(params, onFinally, onFinally));
    },
    [dispatch, reqParams]
  );

  useEffect(() => {
    dispatch(
      getPlatforms(null, (platforms: PlatformShape[]) => {
        setReqParams((prev) => ({
          ...prev,
          ...GET_REPORTS_DEFAULT_PARAMS(currency.value, platforms, selectedDates),
          page: GET_REPORTS_INITIAL_PAGE
        }));
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetTransactions = useCallback(
    (transactionType: CashflowTransactionsGroup) => {
      dispatch(getTransactions({ reqParams, transactionType }));
    },
    [dispatch, reqParams]
  );

  const [transactionType, setTransactionType] = useState<CashflowTransactionsGroup>(CashflowTransactionsGroup.All);
  const handleChangeTransactionType = useCallback(
    (value) => {
      setTransactionType(value);
      onGetTransactions(value as CashflowTransactionsGroup);
    },
    [onGetTransactions]
  );

  const handleLoadMoreTransactionsByPlatform = useCallback(
    (report: IClientTransaction, onFinally: () => void) => {
      const params: ReportsLoadMoreRequestParams = {
        ...reqParams,
        page: report.clientSidePagination.page + LOAD_MORE_PAGE_STEP,
        platforms: report.platform
      };

      dispatch(
        getMoreTransactionsByPlatform({
          reqParams: params,
          transactionType,
          onSuccess: onFinally,
          onError: onFinally
        })
      );
    },
    [dispatch, reqParams, transactionType]
  );

  useEffect(() => {
    if (!REQUIRED_REQUEST_KEYS.every((key) => Object.keys(reqParams).includes(key))) return;

    setLoading(true);

    dispatch(
      getTransactionsAndReports({
        reqParams,
        transactionType,
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reqParams]);

  const [filtersArray, setFiltersArray] = useFiltersArrayWithInitialFetch(platforms);
  const onChangeCheckboxFilters = useOnChangeFilters(reqParams, setFiltersArray, setReqParams);

  const handleFilterChange = useHandleFiltersChange(filtersArray, onChangeCheckboxFilters);
  const handleSelectAll = useHandleFiltersSelectAll(filtersArray, onChangeCheckboxFilters);

  const onClearFilters = useCallback(() => {
    setReqParams(GET_REPORTS_DEFAULT_PARAMS(currency.value, platforms, selectedDates) as ReportsRequestParams);
    setFiltersArray(createReportsFiltersArray(platforms));
    onSetInitialDates();
  }, [platforms, currency.value, selectedDates, setFiltersArray, onSetInitialDates]);

  const onConfirmExport = useCallback(
    async (fileType: ExportFileTypes, onDownloadedSuccess: () => void) => {
      const fileName = getExportReportsFileName();
      const fileUrl = getExportReportsUrl(reqParams, currency.value, fileType);

      await exportFileApi(fileUrl, fileName, fileType, accessToken);
      onDownloadedSuccess();
    },
    [accessToken, currency.value, reqParams]
  );

  const [reportsCurrency, setReportsCurrency] = useState<ReportCurrencyNames>(ReportCurrencyNames.REPORTING);
  const onReportsCurrencyChanged = useCallback((currency) => setReportsCurrency(currency), []);

  const [isAddModalShown, setAddModalShown] = useState(false);
  const onOpenAddModal = useCallback(() => setAddModalShown(true), []);
  const onCloseAddModal = useCallback(() => setAddModalShown(false), []);

  const onAddTransactionConfirm = useCallback(
    (body: TAddTransactionBody, onFormSuccess: () => void, onFormError: () => void) => {
      dispatch(
        addTransaction({
          body,
          onSuccess: () => {
            onGetTransactions(transactionType as CashflowTransactionsGroup);
            onCloseAddModal();
            onFormSuccess();
          },
          onError: () => {
            onFormError();
          }
        })
      );
    },
    [dispatch, onCloseAddModal, onGetTransactions, transactionType]
  );

  const [transactionDeleteId, setTransactionDeleteId] = useState(null);
  const [isDeleteModalShown, setDeleteModalShown] = useState(false);
  const onOpenDeleteModal = useCallback(() => setDeleteModalShown(true), []);
  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalShown(false);
    setTransactionDeleteId(null);
  }, []);

  const handleManualTransactionClick = useCallback(
    (row: IServerTransaction) => {
      setTransactionDeleteId(row.id);
      onOpenDeleteModal();
    },
    [onOpenDeleteModal]
  );

  const onDeleteTransactionConfirm = useCallback(
    (onFormError: () => void) => {
      dispatch(
        deleteTransaction({
          id: transactionDeleteId,
          onSuccess: () => {
            onGetTransactions(transactionType);
            onCloseDeleteModal();
          },
          onError: () => {
            onFormError();
          }
        })
      );
    },
    [dispatch, transactionDeleteId, onGetTransactions, transactionType, onCloseDeleteModal]
  );

  const checkedPlatforms = useMemo(
    () => filtersArray?.[0]?.list?.filter(({ isChecked }) => isChecked).map(({ name }) => name.toUpperCase()),
    [filtersArray]
  );

  const renderKey = useRef(ContentTypes.LOADER);

  const getContent = useCallback(() => {
    if (isLoading) {
      renderKey.current = ContentTypes.LOADER;
      return (
        <div className={styles.loaderWrapper}>
          <PuffLoader size={LOADER_SIZE} color="#077186" />
        </div>
      );
    }

    if (!isLoading && !isEmpty(checkedPlatforms)) {
      renderKey.current = ContentTypes.DATA;
      return (
        <div className={styles.content}>
          {checkedPlatforms.map((platform) => {
            const cashflowDataItem = cashflow.find((item) => item.platform === platform);
            const transactionsDataItem = transactions.find((item) => item.platform === platform);
            const hasTransactions = !isEmpty(transactionsDataItem?.data);

            return (
              hasTransactions && (
                <ReportsTableBlock
                  key={platform}
                  platform={platform}
                  transactionsItem={transactionsDataItem}
                  onLoadMoreTransactions={handleLoadMoreTransactionsByPlatform}
                  cashflowItem={cashflowDataItem}
                  onLoadMoreCashflow={handleLoadMoreCashflowByPlatform}
                  currency={reqParams.currency}
                  isAsideMenuOpen={isAsideMenuOpen}
                  reportCurrency={reportsCurrency}
                  onManualTransactionClick={handleManualTransactionClick}
                />
              )
            );
          })}
        </div>
      );
    }

    renderKey.current = ContentTypes.NO_DATA;
    return <h2 className={styles.noReport}>No transactions for this platform so far.</h2>;
  }, [
    isLoading,
    checkedPlatforms,
    cashflow,
    transactions,
    handleLoadMoreTransactionsByPlatform,
    handleLoadMoreCashflowByPlatform,
    handleManualTransactionClick,
    reqParams.currency,
    isAsideMenuOpen,
    reportsCurrency
  ]);

  return (
    <MainLayout isEmptySkeleton>
      <ContentHeadingBox wrapperClass={styles.contentHeadingBox}>
        <h2 className={styles.title}>Transactions</h2>
      </ContentHeadingBox>

      <ContentHeadingBox wrapperClass={styles.contentHeadingFilters}>
        <div className={styles.pickerGroup}>
          <DatePickerBlock
            dates={selectedDates}
            selectedMonths={selectedMonths}
            onDateChange={handleDateChange}
            onSetSelectedMonth={handleSetSelectedMonth}
            label="Transaction Date:"
            labelClass={styles.datePickerLabel}
          />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
            <ReportsStyledSelect
              options={currencyOptions}
              onOptionChanged={onReportsCurrencyChanged}
              initialOptionValue={reportsCurrency}
            />

            <ReportsStyledSelect
              initialOptionValue={transactionType}
              options={transactionTypeOptions}
              onOptionChanged={handleChangeTransactionType}
            />

            <IconButton
              disableRipple
              onClick={onOpenAddModal}
              sx={{
                path: { fill: '#000', transition: 'fill 0.15s ease-in-out' },
                '& :hover': {
                  path: { fill: '#077186' }
                }
              }}
            >
              <AddManualTransactionIcon />
            </IconButton>
          </Box>
        </div>

        <div className={styles.filtersBox}>
          <button type="button" className={styles.clearFiltersBtn} onClick={onClearFilters}>
            Clear All Filters
          </button>

          <ExportAllBlock onConfirmExport={onConfirmExport} selectWrapperClass={styles.fileTypeWrapper} />

          <Filter
            classes={{ btnClass: styles.filterBtn, dropdownClass: styles.filterContainer }}
            filters={filtersArray}
            onChangeFilters={handleFilterChange}
            onSelectAllFilters={handleSelectAll}
            withTruncateLabels
            truncateWidth={CHECKBOX_LABELS_TRUNCATE_WIDTH}
          />
        </div>
      </ContentHeadingBox>

      <SwitchTransition mode="out-in">
        <CSSTransition key={renderKey.current} timeout={TRANSITION_TIMEOUT} classNames={{ ...FadeTransition }}>
          {getContent()}
        </CSSTransition>
      </SwitchTransition>

      <AddTransactionModal
        isOpen={isAddModalShown}
        onClose={onCloseAddModal}
        platforms={platforms}
        onAddTransactionConfirm={onAddTransactionConfirm}
      />

      <DeleteTransactionModal
        isOpen={isDeleteModalShown}
        onClose={onCloseDeleteModal}
        onDeleteTransactionConfirm={onDeleteTransactionConfirm}
      />
    </MainLayout>
  );
};

export default ReportsPage;
