import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseFiltersSelector } from 'src/redux/selectors';
import { getLoanFilters } from 'src/redux/actions/baseActions';
import { ExportAllBlock, Filter, PortfolioAllocation, RefreshStatistics, ShouldRender } from 'src/components';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { createFiltersArray } from 'src/helpers/marketplaceHelpers';
import styles from './LoanFiltersBlock.module.scss';
import { IDropdownItem } from 'src/interfaces';

interface LoanFiltersBlockProps {
  selectedAllocation: IDropdownItem;
  onChangeSelectedAllocation: (value) => void;
  selectorOptions: IDropdownItem[];
  onConfirmExport: (fileType: ExportFileTypes, onSuccess: () => void) => void;
  lastUpdatedDate: string;
  lastRequestedDate: string;
  displayFilterButton?: boolean;
  displayClearButton?: boolean;
  blockExportCondition?: boolean;
  onExportConditionBlocked?: () => void;
  isPortfolioSelectError?: boolean;
  shouldRenderStatistics: boolean;
}

const LoanFiltersBlock = ({
  selectedAllocation,
  onChangeSelectedAllocation,
  selectorOptions,
  lastUpdatedDate,
  lastRequestedDate,
  displayFilterButton = true,
  displayClearButton = true,
  onConfirmExport,
  blockExportCondition,
  onExportConditionBlocked,
  isPortfolioSelectError,
  shouldRenderStatistics
}: LoanFiltersBlockProps): JSX.Element => {
  const dispatch = useDispatch();

  const { loanTypes, countries, platforms } = useSelector(baseFiltersSelector);
  const filters = useMemo(() => ({ loanTypes, countries, platforms }), [loanTypes, countries, platforms]);

  const [filtersArray, setFiltersArray] = useState(createFiltersArray(filters, true));

  useEffect(() => {
    setFiltersArray(createFiltersArray(filters, true));
  }, [filters]);

  useEffect(() => {
    dispatch(getLoanFilters());
  }, [dispatch]);

  const handleFilterChange = (name: string, id: string) => {
    setFiltersArray((state) =>
      state.map((block) => {
        if (block.id !== name) return block;

        const list = block.list.map((item) => ({
          ...item,
          isChecked: item.id === id ? !item.isChecked : item.isChecked
        }));

        const isAllChecked = list.every(({ isChecked }) => isChecked);

        return {
          ...block,
          isAllChecked,
          list
        };
      })
    );
  };

  const handleSelectAll = (name: string) => {
    setFiltersArray((state) =>
      state.map((block) =>
        block.id === name
          ? {
              ...block,
              isAllChecked: !block.isAllChecked,
              list: block.list.map((item) => ({ ...item, isChecked: block.isAllChecked ? false : true }))
            }
          : block
      )
    );
  };

  const clearAllFilters = () => setFiltersArray(createFiltersArray(filters, true));

  return (
    <div className={styles.wrapper}>
      <div className={styles.portfolioAllocationBox}>
        <PortfolioAllocation
          selectedAllocation={selectedAllocation}
          onChangeValue={onChangeSelectedAllocation}
          selectorOptions={selectorOptions}
          isError={isPortfolioSelectError}
        />

        <div className={styles.exportBlock}>
          <ShouldRender should={shouldRenderStatistics}>
            <RefreshStatistics
              shouldRevers
              lastRequestedDate={lastRequestedDate}
              lastUpdatedDate={lastUpdatedDate}
              wrapperClass={styles.refreshWrapper}
              lastUpdatedClass={styles.lastUpdatedClass}
            />
          </ShouldRender>

          <ExportAllBlock
            onConfirmExport={onConfirmExport}
            blockExportCondition={blockExportCondition}
            onExportConditionBlocked={onExportConditionBlocked}
          />
        </div>
      </div>

      <ShouldRender should={displayClearButton || displayFilterButton}>
        <div className={styles.dateAndFilterWrapper}>
          {displayClearButton ? (
            <button type="button" className={styles.clearFiltersBtn} onClick={clearAllFilters}>
              Clear All Filters
            </button>
          ) : null}

          {displayFilterButton ? (
            <Filter
              classes={{ btnClass: styles.filterBtn }}
              filters={filtersArray}
              onChangeFilters={handleFilterChange}
              onSelectAllFilters={handleSelectAll}
            />
          ) : null}
        </div>
      </ShouldRender>
    </div>
  );
};

export default LoanFiltersBlock;
