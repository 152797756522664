import React from 'react';
import cn from 'classnames';
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import arrow from 'src/assets/images/icons/file_type_arrow.svg';
import styles from './FileTypeSelect.module.scss';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import getSelectStyles from './FileTypeSelectStyles';

const DropdownIndicator = (): JSX.Element => <ReactSVG src={arrow} />;

interface FileTypeSelectProps {
  options: Array<{ value: ExportFileTypes; label: string }>;
  value: { value: ExportFileTypes; label: string };
  selectWrapperClass?: string;
  onChange: (value: { value: ExportFileTypes; label: string }) => void;
  isError?: boolean;
  onSelectFocus?: () => void;
  isSearchable?: boolean;
  label?: string;
}

const FileTypeSelect = ({
  options,
  value,
  onChange,
  selectWrapperClass,
  isError,
  onSelectFocus,
  isSearchable = false,
  label = 'Download:'
}: FileTypeSelectProps): JSX.Element => {
  return (
    <div className={cn(styles.selectContentWrapper, { [selectWrapperClass]: selectWrapperClass })}>
      <span className={styles.label}>{label}</span>
      <Select
        isSearchable={isSearchable}
        options={options}
        value={value}
        onChange={onChange}
        onFocus={onSelectFocus ?? null}
        minMenuHeight={178}
        maxMenuHeight={190}
        placeholder="Select"
        components={{ DropdownIndicator }}
        styles={getSelectStyles(isError)}
      />
    </div>
  );
};

export default FileTypeSelect;
