import React, { FC, memo, useMemo, useState } from 'react';
import cn from 'classnames';
import { Box } from '@mui/material';
import { formatISO, startOfDay } from 'date-fns';

import { IDropdownItem, PlatformShape } from 'src/interfaces';
import { TAddTransactionBody } from 'src/interfaces/reportsReduxShapes';
import styles from './AddTransactionModal.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  ConfirmButton,
  FormikFormatField as FormatField,
  Modal,
  ModalLayout,
  RequestErrorMessage
} from 'src/components';
import isEmpty from 'lodash/isEmpty';
import { Provider } from 'src/constants/marketplaceConstants';
import { ReportsStyledSelect } from '../ReportsStyledSelect';
import { manualTransactionOptions } from '../../constants';
import { validationSchema } from './constants';
import { Fields, Values } from './types';
import { hasError } from './helpers';
import { DatePickerRow, FormRow } from './components';

type AddTransactionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  platforms: PlatformShape[];
  onAddTransactionConfirm: (values: TAddTransactionBody, onSuccess: () => void, onError: () => void) => void;
};

const MAX_TRANSACTION_AMOUNT = 100 * 1000 * 1000;

const AddTransactionModal: FC<AddTransactionModalProps> = memo(
  ({ isOpen, onClose, platforms, onAddTransactionConfirm }) => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [isResponseError, setIsResponseError] = useState(false);

    const platformOptions: IDropdownItem[] = useMemo(
      () =>
        platforms.map((platform) => ({
          value: platform.name,
          label: Provider[platform.name]
        })),
      [platforms]
    );

    return (
      <Modal isOpen={isOpen} onToggle={onClose}>
        <ModalLayout>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '28.125rem',
              borderRadius: '0.625rem',
              background: '#FFFFFF',
              padding: '2rem 2rem 2.5rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '2rem'
              }}
            >
              <h2 className={styles.title}>Add Transaction</h2>
              {isResponseError && (
                <RequestErrorMessage msg={'Something went wrong'} customClass={styles.reqErrorText} />
              )}
            </Box>

            <Formik
              initialValues={{
                [Fields.platform]: '',
                [Fields.type]: '',
                [Fields.amount]: '',
                [Fields.date]: formatISO(startOfDay(new Date()))
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={validationSchema}
              onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
                setSubmitting(true);

                const body = {
                  platform: values.platform,
                  type: values.type,
                  amount: +values.amount,
                  date: values.date
                } as TAddTransactionBody;

                onAddTransactionConfirm(
                  body,
                  () => setSubmitting(false),
                  () => {
                    setIsResponseError(true);
                    setSubmitting(false);
                  }
                );
              }}
            >
              {({ values, errors, touched, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => {
                const platformCurrency = platforms.find((platform) => platform.name === values.platform)?.currency;
                const hasErrors = !isEmpty(errors);

                return (
                  <Form className={styles.form}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        rowGap: '10px',
                        mb: '36px'
                      }}
                    >
                      <FormRow label="Platform:">
                        <ReportsStyledSelect
                          name={Fields.platform}
                          options={platformOptions}
                          onOptionChanged={(value) => setFieldValue(Fields.platform, value)}
                          placeholder="Platform"
                          hasError={hasError(errors, touched, Fields.platform) && hasSubmitted}
                          customStyles={{
                            container: {
                              width: '100%'
                            }
                          }}
                        />
                      </FormRow>
                      <FormRow label="Transaction Type:">
                        <ReportsStyledSelect
                          name={Fields.type}
                          options={manualTransactionOptions}
                          onOptionChanged={(value) => setFieldValue(Fields.type, value)}
                          placeholder="Type"
                          hasError={hasError(errors, touched, Fields.type) && hasSubmitted}
                          customStyles={{
                            container: {
                              width: '100%'
                            }
                          }}
                        />
                      </FormRow>

                      <DatePickerRow
                        id={Fields.date}
                        onDateChange={(date) => setFieldValue(Fields.date, formatISO(startOfDay(date)), false)}
                        onBlur={handleBlur}
                      />

                      <FormRow label="Transaction Amount:">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          <FormatField
                            hideErrorState={!hasSubmitted}
                            thousandSeparator
                            isNumericString
                            id={Fields.amount}
                            name={Fields.amount}
                            placeholder="Amount"
                            onClearRequestError={() => setIsResponseError(false)}
                            maxNumericValue={MAX_TRANSACTION_AMOUNT}
                            classes={{
                              wrapperClassName: styles.inputWrapperClass,
                              inputClassName: cn(styles.inputClass, {
                                [styles.inputError]: hasError(errors, touched, Fields.amount) && hasSubmitted
                              })
                            }}
                          />

                          <span className={styles.currencyLabel}>{platformCurrency ?? ''}</span>
                        </Box>
                      </FormRow>
                    </Box>

                    <ConfirmButton
                      type="button"
                      onClick={() => {
                        setHasSubmitted(true);
                        handleSubmit();
                      }}
                      isDisabled={isSubmitting}
                      btnClass={cn(styles.submitBtn, { [styles.submitBtnDisabled]: hasErrors })}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ModalLayout>
      </Modal>
    );
  }
);

AddTransactionModal.displayName = 'AddTransactionModal';

export default AddTransactionModal;
