import React, { FC } from 'react';
import cn from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NotInterestedRoundedIcon from '@mui/icons-material/NotInterestedRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import styles from './HideUnsuccessfulToggle.module.scss';

type HideUnsuccessfulToggleProps = {
  isChecked: boolean;
  onChangeChecked: (checked: boolean) => void;
  wrapperClass?: string;
};

const HideUnsuccessfulToggle: FC<HideUnsuccessfulToggleProps> = ({ isChecked, onChangeChecked, wrapperClass }) => {
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => onChangeChecked(event.target.checked);

  const label = isChecked ? 'Show All' : 'Hide Unsuccessful';
  return (
    <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={handleChangeChecked}
            classes={{ root: styles.checkboxRoot }}
            icon={<NotInterestedRoundedIcon classes={{ root: styles.iconRoot }} />}
            checkedIcon={<PlayCircleOutlineRoundedIcon classes={{ root: styles.iconRootChecked }} />}
            disableRipple
          />
        }
        label={label}
        classes={{
          root: styles.formControlRoot,
          label: cn(styles.label, { [styles.labelChecked]: isChecked })
        }}
      />
    </div>
  );
};

export default HideUnsuccessfulToggle;
