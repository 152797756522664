import { sub } from 'date-fns';
import { InvestmentHistoryStatuses } from 'src/interfaces';

export const INVESTMENT_HISTORY_LABELS = {
  [InvestmentHistoryStatuses.LOAN_CREATED]: 'Data received',
  [InvestmentHistoryStatuses.SENT]: 'Bid sent',
  [InvestmentHistoryStatuses.EXECUTED]: 'Bid accepted',
  [InvestmentHistoryStatuses.REJECTED]: 'Bid rejected',
  [InvestmentHistoryStatuses.WAITING_FOR_DISBURSEMENT]: 'Waiting for disbursement',
  [InvestmentHistoryStatuses.NOT_FUNDED]: 'Not funded',
  [InvestmentHistoryStatuses.FUNDED]: 'Current',
  [InvestmentHistoryStatuses.CURRENT]: 'Current',
  [InvestmentHistoryStatuses.LATE]: 'Late',
  [InvestmentHistoryStatuses.DEFAULTED]: 'Defaulted',
  [InvestmentHistoryStatuses.COMPLETED]: 'Completed',
  [InvestmentHistoryStatuses.PREPAID]: 'Prepaid'
};

export enum InvestmentStatuses {
  CURRENT = 'current',
  LATE = 'late',
  COMPLETED = 'completed',
  DEFAULTED = 'defaulted',
  PREPAID = 'prepaid'
}

export enum OutstandingBidStatuses {
  SENT = 'sent',
  EXECUTED = 'executed',
  REJECTED = 'rejected',
  WAITING_FOR_DISBURSEMENT = 'waiting_for_disbursement',
  NOT_FUNDED = 'not_funded'
}

export const INITIAL_DATE_PICKER_FROM = sub(new Date(), { years: 5 });
export const INITIAL_DATE_PICKER_TO = new Date();
