import React from 'react';
import errorPicture from 'src/assets/images/error.png';
import styles from './ErrorPageLayout.module.scss';

interface Props {
  children: React.ReactNode;
}

const ErrorPageLayout = ({ children }: Props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>{children}</div>
      <div className={styles.imageWrapper}>
        <img src={errorPicture} alt="error image" className="img-fluid" />
      </div>
    </div>
  );
};

export default ErrorPageLayout;
