import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabLink from './TabLink';
import styles from './TabsNavigationBlock.module.scss';

type ITab = {
  label: string;
  path: string;
};

type Props = {
  tabs: ITab[];
  wrapperClass?: string;
  tabLinkClass?: string;
};

const TabsNavigationBlock: FC<Props> = ({ tabs, wrapperClass, tabLinkClass }): JSX.Element => {
  const {
    push,
    location: { pathname }
  } = useHistory();

  const [value, setValue] = useState(tabs.indexOf(tabs.find(({ path }) => path === pathname)));

  const handleChange = (e, newValue: number) => {
    setValue(newValue);
    push(tabs[newValue].path);
  };

  return (
    <div className={cn(styles.panel, { [wrapperClass]: wrapperClass })}>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{
          root: styles.tabsRoot,
          indicator: styles.indicator
        }}
      >
        {tabs.map(({ label, path }) => (
          <Tab
            key={path}
            label={label}
            href={path}
            LinkComponent={TabLink}
            onClick={(e) => e.preventDefault()}
            classes={{ root: cn(styles.tabLink, { [tabLinkClass]: tabLinkClass }), selected: styles.activeTabLink }}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default TabsNavigationBlock;
