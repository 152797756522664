import React, { FC } from 'react';
import { Element } from 'react-scroll';
import cn from 'classnames';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {
  TableHeaderColumns,
  PaginationProps,
  TBodyCssClasses,
  THeadCssClasses,
  TableHeaderHeadingColumns
} from 'src/interfaces/CustomTableShapes';
import { THead } from './THead';
import { TBody } from './TBody';
import { TFooter } from './TFooter';
import styles from './styles.module.scss';
import { SxProps, Theme } from '@mui/material';

interface CustomTableTableShape {
  columns: TableHeaderColumns;
  rows: Array<{ id?: number | string }>;
  onSort: (type, order) => void;
  isSelectAllDisabled?: boolean;
  onSelectAllChange?: () => void;
  isSelectAllChecked?: boolean;
  getSummaryContent: (colId: string, row) => React.ReactNode;
  emptyRowsMsg: string | React.ReactNode;
  isLoading: boolean;
  rowsChecked?: Array<{ id: string; isChecked: boolean }>;
  onSetChecked?: (id: string) => void;
  guttersLeft?: number;
  guttersRight?: number;
  tHeadClasses?: THeadCssClasses;
  tBodyClasses?: TBodyCssClasses;
  tContainerClass?: string;
  noPaginationWrapperClass?: string;
  hasPagination?: boolean;
  paginationData?: PaginationProps;
  error?: null | string;
  shouldShowList?: boolean;
  hasDetails?: boolean;
  withModalDetails?: boolean;
  detailsContent?;
  onGetDetailsPath?: (row) => string;
  showDefaultPagination?: boolean;
  onSetSortDropdownClass?: (id: string) => string;
  topHeadingColumns?: TableHeaderHeadingColumns;
  stickyHeader?: boolean;
  maxTableContainerHeight?: 'unset' | number | string;
  tableScrollWrapperName?: string;
  tableScrollWrapperContainerName?: string;
  rowMappingUniqueKeys?: string | string[];
  useUuIdBodyKeys?: boolean;
  onRowClick?: (row) => void;
  onSetCustomRowSx?: (row) => SxProps<Theme>;
}

const CustomizedTable: FC<CustomTableTableShape> = ({
  columns = [],
  rows = [],
  onSort,
  isSelectAllDisabled,
  onSelectAllChange,
  isSelectAllChecked,
  tHeadClasses,
  guttersLeft,
  guttersRight,
  paginationData,
  isLoading,
  hasPagination,
  rowsChecked,
  onSetChecked,
  tBodyClasses,
  tContainerClass,
  noPaginationWrapperClass,
  getSummaryContent,
  error,
  emptyRowsMsg,
  hasDetails,
  withModalDetails,
  detailsContent,
  onGetDetailsPath,
  shouldShowList,
  showDefaultPagination = false,
  onSetSortDropdownClass,
  topHeadingColumns,
  stickyHeader,
  maxTableContainerHeight = 'unset',
  tableScrollWrapperName = 'table_scroll_wrapper_element',
  tableScrollWrapperContainerName = 'table_scroll_wrapper_container',
  rowMappingUniqueKeys,
  useUuIdBodyKeys,
  onRowClick,
  onSetCustomRowSx
}): JSX.Element => {
  const noItems = emptyRowsMsg && !isLoading && !rows?.length;
  const showLoadMore = paginationData?.loadMorePagination && rows?.length;

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.noPaginationWrapper]: hasPagination && !showLoadMore,
        [noPaginationWrapperClass]: noPaginationWrapperClass
      })}
    >
      <TableContainer
        component={Paper}
        classes={{ root: cn(styles.tableContainer, { [tContainerClass]: tContainerClass }) }}
        style={{ maxHeight: maxTableContainerHeight }}
        id={tableScrollWrapperContainerName}
      >
        <Element name={tableScrollWrapperName}>
          <Table stickyHeader={stickyHeader}>
            {columns && (
              <THead
                columns={columns}
                onSort={onSort}
                classes={tHeadClasses}
                guttersLeft={guttersLeft}
                guttersRight={guttersRight}
                isSelectAllDisabled={isSelectAllDisabled}
                onSelectAllChange={onSelectAllChange}
                isSelectAllChecked={isSelectAllChecked}
                onSetDropdownClass={onSetSortDropdownClass}
                topHeadingColumns={topHeadingColumns}
              />
            )}

            <TBody
              isLoading={isLoading}
              columns={columns}
              rows={rows}
              error={error}
              emptyRowsMsg={emptyRowsMsg}
              getSummaryContent={getSummaryContent}
              detailsContent={detailsContent}
              onGetDetailsPath={onGetDetailsPath}
              classes={tBodyClasses}
              rowsChecked={rowsChecked}
              onSetChecked={onSetChecked}
              shouldShowList={shouldShowList}
              guttersLeft={guttersLeft}
              guttersRight={guttersRight}
              hasDetails={hasDetails}
              withModalDetails={withModalDetails}
              rowMappingUniqueKeys={rowMappingUniqueKeys}
              useUuIdBodyKeys={useUuIdBodyKeys}
              onRowClick={onRowClick}
              onSetCustomRowSx={onSetCustomRowSx}
            />

            {hasPagination ? (
              <TFooter
                isLoading={isLoading}
                showLoadMorePagination={!!showLoadMore}
                showDefaultPagination={!noItems && !showLoadMore && showDefaultPagination}
                colSpan={columns?.length}
                hasRows={!!rows?.length}
                {...paginationData}
              />
            ) : null}
          </Table>
        </Element>
      </TableContainer>
    </div>
  );
};

export default CustomizedTable;
