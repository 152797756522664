import React from 'react';
import cn from 'classnames';
import bellIcon from 'src/assets/images/icons/notification_bell.svg';
import { header } from 'src/assets/locales/en/common.json';
import styles from '../NavAccountBlock.module.scss';

const Notifications = (): JSX.Element => {
  return (
    <div className={cn('nav-circle', styles.notificationsBlock)}>
      <img src={bellIcon} alt={header.notificationsAlt} className={cn(styles.notificationIcon, 'img-fluid')} />
    </div>
  );
};

export default Notifications;
