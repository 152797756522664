import React, { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { Modal, ModalLayout } from 'src/components';
import { ConfirmButton, GreenBoxButton, RequestErrorMessage } from 'src/components';
import { Box, Typography } from '@mui/material';
import styles from './DeleteTransactionModal.module.scss';

type TDeleteTransactionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteTransactionConfirm: (onSuccess: () => void, onError: () => void) => void;
};

const DeleteTransactionModal: FC<TDeleteTransactionModalProps> = memo(
  ({ isOpen, onClose, onDeleteTransactionConfirm }) => {
    const [isResponseError, setResponseError] = useState(false);

    const handleConfirmDelete = useCallback(() => {
      onDeleteTransactionConfirm(
        () => setResponseError(false),
        () => setResponseError(true)
      );
    }, [onDeleteTransactionConfirm]);

    return (
      <Modal isOpen={isOpen} onToggle={onClose}>
        <ModalLayout>
          <Box
            sx={{
              width: '33.125rem',
              minHeight: '15.625rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '2rem 5rem 2.25rem'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Verlag, sans-serif',
                color: '#000',
                fontSize: '1.25rem',
                lineHeight: 'normal',
                letterSpacing: '-0.02863rem',
                textAlign: 'center',
                mb: '1.25rem'
              }}
            >
              Delete transaction?
            </Typography>

            {isResponseError && <RequestErrorMessage msg={'Something went wrong'} customClass={styles.reqErrorText} />}

            <Typography
              sx={{
                fontFamily: 'Verlag, sans-serif',
                color: '#000',
                fontSize: '0.9375rem',
                lineHeight: 'normal',
                letterSpacing: '-0.02863rem',
                textAlign: 'center',
                mb: '1.25rem'
              }}
            >
              This transaction will be permanently deleted and all the corresponding balances will be affected.
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Verlag, sans-serif',
                color: '#000',
                fontSize: '0.9375rem',
                lineHeight: 'normal',
                letterSpacing: '-0.02863rem',
                textAlign: 'center',
                mb: '1.95rem'
              }}
            >
              Would you like to proceed?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1.25rem'
              }}
            >
              <GreenBoxButton onClick={onClose}>Cancel</GreenBoxButton>

              <ConfirmButton
                type="button"
                onClick={handleConfirmDelete}
                style={{
                  backgroundColor: '#077186'
                }}
              >
                Confirm
              </ConfirmButton>
            </Box>
          </Box>
        </ModalLayout>
      </Modal>
    );
  }
);

DeleteTransactionModal.displayName = 'DeleteTransactionModal';

export default DeleteTransactionModal;
