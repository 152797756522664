import React, { memo, useCallback, useState } from 'react';
import { CustomizedSelect, DropdownIndicator } from 'src/atoms';
import { IDropdownItem } from 'src/interfaces';
import styles from './ReportsStyledSelect.module.scss';
import { CustomizedSelectProps } from 'src/atoms/CustomizedSelect/CustomizedSelect';

const StyledDropdownIndicator = () => <DropdownIndicator className={styles.selectArrow} />;

type ReportsStyledSelectProps<T> = {
  initialOptionValue?: T;
  options: IDropdownItem[];
  onOptionChanged: (value: T) => void;
  hasError?: boolean;
} & Pick<CustomizedSelectProps, 'customStyles' | 'onBlur' | 'name' | 'placeholder'>;

function ReportsStyledSelect<T>({
  initialOptionValue,
  options,
  onOptionChanged,
  name,
  onBlur,
  placeholder,
  customStyles,
  hasError
}: ReportsStyledSelectProps<T>) {
  const [selectedTransactionType, setSelectedTransactionType] = useState(
    initialOptionValue ? options.find(({ value }) => value === initialOptionValue) : null
  );
  const handleChangeCurrency = useCallback(
    (value: IDropdownItem) => {
      setSelectedTransactionType(value);
      onOptionChanged(value.value);
    },
    [onOptionChanged]
  );

  return (
    <CustomizedSelect
      isSearchable={false}
      value={selectedTransactionType}
      onChange={handleChangeCurrency}
      options={options}
      name={name}
      onBlur={onBlur}
      placeholder={placeholder}
      CustomDropdownIndicator={StyledDropdownIndicator}
      customStyles={{
        container: {
          width: 160,
          height: 30,
          borderRadius: '5px',
          padding: '6px 11px 7px 13px',
          minHeight: 'unset',

          ...(hasError ? { border: '1px solid #d80027' } : {}),
          ...(customStyles?.container ?? {})
        },

        control: {
          minHeight: 'unset',
          marginTop: -2,

          ...(customStyles?.control ?? {})
        },

        singleValue: {
          fontSize: 15,
          lineHeight: 1,
          letterSpacing: '-0.778px',
          ...(customStyles?.singleValue ?? {})
        },
        input: {
          fontSize: 15,
          lineHeight: 1,
          letterSpacing: '-0.778px',
          ...(customStyles?.input ?? {})
        },
        placeholder: {
          fontSize: 15,
          lineHeight: 1,
          letterSpacing: '-0.778px',
          ...(customStyles?.placeholder ?? {})
        },

        option: {
          textAlign: 'left',
          justifyContent: 'flex-start',
          padding: '6px 11px 7px 13px',
          fontSize: 15,
          lineHeight: 1.2,
          letterSpacing: '-0.778px',
          ...(customStyles?.option ?? {})
        }
      }}
    />
  );
}

ReportsStyledSelect.displayName = 'ReportsStyledSelect';

export default memo(ReportsStyledSelect);
