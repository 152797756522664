import React, { FC } from 'react';
import cn from 'classnames';
import { ButtonNext, ButtonUnderlined as ButtonBack, StatementBox, ShouldRender } from 'src/components';
import styles from './ConfirmBlock.module.scss';

type Props = {
  isViewPage: boolean;
  title: string;
  backButtonLabel: string;
  onBackButtonClick: () => void;
  hideConfirmBtn?: boolean;
  confirmBtnLabel?: string;
  isConfirmDisabled?: boolean;
  onConfirm: () => void;
  wrapperClass?: string;
};

const ConfirmBlock: FC<Props> = ({
  isViewPage,
  title,
  backButtonLabel,
  onBackButtonClick,
  onConfirm,
  confirmBtnLabel = 'Confirm',
  isConfirmDisabled,
  hideConfirmBtn,
  wrapperClass
}) => {
  return (
    <StatementBox className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.btnBlock}>
        <ButtonBack label={backButtonLabel} onClick={onBackButtonClick} classes={{ btnClass: styles.btnBack }} />

        <ShouldRender should={!hideConfirmBtn}>
          <ButtonNext
            type="button"
            label={confirmBtnLabel}
            isDisabled={isConfirmDisabled}
            btnClass={cn(styles.btnConfirm, { [styles.btnView]: isViewPage })}
            onClick={onConfirm}
          />
        </ShouldRender>
      </div>
    </StatementBox>
  );
};

export default ConfirmBlock;
