import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { LoanTypes } from 'src/constants/globalConstants';
import { IStructureOption, structureOptions, sectorStructureOption } from 'src/constants/statisticsConstants';
import { InvestmentStatistic } from 'src/interfaces';

type TUsePortfolioStructureOptionsProps = {
  allocationType: LoanTypes;
  statisticsData: InvestmentStatistic;
};

export const usePortfolioStructureOptions = ({
  allocationType,
  statisticsData
}: TUsePortfolioStructureOptionsProps): IStructureOption[] => {
  return useMemo(() => {
    const options = allocationType === LoanTypes.SME ? [...structureOptions, sectorStructureOption] : structureOptions;

    return options.filter(({ value }) => !isEmpty(statisticsData?.[value]));
  }, [allocationType, statisticsData]);
};
