import { OutstandingBidStatuses } from 'src/constants/investmentsConstants';
import { LoanSortTypes as LoanOpportunitiesSortTypes } from 'src/constants/opportunitiesContants';
import { SortOrders, ExaloanRatings, LoanTypes, PlatformsIDs } from 'src/constants/globalConstants';
import { LoanTypeShape, IAllocation } from 'src/interfaces';

interface SummaryRangeShape {
  min: number;
  max: number;
}

export interface InvestmentLoansData {
  investments: InvestmentsLoans[];
  loans: InvestmentsLoans[];
  total: number;
  hasMoreInvestments: boolean;
  page: number;
}

export interface MarketplaceSummaryShape {
  borrowerRate: SummaryRangeShape;
  term: SummaryRangeShape;
  loanAmount: SummaryRangeShape;
}

export type OutstandingBidsData = {
  outstandingBids: InvestmentsLoans[];
  hasMoreBids: boolean;
  page: number;
};

export type IOpportunityParced = {
  id: string;
  type: LoanTypes;
  uniq: string;
  country: string;
  origination_date: string;
  maturity_date: string;
  source_platform_name: PlatformsIDs;
  loan_amount: number;
  loan_status: string;
  loan_purpose: string;
  borrower_rate: number;
  employment_status: string;
  borrower_occupation: string;
  borrower_age: number;
  total_income_monthly: number;
  term_original: number;
  grade: ExaloanRatings;
  relative_value: string;
  statement: null;
  loan_country: string;
  loan_score: ExaloanRatings;
  loan_term: number;
  platform: PlatformsIDs;
  matched_date: string;
};

export type IOpportunitiesData = {
  data: null | IOpportunityParced[];
  page: number;
  pagesCount: number;
  size: number;
  total: number;
};

export interface InvestmentsReducer {
  investmentsData: InvestmentLoansData;
  outstandingBidsData: OutstandingBidsData;
  loansFilters: InvestmentsFiltersShape;
  totalOpportunities: number;
  opportunities: IOpportunitiesData;
  cachedParams: null | GetInvestmentsLoansParams;
}

export interface TotalOpportunitiesData {
  totalOpportunities: number;
}

export interface PutInvestmentsTotalOpportunitiesAction {
  type: string;
  payload: TotalOpportunitiesData;
}

export interface OpportunitiesAction {
  type: string;
  payload: {
    reqParams: GetOpportunitiesParams;
    onSuccess?: () => void;
  };
}

export interface GetOpportunitiesParams {
  allocationId?: string;
  page?: number;
  limit?: number;
  maturity_date?: { startDate: Date; endDate: Date };
  opportunity_date?: { startDate: Date; endDate: Date };
  statuses?: string[] | [];
  sort?: { type: LoanOpportunitiesSortTypes; order: SortOrders };
  countries?: string;
  currency?: string;
  amount?: number;
  country?: string[] | [];
  platform?: string[] | [];
  grade?: string[] | [];
  smePurpose?: string[] | [];
  sector?: string[] | [];
  loan_type?: string[] | [];
  type?: LoanTypes;
  relative: string[] | [];
}

export interface IBidOpportunities {
  allocation: string;
  ids: string[];
}

export type BidOpportunitiesReturnType = {
  affected: number;
};

export interface IBidOpportunitiesAction {
  type: string;
  payload: {
    data: IBidOpportunities;
    onSuccess: (data: BidOpportunitiesReturnType) => void;
  };
}

export interface PutInvestmentsLoansAction {
  type: string;
  payload: {
    data: InvestmentLoansData;
    page: number;
  };
}

export interface PutLoanTypesAction {
  type: string;
  payload: LoanTypeShape[];
}

export interface PutInvestmentsFiltersAction {
  type: string;
  payload: InvestmentsFiltersShape;
}

export enum InvestmentsColIDs {
  ID = 'id',
  ORIGINATION_DATE = 'origination_date',
  FUNDED_DATE = 'funded_date',
  BIDDING_DATE = 'bidding_date',
  MATURITY_DATE = 'maturity_date',
  INVESTMENT_AMOUNT = 'invested_amount',
  INTEREST_RATE = 'interest_rate',
  GRADE = 'grade',
  RELATIVE_VALUE = 'percentile',
  TERM = 'term_original',
  LOAN_TYPE = 'loanType',
  STATUS = 'status'
}

export interface GetInvestmentsLoansParams {
  page: number;
  limit: number;
  date?: { startDate: Date; endDate: Date };
  allocations?: string[] | [];
  statuses?: string[] | [];
  grade?: string[] | [];
  platform?: string[] | [];
  sort?: { type: InvestmentsColIDs; order: SortOrders };
  relative: string[] | [];
  currency?: string;
}

export interface GetInvestmentsLoansAction {
  type: string;
  payload: {
    reqParams: GetInvestmentsLoansParams;
    onSuccess?: () => void;
    onError?: (err) => void;
  };
}

export type PutInvestmentsCachedParams = {
  type: string;
  payload: GetInvestmentsLoansParams;
};

export interface GetLoanTypesAction {
  type: string;
}

export interface InvestmentsLoans {
  id: string;
  loan_origination_date: string | Date;
  origination_date?: string | Date;
  platform: string;
  loan_amount: number;
  loan_country: string;
  loan_term: number;
  loan_status: string;
  invested_amount: number;
  loan_score?: ExaloanRatings;
  borrower_rate: number;
  type: string;
  relative_value: string | null;
  bid_statuses?: OutstandingBidStatuses;
  bidding_date?: string | Date;
  funded_date?: string | Date;
  maturity_date?: string | Date;
  uniq: string;
  latest_principal_outstanding: number;
}

export interface InvestmentsFiltersShape {
  loanTypes: LoanTypeShape[];
  allocations: IAllocation[];
  allocationsWithOpportunities?: IAllocation[];
}

export interface GetInvestmentsLoansResponse {
  status: number;
  data: InvestmentLoansData;
}

export interface GetInvestmentsFiltersAction {
  type: string;
  payload?: { withOpportunities: boolean; onFinally?: () => void };
}

export type GetInvestmentHistory = {
  type: string;
  payload: { id: string; onSuccess: (data: InvestmentHistory[]) => void };
};

export type ActualRepayment = {
  id: string;
  actual_payment_date: string;
  actual_interest_payment: number;
  actual_principal_payment: number;
};

export type PlannedRepayment = {
  principal_paid: number;
  interest_paid: number;
  total_paid: number;
  payment_date: string;
};

export type ICashflowData = {
  actual_fees: number;
  actual_interest_payment: number;
  actual_payment_amount: number;
  actual_payment_date: string;
  actual_principal_outstanding: number;
  actual_principal_payment: number;
  is_paid: boolean;
  payment_number: number;
  planned_fees: number;
  planned_interest_payment: number;
  planned_payment_amount: number;
  planned_payment_date: string;
  planned_principal_outstanding: number;
  planned_principal_payment: number;
};

export type IGetInvestmentRepaymentsReq = {
  id: string;
  currency: string;
};

export type IGetInvestmentRepayments = {
  type: string;
  payload: {
    reqData: IGetInvestmentRepaymentsReq;
    onSuccess: (data: ICashflowData[]) => void;
    onError: (error) => void;
  };
};

export enum InvestmentHistoryStatuses {
  LOAN_CREATED = 'loan_created',
  SENT = 'sent',
  EXECUTED = 'executed',
  REJECTED = 'rejected',
  WAITING_FOR_DISBURSEMENT = 'waiting_for_disbursement',
  NOT_FUNDED = 'not_funded',
  FUNDED = 'funded',
  CURRENT = 'current',
  LATE = 'late',
  DEFAULTED = 'defaulted',
  COMPLETED = 'completed',
  PREPAID = 'prepaid'
}

export type InvestmentHistory = {
  date: string;
  status: InvestmentHistoryStatuses;
};

export type GetEntityDetailsReq = {
  id: string;
  isLoan: boolean;
  currency: string;
  onSuccess: (data) => void;
  onError?: (err) => void;
};

export type GetEntityDetails = {
  type: string;
  payload: GetEntityDetailsReq;
};
