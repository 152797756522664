import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Loader from 'react-spinners/PuffLoader';
import {
  MainLayout,
  PortfolioStructure,
  PortfolioAllocation,
  ShouldRender,
  RefreshStatistics,
  ContentHeadingBox
} from 'src/components';
import { calculatePercentage, formatTitle } from 'src/helpers';
import { getAllocations } from 'src/redux/actions/portfolioBuilderActions';
import { getStatistics } from 'src/redux/actions/statisticsActions';
import {
  currencySelector,
  portfolioBuilderAllocationSelector,
  preselectedAllocationSelector,
  statisticsSelector
} from 'src/redux/selectors';
import { getAllocationHook, useUserValues, usePortfolioStructureOptions } from 'src/hooks';
import { SortTypes } from 'src/constants/portfolioBuilderConstants';
import { SortOrders } from 'src/constants/globalConstants';
import { InvestmentsByNames } from 'src/constants/statisticsConstants';
import { Benefits, Widgets } from './components';
import styles from './DashboardPage.module.scss';
import { onPresetAllocationId } from 'src/redux/actions/baseActions';

const LOADER_SIZE = 80;

const DEFAULT_ALLOCATIONS_PARAMS = {
  page: 1,
  limit: 100,
  sort: {
    type: SortTypes.CREATED,
    order: SortOrders.DESC
  }
};

const ALL_PORTFOLIOS_OPTION = { value: null, label: 'All Portfolios' };

const DashboardPage = (): JSX.Element => {
  const dispatch = useDispatch();

  const [portfolioStructureChartData, setPortfolioStructureChartData] = useState([]);
  const [portfolioStructureToggle, setPortfolioStructureToggle] = useState<InvestmentsByNames>(
    InvestmentsByNames.BY_PURPOSE
  );
  const statisticsData = useSelector(statisticsSelector);
  const allocations = useSelector(portfolioBuilderAllocationSelector);
  const currency = useSelector(currencySelector);

  const { profileName, companyName } = useUserValues();

  const allocationID = useSelector(preselectedAllocationSelector);

  const onAllocationChange = useCallback(
    (id: string) => (allocationID !== id ? dispatch(onPresetAllocationId(id)) : null),
    [dispatch, allocationID]
  );

  const { selectedAllocation, selectorOptions, onChangeValue } = getAllocationHook({
    allocations,
    onAllocationChange: onAllocationChange,
    allDataOption: ALL_PORTFOLIOS_OPTION
  });

  const [isAllocationPreset, setAllocationPreset] = useState(false);

  const [isAllocationsFetching, setAllocationsFetching] = useState(true);

  useEffect(() => {
    if (
      isAllocationPreset ||
      (isEmpty(allocations) && isAllocationsFetching) ||
      (isEmpty(selectorOptions) && isAllocationsFetching)
    )
      return;

    const preselectOption = selectorOptions.find((item) => item.value === allocationID);
    onChangeValue(preselectOption);
    setAllocationPreset(true);
  }, [allocations, allocationID, selectorOptions, onChangeValue, isAllocationPreset, isAllocationsFetching]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(
      getAllocations(
        DEFAULT_ALLOCATIONS_PARAMS,
        () => setAllocationsFetching(false),
        () => setAllocationsFetching(false)
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isAllocationPreset) return;

    setLoading(true);
    dispatch(
      getStatistics(
        { allocation: selectedAllocation?.value, currency: currency?.value },
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  }, [dispatch, selectedAllocation, currency, isAllocationPreset]);

  useEffect(() => {
    if (!statisticsData) return;

    const data = statisticsData?.[portfolioStructureToggle];

    if (!data) return;

    const chartData = Object.keys(data).map((key, idx) => ({
      title: formatTitle(key),
      percentage: calculatePercentage(Object.values(data), data[key]),
      id: idx.toString()
    }));

    setPortfolioStructureChartData(chartData);
  }, [portfolioStructureToggle, statisticsData]);

  const structureOptions = usePortfolioStructureOptions({
    allocationType: selectedAllocation?.type,
    statisticsData
  });

  const shouldRenderStatistics = !isLoading && !!statisticsData;

  return (
    <MainLayout isEmptySkeleton>
      <div className={styles.dashboard}>
        <ContentHeadingBox wrapperClass={styles.contentHeadingBox}>
          <div className={styles.headingContent}>
            <h2 className={styles.block_title}>
              {profileName}&nbsp;&nbsp;{companyName ? '|' : null}&nbsp;&nbsp;{companyName}
            </h2>

            <div className={styles.portfolioAllocationBox}>
              <PortfolioAllocation
                selectedAllocation={selectedAllocation}
                selectorOptions={selectorOptions}
                onChangeValue={onChangeValue}
                isSearchable={false}
              />

              <ShouldRender should={shouldRenderStatistics}>
                <RefreshStatistics
                  shouldRevers
                  lastUpdatedDate={statisticsData?.created_at_date}
                  lastRequestedDate={statisticsData?.last_requested_date}
                  refreshBtnBoxClass={styles.refreshBtnBox}
                />
              </ShouldRender>
            </div>
          </div>
        </ContentHeadingBox>

        <ShouldRender should={isLoading}>
          <div className={styles.loaderWrapper}>
            <Loader size={LOADER_SIZE} color="#077186" />
          </div>
        </ShouldRender>

        <ShouldRender should={!isLoading && !statisticsData}>
          <h2 className={styles.noReport}>No data available at the moment</h2>
        </ShouldRender>

        <ShouldRender should={shouldRenderStatistics}>
          <React.Fragment>
            <Benefits
              currency={currency}
              activeInvestments={statisticsData?.value_active ?? 0}
              totalInvestments={statisticsData?.value_total ?? 0}
              activeLoans={statisticsData?.no_active ?? 0}
              totalLoans={statisticsData?.no_total ?? 0}
              totalInterest={statisticsData?.interest_payment ?? 0}
            />

            <Widgets
              currency={currency}
              cumulativeReturn={statisticsData?.cumulative_return ?? 0}
              annualReturn={statisticsData?.annual_return ?? 0}
              investmentByCountry={statisticsData?.loan_investment_by_country}
              uninvestedAmount={statisticsData?.uninvested_amount ?? 0}
              extendedIrr={statisticsData?.extended_irr ?? 0}
            />

            <PortfolioStructure
              chartData={portfolioStructureChartData}
              toggleOptions={structureOptions}
              portfolioStructureToggle={portfolioStructureToggle}
              onPortfolioStrictureToggleChange={(val) => setPortfolioStructureToggle(val)}
              wrapperClass={styles.portfolioStructureWrapper}
            />
          </React.Fragment>
        </ShouldRender>
      </div>
    </MainLayout>
  );
};

export default DashboardPage;
