import React from 'react';
import isNil from 'lodash/isNil';
import { ToggleButton } from 'src/atoms';
import { STATISTIC_TIME_INTERVALS } from './constants';
import styles from './ChartHeader.module.scss';
import { GroupBy } from 'src/constants/statisticsConstants';

type TOptions = { value: GroupBy; label: string }[];

interface IChartHeaderProps {
  title: string;
  type?: string;
  onTypeChange?: React.Dispatch<React.SetStateAction<GroupBy>> | ((type: GroupBy | string) => void);
  options?: TOptions;
}

const ChartHeader = ({ title, onTypeChange, type, options }: IChartHeaderProps): JSX.Element => {
  const hasToggleButton = !isNil(type) && onTypeChange;

  return (
    <div>
      <div className={styles.rollingReturnHeader}>
        <h2>{title}</h2>
      </div>
      <div className={styles.headerToggleWrapper}>
        {hasToggleButton ? (
          <ToggleButton value={type} options={options ?? STATISTIC_TIME_INTERVALS} onChange={onTypeChange} />
        ) : null}
      </div>
    </div>
  );
};

export default ChartHeader;
