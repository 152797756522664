import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UserRoles } from 'src/constants/userConstants';
import { ASIDE_MENU_LINKS, ASIDE_MENU_OPERATIONS_LINKS } from 'src/constants/routes';
import { useUserValues } from 'src/hooks';
import { Drawer } from './Drawer';
import SideMenuOption from './SideMenuOption';
import styles from './AsideNavigationMenu.module.scss';
import { PAGES } from 'src/constants/pages';

interface AsideMenuProps {
  isOpen: boolean;
}

const AsideNavigationMenu = ({ isOpen }: AsideMenuProps): JSX.Element => {
  const {
    location: { pathname }
  } = useHistory();

  const { userRole } = useUserValues();

  const sideMenuLinks = useMemo(() => {
    switch (userRole) {
      case UserRoles.ADMIN:
      case UserRoles.INVESTOR:
        return ASIDE_MENU_LINKS;

      case UserRoles.OPERATIONS:
        return ASIDE_MENU_OPERATIONS_LINKS;

      default:
        return ASIDE_MENU_LINKS;
    }
  }, [userRole]);

  const onCheckIsActiveLink = (pathname: string, linkOptionPath: string) => {
    return linkOptionPath === PAGES.INVESTMENTS && pathname.includes(PAGES.OUTSTANDING_BIDS);
  };

  return (
    <Drawer variant="permanent" open={isOpen}>
      <ul className={styles.menuList}>
        {sideMenuLinks.map(({ path, ...rest }) => {
          const { title: popoverTitle } = sideMenuLinks.find((links) => links.path === path);

          const isActive = onCheckIsActiveLink(pathname, path);
          return (
            <SideMenuOption
              key={path}
              path={path}
              isAsideOpen={isOpen}
              popoverTitle={popoverTitle}
              isActive={isActive}
              {...rest}
            />
          );
        })}
      </ul>
    </Drawer>
  );
};

export default AsideNavigationMenu;
