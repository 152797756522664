import { call, put, ForkEffect, takeEvery, select } from 'redux-saga/effects';
import { marketplaceTypes } from '../types';
import * as marketplaceActions from '../actions/marketplaceActions';
import * as apiHelpers from './sagaHelpers/marketplaceApiHelpers';
import * as Shapes from 'src/interfaces/marketplaceReduxShapes';
import { marketplaceReqParamsSelector } from '../selectors';
import { callbackResolver } from 'src/utils/reduxHelpers';

function* getLoansWorker({ payload }: Shapes.GetMarketplaceLoansAction) {
  try {
    const {
      data: { data }
    } = yield call(apiHelpers.getMarketplaceLoansHelper, payload.reqParams);

    const { page }: Shapes.GetMarketplaceLoansParams = yield select(marketplaceReqParamsSelector);

    if (payload.reqParams.page > page) {
      yield put(marketplaceActions.putLoadMoreMarketplaceLoans({ data, reqParams: payload.reqParams }));
      callbackResolver(payload?.onSuccess);

      return;
    }

    yield put(marketplaceActions.putMarketplaceLoans({ data, reqParams: payload.reqParams }));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getLoansWorker', error);
  }
}

export function* marketplaceWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(marketplaceTypes.GET_MARKETPLACE_LOANS, getLoansWorker);
}
