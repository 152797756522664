/* eslint-disable no-case-declarations */
import React from 'react';
import cn from 'classnames';
import { IPortfolioProfitability } from 'src/interfaces';
import { ExaloanRatings } from 'src/constants/globalConstants';
import { formatMoney, formatPercentage } from 'src/helpers';
import { PortfolioProfitabilityColIds } from './constants';
import styles from './PortfolioProfitability.module.scss';

const { SCORE, INVESTED_AMOUNT, INTEREST_RATE, DEFAULT_AMOUNT, DEFAULT_RATE, NET_RETURN, NET_RETURN_PERCENTAGE } =
  PortfolioProfitabilityColIds;

export const getRowContent = (colId: string, row: IPortfolioProfitability, currency: string): JSX.Element => {
  const value = row[colId];

  switch (colId) {
    case SCORE:
      const scoreClassName = cn({
        [styles.scoreA]: value === ExaloanRatings.AA,
        [styles.scoreA]: value === ExaloanRatings.A,
        [styles.scoreB]: value === ExaloanRatings.B,
        [styles.scoreC]: value === ExaloanRatings.C,
        [styles.scoreD]: value === ExaloanRatings.D,
        [styles.scoreE]: value === ExaloanRatings.E,
        [styles.scoreF]: value === ExaloanRatings.F
      });

      return <span className={scoreClassName}>{value}</span>;

    case INVESTED_AMOUNT:
    case DEFAULT_AMOUNT:
    case NET_RETURN:
      return <span>{formatMoney(value, currency)}</span>;

    case INTEREST_RATE:
    case DEFAULT_RATE:
    case NET_RETURN_PERCENTAGE:
      return <span>{formatPercentage(value)}</span>;

    default:
      return <span>{value}</span>;
  }
};
