import parseJSON from 'date-fns/parseJSON';
import { formatInTimeZone, OptionsWithTZ } from 'date-fns-tz';
import { UTC_0_TIMEZONE } from 'src/constants/globalConstants';

export const formatDateFromJSON = (
  date: Date | string,
  formatValue: string,
  errorStr?: string,
  options?: OptionsWithTZ
): string => {
  try {
    return formatInTimeZone(parseJSON(date), UTC_0_TIMEZONE, formatValue, options ?? {});
  } catch (e) {
    return errorStr ?? '';
  }
};
