import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout, ButtonNext, CustomizedTable, ContentHeadingBox, ContentBodyBox } from 'src/components';
import plusIcon from 'src/assets/images/icons/new_allocation_plus.svg';
import * as actions from 'src/redux/actions/portfolioBuilderActions';
import { getTotalOpportunities } from 'src/redux/actions/investmentsActions';
import { onPresetAllocationId } from 'src/redux/actions/baseActions';
import { portfolioAllocationsDataSelector as allocationsDataSelector, baseReducerSelector } from 'src/redux/selectors';
import { getErrorMessage } from 'src/helpers';
import {
  SortTypes,
  PORTFOLIO_TABLE_HEADINGS_LIST,
  PortfolioBuilderColIDs
} from 'src/constants/portfolioBuilderConstants';
import { PAGES, DYNAMIC_PAGES } from 'src/constants/pages';
import { SortOrders } from 'src/constants/globalConstants';
import { initialParams } from './constants';
import { DeleteAllocationModal, DeleteAllocationError } from './components';
import { renderTableContent } from './helpers';
import styles from './PortfolioBuilderHomePage.module.scss';

const PortfolioBuilderHomePage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { allocations } = useSelector(allocationsDataSelector);
  const { isAsideMenuOpen } = useSelector(baseReducerSelector);

  const handleClickNewAllocation = () => push(PAGES.NEW_ALLOCATION);

  const [allocationsReqParams, setAllocationsReqParams] = useState(initialParams);

  const [isTableLoading, setTableLoading] = useState(false);

  useMemo(() => {
    setTableLoading(true);
    dispatch(actions.getAllocations(allocationsReqParams, () => setTableLoading(false)));
  }, [dispatch, allocationsReqParams]);

  const getAllocationsHelper = useCallback(
    (onSuccessCallback?: () => void) => {
      dispatch(actions.getAllocations(allocationsReqParams, onSuccessCallback));
    },
    [dispatch, allocationsReqParams]
  );

  const getOpportunitiesHelper = useCallback(() => {
    dispatch(getTotalOpportunities());
  }, [dispatch]);

  const [deleteError, setDeleteError] = useState<null | string>(null);
  const handleDeleteCurrentAllocation = useCallback(
    (id: string) => {
      dispatch(
        actions.deleteCurrentAllocation(
          id,
          () => {
            setDeleteModalOpen(false);
            getAllocationsHelper();
            getOpportunitiesHelper();
          },
          (err) => {
            setDeleteModalOpen(true);
            setDeleteError(getErrorMessage(err) as string);
          }
        )
      );
    },
    [dispatch, getAllocationsHelper, getOpportunitiesHelper]
  );

  const handleViewAllocation = useCallback(
    (id: string) => {
      dispatch(actions.getAllocationDetails(id, () => push(DYNAMIC_PAGES.VIEW_ALLOCATION(id))));
    },
    [dispatch, push]
  );

  const handleGoToInvestment = useCallback(
    (id: string) => {
      dispatch(onPresetAllocationId(id));
      push(PAGES.INVESTMENTS);
    },
    [dispatch, push]
  );

  const handleStartAllocation = useCallback(
    (id: string) => {
      dispatch(
        actions.startInvestingAllocation(id, () => {
          getAllocationsHelper();
          getOpportunitiesHelper();
        })
      );
    },
    [dispatch, getAllocationsHelper, getOpportunitiesHelper]
  );

  const handleStopAllocation = useCallback(
    (id: string) => {
      dispatch(
        actions.stopInvestingAllocation(id, () => {
          getAllocationsHelper();
          getOpportunitiesHelper();
        })
      );
    },
    [dispatch, getAllocationsHelper, getOpportunitiesHelper]
  );

  const handleSortTable = (type: SortTypes, order: SortOrders) => {
    setAllocationsReqParams((prev) => ({ ...prev, sort: { type, order } }));
  };

  const [deleteAllocation, setDeleteAllocation] = useState(null);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const onToggleDeleteModal = (allocation?) => {
    if (allocation) setDeleteAllocation(allocation);

    setDeleteModalOpen((prev) => !prev);
  };

  const getSummaryContent = useCallback(
    (colId, row) => {
      return renderTableContent(
        colId,
        row,
        handleStartAllocation,
        handleStopAllocation,
        handleViewAllocation,
        handleGoToInvestment,
        onToggleDeleteModal
      );
    },
    [handleStartAllocation, handleStopAllocation, handleViewAllocation, handleGoToInvestment]
  );

  const [isMaxAllocationsError, setMaxAllocationsError] = useState(false);

  const showDeleteModal = isDeleteModalOpen && !deleteError;
  const showDeleteError = isDeleteModalOpen && !!deleteError;
  const isMaxAllocations = allocations?.length >= 100;
  return (
    <MainLayout isEmptySkeleton>
      <div className={styles.contentWrapper}>
        <ContentHeadingBox>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>Portfolio Allocations</h2>
            <ButtonNext
              label="New allocation"
              onClick={isMaxAllocations ? () => setMaxAllocationsError(true) : handleClickNewAllocation}
              beforeImg={plusIcon}
              btnClass={styles.newAllocationBtn}
              beforeImgClass={styles.newAllocationPlusIcon}
              type="button"
            />
          </div>
        </ContentHeadingBox>

        <ContentBodyBox>
          <CustomizedTable
            columns={PORTFOLIO_TABLE_HEADINGS_LIST}
            rows={allocations}
            onSort={handleSortTable}
            isLoading={isTableLoading}
            guttersRight={45}
            guttersLeft={40}
            getSummaryContent={getSummaryContent}
            hasDetails={false}
            tContainerClass={styles.tContainerClass}
            emptyRowsMsg={
              <span className={styles.notFound} onClick={handleClickNewAllocation}>
                There are no Allocations yet. Create Allocation
              </span>
            }
            onSetSortDropdownClass={(id) => (id === PortfolioBuilderColIDs.NAME ? styles.nameSortDropdown : null)}
          />
        </ContentBodyBox>

        <DeleteAllocationModal
          isDeleteModalOpen={showDeleteModal}
          isAsideMenuOpen={isAsideMenuOpen}
          onToggleModal={onToggleDeleteModal}
          onDeleteAllocation={() => handleDeleteCurrentAllocation(deleteAllocation?.id)}
          name={deleteAllocation?.name ?? ''}
        />

        <DeleteAllocationError
          isOpen={showDeleteError || isMaxAllocationsError}
          deleteErrorMsg={deleteError}
          isMaxError={isMaxAllocationsError}
          onClose={() => {
            setDeleteModalOpen(false);
            setDeleteError(null);
            setMaxAllocationsError(false);
          }}
        />
      </div>
    </MainLayout>
  );
};

export default PortfolioBuilderHomePage;
