import { investmentsTypes } from '../types';
import * as Shapes from 'src/interfaces/investmentsReduxShapes';

export const getInvestments = (
  reqParams: Shapes.GetInvestmentsLoansParams,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.GetInvestmentsLoansAction => ({
  type: investmentsTypes.GET_INVESTMENTS_LOANS,
  payload: { reqParams, onSuccess, onError }
});

export const putInvestmentsLoans = (
  data: Shapes.InvestmentLoansData,
  page: number
): Shapes.PutInvestmentsLoansAction => ({
  type: investmentsTypes.PUT_INVESTMENTS_LOANS_DATA,
  payload: { data, page }
});

export const putMoreInvestmentsLoans = (
  data: Shapes.InvestmentLoansData,
  page: number
): Shapes.PutInvestmentsLoansAction => ({
  type: investmentsTypes.PUT_MORE_INVESTMENTS_LOANS_DATA,
  payload: { data, page }
});

export const putInvestmentsCachedParams = (
  params: Shapes.GetInvestmentsLoansParams
): Shapes.PutInvestmentsCachedParams => ({
  type: investmentsTypes.PUT_INVESTMENTS_CACHED_PARAMS,
  payload: params
});

export const getInvestmentsFilters = (
  withOpportunities?: boolean,
  onFinally?: () => void
): Shapes.GetInvestmentsFiltersAction => ({
  type: investmentsTypes.GET_INVESTMENTS_FILTERS,
  payload: { withOpportunities, onFinally }
});

export const putInvestmentsFilters = (data: Shapes.InvestmentsFiltersShape): Shapes.PutInvestmentsFiltersAction => ({
  type: investmentsTypes.PUT_INVESTMENTS_FILTERS,
  payload: data
});

export const getTotalOpportunities = (): { type: string } => ({
  type: investmentsTypes.GET_TOTAL_OPPORTUNITIES
});

export const putTotalOpportunities = (data): Shapes.PutInvestmentsTotalOpportunitiesAction => ({
  type: investmentsTypes.PUT_TOTAL_OPPORTUNITIES,
  payload: data
});

export const getFilteredOpportunities = (
  reqParams: Shapes.GetOpportunitiesParams,
  onSuccess?: () => void
): Shapes.OpportunitiesAction => ({
  type: investmentsTypes.GET_FILTERED_OPPORTUNITIES,
  payload: { reqParams, onSuccess }
});

export const putOpportunities = (data): Shapes.OpportunitiesAction => ({
  type: investmentsTypes.PUT_OPPORTUNITIES,
  payload: data
});

export const putMoreOpportunities = (data): Shapes.OpportunitiesAction => ({
  type: investmentsTypes.PUT_MORE_OPPORTUNITIES,
  payload: data
});

export const bidOpportunities = (
  data: Shapes.IBidOpportunities,
  onSuccess?: (data: Shapes.BidOpportunitiesReturnType) => void
): Shapes.IBidOpportunitiesAction => ({
  type: investmentsTypes.BID_OPPORTUNITIES,
  payload: { data, onSuccess }
});

export const getInvestmentHistory = (
  id: string,
  onSuccess: (data: Shapes.InvestmentHistory[]) => void
): Shapes.GetInvestmentHistory => ({
  type: investmentsTypes.GET_INVESTMENT_HISTORY,
  payload: { id, onSuccess }
});

export const getOutstandingBids = (
  reqParams: Shapes.GetInvestmentsLoansParams,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.GetInvestmentsLoansAction => ({
  type: investmentsTypes.GET_OUTSTANDING_BIDS,
  payload: { reqParams, onSuccess, onError }
});

export const putOutstandingBids = (
  data: Shapes.InvestmentLoansData,
  page: number
): Shapes.PutInvestmentsLoansAction => ({
  type: investmentsTypes.PUT_OUTSTANDING_BIDS,
  payload: { data, page }
});

export const putMoreOutstandingBids = (
  data: Shapes.InvestmentLoansData,
  page: number
): Shapes.PutInvestmentsLoansAction => ({
  type: investmentsTypes.PUT_MORE_OUTSTANDING_BIDS,
  payload: { data, page }
});

export const getInvestmentRepayments = (
  reqData: Shapes.IGetInvestmentRepaymentsReq,
  onSuccess: (data: Shapes.ICashflowData[]) => void,
  onError: (error) => void
): Shapes.IGetInvestmentRepayments => ({
  type: investmentsTypes.GET_INVESTMENT_REPAYMENTS,
  payload: { reqData, onSuccess, onError }
});

export const getEntityDetails = (payload: Shapes.GetEntityDetailsReq): Shapes.GetEntityDetails => ({
  type: investmentsTypes.GET_ENTITY_DETAILS,
  payload
});
