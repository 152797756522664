import { SortTypes } from 'src/constants/portfolioBuilderConstants';
import { SortOrders } from 'src/constants/globalConstants';

export const initialParams = {
  page: 1,
  sort: {
    type: SortTypes.CREATED,
    order: SortOrders.DESC
  }
};
