import xlsxIcon from 'src/assets/images/icons/export_file_types/xlsx.svg';
import csvIcon from 'src/assets/images/icons/export_file_types/csv.svg';

export enum ExportFileTypes {
  XLS = 'xls',
  CSV = 'csv',
  PDF = 'pdf',
  JSON = 'json',
  XLSX = 'xlsx'
}

export const EXPORT_FILE_ICONS = {
  [ExportFileTypes.CSV]: csvIcon,
  [ExportFileTypes.XLSX]: xlsxIcon
};

export const FILE_TYPES = [
  {
    value: ExportFileTypes.CSV,
    label: 'csv'
  },
  {
    value: ExportFileTypes.XLSX,
    label: 'xlsx'
  }
];

export type FiletypeSelectPayload = { value: ExportFileTypes; label: string };
