import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { StatementBox } from 'src/components';
import { LoanTypes } from 'src/constants/globalConstants';
import { TypeBlockValues, FieldNames } from '../../model';
import styles from './TypeBlock.module.scss';
import {
  AmountBlock,
  AutoInvestBlock,
  MaxBidSizeBlock,
  ReinvestingStrategy,
  SelectTypeBlock,
  StructureEndDate
} from './components';

type Props = {
  isViewMode: boolean;
  isEditPage: boolean;
  isAutoApproval: boolean;
  onToggleAuthApproval: () => void;
  onSetAllocationType: React.Dispatch<React.SetStateAction<LoanTypes>>;
  wrapperClass?: string;
};

const TypeBlock: FC<Props> = ({
  isViewMode,
  isEditPage,
  isAutoApproval,
  onToggleAuthApproval,
  onSetAllocationType,
  wrapperClass
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<TypeBlockValues>();

  useEffect(() => {
    if (values.loanType?.value) onSetAllocationType(values.loanType?.value);
  }, [values.loanType?.value, onSetAllocationType]);

  return (
    <StatementBox className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <div className={styles.fieldsBlock}>
        <AmountBlock
          isViewMode={isViewMode}
          isEditMode={isEditPage}
          currencyValue={values.currency}
          amountValue={values.amount}
          onCurrencyChange={(value) => setFieldValue(FieldNames.CURRENCY, value)}
          onCurrencyBlur={() => setFieldTouched(FieldNames.CURRENCY, true)}
        />

        <MaxBidSizeBlock
          isViewMode={isViewMode}
          isEditMode={isEditPage}
          currencyValue={values.currency}
          maxBixSizeValue={values.maxBidSize}
        />

        <SelectTypeBlock
          isViewMode={isViewMode}
          isEditMode={isEditPage}
          value={values.loanType}
          onChange={(value) => setFieldValue(FieldNames.LOAN_TYPE, value)}
          onBlur={() => setFieldTouched(FieldNames.LOAN_TYPE, true)}
        />
      </div>

      <div className={styles.fieldsBlock}>
        <AutoInvestBlock
          isViewMode={isViewMode}
          isAutoApproval={isAutoApproval}
          onToggleAuthApproval={onToggleAuthApproval}
        />

        <ReinvestingStrategy
          isViewMode={isViewMode}
          value={values.reinvestmentStrategy}
          onChange={(value) => setFieldValue(FieldNames.REINVESTMENT_STRATEGY, value)}
          onBlur={() => setFieldTouched(FieldNames.REINVESTMENT_STRATEGY, true)}
        />

        <StructureEndDate
          isViewMode={isViewMode}
          value={values.structureEndDate}
          onChange={(value) => setFieldValue(FieldNames.STRUCTURE_END_DATE, value)}
          onBlur={() => setFieldTouched(FieldNames.STRUCTURE_END_DATE, true)}
        />
      </div>
    </StatementBox>
  );
};

export default TypeBlock;
