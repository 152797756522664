import React, { FC } from 'react';
import { StatementBox } from 'src/components';
import { ConstraintsTitle } from '../atoms';
import { CriteriaRow } from '../..';
import { PortfolioLoanType } from '../../../model';
import styles from './SMESectors.module.scss';

type Props = {
  isViewPage: boolean;
  sectors: PortfolioLoanType[];
  onChange: (id: string, value: number) => void;
};

const SMESectors: FC<Props> = ({ isViewPage, sectors, onChange }) => {
  return (
    <StatementBox className={styles.box}>
      <ConstraintsTitle title="SME Sectors" />

      <div className={styles.content}>
        {sectors?.map(({ id, name, percentage }) => (
          <CriteriaRow
            key={id}
            label={name}
            value={percentage}
            onChange={(value: number) => onChange(id, value)}
            readOnly={isViewPage}
          />
        ))}
      </div>
    </StatementBox>
  );
};

export default SMESectors;
