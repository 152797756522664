/* eslint-disable no-case-declarations */
import React from 'react';
import { InvestmentRequestParams } from 'src/pages/InvestmentsPage/model';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { startOfDay, endOfDay, formatISO } from 'date-fns';
import { stringify } from 'query-string';
import NumberFormat from 'react-number-format';
import { CircleFlag } from 'react-circle-flags';
import { PlatformShape, GradeShape } from 'src/interfaces/baseReduxShapes';
import { InvestmentsLoans, GetInvestmentsLoansParams, InvestmentsColIDs } from 'src/interfaces/investmentsReduxShapes';
import { formatDateFromJSON } from 'src/helpers/dateHelpers';
import { currencySymbols, ExaloanRatings, LOAN_TABLE_DATE_FORMAT, SortOrders } from 'src/constants/globalConstants';
import { Provider, ProviderIds } from 'src/constants/marketplaceConstants';
import { getRelativeValuesList } from 'src/constants/relativeValueFilters';
import { formatTitle, getCountryCode } from 'src/helpers';
import {
  INITIAL_DATE_PICKER_FROM,
  INITIAL_DATE_PICKER_TO,
  INVESTMENT_HISTORY_LABELS,
  OutstandingBidStatuses
} from 'src/constants/investmentsConstants';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import {
  LoanTypeShorts,
  outstandingBidLabels,
  investmentsStatuses,
  outstandingBidStatuses,
  InvestmentsCheckboxEnum,
  SUCCESSFUL_BID_STATUSES
} from '../constants';
import styles from './InvestmentContent.module.scss';

export const renderSummaryContent = (
  id: InvestmentsColIDs,
  row: InvestmentsLoans,
  currency: string,
  isBidsPage: boolean
): JSX.Element => {
  switch (id) {
    case InvestmentsColIDs.ID:
      return (
        <div className={styles.flagWrapper}>
          {row?.loan_country && (
            <CircleFlag
              countryCode={
                row?.platform === ProviderIds.EXALENDER
                  ? 'eu'
                  : getCountryCode(row?.loan_country?.toLowerCase())?.toLowerCase()
              }
              onLoad={(e) => e.currentTarget.removeAttribute('title')}
              className="img-fluid"
            />
          )}
          <span className={cn(styles.textInfo, styles.platformText)}> {row.uniq}</span>
        </div>
      );

    case InvestmentsColIDs.BIDDING_DATE:
      return <span className={styles.textInfo}>{formatDateFromJSON(row?.bidding_date, LOAN_TABLE_DATE_FORMAT)}</span>;

    case InvestmentsColIDs.FUNDED_DATE:
      return <span className={styles.textInfo}>{formatDateFromJSON(row?.funded_date, LOAN_TABLE_DATE_FORMAT)}</span>;

    case InvestmentsColIDs.MATURITY_DATE:
      return <span className={styles.textInfo}>{formatDateFromJSON(row?.maturity_date, LOAN_TABLE_DATE_FORMAT)}</span>;

    case InvestmentsColIDs.INVESTMENT_AMOUNT:
      return (
        <div className={styles.amountInvestedAvailable}>
          <NumberFormat
            thousandSeparator
            type="text"
            displayType="text"
            prefix={`${currencySymbols[currency]} `}
            value={row?.invested_amount?.toFixed(2)}
            renderText={(value) => <span className={styles.textInfo}>{`${value?.trim() ? value : '-'}\u00A0`}</span>}
          />

          <NumberFormat
            thousandSeparator
            type="text"
            displayType="text"
            prefix={`${currencySymbols[currency]} `}
            value={(isBidsPage ? row?.loan_amount : row?.latest_principal_outstanding)?.toFixed(2)}
            renderText={(value) => <span className={styles.textInfo}>{`(${value.trim() ? value : '-'})`}</span>}
          />
        </div>
      );

    case InvestmentsColIDs.INTEREST_RATE:
      return <span className={styles.textInfo}>{(row.borrower_rate * 100).toFixed(2)}%</span>;

    case InvestmentsColIDs.GRADE:
      const scoreClassName = cn(styles.textInfo, {
        [styles.scoreA]: row.loan_score === ExaloanRatings.A,
        [styles.scoreB]: row.loan_score === ExaloanRatings.B,
        [styles.scoreC]: row.loan_score === ExaloanRatings.C,
        [styles.scoreD]: row.loan_score === ExaloanRatings.D,
        [styles.scoreE]: row.loan_score === ExaloanRatings.E,
        [styles.scoreF]: row.loan_score === ExaloanRatings.F
      });

      return <span className={scoreClassName}>{row.loan_score}</span>;

    case InvestmentsColIDs.RELATIVE_VALUE:
      return <span className={cn(styles.textInfo, styles.relativeValue)}>{row?.relative_value ?? ''}</span>;

    case InvestmentsColIDs.TERM:
      return <span className={styles.textInfo}>{Math.round(row.loan_term * 100) / 100}</span>;

    case InvestmentsColIDs.LOAN_TYPE:
      return <span className={styles.textInfo}>{LoanTypeShorts[row.type]}</span>;

    case InvestmentsColIDs.STATUS:
      return (
        <span className={styles.textInfo}>
          {isBidsPage
            ? INVESTMENT_HISTORY_LABELS?.[row.bid_statuses] ?? outstandingBidLabels?.[row.bid_statuses]
            : formatTitle(row.loan_status)}
        </span>
      );

    default:
      return <></>;
  }
};

export const getInvestmentFilterBlockLabels = (isBidPage: boolean): Record<InvestmentsCheckboxEnum, string> => ({
  [InvestmentsCheckboxEnum.STATUS]: isBidPage ? 'Bid Status' : 'Investment Status',
  [InvestmentsCheckboxEnum.RELATIVE_VALUE]: 'Relative Value',
  [InvestmentsCheckboxEnum.PLATFORMS]: 'Platform',
  [InvestmentsCheckboxEnum.GRADES]: 'Exaloan Score'
});

type IFilterBlock = {
  id: InvestmentsCheckboxEnum;
  blockLabel: string;
  isAllChecked: boolean;
  list: { id: string; name: string; isChecked: boolean }[];
};

export const createInvestmentFiltersArray = (
  grades: GradeShape[],
  platforms: PlatformShape[],
  isBidsPage: boolean
): IFilterBlock[] => {
  const statuses = (isBidsPage ? outstandingBidStatuses : investmentsStatuses).map((status) => {
    return {
      ...status,
      isChecked: isBidsPage ? SUCCESSFUL_BID_STATUSES.includes(status?.id) : true
    };
  });

  const filters = [
    {
      label: InvestmentsCheckboxEnum.PLATFORMS,
      list: platforms.map((item) => ({ ...item, name: Provider[item.name], isChecked: true }))
    },
    {
      label: InvestmentsCheckboxEnum.GRADES,
      list: grades.map((item) => ({ ...item, isChecked: true }))
    },
    {
      label: InvestmentsCheckboxEnum.STATUS,
      list: statuses
    },
    {
      label: InvestmentsCheckboxEnum.RELATIVE_VALUE,
      list: getRelativeValuesList().map((item) => ({ ...item, isChecked: true }))
    }
  ];

  return filters.map((filter) => ({
    id: filter.label,
    blockLabel: getInvestmentFilterBlockLabels(isBidsPage)[filter.label],
    isAllChecked: filter.list.every(({ isChecked }) => isChecked),
    list: filter.list
  }));
};

export const getFiltersRequestObject = (filtersArray) =>
  filtersArray.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.list.reduce((acc, listItem) => {
        switch (item.id) {
          case InvestmentsCheckboxEnum.STATUS:
            return listItem.isChecked ? [...acc, listItem.id] : acc;

          case InvestmentsCheckboxEnum.RELATIVE_VALUE:
            return listItem.isChecked ? [...acc, listItem.value] : acc;

          case InvestmentsCheckboxEnum.GRADES:
            return listItem.isChecked ? [...acc, listItem.name] : acc;

          case InvestmentsCheckboxEnum.PLATFORMS:
            return listItem.isChecked ? [...acc, listItem?.name?.toUpperCase()] : acc;

          default:
            return acc;
        }
      }, [])
    }),
    {}
  );

export const getStatuses = (isBidsPage: boolean) => {
  return (isBidsPage ? outstandingBidStatuses : investmentsStatuses).map((status) => status?.id);
};

export const getExportInvestmentsUrl = (
  reqParams: GetInvestmentsLoansParams,
  currency: string,
  fileType: ExportFileTypes,
  isBidsPage: boolean
): string => {
  const query = {
    currency,
    file_type: fileType,
    allocation_ids: reqParams.allocations,
    [isBidsPage ? 'executed_date_from' : 'funding_date_from']: formatISO(startOfDay(reqParams.date.startDate)),
    [isBidsPage ? 'executed_date_end' : 'funding_date_end']: formatISO(endOfDay(reqParams.date.endDate))
  };

  return `export/${isBidsPage ? 'bids' : 'investments'}?${stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  })}`;
};

export const getExportInvestmentsFileName = (isBidsPage: boolean): string => {
  return `Exaloan ${isBidsPage ? 'outstanding bids' : 'investments'} ${formatDateFromJSON(new Date(), 'MM/dd/yyyy')}`;
};

export const GET_INVESTMENT_LOANS_DEFAULT_PARAMS = (
  allocations?: string[],
  statuses?: string[],
  isBidsPage?: boolean
): InvestmentRequestParams => {
  const defaultParams: InvestmentRequestParams = {
    page: 1,
    limit: 100,
    date: { startDate: INITIAL_DATE_PICKER_FROM, endDate: INITIAL_DATE_PICKER_TO },
    sort: isBidsPage ? null : { type: InvestmentsColIDs.FUNDED_DATE, order: SortOrders.DESC },
    relative: []
  };

  if (!isEmpty(allocations)) Object.assign(defaultParams, { allocations });

  if (!isEmpty(statuses)) {
    Object.assign(defaultParams, {
      statuses: isBidsPage
        ? statuses?.filter((status) => SUCCESSFUL_BID_STATUSES?.includes(status as OutstandingBidStatuses))
        : statuses
    });
  }

  return defaultParams;
};
