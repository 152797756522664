import React, { FC } from 'react';
import cn from 'classnames';
import { SingleSlider, NumericInput } from 'src/components';
import styles from './CriteriaRow.module.scss';

type Props = {
  readOnly: boolean;
  label: string | React.ReactNode;
  value: number;
  onChange: (value: number) => void;
  defaultValue?: number;
  wrapperClass?: string;
  labelClass?: string;
  inputBlockClass?: string;
  inputClass?: string;
  sliderBoxClass?: string;
};

const CriteriaRow: FC<Props> = ({
  readOnly,
  label,
  value = 0,
  onChange,
  defaultValue = 0,
  wrapperClass,
  labelClass,
  inputBlockClass,
  inputClass,
  sliderBoxClass
}) => {
  return (
    <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>
      <div className={cn(styles.inputBlock, { [labelClass]: labelClass })}>
        <div className={cn(styles.label, { [inputBlockClass]: inputBlockClass })}>{label}</div>

        <NumericInput
          isPercentageField
          readOnly={readOnly}
          value={value}
          onChanged={(value) => (readOnly ? null : onChange(+value))}
          className={cn(styles.input, { [styles.inputReadOnly]: readOnly, [inputClass]: inputClass })}
          suffix="%"
        />
      </div>

      <div className={cn(styles.sliderBox, { [sliderBoxClass]: sliderBoxClass })}>
        <SingleSlider
          value={value}
          defaultValue={defaultValue}
          onChange={(_: Event, value: number | Array<number>) => (readOnly ? null : onChange(value as number))}
          className={cn({ [styles.sliderReadOnly]: readOnly })}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default CriteriaRow;
