import React, { FC } from 'react';
import cn from 'classnames';
import { Typography } from 'src/components';
import styles from './BlockTitle.module.scss';

type Props = {
  children: React.ReactNode;
  variant?: 'lg' | 'md' | 'sm';
  className?: string;
};

const BlockTitle: FC<Props> = ({ children, variant = 'lg', className }) => {
  return <Typography className={cn(styles.title, styles[variant], { [className]: className })}>{children}</Typography>;
};

export default BlockTitle;
