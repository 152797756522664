import React from 'react';
import StatementBox from 'src/atoms/StatementBox/StatementBox';
import styles from './StatisticWidget.module.scss';

interface IWidgetSlot {
  title: string;
  text: string;
}

interface IStatisticWidget {
  title: string;
  slots: IWidgetSlot[];
}

interface IStatisticWidgetProps {
  widgets: IStatisticWidget[];
}

const StatisticWidget = ({ widgets }: IStatisticWidgetProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {widgets.map((widget, idx) => (
        <StatementBox key={`${widget.title}_${idx}`} className={styles.statisticWidgetContainer}>
          <div>
            <h2>{widget.title}</h2>
            <div className={styles.statisticWidgetSlots}>
              {widget.slots.map((slot, idx) => {
                return (
                  <div key={`${slot.title}_${idx}`} className={styles.statisticWidgetSlot}>
                    <p className={styles.slotText}>{slot.text}</p>
                    <p>{slot.title}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </StatementBox>
      ))}
    </div>
  );
};

export default StatisticWidget;
