import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import debounce from 'lodash/debounce';
import MuiSlider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { onlyDecimalDigitsRegex } from 'src/helpers/regexHelpers';
import styles from './Slider.module.scss';

import { styled } from '@mui/material/styles';

const StyledSlider = styled(MuiSlider)(({ theme }) => {
  return {
    color: '#077186',
    height: 10,
    padding: '13px 0',

    '& .MuiSlider-thumb': {
      height: 31,
      width: 15,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      boxShadow: 'none',
      borderRadius: '5px',

      '&:hover': {
        boxShadow: '0 0 0 4px rgba(8, 114, 135, 0.16)'
      }
    },

    '& .MuiSlider-track': {
      height: 10
    },

    '& .MuiSlider-rail': {
      color: theme.palette.mode === 'dark' ? '#f3f3f3' : '#f3f3f3',
      opacity: 1,
      height: 10
    }
  };
});

interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent = ({ children, open, value }: ValueLabelProps) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} classes={{ popper: styles.popperHidden }}>
    {children}
  </Tooltip>
);

interface SliderProps {
  id;
  minValue: number;
  maxValue: number;
  label: string;
  step: number;
  valueLabels?: {
    isLabelInputs?: boolean;
    minThousandSeparator?: boolean;
    maxThousandSeparator?: boolean;
    minPrefix?: string;
    maxPrefix?: string;
    minAfter?: string;
    maxAfter?: string;
  };
  onValueChange: (id, value: number[]) => void;
  onSliderChange: (id, value: number[]) => void;
  sliderValue: number[];
  labelsValue?: { min: number | string; max: number | string };
  isDisabled?: boolean;
  isWider?: boolean;
  valueLabelDisplay?: 'auto' | 'off' | 'on';
  decimalScale?: number;
}

enum InputsTypes {
  MIN = 'MIN',
  MAX = 'MAX'
}

const TIMEOUT = 200;
const DECIMAL_SCALE = 2;

const Slider = ({
  minValue,
  maxValue,
  sliderValue,
  labelsValue,
  label,
  valueLabels,
  step,
  id,
  isDisabled,
  onValueChange,
  onSliderChange,
  isWider,
  valueLabelDisplay = 'off',
  decimalScale = DECIMAL_SCALE
}: SliderProps): JSX.Element => {
  const [minSelected, maxSelected] = sliderValue;

  const onLabelInputChange = debounce(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>, type: InputsTypes, prefix?: string, suffix?: string) => {
      let numericValue: string | number = value;

      if (prefix) numericValue = numericValue.replace(prefix, '');
      if (suffix) numericValue = numericValue.replace(suffix, '');

      numericValue = +numericValue.replace(onlyDecimalDigitsRegex, '');

      const isMinField = type === InputsTypes.MIN;
      const newValues = isMinField ? [numericValue, maxSelected] : ([minSelected, numericValue] as number[]);

      onValueChange(id, newValues);
    },
    TIMEOUT
  );

  const hasError = labelsValue.max < labelsValue.min || (labelsValue.min === 0 && labelsValue.max === 0);

  return (
    <div className={styles.sliderBlockWrapper}>
      <h3 className={styles.label}>{label}</h3>

      <div className={styles.sliderWrapper}>
        <StyledSlider
          disabled={isDisabled}
          value={sliderValue}
          onChange={(e, newValue: number | number[]) => onSliderChange(id, newValue as number[])}
          onChangeCommitted={(e, newValue: number | number[]) => onValueChange(id, newValue as number[])}
          valueLabelDisplay={valueLabelDisplay}
          components={{ ValueLabel: ValueLabelComponent }}
          aria-labelledby="range-slider"
          defaultValue={sliderValue}
          min={minValue}
          max={maxValue}
          step={step}
        />
      </div>

      <div className={styles.minMaxWrapper}>
        <NumberFormat
          prefix={valueLabels?.minPrefix}
          displayType={valueLabels?.isLabelInputs ? 'input' : 'text'}
          suffix={valueLabels?.minAfter}
          value={labelsValue.min ?? minSelected}
          thousandSeparator={valueLabels?.minThousandSeparator}
          decimalScale={decimalScale}
          onChange={(e) => onLabelInputChange(e, InputsTypes.MIN, valueLabels?.maxPrefix, valueLabels?.minAfter)}
          type="text"
          className={cn(
            styles.valueLabel,
            { [styles.valueLabelInput]: valueLabels?.isLabelInputs, [styles.valueLabelInputWider]: isWider },
            { [styles.hasError]: hasError }
          )}
        />
        <NumberFormat
          prefix={valueLabels?.maxPrefix}
          displayType={valueLabels?.isLabelInputs ? 'input' : 'text'}
          suffix={valueLabels?.maxAfter}
          value={labelsValue.max ?? maxSelected}
          thousandSeparator={valueLabels?.maxThousandSeparator}
          decimalScale={decimalScale}
          onChange={(e) => onLabelInputChange(e, InputsTypes.MAX, valueLabels?.maxPrefix, valueLabels?.maxAfter)}
          type="text"
          className={cn(
            styles.valueLabel,
            { [styles.valueLabelInput]: valueLabels?.isLabelInputs, [styles.valueLabelInputWider]: isWider },
            { [styles.hasError]: hasError }
          )}
        />
      </div>
    </div>
  );
};

export default Slider;
