import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import dropdownArrow from 'src/assets/images/icons/dropdown_arrow.svg';
import { getStyles } from './getStyles';
import styles from './CountriesSelect.module.scss';

const DropdownArrow = ({ isError }) => (
  <ReactSVG src={dropdownArrow} className={cn(styles.dropdownArrow, { [styles.dropdownError]: isError })} />
);

interface CountriesSelectProps {
  onChange: (field: string, value: { value: string; label: string }, shouldValidate?: boolean | undefined) => void;
  onBlur: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  name: string;
  id: string;
  value: { value: string; label: string };
  placeholder: string;
  isError?: boolean;
  errorMsg?: string | null;
  customClass?: string;
  required?: boolean;
}

const GERMANY_CODE = 'DE';

const CountriesSelect = ({
  onChange,
  onBlur,
  name,
  id,
  value,
  placeholder,
  isError,
  errorMsg,
  customClass,
  required
}: CountriesSelectProps): JSX.Element => {
  const options = useMemo(() => {
    const countries = countryList().getData();

    const germany = countries.find(({ value }) => value === GERMANY_CODE);
    const germanyIdx = countries.findIndex(({ value }) => value === GERMANY_CODE);

    countries.splice(germanyIdx, 1);

    return [germany, ...countries];
  }, []);

  const handleChange = (value) => onChange(name, value);
  const handleBlur = () => onBlur(name, true);

  return (
    <div className={styles.selectWrapper}>
      <Select
        components={{ DropdownIndicator: () => DropdownArrow({ isError }) }}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        id={id}
        value={value}
        placeholder={`${placeholder} ${required ? '*' : ''}`}
        className={cn({ [customClass]: customClass })}
        styles={getStyles(isError)}
      />

      {isError && <span className={styles.errorMessage}>{errorMsg}</span>}
    </div>
  );
};

export default CountriesSelect;
