export const marketplaceTypes = {
  GET_MARKETPLACE_LOANS: 'MARKETPLACE/GET_MARKETPLACE_LOANS',
  PUT_MARKETPLACE_LOANS_DATA: 'MARKETPLACE/PUT_MARKETPLACE_LOANS_DATA',
  PUT_LOAD_MORE_MARKETPLACE_LOANS_DATA: 'MARKETPLACE/PUT_LOAD_MORE_MARKETPLACE_LOANS_DATA',
  EXPORT_LOANS: 'MARKETPLACE/EXPORT_LOANS',
  CLEAR_LOANS: 'MARKETPLACE/CLEAR_LOANS',
  UPDATE_CURRENCY: 'AUTH/UPDATE_CURRENCY'
};

export const authActionTypes = {
  SIGN_IN: 'AUTH/SIGN_IN',
  SIGN_OUT: 'AUTH/SIGN_OUT',
  PUT_USER: 'AUTH/PUT_USER',
  REFRESH_ERROR: 'AUTH/REFRESH_ERROR',
  UNAUTHORIZED_ERROR: 'AUTH/UNAUTHORIZED_ERROR',
  TEMP_SAVE_USER: 'AUTH/TEMP_SAVE_USER',
  SET_AUTHENTICATED: 'AUTH/SET_AUTHENTICATED'
};

export const investmentsTypes = {
  GET_INVESTMENTS_LOANS: 'INVESTMENTS/GET_INVESTMENTS_LOANS',
  PUT_INVESTMENTS_LOANS_DATA: 'INVESTMENTS/PUT_INVESTMENTS_LOANS_DATA',
  PUT_MORE_INVESTMENTS_LOANS_DATA: 'INVESTMENTS/PUT_MORE_INVESTMENTS_LOANS_DATA',
  PUT_INVESTMENTS_CACHED_PARAMS: 'PUT_INVESTMENTS_CACHED_PARAMS',
  GET_LOAN_TYPES: 'INVESTMENTS/GET_LOAN_TYPES',
  GET_INVESTMENTS_FILTERS: 'INVESTMENTS/GET_INVESTMENTS_FILTERS',
  PUT_INVESTMENTS_FILTERS: 'INVESTMENTS/PUT_INVESTMENTS_FILTERS',
  GET_ALLOCATIONS: 'INVESTMENTS/GET_ALLOCATIONS',
  CLEAR_INVESTMENTS: 'INVESTMENTS/CLEAR_INVESTMENTS',
  GET_TOTAL_OPPORTUNITIES: 'INVESTMENTS/GET_TOTAL_OPPORTUNITIES',
  PUT_TOTAL_OPPORTUNITIES: 'INVESTMENTS/PUT_TOTAL_OPPORTUNITIES',
  GET_OPPORTUNITIES: 'INVESTMENTS/GET_OPPORTUNITIES',
  GET_FILTERED_OPPORTUNITIES: 'INVESTMENTS/GET_FILTERED_OPPORTUNITIES',
  PUT_OPPORTUNITIES: 'INVESTMENTS/PUT_OPPORTUNITIES',
  PUT_MORE_OPPORTUNITIES: 'INVESTMENTS/PUT_MORE_OPPORTUNITIES',
  BID_OPPORTUNITIES: 'INVESTMENTS/BID_OPPORTUNITIES',
  CLEAR_OPPORTUNITIES: 'INVESTMENTS/CLEAR_OPPORTUNITIES',
  GET_INVESTMENT_HISTORY: 'INVESTMENTS/GET_INVESTMENT_HISTORY',
  GET_OUTSTANDING_BIDS: 'INVESTMENTS/GET_OUTSTANDING_BIDS',
  PUT_OUTSTANDING_BIDS: 'INVESTMENTS/PUT_OUTSTANDING_BIDS',
  PUT_MORE_OUTSTANDING_BIDS: 'INVESTMENTS/PUT_MORE_OUTSTANDING_BIDS',
  GET_INVESTMENT_REPAYMENTS: 'INVESTMENTS/GET_INVESTMENT_REPAYMENTS',
  CLEAR_CACHED_REQ_PARAMS: 'INVESTMENTS/CLEAR_CACHED_REQ_PARAMS',
  GET_ENTITY_DETAILS: 'INVESTMENTS/GET_ENTITY_DETAILS'
};

export const portfolioBuilderTypes = {
  GET_ALLOCATIONS: 'PORTFOLIO_BUILDER/GET_ALLOCATIONS',
  PUT_ALLOCATIONS: 'PORTFOLIO_BUILDER/PUT_ALLOCATIONS',
  ADD_ALLOCATION: 'PORTFOLIO_BUILDER/ADD_ALLOCATION',
  EDIT_ALLOCATION: 'PORTFOLIO_BUILDER/EDIT_ALLOCATION',
  DELETE_CURRENT_ALLOCATION: 'PORTFOLIO_BUILDER/DELETE_CURRENT_ALLOCATION',
  DELETE_GROUP_ALLOCATIONS: 'PORTFOLIO_BUILDER/DELETE_GROUP_ALLOCATIONS',
  GET_ALLOCATION_DETAILS: 'PORTFOLIO_BUILDER/GET_ALLOCATION_DETAILS',
  PUT_ALLOCATION_DETAILS: 'PORTFOLIO_BUILDER/PUT_ALLOCATION_DETAIL',
  CLEAR_ALLOCATION_DETAILS: 'PORTFOLIO_BUILDER/CLEAR_ALLOCATION_DETAILS',
  START_INVESTING: 'PORTFOLIO_BUILDER/START_INVESTING',
  STOP_INVESTING: 'PORTFOLIO_BUILDER/STOP_INVESTING',
  GET_LOAN_CONSTRAINTS: 'PORTFOLIO_BUILDER/GET_LOAN_CONSTRAINTS',
  PUT_LOAN_CONSTRAINTS: 'PORTFOLIO_BUILDER/PUT_LOAN_CONSTRAINTS',
  CLEAR_LOAN_CONSTRAINTS: 'PORTFOLIO_BUILDER/CLEAR_LOAN_CONSTRAINTS',
  DELETE_ALLOCATION: 'PORTFOLIO_BUILDER/DETELETE_ALLOCATION',
  MODIFY_ALLOCATION: 'PORTFOLIO_BUILDER/MODIFY_ALLOCATION'
};

export const baseTypes = {
  GET_LOAN_TYPES: 'BASE/GET_LOAN_TYPES',
  PUT_LOAN_TYPES: 'BASE/PUT_LOAN_TYPES',
  GET_LOANS_SUMMARY: 'BASE/GET_LOANS_SUMMARY',
  PUT_LOANS_SUMMARY: 'BASE/PUT_LOANS_SUMMARY',
  GET_ALL_LOAN_FILTERS: 'BASE/GET_ALL_LOAN_FILTERS',
  PUT_ALL_LOAN_FILTERS: 'BASE/PUT_ALL_LOAN_FILTERS',
  GET_CURRENCIES: 'BASE/GET_CURRENCIES',
  PUT_CURRENCIES: 'BASE/PUT_CURRENCIES',
  GET_REGIONS: 'BASE/GET_REGIONS',
  PUT_REGIONS: 'BASE/PUT_REGIONS',
  GET_GRADES: 'BASE/GET_GRADES',
  PUT_GRADES: 'BASE/PUT_GRADES',
  GET_PLATFORMS: 'BASE/GET_PLATFORMS',
  PUT_PLATFORMS: 'BASE/PUT_PLATFORMS',
  HANDLE_CHANGE_ASIDE_MENU: 'BASE/HANDLE_CHANGE_ASIDE_MENU',
  GET_CONSUMER_PURPOSES: 'BASE/GET_CONSUMER_PURPOSES',
  PUT_CONSUMER_PURPOSES: 'BASE/PUT_CONSUMER_PURPOSES',
  GET_SME_PURPOSES: 'BASE/GET_SME_PURPOSES',
  PUT_SME_PURPOSES: 'BASE/PUT_SME_PURPOSES',
  GET_SME_SECTORS: 'BASE/GET_SME_SECTORS',
  PUT_SME_SECTORS: 'BASE/PUT_SME_SECTORS',
  SET_PRESELECTED_ALLOCATION_ID: 'BASE/SET_PRESELECTED_ALLOCATION_ID'
};

export const statisticsTypes = {
  GET_STATISTICS: 'STATISTICS/GET_STATISTICS',
  PUT_STATISTICS: 'STATISTICS/PUT_STATISTICS',
  REFRESH_STATISTICS: 'STATISTICS/REFRESH_STATISTICS'
};

export const apiTypes = {
  SET_API_TOKENS: 'API/SET_API_TOKENS',
  GET_API_TOKENS: 'API/GET_API_TOKENS'
};

export const reportTypes = {
  GET_REPORTS: 'REPORTS/GET_REPORTS',
  PUT_REPORTS: 'REPORTS/PUT_REPORTS',

  GET_MORE_REPORTS_BY_PLATFORM: 'REPORTS/GET_MORE_REPORTS_BY_PLATFORM',
  PUT_MORE_REPORTS_BY_PLATFORM: 'REPORTS/PUT_MORE_REPORTS_BY_PLATFORM',

  GET_TRANSACTIONS: 'REPORTS/GET_TRANSACTIONS',
  PUT_TRANSACTIONS: 'REPORTS/PUT_TRANSACTIONS',

  GET_MORE_TRANSACTIONS_BY_PLATFORM: 'REPORTS/GET_MORE_TRANSACTIONS_BY_PLATFORM',
  PUT_MORE_TRANSACTIONS_BY_PLATFORM: 'REPORTS/PUT_MORE_TRANSACTIONS_BY_PLATFORM',

  GET_TRANSACTIONS_AND_REPORTS: 'REPORTS/GET_TRANSACTIONS_AND_REPORTS',
  PUT_TRANSACTIONS_AND_REPORTS: 'REPORTS/PUT_TRANSACTIONS_AND_REPORTS',

  ADD_TRANSACTION: 'REPORTS/ADD_TRANSACTION',
  DELETE_TRANSACTION: 'REPORTS/DELETE_TRANSACTION'
};
