import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { portfolioBuilderTypes } from '../types';
import * as actions from '../actions/portfolioBuilderActions';
import * as apiHelpers from './sagaHelpers/portfolioBuilderApiHelpers';
import * as Shapes from 'src/interfaces/portfolioBuilderReduxShapes';
import { callbackResolver } from 'src/utils/reduxHelpers';

function* getAllocationsWorker({ payload }: Shapes.GetAllocationsAction) {
  try {
    const { data } = yield call(apiHelpers.getAllocationsHelper, payload.reqParams);
    yield put(actions.putAllocationsData(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('getAllocationsWorker', error);
    callbackResolver(payload?.onSuccess, error);
  }
}

function* addAllocationWorker({ payload }: Shapes.AddAllocationAction) {
  try {
    yield call(apiHelpers.addAllocationHelper, payload.allocation);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('addDefaultAllocationWorker', error);
  }
}

function* editAllocationWorker({ payload }: Shapes.EditAllocationAction) {
  try {
    yield call(apiHelpers.editAllocationHelper, payload.allocation, payload.allocationId);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('editDefaultAllocationWorker', error);
  }
}

function* deleteCurrentAllocationWorker({ payload }: Shapes.DeleteCurrentAllocationAction) {
  try {
    yield call(apiHelpers.deleteCurrentAllocationHelper, payload.id);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    if (payload?.onError) payload?.onError(error);
    console.error('deleteCurrentAllocationWorker', error);
  }
}

function* deleteAllocationGroupWorker({ payload }: Shapes.DeleteAllocationGroup) {
  try {
    yield call(apiHelpers.deleteAllocationsGroupHelper, payload.allocationIds);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    if (payload?.onError) payload?.onError(error);
    console.error('deleteAllocationGroupWorker', error);
  }
}

function* getAllocationDetailsWorker({ payload }: Shapes.GetAllocationDetails) {
  try {
    const { data } = yield call(apiHelpers.getAllocationDetailsHelper, payload.id);

    yield put(actions.putAllocationDetails(data.data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('getAllocationDetailsWorker', error);
  }
}

function* startInvestingAllocationWorker({ payload }: Shapes.StartInvestingAllocation) {
  try {
    yield call(apiHelpers.startAllocationHelpers, payload.allocationID);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('startInvestingAllocationWorker', error);
  }
}

function* stopInvestingAllocationWorker({ payload }: Shapes.StopInvestingAllocation) {
  try {
    yield call(apiHelpers.stopAllocationHelpers, payload.allocationID);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('startInvestingAllocationWorker', error);
  }
}

function* getLoanConstrainsWorker({ payload }: Shapes.GetLoanConstraints) {
  try {
    const { data } = yield call(apiHelpers.getLoanConstraintsHelper, payload.reqParams);
    yield put(actions.putLoanConstraints(data?.data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('getLoanConstrainsWorker', error);
  }
}

export function* portfolioBuilderWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(portfolioBuilderTypes.GET_ALLOCATIONS, getAllocationsWorker);
  yield takeLatest(portfolioBuilderTypes.ADD_ALLOCATION, addAllocationWorker);
  yield takeLatest(portfolioBuilderTypes.EDIT_ALLOCATION, editAllocationWorker);
  yield takeLatest(portfolioBuilderTypes.DELETE_CURRENT_ALLOCATION, deleteCurrentAllocationWorker);
  yield takeLatest(portfolioBuilderTypes.DELETE_GROUP_ALLOCATIONS, deleteAllocationGroupWorker);
  yield takeLatest(portfolioBuilderTypes.GET_ALLOCATION_DETAILS, getAllocationDetailsWorker);
  yield takeLatest(portfolioBuilderTypes.START_INVESTING, startInvestingAllocationWorker);
  yield takeLatest(portfolioBuilderTypes.STOP_INVESTING, stopInvestingAllocationWorker);
  yield takeLatest(portfolioBuilderTypes.GET_LOAN_CONSTRAINTS, getLoanConstrainsWorker);
}
