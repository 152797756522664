/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch, useSelector } from 'react-redux';
import { totalOpportunitiesSelector } from '../redux/selectors';
import { useEffect } from 'react';
import { TotalOpportunitiesData } from 'src/interfaces/investmentsReduxShapes';
import { getTotalOpportunities } from '../redux/actions/investmentsActions';

export const getTotalOpportunitiesHook = (): TotalOpportunitiesData => {
  const dispatch = useDispatch();
  const totalOpportunities = useSelector(totalOpportunitiesSelector);

  useEffect(() => {
    dispatch(getTotalOpportunities());
  }, [dispatch]);

  return { totalOpportunities };
};
