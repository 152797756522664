import React, { memo } from 'react';
import truncate from 'lodash/truncate';
import { FormikCustomField as CustomField, StatementBox } from 'src/components';
import styles from './NameBlock.module.scss';
import { FieldNames } from '../../model';

const NAME_TRUNCATE_LENGTH = 25;

type NameBlockProps = {
  isViewMode: boolean;
  name: string;
};

const NameBlock = memo<NameBlockProps>(({ isViewMode, name }) => {
  return (
    <StatementBox style={{ marginBottom: 12 }}>
      <div className={styles.nameBlock}>
        {isViewMode ? (
          <div className={styles.allocationViewInfo}>
            <span className={styles.allocationViewLabel}>Name</span>
            <span className={styles.allocationViewValue}>{truncate(name, { length: NAME_TRUNCATE_LENGTH })}</span>
          </div>
        ) : (
          <CustomField
            label="Name"
            id={FieldNames.NAME}
            name={FieldNames.NAME}
            placeholder="Name of loan for e.g. Global loan fund Tom.."
            classes={{
              inputClassName: styles.inputClass,
              labelClassName: styles.fieldLabelClass,
              wrapperClassName: styles.nameWrapperClass,
              errorClass: styles.errorClass
            }}
          />
        )}
      </div>
    </StatementBox>
  );
});

NameBlock.displayName = 'NameBlock';

export default NameBlock;
