import { Currencies, PlatformsIDs, LoanTypes, ExaloanRatings, RelativeValues } from 'src/constants/globalConstants';

export type InvestmentCriterias = {
  general?: {
    loan_amount_min: number;
    loan_amount_max: number;
    interest_rate_min: number;
    interest_rate_max: number;
    term_min: number;
    term_max: number;
    investment_per_loan: number;
  };
  grades?: Record<ExaloanRatings, number>;
  loan_size_constraints?: Record<number, number>;
  relative_value?: Record<RelativeValues, number>;
  purposes?: Record<string, number>;
  sectors?: Record<number, number>;
};

export type IPlatform = {
  platform: PlatformsIDs;
  percentage: number;
  investment_criterias: InvestmentCriterias;
};

export enum InvestmentReinvestmentStrategy {
  DoNotReinvest = 'none',
  ReinvestPrincipal = 'principal',
  ReinvestAll = 'all'
}

export type AllocationParams = {
  loan_type: LoanTypes;
  max_bid_amount?: number;
  reinvestment_strategy?: InvestmentReinvestmentStrategy;
  structure_end_date?: string;
  platforms: IPlatform[];
};

export interface IAllocation {
  id: string;
  name: string;
  status: number;
  amount: number;
  created_at: string;
  updated_at: string;
  currency: Currencies;
  allocation_params: AllocationParams;
  auto_approval: boolean;
  include_previous_loans: boolean;
  type?: LoanTypes;
  opportunities_amount?: number;
}

export type AllocationPayload = {
  name: string;
  amount: number;
  currency: Currencies;
  auto_approval: boolean;
  include_previous_loans: boolean;
  allocation_params: AllocationParams;
};

export interface IDropdownItem {
  value;
  label: string;
  type?: LoanTypes;
}

export interface IAllocationOptions {
  selectedAllocation: null | IDropdownItem;
  selectorOptions: IDropdownItem[];
  onChangeValue: (item: IDropdownItem) => void;
  isSearchable?: boolean;
  isError?: boolean;
}
