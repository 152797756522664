import { ExportFileTypes } from '../constants/exportFilesConstants';

type Props = {
  fileType: ExportFileTypes;
  href: string;
  fileName: string;
};

export const onExport = ({ fileName, href, fileType }: Props): void => {
  const link = document.createElement('a');
  link.href = href;

  link.setAttribute('download', `${fileName}.${fileType}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const exportFileApi = async (
  fileURL: string,
  fileName: string,
  fileType: string,
  accessToken: string
): Promise<void> => {
  fetch(process?.env?.REACT_APP_BACKEND_URL + fileURL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${fileName}.${fileType}`);

      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    });
};
