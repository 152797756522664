import React, { useCallback, useState } from 'react';
import { getMonth } from 'date-fns';
import { DatePickersNames } from 'src/components/DatePickerBlock/DatePickerBlock';
import { GetMarketplaceLoansParams } from 'src/interfaces/marketplaceReduxShapes';
import { INITIAL_SELECTED_DATES, INITIAL_SELECTED_MONTH } from './constants';
import { SelectedDates, SelectedMonths } from './model';

type UseDatesReturnValue = {
  selectedDates: SelectedDates;
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDates>>;
  selectedMonths: SelectedMonths;
  setSelectedMonths: React.Dispatch<React.SetStateAction<SelectedMonths>>;
  handleSetSelectedMonth: (id: DatePickersNames, value: number) => void;
  handleDateChange: (id: DatePickersNames, date: Date) => void;
  onSetInitialDates: () => void;
};

export const usePickerDates = (
  setReqParams: React.Dispatch<React.SetStateAction<GetMarketplaceLoansParams>>,
  onClearLoans: () => void
): UseDatesReturnValue => {
  const [selectedDates, setSelectedDates] = useState<SelectedDates>(INITIAL_SELECTED_DATES);
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonths>(INITIAL_SELECTED_MONTH);

  const handleSetSelectedMonth = useCallback((id: DatePickersNames, value: number) => {
    setSelectedMonths((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleDateChange = useCallback(
    (id: DatePickersNames, date: Date) => {
      onClearLoans();
      setSelectedDates((prevDates) => ({ ...prevDates, [id]: date }));
      const shouldUpdateMonths = getMonth(date) !== selectedMonths[id];
      if (shouldUpdateMonths) setSelectedMonths((prevMonth) => ({ ...prevMonth, [id]: getMonth(date) }));
      setReqParams((prevParams) => ({ ...prevParams, date: { ...prevParams.date, [id]: date, page: 1 } }));
    },
    [selectedMonths, setReqParams, onClearLoans]
  );

  const onSetInitialDates = useCallback(() => {
    setSelectedDates(INITIAL_SELECTED_DATES);
    setSelectedMonths(INITIAL_SELECTED_MONTH);
  }, []);

  return {
    selectedDates,
    setSelectedDates,
    selectedMonths,
    setSelectedMonths,
    handleSetSelectedMonth,
    handleDateChange,
    onSetInitialDates
  };
};
