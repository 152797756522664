import React from 'react';
import cn from 'classnames';
import circularLoader from 'src/assets/images/ellipse_loader.png';
import styles from './CircularLoader.module.scss';

interface CircularLoaderProps {
  imageClassName?: string;
}

const CircularLoader = ({ imageClassName }: CircularLoaderProps): JSX.Element => {
  return (
    <img
      src={circularLoader}
      alt="circular loader"
      className={cn(styles.circularLoader, { [imageClassName]: imageClassName })}
    />
  );
};

export default CircularLoader;
