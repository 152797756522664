import { Auth } from 'aws-amplify';
import { CognitoUserSession, CodeDeliveryDetails, ISignUpResult } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { UserData } from 'src/interfaces';

export const getCurrentSession = (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};

export const getCurrentAuthenticatedUser = (): Promise<UserData> => {
  return Auth.currentAuthenticatedUser();
};

export const forceChangePassword = (user, password: string): Promise<UserData> => {
  return Auth.completeNewPassword(user, password).then((user) => user);
};

export const signOutUserHelper = (): Promise<void> => {
  return Auth.signOut();
};

export const forgotPasswordHelper = (email: string): Promise<CodeDeliveryDetails> => {
  return Auth.forgotPassword(email);
};

export const forgotPasswordSubmitHelper = (
  email: string,
  validationCode: string,
  newPassword: string
): Promise<void> => {
  return Auth.forgotPasswordSubmit(email, validationCode, newPassword);
};

export const signInHelper = (email: string, password: string): Promise<UserData> => {
  return Auth.signIn(email, password);
};

enum SignUpCustomAttributes {
  COMPANY_NAME = 'custom:company_name',
  COMPANY_NUMBER = 'custom:company_number',
  COUNTRY = 'custom:country',
  FIRST_NAME = 'custom:first_name',
  LAST_NAME = 'custom:last_name',
  USER_AGREEMENT = 'custom:user_agreement',
  EXALOAN_OFFERS = 'custom:exaloan_offers'
}

interface SignUpParamsShape {
  username: string;
  password: string;
  attributes: {
    email: string;
    [SignUpCustomAttributes.COMPANY_NAME]: string;
    [SignUpCustomAttributes.COMPANY_NUMBER]: string;
    [SignUpCustomAttributes.COUNTRY]: string;
    [SignUpCustomAttributes.FIRST_NAME]: string;
    [SignUpCustomAttributes.LAST_NAME]: string;
    [SignUpCustomAttributes.USER_AGREEMENT]: string;
    [SignUpCustomAttributes.EXALOAN_OFFERS]: string;
  };
}

export const signUpHelper = (params: SignUpParamsShape): Promise<ISignUpResult> => {
  return Auth.signUp(params);
};

export const setAuthToken = (token: string): string =>
  (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export const clearAuthToken = (): string => (axios.defaults.headers.common['Authorization'] = null);
