interface Error {
  response: {
    data: {
      message: string | string[];
    };
  };
}

export const getErrorMessage = (err: Error): string | string[] => {
  return err?.response?.data?.message ?? '';
};
