import { v4 as uuid } from 'uuid';
import { RelativeValues } from 'src/constants/globalConstants';

export const RELATIVE_VALUES_MAP = {
  [RelativeValues['3P']]: '+++',
  [RelativeValues['2P']]: '++',
  [RelativeValues['P']]: '+',
  [RelativeValues['N']]: '-',
  [RelativeValues['2N']]: '--',
  [RelativeValues['3N']]: '---'
};

export type RelativeValueFilter = { id: string; name: string; value: RelativeValues };

export const getRelativeValuesList = (): RelativeValueFilter[] => [
  { id: uuid(), name: '+++', value: RelativeValues['3P'] },
  { id: uuid(), name: '++', value: RelativeValues['2P'] },
  { id: uuid(), name: '+', value: RelativeValues['P'] },
  { id: uuid(), name: '-', value: RelativeValues['N'] },
  { id: uuid(), name: '--', value: RelativeValues['2N'] },
  { id: uuid(), name: '---', value: RelativeValues['3N'] }
];
