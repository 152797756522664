import { stringify } from 'query-string';
import { GetStatisticsParams, InvestmentStatistic, ICurrency } from 'src/interfaces';
import { formatMoney, formatPercentage, thousandSeparatorFormatter } from 'src/helpers';

type Slot = {
  title: string;
  text: string;
};

type Widget = {
  title: string;
  slots: Slot[];
};

export const getWidgets = (statisticsData: InvestmentStatistic, currency: ICurrency): Widget[] => {
  return [
    {
      title: 'My Return Index',
      slots: [
        {
          title: 'Cumulative',
          text: formatPercentage(statisticsData?.cumulative_return)
        },
        {
          title: 'Annualized',
          text: formatPercentage(statisticsData?.annual_return)
        },
        {
          title: 'IRR',
          text: formatPercentage(statisticsData?.extended_irr ?? 0)
        }
      ]
    },
    {
      title: 'My Investments',
      slots: [
        {
          title: 'Active Investments',
          text: `${formatMoney(statisticsData?.value_active, currency.value)}`
        },
        {
          title: 'No. of Active Loans',
          text: thousandSeparatorFormatter(statisticsData?.no_active)
        },
        {
          title: 'Total investments',
          text: `${formatMoney(statisticsData?.value_total, currency.value)}`
        },
        {
          title: 'No. of Loans',
          text: thousandSeparatorFormatter(statisticsData?.no_total)
        },
        {
          title: 'Avg. Interest Rate',
          text: formatPercentage(statisticsData?.avg_interest_rate_active_loans)
        },
        {
          title: 'Avg. Default Rate',
          text: formatPercentage(statisticsData?.avg_default_rate)
        },
        {
          title: 'Avg. Term',
          text: `${statisticsData?.avg_term_by_active_loans?.toFixed(1)}m`
        }
      ]
    }
  ];
};

export const getExportStatisticsUrl = (reqParams: GetStatisticsParams, currency: string, fileType: string): string => {
  const query = {
    currency,
    file_type: fileType,
    allocation_id: reqParams?.allocation
  };

  return `export/statistics?${stringify(query, {
    skipEmptyString: true,
    skipNull: true
  })}`;
};
