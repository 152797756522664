import React, { FC } from 'react';
import cn from 'classnames';
import { NumericInput, Typography } from 'src/components';
import styles from './InvestmentPercentage.module.scss';

interface Props {
  onInvestmentChange: (value: number) => void;
  investmentPercentage: number | null;
  isViewCase?: boolean;
  isError?: boolean;
  titleClass?: string;
  investmentLabelClass?: string;
  afterClass?: string;
}

const InvestmentPercentage: FC<Props> = ({
  onInvestmentChange,
  isViewCase,
  investmentPercentage,
  isError,
  titleClass,
  investmentLabelClass,
  afterClass
}): JSX.Element => {
  return (
    <div className={styles.detailsBlock}>
      <Typography className={cn({ [titleClass]: titleClass })}>General constraints</Typography>

      <div className={styles.investmentBlock}>
        <span
          className={cn(styles.detailsText, styles.investmentLabel, { [investmentLabelClass]: investmentLabelClass })}
        >
          Investment Per Loan:
        </span>
        <NumericInput
          isPercentageField
          disabled={isViewCase}
          readOnly={isViewCase}
          className={cn(styles.detailsText, styles.detailsValueBox, styles.investmentInput, {
            [styles.investmentInputError]: isError
          })}
          value={investmentPercentage}
          onChanged={(value) => onInvestmentChange(+value)}
        />
        &nbsp;<span className={cn({ [afterClass]: afterClass })}>%</span>
      </div>
    </div>
  );
};

export default InvestmentPercentage;
