import { parseISO, getWeek, format, getUnixTime } from 'date-fns';
import { GroupBy, Purposes, GroupByFormatsFns } from 'src/constants/statisticsConstants';
import { InvestmentStatistic } from 'src/interfaces/InvestmentShapes';
import { Provider } from 'src/constants/marketplaceConstants';
import { calculatePercentage } from 'src/helpers';
import { formatTitle } from 'src/helpers';
import { TMappedGraphData } from 'src/components';

export const statisticMapper = (data: InvestmentStatistic): InvestmentStatistic => {
  for (const key in data.loan_investment_by_purpose) {
    // Get current object
    const obj = data.loan_investment_by_purpose[key];

    // Add copy with the key according to mapping
    data.loan_investment_by_purpose[Purposes[key]] = obj;
    // Delete old version
    delete data.loan_investment_by_purpose[key];
  }

  for (const key in data.loan_investment_by_platform) {
    const obj = data.loan_investment_by_platform[key];

    data.loan_investment_by_platform[Provider[key]] = obj;
    delete data.loan_investment_by_platform[key];
  }

  for (const key in data.loan_investment_by_term) {
    // Get current object
    const obj = data.loan_investment_by_term[key];

    // Add copy with the key according to mapping
    data.loan_investment_by_term[`${key} Months`] = obj;
    // Delete old version
    delete data.loan_investment_by_term[key];
  }

  return data;
};

export const statisticChunkGroupper = (data: Record<string, number>, type: GroupBy): Record<string, number> => {
  return Object.entries(data ?? {}).reduce((acc, [dateString, value]) => {
    const key = getFormattedDateFromTimeStrByPeriod(type, dateString);

    if (!acc[key]) acc[key] = 0;
    acc[key] += value;

    return acc;
  }, {});
};

type TStatisticsMappedChartGroups = Record<string, Record<string, number>>;

export const statisticMappedChartSerializer = (groups: TStatisticsMappedChartGroups): TMappedGraphData[] => {
  const mappedChunks: TMappedGraphData[] = Object.keys(groups).reduce((acc, groupKey) => {
    const categoryData: Record<string, number> = groups[groupKey];

    const filteredCategoryData = Object.keys(categoryData).reduce(
      (acc, key) => ({ ...acc, [key]: categoryData[key] }),
      {}
    );

    const dates = Object.keys(filteredCategoryData).sort(
      (a, b) => (new Date(a) as unknown as number) - (new Date(b) as unknown as number)
    );

    const data = dates.map((date) => +filteredCategoryData[date].toFixed(2));

    return [...acc, { name: formatTitle(groupKey), dates, data, type: 'column' as const }];
  }, []);

  return mappedChunks;
};

const formatByType = (date: Date, formatType: GroupByFormatsFns) => {
  return format(date, formatType, {
    weekStartsOn: 1,
    useAdditionalWeekYearTokens: true,
    useAdditionalDayOfYearTokens: true
  });
};

export const getFormattedDateFromTimeStrByPeriod = (type: GroupBy, timeStr: string): string => {
  const date = parseISO(timeStr);

  if (type === GroupBy.WEEK) {
    const weekNumber = getWeek(date, { weekStartsOn: 1 });
    const yearStr = formatByType(date, GroupByFormatsFns.year);

    return `Week ${weekNumber} ${yearStr}`;
  }

  return formatByType(date, GroupByFormatsFns[type]);
};

type TStatisticInvestmentChartSeriesItem = {
  name: string;
  y: number;
  percentage: number;
  date: string;
};

export const statisticInvestmentSerializer = (
  groups: Record<string, number>,
  totalInvestment: number
): TStatisticInvestmentChartSeriesItem[] => {
  return Object.keys(groups)
    .reduce(
      (acc, key) => [
        ...acc,
        {
          name: groups[key].toFixed(2),
          y: +groups[key].toFixed(2),
          percentage: +calculatePercentage(totalInvestment, groups[key]).toFixed(2),
          date: key
        }
      ],
      []
    )
    .sort((a, b) => getUnixTime(new Date(a.date)) - getUnixTime(new Date(b.date)));
};
