import React, { memo } from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import { FieldNames, SelectOption } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';
import { selectCurrencyOptions as currenciesOptions } from 'src/constants/globalConstants';

import styles from '../../TypeBlock.module.scss';
import { CustomizedSelect, ShouldRender, FormikFormatField as FormatField } from 'src/components';

type AmountBlockProps = {
  isViewMode: boolean;
  isEditMode: boolean;
  currencyValue: SelectOption;
  amountValue: string;
  onCurrencyChange: (value) => void;
  onCurrencyBlur: () => void;
};

const AmountBlock = memo<AmountBlockProps>(
  ({ isViewMode, isEditMode, currencyValue, amountValue, onCurrencyChange, onCurrencyBlur }) => {
    const isNewMode = !isViewMode && !isEditMode;
    return (
      <div className={styles.amountBlock}>
        <ShouldRender should={!isViewMode}>
          <label htmlFor="amount" className={cn(styles.fieldLabelClass, { [styles.viewLabel]: isViewMode })}>
            Allocation Amount
          </label>
        </ShouldRender>

        <div className={styles.amountInner}>
          <ShouldRender should={isNewMode}>
            <div className={styles.currencySelectWrapper}>
              <CustomizedSelect
                name={FieldNames.CURRENCY}
                value={currencyValue}
                options={currenciesOptions}
                onChange={onCurrencyChange}
                onBlur={onCurrencyBlur}
                placeholder="EUR"
                isSearchable={false}
              />
            </div>
          </ShouldRender>

          <ShouldRender should={isEditMode}>
            <React.Fragment>
              <span className={styles.allocationViewCurrency}>{currencyValue.value}</span>
            </React.Fragment>
          </ShouldRender>

          {isViewMode ? (
            <div className={styles.allocationViewInfo}>
              <span className={styles.allocationViewLabel}>Allocation Amount</span>
              <NumberFormat
                displayType="text"
                value={amountValue}
                thousandSeparator
                type="text"
                renderText={(value) => (
                  <div className={styles.allocationViewAmount}>
                    <span className={styles.allocationViewCurrency}>{currencyValue.value}</span>
                    <span className={styles.allocationViewValue}>{value}</span>
                  </div>
                )}
              />
            </div>
          ) : (
            <FormatField
              id={FieldNames.AMOUNT}
              name={FieldNames.AMOUNT}
              placeholder="Amount for e.g. 10,000"
              thousandSeparator
              isNumericString
              allowNegative={false}
              autoComplete="off"
              classes={{
                inputClassName: styles.inputClass,
                wrapperClassName: styles.amountWrapperClass,
                errorClass: styles.errorClass
              }}
            />
          )}
        </div>
      </div>
    );
  }
);

AmountBlock.displayName = 'AmountBlock';

export default AmountBlock;
