import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import {
  FormikCustomField as CustomField,
  ButtonUnderlined as ButtonBack,
  RequestErrorMessage,
  MainLayout,
  AuthTitleBlock,
  ConfirmButton,
  CheckboxWithLabel,
  UserAgreementCheckbox
} from 'src/components';
import { userSelector } from 'src/redux/selectors';
import { PAGES } from 'src/constants/pages';
import { forceChangePassword } from 'src/helpers/authHelpers';
import { InputEnums, SignUpFields } from 'src/interfaces';
import { signOutUser } from 'src/redux/actions/authActions';
import { validationSchema, formFields } from './constants';
import styles from './UpdatePasswordPage.module.scss';

interface Values {
  password: string;
  confirmPassword: string;
  agreement: boolean;
  offers: boolean;
}

const UpdatePasswordPage = (): JSX.Element => {
  const { push } = useHistory();
  const user = useSelector(userSelector);

  const [isResponseError, setResponseError] = useState(false);
  const onClearResponseError = () => setResponseError(false);
  const dispatch = useDispatch();

  return (
    <MainLayout isPrivate={false}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <AuthTitleBlock
            title="Update Password"
            description={'Enter Your Password'}
            classes={{ blockClass: styles.titleBlock, titleClass: styles.title }}
          />

          {isResponseError && <RequestErrorMessage msg={'Something Went Wrong'} />}
        </div>

        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
            offers: false,
            agreement: false
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ password }: Values, { setSubmitting }: FormikHelpers<Values>) => {
            try {
              await forceChangePassword(user, password);
              dispatch(signOutUser());
              push(PAGES.CHANGE_PASSWORD_SUCCESS);
            } catch (error) {
              console.error('forgotPasswordSubmitHelper error', error);
              setResponseError(true);
            }

            setSubmitting(false);
          }}
        >
          {({ errors, values, handleChange }) => {
            const isSomeValuesFalsy = some(values, (value) => !value);

            return (
              <Form className={styles.form}>
                {formFields.map(({ name, id, type, placeholder }) => (
                  <CustomField
                    key={name}
                    name={name}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    onClearRequestError={onClearResponseError}
                    isPassword={type === InputEnums.PASSWORD}
                    classes={{ wrapperClassName: styles.fieldWrapper }}
                    readOnly={name === 'email'}
                  />
                ))}

                <UserAgreementCheckbox
                  onLinkClick={() => null}
                  label="I confirm that I have read and agree to the "
                  checkboxProps={{
                    isChecked: values.agreement,
                    name: SignUpFields.USER_AGREEMENT,
                    id: SignUpFields.USER_AGREEMENT,
                    onChange: handleChange
                  }}
                />
                <CheckboxWithLabel
                  isChecked={values.offers}
                  name={SignUpFields.NEWS_OFFERS}
                  id={SignUpFields.NEWS_OFFERS}
                  title="I confirm to receive Exaloan news and offers."
                  onChange={handleChange}
                  classes={{ labelRootClass: styles.offersRoot }}
                />

                <ConfirmButton type="submit" isDisabled={isSomeValuesFalsy || !isEmpty(errors)} />
              </Form>
            );
          }}
        </Formik>
        <ButtonBack onClick={() => push(PAGES.SIGN_IN)} />
      </div>
    </MainLayout>
  );
};

export default UpdatePasswordPage;
