import React, { useMemo, useState, memo, FC } from 'react';
import cn from 'classnames';
import { StatisticsColumnChart, StatementBox } from 'src/components';
import { GroupBy } from 'src/constants/statisticsConstants';
import { TNetReturnByMonth, TNetReturnByWeek, TNetReturnByYear } from 'src/interfaces';

import { ChartHeader, STATISTIC_TIME_INTERVALS } from 'src/components/ChartHeader';
import { IGraphData } from 'src/components/StatisticsColumnChart/StatisticsColumnChart';
import { useNetReturnChartData } from './hooks';
import { sortReturnsByYear, sortReturnsByYearAndMonth, sortReturnsByYearAndWeek } from './helpers';

interface NetReturnByChartProps {
  netReturnByYear: TNetReturnByYear[];
  netReturnByMonth: TNetReturnByMonth[];
  netReturnByWeek: TNetReturnByWeek[];
  currency?: string;
  wrapperClass?: string;
}

const chartHeaderOptions = STATISTIC_TIME_INTERVALS.filter((item) => item.value !== GroupBy.DAY);

const NetReturnByChart: FC<NetReturnByChartProps> = memo(
  ({ netReturnByYear, netReturnByMonth, netReturnByWeek, currency, wrapperClass }): JSX.Element => {
    const [type, setType] = useState(GroupBy.WEEK);

    const dataMap: Record<GroupBy.WEEK, Array<TNetReturnByYear | TNetReturnByMonth | TNetReturnByWeek>> = useMemo(
      () => ({
        [GroupBy.WEEK]: sortReturnsByYearAndWeek(netReturnByWeek),
        [GroupBy.MONTH]: sortReturnsByYearAndMonth(netReturnByMonth),
        [GroupBy.YEAR]: sortReturnsByYear(netReturnByYear)
      }),
      [netReturnByMonth, netReturnByWeek, netReturnByYear]
    );

    const chartData: IGraphData[] = useNetReturnChartData({ dataMap, type });

    const { dataMax, dataMin } = useMemo(
      () => ({
        dataMax: Math.max(...chartData.map((item) => item.y)),
        dataMin: Math.min(...chartData.map((item) => item.y))
      }),
      [chartData]
    );

    const { min, max } = useMemo(() => {
      return {
        max: dataMax > 0 ? (dataMax > Math.abs(dataMin) ? dataMax : Math.abs(dataMin)) : dataMax,
        min: dataMin > 0 ? 0 : Math.abs(dataMin) > Math.abs(dataMax) ? dataMin : dataMax * -1
      };
    }, [dataMax, dataMin]);

    const yAxisChartOptions = useMemo(
      () => ({
        type: 'linear' as const,
        max: dataMin && dataMin > 0 ? undefined : max * 1.1,
        min: dataMin && dataMin > 0 ? undefined : min * 1.1
      }),
      [dataMin, max, min]
    );

    return (
      <StatementBox style={{ width: '100%' }} className={cn({ [wrapperClass]: wrapperClass })}>
        <ChartHeader title="Net Return" type={type} onTypeChange={setType} options={chartHeaderOptions} />

        <StatisticsColumnChart
          data={chartData}
          type={type}
          xLabelType="direct"
          yLabelType="percent"
          currency={currency}
          externalChartOptions={{
            yAxis: yAxisChartOptions
          }}
        />
      </StatementBox>
    );
  }
);

NetReturnByChart.displayName = 'NetReturnByChart';

export default NetReturnByChart;
