import { SortOrders } from 'src/constants/globalConstants';
import { ColumnAlignOptions } from 'src/interfaces/CustomTableShapes';
import { InvestmentsColIDs } from 'src/interfaces/investmentsReduxShapes';

export const INVESTMENT_COLUMNS = [
  {
    label: 'Loan ID',
    id: InvestmentsColIDs.ID,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.LEFT
  },

  {
    label: 'Funding Date',
    id: InvestmentsColIDs.FUNDED_DATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Newest', order: SortOrders.DESC },
      { title: 'Oldest', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Maturity Date',
    id: InvestmentsColIDs.MATURITY_DATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Newest', order: SortOrders.DESC },
      { title: 'Oldest', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Invested (Outstanding)',
    id: InvestmentsColIDs.INVESTMENT_AMOUNT,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Interest Rate',
    id: InvestmentsColIDs.INTEREST_RATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Exaloan Score',
    id: InvestmentsColIDs.GRADE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.ASC },
      { title: 'Low - High', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Relative Value',
    id: InvestmentsColIDs.RELATIVE_VALUE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Term',
    id: InvestmentsColIDs.TERM,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Shortest - Longest', order: SortOrders.ASC },
      { title: 'Longest - Shortest', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Loan Type',
    id: InvestmentsColIDs.LOAN_TYPE,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Investment Status',
    id: InvestmentsColIDs.STATUS,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export const OUTSTANDING_BID_COLUMNS = [
  {
    label: 'Loan ID',
    id: InvestmentsColIDs.ID,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.LEFT
  },

  {
    label: 'Bidding date',
    id: InvestmentsColIDs.BIDDING_DATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Newest', order: SortOrders.DESC },
      { title: 'Oldest', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },

  {
    label: 'Bid Amount (Loan)',
    id: InvestmentsColIDs.INVESTMENT_AMOUNT,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Interest Rate',
    id: InvestmentsColIDs.INTEREST_RATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Exaloan Score',
    id: InvestmentsColIDs.GRADE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.ASC },
      { title: 'Low - High', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Relative Value',
    id: InvestmentsColIDs.RELATIVE_VALUE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },

  {
    label: 'Term',
    id: InvestmentsColIDs.TERM,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Shortest - Longest', order: SortOrders.ASC },
      { title: 'Longest - Shortest', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },

  {
    label: 'Loan Type',
    id: InvestmentsColIDs.LOAN_TYPE,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Bid Status',
    id: InvestmentsColIDs.STATUS,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];
