export const safeParseInt = (input: string): number => {
  return parseInt(input, 10);
};

export const thousandsSeparator = (number: number): string => {
  if (!number) {
    return '';
  }

  const numParts = number.toString().split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numParts.join('.');
};
