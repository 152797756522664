import { GroupBy } from 'src/constants/statisticsConstants';

export const STATISTIC_TIME_INTERVALS = [
  {
    value: GroupBy.DAY,
    label: 'Daily'
  },
  {
    value: GroupBy.WEEK,
    label: 'Weekly'
  },
  {
    value: GroupBy.MONTH,
    label: 'Monthly'
  },
  {
    value: GroupBy.YEAR,
    label: 'Yearly'
  }
];
