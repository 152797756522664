import React from 'react';
import MainLayout from 'src/components/MainLayout/MainLayout';

const PlatformAnalyticsPage = (): JSX.Element => {
  return (
    <MainLayout isPrivate isPrivateInnerFull>
      <h2>PlatformAnalytics</h2>
    </MainLayout>
  );
};

export default PlatformAnalyticsPage;
