export type SelectedDates = {
  startDate: Date;
  endDate: Date;
};

export type SelectedMonths = {
  startDate: number;
  endDate: number;
};

export enum ContentTypes {
  LOADER = 'LOADER',
  DATA = 'DATA',
  NO_DATA = 'NO_DATA'
}
