/* eslint-disable no-case-declarations */
import React, { FC } from 'react';
import truncate from 'lodash/truncate';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import { formatDateFromJSON } from 'src/helpers/dateHelpers';
import { LOAN_TABLE_DATE_FORMAT } from 'src/constants/globalConstants';
import { PortfolioBuilderColIDs } from 'src/constants/portfolioBuilderConstants';
import { allocationStatusNames, STATUS as STATUS_CODES } from 'src/constants/portfolioBuilderConstants';
import { IAllocation } from 'src/interfaces';
import { TrashBinIcon, WorkBagIcon, LaunchLinkIcon } from 'src/atoms';
import startIcon from 'src/assets/images/icons/allocation_play.svg';
import stopIcon from 'src/assets/images/icons/allocation_stop.svg';
import styles from './styles.module.scss';

const { NAME, ACTIONS, CREATED, UPDATED, STATUS, ALLOCATION } = PortfolioBuilderColIDs;

const TRUNCATE_WIDTH = 40;

export const renderTableContent = (
  colId: PortfolioBuilderColIDs,
  row: IAllocation,
  onStartInvesting: (id: string) => void,
  onStopInvesting: (id: string) => void,
  onViewAllocation: (id: string) => void,
  onGoToInvestment: (id: string) => void,
  onToggleDeleteModal: (allocation?: IAllocation) => void
): JSX.Element => {
  const isStatusActive = row?.status === STATUS_CODES.ACTIVE;

  switch (colId) {
    case NAME:
      const name = truncate(row?.name, { length: TRUNCATE_WIDTH }).trim();

      return <span className={cn(styles.allocationText, styles.allocationName)}>{name}</span>;

    case ACTIONS:
      return (
        <ActionsBlock
          isStatusActive={isStatusActive}
          onToggleModal={onToggleDeleteModal}
          onGoToInvestments={() => onGoToInvestment(row.id)}
          onViewAllocation={() => onViewAllocation(row.id)}
          allocation={row}
        />
      );

    case CREATED:
      const formattedCreated = row?.created_at ? formatDateFromJSON(row?.created_at, LOAN_TABLE_DATE_FORMAT) : '-';

      return <span className={styles.allocationText}>{formattedCreated}</span>;

    case UPDATED:
      const formattedModified = row?.updated_at ? formatDateFromJSON(row?.updated_at, LOAN_TABLE_DATE_FORMAT) : '-';

      return <span className={styles.allocationText}>{formattedModified}</span>;

    case STATUS:
      return (
        <span className={cn(styles.allocationText, styles.statusText, { [styles.statusRed]: !isStatusActive })}>
          {allocationStatusNames[row?.status]}
        </span>
      );

    case ALLOCATION:
      return (
        <div className={styles.investingBlock}>
          {isStatusActive ? (
            <button type="button" className={styles.investingBtn} onClick={() => onStopInvesting(row.id)}>
              <ReactSVG className={styles.stopIcon} src={stopIcon} />
              <span className={cn(styles.active, styles.investingText)}>Stop investing</span>
            </button>
          ) : (
            <button type="button" className={styles.investingBtn} onClick={() => onStartInvesting(row.id)}>
              <ReactSVG className={styles.startIcon} src={startIcon} />
              <span className={cn(styles.nonActive, styles.investingText)}>Start Investing</span>
            </button>
          )}
        </div>
      );

    default:
      return <></>;
  }
};

type ActionsBlockProps = {
  isStatusActive: boolean;
  onGoToInvestments: () => void;
  onViewAllocation: () => void;
  onToggleModal: (allocation: IAllocation) => void;
  allocation: IAllocation;
};

const ActionsBlock: FC<ActionsBlockProps> = ({
  isStatusActive,
  onGoToInvestments,
  onViewAllocation,
  onToggleModal,
  allocation
}) => {
  return (
    <div className={styles.actions}>
      <button className={styles.actionBtn} type="button" onClick={onViewAllocation}>
        <LaunchLinkIcon />
        <span className={styles.actionPopover}>View and Edit Portfolio</span>
      </button>

      <button className={styles.actionBtn} type="button" onClick={onGoToInvestments}>
        <WorkBagIcon />
        <span className={styles.actionPopover}>See portfolio investments</span>
      </button>

      <button
        className={cn(styles.actionBtn, { [styles.actionBtnDisabled]: isStatusActive })}
        type="button"
        disabled={isStatusActive}
        onClick={() => onToggleModal(allocation)}
      >
        <TrashBinIcon />
        <span className={styles.actionPopover}>Delete portfolio</span>
      </button>
    </div>
  );
};
