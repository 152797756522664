import React, { FC } from 'react';
import cn from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styles from './DisplayFormatted.module.scss';

interface Props extends NumberFormatProps {
  className?: string;
  after?: React.ReactNode;
  afterClass?: string;
  wrapperClass?: string;
  align?: 'center' | 'start' | 'end';
}

const DisplayFormatted: FC<Props> = ({
  value,
  className,
  thousandSeparator = true,
  decimalScale = 2,
  after,
  afterClass,
  wrapperClass,
  align = 'center',
  ...rest
}) => {
  return (
    <div className={cn(styles.wrapper, styles[align], { [wrapperClass]: wrapperClass })}>
      <NumberFormat
        displayType="text"
        thousandSeparator={thousandSeparator}
        decimalScale={decimalScale}
        value={value}
        className={cn(styles.value, { [className]: className })}
        {...rest}
      />

      {after && <span className={cn(styles.after, { [afterClass]: afterClass })}>{after}</span>}
    </div>
  );
};

export default DisplayFormatted;
