/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import truncate from 'lodash/truncate';
import { IAllocation, IAllocationOptions, IDropdownItem } from 'src/interfaces/AllocationTypes';
import { totalOpportunitiesSelector } from 'src/redux/selectors';

type Props = {
  allocations: IAllocation[];
  onAllocationChange: (id: string, item: IDropdownItem) => void;
  defaultValue?: IDropdownItem;
  allDataOption?: IDropdownItem;
  isOpportunities?: boolean;
};

const ALLOCATION_NAME_TRUNCATE = 25;

export const getAllocationHook = ({
  allocations,
  onAllocationChange, //* argument function, if provided, should be memoised with useCallback when provided in hook
  defaultValue = null,
  allDataOption,
  isOpportunities
}: Props): IAllocationOptions => {
  const totalOpportunities = useSelector(totalOpportunitiesSelector);

  const [selectedAllocation, setSelectedAllocation] = useState<null | IDropdownItem>(defaultValue);
  const [selectorOptions, setSelectorOptions] = useState<IDropdownItem[]>([]);

  const onChangeValue = useCallback(
    (item: IDropdownItem): void => {
      setSelectedAllocation(item);
      if (onAllocationChange) onAllocationChange(item?.value, item);
    },
    [onAllocationChange]
  );

  useEffect(() => {
    if (isNil(allocations) || isEmpty(allocations)) return;

    const data: IDropdownItem[] = allocations.map((allocation) => {
      const label = truncate(allocation.name, { length: ALLOCATION_NAME_TRUNCATE });

      return {
        label: isOpportunities ? `(${allocation?.opportunities_amount}) ${label}` : label,
        value: allocation.id,
        type: allocation?.allocation_params?.loan_type ?? allocation?.type
      };
    });

    setSelectorOptions(allDataOption ? [allDataOption, ...data] : data);

    const shouldPresetOpportunityAllocation = isOpportunities && totalOpportunities;

    if (shouldPresetOpportunityAllocation) {
      const maxOpportunitiesAllocation = allocations.reduce((acc, allocation) => {
        return acc?.opportunities_amount > allocation.opportunities_amount ? acc : allocation;
      });

      const maxOpportunitiesOption = data.find((option) => option.value === maxOpportunitiesAllocation.id);
      onChangeValue(maxOpportunitiesOption);
    }
  }, [allocations, allDataOption, isOpportunities, totalOpportunities, onChangeValue]);

  return { selectedAllocation, selectorOptions, onChangeValue };
};
