import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { ButtonNext, ButtonUnderlined as ButtonBack } from 'src/atoms';
import { MainLayout, AuthTitleBlock, FormikCustomField as CustomField, RequestErrorMessage } from 'src/components';
import { PAGES } from 'src/constants/pages';
import { authSelector } from 'src/redux/selectors';
import { forgotPasswordHelper } from 'src/helpers/authHelpers';
import { CognitoError, ErrorMessages } from '../LoginPage/LoginPage';
import { validationSchema, initialValues, FormValues } from './constants';
import styles from './PasswordRecoveryPage.module.scss';

const PasswordRecoveryPage = (): JSX.Element => {
  const { isAuthenticated } = useSelector(authSelector);
  const { push } = useHistory();

  const [errorResponse, setErrorResponse] = useState('');
  const onClearResponseError = () => setErrorResponse('');

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isPrivate={false}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleBlockWrapper}>
          <AuthTitleBlock
            title="Forgot your password?"
            description="Enter your Email below and we will send a message to reset your password."
            classes={{ blockClass: styles.titleBlock, titleClass: styles.title }}
          />

          <RequestErrorMessage msg={errorResponse} />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async ({ email }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            try {
              await forgotPasswordHelper(email);
              push(PAGES.CONFIRM_PASSWORD_RECOVERY, { email });
            } catch (error) {
              const err = error as CognitoError;
              setErrorResponse(ErrorMessages[err?.code] ?? err?.message);
            }

            setSubmitting(false);
          }}
        >
          {({ errors, values }) => {
            const isNextDisabled = some(values, (value) => !value) || !isEmpty(errors);

            return (
              <Form className={styles.form}>
                <CustomField
                  autoComplete
                  name="email"
                  id="email"
                  type="text"
                  placeholder="E-mail"
                  classes={{ wrapperClassName: styles.emailFieldWrapper }}
                  onClearRequestError={onClearResponseError}
                  isError={!!errorResponse}
                />
                <ButtonNext type="submit" isDisabled={isNextDisabled} label="Send Verification Code" />
              </Form>
            );
          }}
        </Formik>

        <ButtonBack onClick={() => push(PAGES.SIGN_IN)} label="Back to sign in" />
      </div>
    </MainLayout>
  );
};

export default PasswordRecoveryPage;
