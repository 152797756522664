import React from 'react';
import cn from 'classnames';
import styles from './ButtonUnderlined.module.scss';

interface ButtonUnderlinedProps {
  onClick: () => void;
  label?: string;
  prefix?: string;
  isDisabled?: boolean;
  classes?: { btnClass?: string; btnDisabledClass?: string };
}

const ButtonUnderlined = ({
  onClick,
  label = 'Back',
  prefix = '<',
  isDisabled,
  classes
}: ButtonUnderlinedProps): JSX.Element => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={cn(styles.btn, {
        [styles.btnDisabled]: isDisabled,
        [classes?.btnClass]: classes?.btnClass,
        [classes?.btnDisabledClass]: classes?.btnDisabledClass
      })}
    >
      {prefix}
      {prefix ? <>&nbsp;&nbsp;</> : null}
      {label}
    </button>
  );
};

export default ButtonUnderlined;
