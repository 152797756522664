// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = (isError: boolean) => ({
  container: (base) => ({
    ...base,
    minWidth: '100%'
  }),
  option: (base, state) => ({
    ...base,
    fontFamily: 'Raleway, sanserif',
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    backgroundColor: state.isSelected ? 'rgba(7, 113, 134, 0.102)' : '#fff',
    color: '#111a3f',

    '&:hover': { backgroundColor: 'rgba(7, 113, 134, 0.102)' }
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontFamily: 'Raleway, sanserif',
    fontSize: 25,
    lineHeight: '29px',
    color: isError ? '#f00' : '#9dafbd'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: '0 10px'
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    borderRadius: 'none',
    boxShadow: 'none',
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    borderBottom: isError ? '1px solid #f00' : '1px solid #9DAFBD',
    '&:hover': { borderColor: isError ? '1px solid #f00' : '#9DAFBD' }
  }),
  menu: (base) => ({
    ...base,
    zIndex: 50,
    marginTop: 1,
    marginBottom: 0,
    left: 0,
    minWidth: 88,
    background: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 5px 5px'
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px'
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F2F3F6',
      borderRadius: '50px',
      width: '4px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D5D5D5',
      borderRadius: '3px',
      width: '6px'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 0 6px 0'
  }),
  input: (base) => ({ ...base, fontSize: 24, fontFamily: 'Raleway, sanserif', color: '#111a3f' }),
  singleValue: (base) => ({
    ...base,
    fontFamily: 'Raleway, sanserif',
    fontSize: 25,
    lineHeight: '29px',
    color: '#111a3f'
  })
});
