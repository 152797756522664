import { TNetReturnByMonth, TNetReturnByWeek, TNetReturnByYear } from 'src/interfaces';

export const getMonthName = (monthNumber: number): string => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'short' });
};

export const sortReturnsByYearAndMonth = (data: TNetReturnByMonth[]): TNetReturnByMonth[] => {
  return data.sort((a, b) => a.year - b.year || a.month - b.month).filter((item) => item.net_return !== 0);
};

export const sortReturnsByYearAndWeek = (data: TNetReturnByWeek[]): TNetReturnByWeek[] => {
  return data.sort((a, b) => a.year - b.year || a.week - b.week).filter((item) => item.net_return !== 0);
};

export const sortReturnsByYear = (data: TNetReturnByYear[]): TNetReturnByYear[] => {
  return data.sort((a, b) => a.year - b.year).filter((item) => item.net_return !== 0);
};
