import React from 'react';
import cn from 'classnames';
import { makeStyles, createStyles } from '@mui/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 8,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    colorSecondary: {
      '&.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    icon: {
      width: 18,
      height: 18,
      borderRadius: '50%',
      backgroundColor: '#fff',
      border: '2px solid #979797'
    },
    checkedIcon: {
      backgroundColor: '#0F1942',
      transition: 'all 0.15s easy-in-out',

      'input:hover ~ &': {
        backgroundColor: '#0F1942'
      }
    }
  })
);

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      checkedIcon={<span className={cn(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      classes={{
        root: classes.root,
        colorSecondary: classes.colorSecondary
      }}
      {...props}
    />
  );
}

const useFormControlStyles = makeStyles(() =>
  createStyles({
    labelRoot: {
      marginLeft: 0
    },
    labelText: {
      color: '#000',
      letterSpacing: '-0.777778px',
      fontFamily: 'Verlag, sans-serif',
      lineHeight: '26px',
      fontSize: '22px',
      fontWeight: 400
    }
  })
);

interface RadioWithLabelProps {
  value: string | number;
  label: string;
  isDisabled?: boolean;
  classes?: {
    labelRootClass?: string;
    labelClass?: string;
  };
}

const RadioWithLabel = ({ value, label, isDisabled, classes }: RadioWithLabelProps): JSX.Element => {
  const { labelRoot, labelText } = useFormControlStyles();
  return (
    <FormControlLabel
      value={value}
      label={label}
      classes={{
        root: cn(labelRoot, { [classes?.labelRootClass]: classes?.labelRootClass }),
        label: cn(labelText, { [classes?.labelClass]: classes?.labelClass })
      }}
      control={<StyledRadio disabled={isDisabled} />}
    />
  );
};

export default RadioWithLabel;
