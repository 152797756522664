import React from 'react';
import cn from 'classnames';
import { makeStyles, createStyles } from '@mui/styles';
import MuiModal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

interface StylesProps {
  modal?: {
    padding?: string;
    alignItems?: string;
    justifyContent?: string;
    transition?: string;
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: (props: StylesProps) => ({
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      padding: '10%',
      ...props?.modal
    }),
    backDrop: {
      backgroundColor: 'rgba(196, 196, 196, 0.51)'
    }
  })
);

const MODAL_TRANSITION_TIMEOUT = 300;
const MODAL_BACKDROP_TIMEOUT = 500;

interface ModalProps {
  children?: React.ReactNode;
  onToggle?: () => void;
  isOpen: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  styles?: { backDrop?: string; modalClass?: string };
  stylesProps?: StylesProps;
}

const Modal = ({
  children,
  onToggle = null,
  isOpen,
  disableBackdropClick,
  disableEscapeKeyDown,
  styles,
  stylesProps
}: ModalProps): JSX.Element => {
  const classes = useStyles(stylesProps);

  return (
    <MuiModal
      className={cn(classes.modal, { [styles?.modalClass]: styles?.modalClass })}
      open={isOpen}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return;
        if (reason === 'escapeKeyDown' && disableEscapeKeyDown) return;

        onToggle();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: { root: cn(classes.backDrop, styles?.backDrop) },
        timeout: MODAL_BACKDROP_TIMEOUT
      }}
    >
      <Fade in={isOpen} timeout={MODAL_TRANSITION_TIMEOUT}>
        <div style={{ zIndex: 1 }}>{children}</div>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
