import React, { FC } from 'react';
import { BlackBoxButton, ModalWithAside, ShouldRender, Typography } from 'src/components';
import exclamationImg from 'src/assets/images/exclamation_mark_rounded.png';
import styles from './DeleteAllocationError.module.scss';

type Props = {
  deleteErrorMsg: string | null;
  isOpen: boolean;
  onClose: () => void;
  isMaxError: boolean;
};

const MAX_ALLOCATIONS_ERROR = 'User can’t have more than 100 allocations';

const DeleteAllocationError: FC<Props> = ({ deleteErrorMsg, isOpen, onClose, isMaxError }) => {
  return (
    <ModalWithAside isOpen={isOpen} onToggle={onClose}>
      <ShouldRender should={!!deleteErrorMsg || isMaxError}>
        <div className={styles.deleteMessageBlock}>
          <div className={styles.imgWrapper}>
            <img src={exclamationImg} alt="attention" className="img-fluid" />
          </div>

          <Typography className={styles.deleteMessageText}>
            {isMaxError ? MAX_ALLOCATIONS_ERROR : deleteErrorMsg}
          </Typography>
          <BlackBoxButton type="button" label="Accept" onClick={onClose} btnClass={styles.deleteMessageBtn} />
        </div>
      </ShouldRender>
    </ModalWithAside>
  );
};

export default DeleteAllocationError;
