import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CheckboxBlock, BlackBoxButton } from 'src/components';
import Fade from 'src/components/reactTransitions/FadeTransition.module.css';
import styles from './Filter.module.scss';

const POPPER_TRANSITION_TIMEOUT = 150;

export interface IFilter {
  id: string;
  blockLabel: string;
  list: Array<{
    id: string;
    name: string;
    isChecked: boolean;
  }>;
  isAllChecked: boolean;
}

interface FilterProps {
  isViewCase?: boolean;
  filters: Array<IFilter>;
  onChangeFilters: (blockId: string, id: string, isChecked: boolean, name: string) => void;
  onSelectAllFilters: (name: string, isChecked: boolean) => void;
  classes?: {
    dropdownClass?: string;
    btnClass?: string;
  };
  withTruncateLabels?: boolean;
  truncateWidth?: number;
}

const Filter = ({
  filters,
  onChangeFilters,
  onSelectAllFilters,
  isViewCase,
  classes,
  withTruncateLabels,
  truncateWidth
}: FilterProps): JSX.Element => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const handleToggleFilter = () => setFilterOpen((prev) => !prev);
  const handleClickAway = () => setFilterOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.filterWrapper}>
        <BlackBoxButton
          onClick={handleToggleFilter}
          btnClass={cn({ [classes?.btnClass]: classes?.btnClass })}
          label="Filter"
        />

        <CSSTransition in={isFilterOpen} timeout={POPPER_TRANSITION_TIMEOUT} classNames={{ ...Fade }} unmountOnExit>
          <div className={cn(styles.filterDropdown, { [classes?.dropdownClass]: classes?.dropdownClass })}>
            <div className={styles.dropdownContent}>
              <ul className={styles.checkboxBlocsList}>
                {!isEmpty(filters) &&
                  filters.map(({ id, blockLabel, list, isAllChecked }) =>
                    !isEmpty(list) ? (
                      <li key={id} className={styles.checkboxBlockItem}>
                        <CheckboxBlock
                          isDisabled={isViewCase}
                          blockId={id}
                          list={list}
                          blockLabel={blockLabel}
                          isAllChecked={isAllChecked}
                          onChange={onChangeFilters}
                          onSelectAll={onSelectAllFilters}
                          withTruncate={withTruncateLabels}
                          truncateWidth={truncateWidth}
                        />
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </div>
        </CSSTransition>
      </div>
    </ClickAwayListener>
  );
};

export default Filter;
