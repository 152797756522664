import React, { FC, useEffect, useMemo, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import isNil from 'lodash/isNil';
import { PieChart } from 'react-minimal-pie-chart';
import { StatementBox } from 'src/components';
import { InvestmentStatistic } from 'src/interfaces';
import { formatMoney } from 'src/helpers';
import { pieCharColors } from 'src/constants/colors';
import { chartDataSchema } from './constants';
import styles from './CapitalDistributionBlock.module.scss';

interface Props {
  title?: string;
  data: InvestmentStatistic;
  isLoading?: boolean;
  currency?: string;
}

const hasValue = (value: number | undefined): boolean => !isNil(value);

const CapitalDistributionBlock: FC<Props> = ({ title, isLoading, data, currency }): JSX.Element => {
  const [pieData, setPieData] = useState([]);
  const [statisticsData, setStatisticsData] = useState<InvestmentStatistic>({} as InvestmentStatistic);
  const [selected, setSelected] = useState<number | undefined>(undefined);

  const chartDataSchemaFiltered = useMemo(
    () => chartDataSchema.filter((item) => hasValue(data?.late_bins_by_current_invested_perc?.[item.field])),
    [data]
  );

  useEffect(() => {
    if (!data) return;

    const filteredData = chartDataSchema.filter((item) =>
      hasValue(data?.late_bins_by_current_invested_perc?.[item.field])
    );

    const list = filteredData.reduce(
      (acc, item, idx) =>
        hasValue(data?.late_bins_by_current_invested_perc?.[item.field])
          ? [
              ...acc,
              {
                title: item.title,
                value: data?.late_bins_by_current_invested_perc?.[item.field] ?? 0,
                color: pieCharColors[idx]
              }
            ]
          : acc,
      []
    );

    setPieData(list);
    setStatisticsData(data);
  }, [data]);

  const outstandingAmounts = chartDataSchemaFiltered?.filter((item) => item.field !== 'Repaid');
  return (
    <StatementBox className={styles.wrapper}>
      <React.Fragment>
        <h2 className={styles.title}>{title}</h2>

        {!isLoading ? (
          <div className={styles.body}>
            <div className={styles.summary}>
              <div className={styles.rowsBlock}>
                <div className={styles.row}>
                  <div>My Investments</div>
                  <div>
                    {hasValue(statisticsData?.my_investments)
                      ? formatMoney(statisticsData?.my_investments, currency)
                      : '-'}
                  </div>
                </div>
              </div>

              <div className={styles.rowsBlock}>
                <div className={styles.row}>
                  <div>Total Payments</div>
                  <div>
                    {hasValue(statisticsData?.total_payments)
                      ? formatMoney(statisticsData?.total_payments, currency)
                      : '-'}
                  </div>
                </div>

                <div className={styles.subrow}>
                  <div>Repaid</div>
                  <div>
                    {hasValue(statisticsData?.late_bins_by_current_invested?.Repaid)
                      ? formatMoney(statisticsData?.late_bins_by_current_invested?.Repaid, currency)
                      : '-'}
                  </div>
                </div>

                <div className={styles.subrow}>
                  <div>Interest Payment</div>
                  <div>
                    {hasValue(statisticsData?.interest_payment)
                      ? formatMoney(statisticsData?.interest_payment, currency)
                      : '-'}
                  </div>
                </div>

                <div className={styles.subrow}>
                  <div>Late fees</div>
                  <div>
                    {hasValue(statisticsData?.late_fees) ? formatMoney(statisticsData?.late_fees, currency) : '-'}
                  </div>
                </div>

                {hasValue(statisticsData?.originator_fees) ? (
                  <div className={styles.subrow}>
                    <div>Originator Fees</div>
                    <div>
                      {hasValue(statisticsData?.originator_fees)
                        ? formatMoney(statisticsData?.originator_fees, currency)
                        : '-'}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={styles.rowsBlock}>
                <div className={styles.row}>
                  <div>Principal Capital</div>
                  <div></div>
                </div>

                <div className={styles.row}>
                  <div>Paid-back</div>
                  <div>
                    {hasValue(statisticsData?.paid_back) ? formatMoney(statisticsData?.paid_back, currency) : '-'}
                  </div>
                </div>

                <div className={styles.row}>
                  <div>Outstanding amounts</div>
                </div>

                {outstandingAmounts?.map((item) =>
                  hasValue(statisticsData?.late_bins_by_current_invested?.[item.field]) ? (
                    <div key={item.field} className={styles.subrow}>
                      <div>{item.title}</div>
                      <div>{formatMoney(statisticsData?.late_bins_by_current_invested?.[item.field], currency)}</div>
                    </div>
                  ) : null
                )}
              </div>
            </div>

            <div className={styles.chart}>
              <div style={{ width: '500px', height: '500px', textAlign: 'center' }}>
                <PieChart
                  data={pieData}
                  lineWidth={45}
                  animate
                  startAngle={270}
                  className={styles.pieChart}
                  segmentsStyle={(idx) => ({
                    strokeWidth: idx === selected ? 23 : 20,
                    transition: 'stroke-width 0.3s',
                    cursor: 'pointer'
                  })}
                  onClick={(event, idx) => setSelected(idx)}
                  onMouseOver={(event, idx) => setSelected(idx)}
                  onMouseOut={() => setSelected(null)}
                  animationDuration={500}
                  animationEasing="ease-in-out"
                  labelPosition={0}
                  labelStyle={{ fontSize: '0.3em', lineBreak: 'strict' }}
                  label={({ x, y, dx, dy }) => {
                    if (isNil(selected)) return;

                    const data = chartDataSchemaFiltered?.[selected];
                    const hasData = hasValue(statisticsData?.late_bins_by_current_invested?.[data?.field]);

                    return (
                      <svg>
                        <text
                          x={x}
                          y={y - 4}
                          dx={dx}
                          dy={dy}
                          dominantBaseline="central"
                          textAnchor="middle"
                          style={{
                            fontSize: '5px',
                            fontFamily: 'Verlag, sans-serif'
                          }}
                        >
                          {data?.title ?? ''}
                        </text>
                        <text
                          x={x}
                          y={y + 2}
                          dx={dx}
                          dy={dy}
                          dominantBaseline="central"
                          textAnchor="middle"
                          style={{
                            fontSize: '5px',
                            fontFamily: 'Verlag, sans-serif'
                          }}
                        >
                          {`${
                            hasData
                              ? formatMoney(statisticsData?.late_bins_by_current_invested?.[data?.field], currency)
                              : '0'
                          }`}
                        </text>
                      </svg>
                    );
                  }}
                />
              </div>
              <div className={styles.chartLegend}>
                {chartDataSchemaFiltered.map((item, idx) => (
                  <div
                    key={item.title}
                    className={styles.legentRow}
                    onMouseEnter={() => setSelected(idx)}
                    onMouseLeave={() => setSelected(null)}
                  >
                    <div className={styles.legentDot} style={{ backgroundColor: pieCharColors[idx] }}></div>
                    <div className={styles.legentDescription}>{item?.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.loaderWrapper}>
            <PuffLoader size={80} color="#077186" />
          </div>
        )}
      </React.Fragment>
    </StatementBox>
  );
};

export default CapitalDistributionBlock;
