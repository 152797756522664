import React, { FC, memo, useState, useCallback } from 'react';
import { getMonth } from 'date-fns';
import { DatePicker } from 'src/components/DatePicker';
import { PopperPlacementEnum } from 'src/components/DatePicker/DatePicker';
import { FormRow } from '../FormRow';
import styles from './styles.module.scss';

type TDatePickerRowProps = {
  id: string;
  onDateChange: (date: Date) => void;
  onBlur?: (e) => void;
  onClearReqError?: () => void;
};

const DatePickerRow: FC<TDatePickerRowProps> = memo(({ id, onDateChange, onBlur, onClearReqError }) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const onOpenPicker = useCallback(() => {
    setDatePickerOpen(true);
    if (onClearReqError) onClearReqError();
  }, [onClearReqError]);

  const onClosePicker = useCallback(() => {
    setDatePickerOpen(false);
    if (onClearReqError) onClearReqError();
  }, [onClearReqError]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [selectedMonth, setSelectedMonth] = useState<number>(getMonth(selectedDate));
  const handleSetSelectedMonth = (_, value: number) => setSelectedMonth(value);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    if (getMonth(date) !== selectedMonth) setSelectedMonth(getMonth(date));

    if (onDateChange) onDateChange(date);
  };

  return (
    <FormRow label="Transaction Date:">
      <DatePicker
        id={id}
        value={selectedDate}
        onBlur={onBlur}
        onDateChange={(_, date) => handleDateChange(date)}
        selectedMonth={selectedMonth}
        onSetSelectedMonth={handleSetSelectedMonth}
        isCalendarOpen={isDatePickerOpen}
        onOpenCalendar={onOpenPicker}
        onCloseCalendar={onClosePicker}
        calendarLabel="Transaction Date"
        popperPlacement={PopperPlacementEnum.BOTTOM_START}
        popperOffset={{ y: 4 }}
        dateInputClassName={styles.dateInputClass}
      />
    </FormRow>
  );
});

DatePickerRow.displayName = 'DatePickerRow';

export default DatePickerRow;
