import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import round from 'lodash/round';
import { PercentageInput, CustomizedCheckbox, ShouldRender } from 'src/components';
import { adjustPieChartPercentage, setDefaultPieChartPercentage } from 'src/helpers/portfolioBuilderHelpers';
import { chartColors } from 'src/constants/colors';
import styles from './PercentageDropdown.module.scss';

export interface PercentageDropdownItemShape {
  id: string;
  title: string;
  percentage: number;
  isSelected?: boolean;
  isPreset?: boolean;
}

interface PercentageDropdownShape {
  data: PercentageDropdownItemShape[];
  onChange: (data: PercentageDropdownItemShape[]) => void;
  maxRowsCount?: number;
  windowPadding?: number;
  children?: React.ReactNode;
  isStandalone?: boolean;
  showColorMarkerLegend?: boolean;
  showTotalHeader?: boolean;
  customClasses?: {
    column?: string;
    baseWrapperClass?: string;
  };
  isViewCase?: boolean;
  onError: (err) => void;
  setDefault?: boolean;
}

const checkIsInsideClick = (event, target) => !!event?.path?.find((item) => item === target);

const PercentageDropdown = ({
  data,
  onChange,
  children,
  maxRowsCount = 5,
  windowPadding = 10,
  isStandalone = false,
  showTotalHeader = true,
  showColorMarkerLegend = false,
  customClasses = {},
  isViewCase,
  setDefault = true,
  onError
}: PercentageDropdownShape): JSX.Element => {
  const wrapperRef = useRef(null);
  const targetRef = useRef(null);
  const [isError, setIsError] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [paramsState, setParamsState] = useState({
    isActive: false,
    totalValue: 100
  });

  const onOpenListener = useCallback(
    (event) => {
      const isInsideClick = checkIsInsideClick(event, targetRef.current);

      setParamsState((state) => {
        if (state.isActive && isInsideClick) {
          return state;
        }

        return {
          ...state,
          isActive: true
        };
      });
    },
    // eslint-disable-next-line
    [windowPadding]
  );

  const onCloseListener = (event) => {
    const isInsideClick = checkIsInsideClick(event, targetRef.current);

    if (!isInsideClick) {
      setParamsState((state) => ({
        ...state,
        isActive: false
      }));
    }
  };

  const update = (list) => onChange(list);

  const updateTotalValue = useCallback(
    (list: PercentageDropdownItemShape[]) => {
      const totalValue = list.reduce((acc, item) => acc + get(item, 'percentage', 0), 0);

      if (round(totalValue, 1) !== 100) {
        onError(true);
        setIsError(true);
      } else {
        onError(false);
        setIsError(false);
      }

      setParamsState((state) => ({
        ...state,
        totalValue: round(totalValue, 1)
      }));
    },
    [onError]
  );

  const onItemSelected = (selected) => {
    setDataList((state) => {
      const newState = state.map((item) =>
        item.title === selected.title ? { ...item, isPreset: item.isSelected, isSelected: !item.isSelected } : item
      );

      setDefaultPieChartPercentage(newState, true);

      update(newState);
      updateTotalValue(newState);

      return newState;
    });
  };

  const onItemChanged = (item, value) => {
    setDataList((state) => {
      const newState = state.map((newListItem) =>
        newListItem.title === item.title ? { ...newListItem, percentage: value } : newListItem
      );

      if (!setDefault) {
        setDefaultPieChartPercentage(newState);
      } else {
        adjustPieChartPercentage(newState, item.percentage - value);
      }

      update(newState);
      updateTotalValue(newState);

      return newState;
    });
  };

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    targetRef.current.addEventListener('click', onOpenListener);
    window.addEventListener('click', onCloseListener);
    return () => {
      if (targetRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        targetRef.current.removeEventListener('click', onOpenListener);
      }
      window.removeEventListener('click', onCloseListener);
    };
  }, [onOpenListener]);

  return (
    <div
      ref={targetRef}
      className={cn(styles.baseWrapper, { [customClasses?.baseWrapperClass]: customClasses?.baseWrapperClass })}
    >
      {children}
      <div
        className={cn(styles.wrapper, { [styles.active]: paramsState.isActive, [styles.standalone]: isStandalone })}
        ref={wrapperRef}
      >
        <ShouldRender should={showTotalHeader}>
          <div className={styles.header}>
            <span>Total: </span>
            <span className={styles.totalPercent}>{paramsState.totalValue}%</span>
          </div>
        </ShouldRender>

        <div className={styles.body}>
          {Array.from(Array(Math.ceil(dataList.length / maxRowsCount))).map((dummy, columnIndex) => (
            <div key={columnIndex} className={cn(styles.column, { [customClasses?.column]: customClasses?.column })}>
              {dataList
                .slice(maxRowsCount * columnIndex, maxRowsCount + maxRowsCount * columnIndex)
                .map((item, rowIndex) => {
                  const color = chartColors[(columnIndex * maxRowsCount + rowIndex) % chartColors.length];

                  return (
                    <div className={styles.columnItem} key={item.title}>
                      <CustomizedCheckbox
                        isDisabled={isViewCase}
                        isChecked={item.isSelected}
                        onClick={() => onItemSelected(item)}
                      />

                      {showColorMarkerLegend ? (
                        <div className={styles.dotLegendWrapper} onClick={() => onItemSelected(item)}>
                          <div
                            className={styles.legendMarker}
                            style={{
                              backgroundColor: color
                            }}
                          />
                          <div className={styles.dotLegendTitle}>{item.title}</div>
                        </div>
                      ) : (
                        <div className={styles.dotLegendTitle} onClick={() => onItemSelected(item)}>
                          {item.title}
                        </div>
                      )}

                      <PercentageInput
                        isDisabled={isViewCase}
                        className={styles.input}
                        suffix={'%'}
                        value={item.percentage}
                        onChanged={(value) => onItemChanged(item, value)}
                        isAllowed={!item.isSelected}
                        isError={!item.isSelected && isError}
                      />
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PercentageDropdown;
