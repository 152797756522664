import { sub } from 'date-fns';
import { TableHeaderColumns, ColumnAlignOptions } from 'src/interfaces/CustomTableShapes';
import { SortOrders, selectCurrencyOptions, LoanTypes } from './globalConstants';
import { PAGES } from './pages';
import { IRangeFilter } from 'src/components';

enum EmploymentStatuses {
  UNSPECIFIED = '0',
  UNEMPLOYED = '1',
  PARTIALLY_EMPLOYED = '2',
  FULLY_EMPLOYED = '3',
  SELF_EMPLOYED = '4',
  ENTREPRENEUR = '5',
  RETIREE = '6',
  STUDENT = '7'
}

export const EmploymentStatusesLabels = {
  [EmploymentStatuses.UNSPECIFIED]: 'Other/Unspecified',
  [EmploymentStatuses.UNEMPLOYED]: 'Unemployed',
  [EmploymentStatuses.PARTIALLY_EMPLOYED]: 'Partially Employed',
  [EmploymentStatuses.FULLY_EMPLOYED]: 'Fully Employed',
  [EmploymentStatuses.SELF_EMPLOYED]: 'Selfemployed',
  [EmploymentStatuses.ENTREPRENEUR]: 'Entrepreneur',
  [EmploymentStatuses.RETIREE]: 'Retiree',
  [EmploymentStatuses.STUDENT]: 'Student'
};

enum OccupationAreaTypes {
  UNSPECIFIED = '0',
  MINING = '1',
  PROCESSING = '2',
  ENERGY = '3',
  UTILITIES = '4',
  CONSTRUCTION = '5',
  RETAIL = '6',
  TRANSPORT = '7',
  HOSPITALITY = '8',
  TELECOM = '9',
  FINANCE = '10',
  REAL_ESTATE = '11',
  RESEARCH = '12',
  ADMINISTRATIVE = '13',
  CIVIL_SERVICE = '14',
  EDUCATION = '15',
  HEALTHCARE = '16',
  ART = '17',
  AGRICULTURE = '18'
}

export const OccupationAreaTypesLabels = {
  [OccupationAreaTypes.UNSPECIFIED]: 'Other/Unspecified',
  [OccupationAreaTypes.MINING]: 'Mining',
  [OccupationAreaTypes.PROCESSING]: 'Processing',
  [OccupationAreaTypes.ENERGY]: 'Energy',
  [OccupationAreaTypes.UTILITIES]: 'Utilities',
  [OccupationAreaTypes.CONSTRUCTION]: 'Construction',
  [OccupationAreaTypes.RETAIL]: 'Retail',
  [OccupationAreaTypes.TRANSPORT]: 'Transport',
  [OccupationAreaTypes.HOSPITALITY]: 'Hospitality',
  [OccupationAreaTypes.TELECOM]: 'Telecom',
  [OccupationAreaTypes.FINANCE]: 'Finance',
  [OccupationAreaTypes.REAL_ESTATE]: 'Real Estate',
  [OccupationAreaTypes.RESEARCH]: 'Research',
  [OccupationAreaTypes.ADMINISTRATIVE]: 'Administrative',
  [OccupationAreaTypes.CIVIL_SERVICE]: 'Civil Service',
  [OccupationAreaTypes.EDUCATION]: 'Education',
  [OccupationAreaTypes.HEALTHCARE]: 'Healthcare',
  [OccupationAreaTypes.ART]: 'Art',
  [OccupationAreaTypes.AGRICULTURE]: 'Agriculture'
};

export enum RangeFiltersTypes {
  LOAN_AMOUNT = 'loanAmount',
  INTEREST_RATE = 'borrowerRate',
  TERM = 'term',
  BORROWER_RATE = 'borrowerRate'
}

export const MARKETPLACE_RANGE_FILTERS = (currency: string, loanType: LoanTypes): IRangeFilter[] => {
  const termInDays = [LoanTypes.INVOICE_FINANCING].includes(loanType);

  return [
    {
      id: RangeFiltersTypes.LOAN_AMOUNT,
      label: `Loan Amount (${currency})`,
      step: 1,
      valueLabels: {
        isLabelInputs: true,
        maxThousandSeparator: true,
        minThousandSeparator: true
      }
    },
    {
      id: RangeFiltersTypes.BORROWER_RATE,
      label: 'Interest Rate (%)',
      step: 0.1,
      valueLabels: {
        isLabelInputs: true
      }
    },
    {
      id: RangeFiltersTypes.TERM,
      label: `Term (${termInDays ? 'days' : 'months'})`,
      step: 1,
      decimalScale: 0,
      valueLabels: {
        isLabelInputs: true
      }
    }
  ];
};

export enum LoanSortTypes {
  ORIGINATION_DATE = 'loan_origination_date',
  GRADE = 'grade',
  INTEREST_RATE = 'borrower_rate',
  TERM = 'term_original',
  LOAN_AMOUNT = 'loan_amount',
  RELATIVE_VALUE = 'percentile'
}

export enum MarketplaceColIDs {
  ID = 'id',
  ORIGINATION_DATE = 'loan_origination_date',
  LOAN_AMOUNT = 'loan_amount',
  INTEREST_RATE = 'borrower_rate',
  GRADE = 'grade',
  RELATIVE_VALUE = 'percentile',
  SECTOR = 'sector',
  TERM = 'term_original',
  LOAN_PURPOSE = 'loanPurpose'
}

export const MARKETPLACE_COLUMNS: TableHeaderColumns = [
  {
    label: 'Loan ID',
    id: MarketplaceColIDs.ID,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.LEFT,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Listing Date',
    id: LoanSortTypes.ORIGINATION_DATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Latest', order: SortOrders.DESC },
      { title: 'Oldest', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Loan Amount',
    id: LoanSortTypes.LOAN_AMOUNT,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Interest Rate',
    id: LoanSortTypes.INTEREST_RATE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Exaloan Score',
    id: LoanSortTypes.GRADE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.ASC },
      { title: 'Low - High', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Relative Value',
    id: LoanSortTypes.RELATIVE_VALUE,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'SME Sector',
    id: MarketplaceColIDs.SECTOR,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.SME]
  },
  {
    label: 'Term',
    id: LoanSortTypes.TERM,
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'Shortest - Longest', order: SortOrders.ASC },
      { title: 'Longest - Shortest', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME, LoanTypes.INVOICE_FINANCING]
  },
  {
    label: 'Loan Purpose',
    id: MarketplaceColIDs.LOAN_PURPOSE,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER,
    loanTypes: [LoanTypes.CONSUMER, LoanTypes.SME]
  }
];

export const SET_RANGES_PARAMS_DEBOUNCE_TIMEOUT = 300;
export const INITIAL_DATE_PICKER_FROM = sub(new Date(), { years: 5 });
export const INITIAL_DATE_PICKER_TO = new Date();

export const subPagesByLoanTypes = {
  [LoanTypes.CONSUMER]: PAGES.MARKETPLACE_CONSUMER,
  [LoanTypes.SME]: PAGES.MARKETPLACE_SME,
  [LoanTypes.INVOICE_FINANCING]: PAGES.MARKETPLACE_INVOICE_FINANCING
};

export const GET_LOANS_DEFAULT_PARAMS = {
  page: 1,
  limit: 100,
  sort: { type: LoanSortTypes.ORIGINATION_DATE, order: SortOrders.DESC },
  date: {
    startDate: INITIAL_DATE_PICKER_FROM,
    endDate: INITIAL_DATE_PICKER_TO
  },
  currency: selectCurrencyOptions[1].value,
  loan_type: [],
  country: [],
  platform: [],
  grade: [],
  sector: [],
  smePurpose: [],
  relative: [],
  type: LoanTypes.CONSUMER
};

export enum MarketplaceCheckboxEnums {
  COUNTRIES = 'countries',
  LOAN_TYPES = 'loanTypes',
  PLATFORMS = 'platforms',
  GRADES = 'grades',
  SME_PURPOSES = 'smePurposes',
  SME_SECTORS = 'sectors',
  RELATIVE_VALUE = 'relative'
}

export enum Provider {
  FLEXFUNDING = 'FlexFunding',
  BONDORA = 'Bondora',
  CROWD4CASH = 'Crowd4Cash',
  PINJAMWINWIN = 'Pinjamwinwin',
  GELDVOORELKAAR = 'Geldvoorelkaar',
  COLECTUAL = 'Colectual',
  CREDITSHELF = 'Creditshelf',
  LEND = 'Lend',
  EXALENDER = 'Exalender',
  FINBEE = 'Finbee',
  VAURAUS = 'Vauraus',
  ACREDIUS = 'Acredius',
  ANODU = 'Anodu'
}

export enum ProviderIds {
  FlexFunding = 'FLEXFUNDING',
  Bondora = 'BONDORA',
  Crowd4Cash = 'CROWD4CASH',
  Pinjamwinwin = 'PINJAMWINWIN',
  Geldvoorelkaar = 'GELDVOORELKAAR',
  Colectual = 'COLECTUAL',
  Creditshelf = 'CREDITSHELF',
  LEND = 'LEND',
  EXALENDER = 'EXALENDER',
  FINBEE = 'FINBEE',
  VAURAUS = 'VAURAUS',
  ACREDIUS = 'ACREDIUS',
  ANODU = 'ANODU'
}

export const marketplaceCheckboxBlockLabels = {
  [MarketplaceCheckboxEnums.COUNTRIES]: 'Countries',
  [MarketplaceCheckboxEnums.LOAN_TYPES]: 'Loan Purposes',
  [MarketplaceCheckboxEnums.PLATFORMS]: 'Platforms',
  [MarketplaceCheckboxEnums.GRADES]: 'Exaloan Score',
  [MarketplaceCheckboxEnums.SME_PURPOSES]: 'Loan Purposes',
  [MarketplaceCheckboxEnums.SME_SECTORS]: 'Loan Sectors',
  [MarketplaceCheckboxEnums.RELATIVE_VALUE]: 'Relative Value'
};

export const consumerFilterIds = [
  MarketplaceCheckboxEnums.COUNTRIES,
  MarketplaceCheckboxEnums.LOAN_TYPES,
  MarketplaceCheckboxEnums.PLATFORMS,
  MarketplaceCheckboxEnums.GRADES,
  MarketplaceCheckboxEnums.RELATIVE_VALUE
];

export const smeFilterIds = [
  MarketplaceCheckboxEnums.COUNTRIES,
  MarketplaceCheckboxEnums.SME_PURPOSES,
  MarketplaceCheckboxEnums.SME_SECTORS,
  MarketplaceCheckboxEnums.PLATFORMS,
  MarketplaceCheckboxEnums.GRADES,
  MarketplaceCheckboxEnums.RELATIVE_VALUE
];

export const invoiceFinancingFilterIds = [
  MarketplaceCheckboxEnums.COUNTRIES,
  MarketplaceCheckboxEnums.PLATFORMS,
  MarketplaceCheckboxEnums.GRADES,
  MarketplaceCheckboxEnums.RELATIVE_VALUE
];
