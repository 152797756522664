import React, { FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import FadeTransition from 'src/components/reactTransitions/FadeTransition.module.css';
import { DEFAULT_TRANSITION_TIMEOUT } from 'src/constants/globalConstants';
import { Typography, CircularLoader, GreenBoxButton, Modal, ModalLayout } from 'src/components';
import { getTotalOpportunitiesHook } from 'src/hooks';
import style from './OpportunitiesContent.module.scss';
import { IAffected } from './model';

type ConfirmInvestContentProps = {
  onConfirm: () => void;
  onCancel: () => void;
  opportunities: number;
};

const ConfirmInvestContent: FC<ConfirmInvestContentProps> = ({ onConfirm, onCancel, opportunities }) => {
  return (
    <>
      <Typography variant="subtitle1" align="center" className={style.complete_text}>
        Confirm investment in {opportunities} <br />
        opportunity
      </Typography>
      <div className={style.complete_bottom}>
        <div className={style.complete_button}>
          <GreenBoxButton onClick={onConfirm} color="blue" height="sm">
            Confirm
          </GreenBoxButton>
        </div>
        <div className={style.complete_button}>
          <GreenBoxButton onClick={onCancel} color="red" height="sm">
            Decline
          </GreenBoxButton>
        </div>
      </div>
    </>
  );
};

type InvestCompleteProps = {
  onClose: () => void;
  affectedInfo: IAffected;
};

const InvestComplete: FC<InvestCompleteProps> = ({ onClose, affectedInfo }) => {
  getTotalOpportunitiesHook();

  const affectedTitle = affectedInfo?.affected === 1 ? 'opportunity' : 'opportunities';
  const failedTitle = affectedInfo?.failed === 1 ? 'opportunity is' : 'opportunities are';

  return (
    <>
      <div className={style.investComplete}>
        <Typography
          variant="subtitle2"
          align="center"
          className={cn(style.title, { [style.titleWithFailed]: !!affectedInfo?.failed })}
        >
          {`We are sending a signal to invest in ${affectedInfo?.affected} ${affectedTitle}.`}
          {!!affectedInfo?.failed && (
            <Typography variant="subtitle2" align="center" component="span">
              {`Unfortunately, other ${affectedInfo?.failed} ${failedTitle} not available for investing anymore`}
            </Typography>
          )}
        </Typography>

        <Typography variant="body3" align="center" className={style.text}>
          Track current investments status on the Investment page
        </Typography>

        <GreenBoxButton btnClass={style.button} onClick={onClose} color="blue" height="sm">
          Ok
        </GreenBoxButton>
      </div>
    </>
  );
};

const DownloadingContent: FC = () => {
  return (
    <div className={style.downloadingContent}>
      <div className={style.loaderWrapper}>
        <CircularLoader />
      </div>
    </div>
  );
};

interface OpportunitiesInvestProps {
  onConfirmInvest: () => void;
  opportunities: null | number;
  isInvested: boolean;
  buttonText: string;
  affectedInfo: IAffected;
  onCloseResultModal: () => void;
}

const OpportunitiesInvest = ({
  onConfirmInvest,
  opportunities,
  isInvested,
  buttonText,
  affectedInfo,
  onCloseResultModal
}: OpportunitiesInvestProps): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInvesting, setInvesting] = useState(false);

  const handleToggleModal = () => {
    setModalOpen((state) => !state);
    if (isInvested) onCloseResultModal();
    setInvesting(false);
  };

  const handleConfirmInvest = () => {
    setInvesting(true);
    onConfirmInvest();
  };

  const onInvestComplete = useCallback(() => {
    setInvesting(false);
    setModalOpen(false);
    onCloseResultModal();
  }, [onCloseResultModal]);

  return (
    <>
      <div className={style.opportunities_control_invest}>
        <GreenBoxButton disabled={!opportunities} onClick={handleToggleModal} color="blue">
          {buttonText}
        </GreenBoxButton>
      </div>

      <Modal
        isOpen={isModalOpen}
        onToggle={handleToggleModal}
        disableBackdropClick={isInvesting}
        disableEscapeKeyDown={isInvesting}
      >
        <ModalLayout wrapperClass={style.modal}>
          <div className={style.modal_content}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={isInvested ? 'downloadComplete' : 'processDownloading'}
                timeout={DEFAULT_TRANSITION_TIMEOUT}
                classNames={{ ...FadeTransition }}
              >
                {isInvested ? (
                  <InvestComplete onClose={onInvestComplete} affectedInfo={affectedInfo} />
                ) : (
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={isInvesting ? 'isDownloading' : 'confirmDownload'}
                      timeout={DEFAULT_TRANSITION_TIMEOUT}
                      classNames={{ ...FadeTransition }}
                    >
                      {isInvesting ? (
                        <DownloadingContent />
                      ) : (
                        <ConfirmInvestContent
                          onConfirm={handleConfirmInvest}
                          onCancel={handleToggleModal}
                          opportunities={opportunities}
                        />
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                )}
              </CSSTransition>
            </SwitchTransition>
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default OpportunitiesInvest;
