import React from 'react';
import { LOAN_TABLE_DATE_FORMAT } from 'src/constants/globalConstants';
import { IApiData } from 'src/interfaces';
import { formatDateFromJSON } from 'src/helpers';
import { ApiColIDs } from './model';
import styles from './ApiSettingsPage.module.scss';
import { GreenBoxButton } from 'src/components';

export const renderApiTableContent = (
  colId: ApiColIDs,
  row: IApiData,
  isLoading: boolean,
  onDeleteToken: (appID: string) => void
): JSX.Element => {
  const value = row?.[colId] ?? null;

  switch (colId) {
    case ApiColIDs.name:
    case ApiColIDs.app_id:
      return <span>{value}</span>;

    case ApiColIDs.created_at:
      return <span>{value ? formatDateFromJSON(value, LOAN_TABLE_DATE_FORMAT) : '-'}</span>;

    case ApiColIDs.expires_at:
      return <span>{value ? formatDateFromJSON(value, LOAN_TABLE_DATE_FORMAT) : 'Never'}</span>;

    case ApiColIDs.delete:
      return row?.app_id ? (
        <GreenBoxButton onClick={() => onDeleteToken(row.app_id)} disabled={isLoading} btnClass={styles.deleteBtn}>
          Delete
        </GreenBoxButton>
      ) : null;

    default:
      return <span>{value}</span>;
  }
};
