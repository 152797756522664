import React, { memo, FC, useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { renderToString } from 'react-dom/server';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { formatMoney, hexToRgba } from 'src/helpers';
import { GroupBy } from 'src/constants/statisticsConstants';
import { TStatisticsColumnMappedChartProps, TGetChartConfigPayload } from './types';
import { statisticChunkGroupper, statisticMappedChartSerializer } from 'src/helpers/statisticsHelpers';

const StatisticsColumnMappedChart: FC<TStatisticsColumnMappedChartProps> = memo(
  ({ data, type, currency, wrapperClassName, yAxisType, columnStackingType, colors, externalChartOptions }) => {
    const groups = useMemo(
      () => Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: statisticChunkGroupper(data?.[key], type) }), {}),
      [data, type]
    );

    const mappedData = useMemo(() => statisticMappedChartSerializer(groups), [groups]);

    const options: Highcharts.Options = useMemo(
      () =>
        isEmpty(externalChartOptions)
          ? getChartConfig({ series: mappedData, currency, yAxisType, columnStackingType, colors })
          : merge(
              {},
              getChartConfig({ series: mappedData, currency, yAxisType, columnStackingType, colors }),
              externalChartOptions
            ),
      [mappedData, currency, yAxisType, columnStackingType, colors, externalChartOptions]
    );

    return (
      <div className={cn({ [wrapperClassName]: wrapperClassName })}>
        {/* //* current usage of conditional rendering is needed to redraw chart correctly, shouldn't change it. */}
        {type === GroupBy.DAY && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.MONTH && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.WEEK && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.YEAR && <HighchartsReact options={options} highcharts={Highcharts} />}
        {typeof type === 'undefined' && <HighchartsReact options={options} highcharts={Highcharts} />}
      </div>
    );
  }
);

const getChartConfig = ({
  series,
  currency,
  yAxisType = 'linear',
  columnStackingType = 'normal',
  colors = ['#077186', '#69B6BE']
}: TGetChartConfigPayload): Highcharts.Options => {
  const plotOptions = {
    column: {
      stacking: columnStackingType
    },

    series: {
      lineWidth: 150,
      cropThreshold: 1000,
      minPointLength: 0
    }
  } as Highcharts.PlotOptions;

  return {
    series: series,
    colors: colors,

    chart: {
      type: 'column'
    },

    xAxis: {
      tickLength: 5,

      labels: {
        enabled: true,
        formatter: function () {
          if (this.pos > series[this.pos]?.dates.length) return '';

          const currentObject = series[0]?.dates[this.pos];

          return `${currentObject ?? ''}`;
        }
      }
    },

    yAxis: {
      type: yAxisType,

      title: {
        text: ''
      },
      labels: {
        enabled: true,
        formatter: function () {
          return `${columnStackingType === 'normal' ? formatMoney(this.value, currency) : this.value + '%'}`;
        }
      }
    },

    plotOptions: plotOptions,

    tooltip: {
      enabled: true,
      shared: true,
      useHTML: true,
      backgroundColor: '#fff',
      borderColor: '#077186',

      positioner: function (labelWidth, _, point) {
        let tooltipX = point.plotX + this.chart.plotLeft - labelWidth / 2;

        const shouldSetRight = this.chart.chartWidth < tooltipX + labelWidth * 1.01;
        const shouldSetLeft = tooltipX < 0 || tooltipX < labelWidth * 0.02;

        if (shouldSetRight) tooltipX = point.plotX + this.chart.plotLeft - labelWidth * 0.85;
        if (shouldSetLeft) tooltipX = point.plotX * 0.5;

        return {
          x: tooltipX,
          y: point.plotY
        };
      },

      formatter: function () {
        const pointX = this.points?.[0]?.x;
        const date = series?.[0]?.dates?.[pointX];

        return renderToString(
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3px' }}>
            {date && <span style={{ fontSize: 16, fontWeight: 500, lineHeight: 1, marginBottom: '2px' }}>{date}</span>}

            {this.points.map((point) => (
              <div key={point.y}>
                <span style={{ color: point.color as string, lineHeight: 1 }}>●</span>
                &nbsp;
                <span
                  style={{
                    color: hexToRgba('#077186', 0.9),
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: 1
                  }}
                >
                  {formatMoney(point.y, currency)}
                </span>
                <span style={{ fontSize: 16, fontWeight: 500, lineHeight: 1 }}>
                  :{' '}
                  <span style={{ fontSize: 16, fontWeight: 600, lineHeight: 1 }}>{point.percentage?.toFixed(2)}%</span>
                </span>
              </div>
            ))}
          </div>
        );
      }
    },

    title: { text: '' },
    subtitle: { text: '' },
    credits: { enabled: false },
    accessibility: { announceNewData: { enabled: true } }
  };
};

StatisticsColumnMappedChart.displayName = 'StatisticsColumnMappedChart';

export default StatisticsColumnMappedChart;
