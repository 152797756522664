import React, { FC } from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import { Map, Typography } from 'src/components';
import { formatPercentage } from 'src/helpers';
import { ICurrency } from 'src/interfaces';
import { currencySymbols } from 'src/constants/globalConstants';
import styles from '../DashboardPage.module.scss';

type Props = {
  currency: ICurrency;
  cumulativeReturn: number;
  annualReturn: number;
  investmentByCountry: Record<string, number>;
  uninvestedAmount: number;
  extendedIrr: number;
};

const Widgets: FC<Props> = ({
  currency,
  cumulativeReturn = 0,
  annualReturn = 0,
  investmentByCountry,
  uninvestedAmount,
  extendedIrr
}) => (
  <div className={styles.widgets}>
    <div className={styles.blocks}>
      <div className={cn(styles.block, styles.returnIndexBlock)}>
        <Typography variant="h3" weight={300} display="block" align="center" className={styles.widgetsTitle}>
          My Return Index
        </Typography>

        <div className={styles.widgetsReturnIndexBox}>
          <div>
            <Typography variant="subtitle2" display="block" align="center">
              Cumulative
            </Typography>
            <Typography variant="h1" component="p" display="block" weight={300} align="center">
              {formatPercentage(cumulativeReturn)}
            </Typography>
          </div>

          <div>
            <Typography variant="subtitle2" display="block" align="center">
              Annualized
            </Typography>
            <Typography variant="h1" component="p" display="block" weight={300} align="center">
              {formatPercentage(annualReturn)}
            </Typography>
          </div>

          <div>
            <Typography variant="subtitle2" display="block" align="center">
              IRR
            </Typography>
            <Typography variant="h1" component="p" display="block" weight={300} align="center">
              {formatPercentage(extendedIrr)}
            </Typography>
          </div>
        </div>
      </div>

      <div className={cn(styles.block, styles.unusedAmountBlock)}>
        <Typography variant="h3" weight={300} display="block" align="center" className={styles.widgetsTitle}>
          Dry Powder
        </Typography>

        <div className={styles.unusedAmountBox}>
          <NumberFormat
            type="text"
            displayType="text"
            thousandSeparator
            prefix={`${currencySymbols[currency.value]} `}
            value={uninvestedAmount.toFixed(2)}
            className={styles.unusedAmountValue}
          />
        </div>
      </div>
    </div>
    <div className={styles.mapBlock}>
      <Map countries={investmentByCountry} currency={currency} />
    </div>
  </div>
);

export default Widgets;
