import * as Yup from 'yup';
import { latinUmlautNumbersSymbolRegex } from 'src/helpers';
import { InputEnums } from 'src/interfaces/RandomShapes';

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Wrong format. Please, enter the email as “your@email.com”')
    .required('Email cannot be blank'),
  code: Yup.string().required('This field is required'),

  password: Yup.string()
    .required('Password can’t be blank')
    .min(8, 'Need at least 8 chars long')
    .matches(latinUmlautNumbersSymbolRegex, 'Please, use only Latin')
    .matches(/[a-z]/, 'Need at least 1 lowercase')
    .matches(/[A-Z]/, 'Need at least 1 uppercase')
    .matches(/[0-9]/, 'Need at least 1 digit')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/, 'Need at least 1 symbol character.'),

  confirmPassword: Yup.string()
    .required('Confirm password can’t be blank')
    .oneOf([Yup.ref('password')], 'Passwords mismatch')
});

export const formFields = [
  {
    name: 'email',
    id: 'email',
    type: InputEnums.EMAIL,
    placeholder: 'Confirm Email'
  },
  {
    name: 'code',
    id: 'code',
    type: InputEnums.TEXT,
    placeholder: 'Verification Code'
  },
  {
    name: 'password',
    id: 'password',
    type: InputEnums.PASSWORD,
    placeholder: 'New Password'
  },
  {
    name: 'confirmPassword',
    id: 'confirmPassword',
    type: InputEnums.PASSWORD,
    placeholder: 'Confirm Password'
  }
];
