import { IAction } from 'src/interfaces';
import { TransactionsReducer, IClientReport, IClientTransaction } from 'src/interfaces/reportsReduxShapes';
import { reportTypes, authActionTypes } from '../types';

const initialState: TransactionsReducer = {
  transactions: [],
  cashflow: []
};

export default (state = initialState, { type, payload }: IAction): TransactionsReducer => {
  switch (type) {
    case reportTypes.PUT_REPORTS:
      return { ...state, cashflow: payload };

    case reportTypes.PUT_MORE_REPORTS_BY_PLATFORM:
      return {
        ...state,
        cashflow: state.cashflow.map((report: IClientReport) =>
          report.platform === payload.platform
            ? {
                ...report,
                data: [...report.data, ...payload.data],
                clientSidePagination: { ...report.clientSidePagination, page: payload.page }
              }
            : report
        )
      };

    case reportTypes.PUT_TRANSACTIONS:
      return { ...state, transactions: payload };

    case reportTypes.PUT_MORE_TRANSACTIONS_BY_PLATFORM:
      return {
        ...state,
        transactions: state.transactions.map((transaction: IClientTransaction) =>
          transaction.platform === payload.platform
            ? {
                ...transaction,
                data: [...transaction.data, ...payload.data],
                clientSidePagination: { ...transaction.clientSidePagination, page: payload.page }
              }
            : transaction
        )
      };

    case reportTypes.PUT_TRANSACTIONS_AND_REPORTS:
      return { ...state, cashflow: payload.cashflow, transactions: payload.transactions };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
