import { SortOrders, LoanTypes } from 'src/constants/globalConstants';
import { TableHeaderColumns, ColumnAlignOptions, GetOpportunitiesParams } from 'src/interfaces';

export enum OpportunitiesColIDs {
  CHECK_BOX = 'checkbox',
  ORIGINATOR = 'originator',
  MATCHED_DATE = 'matched_date',
  LOAN_AMOUNT = 'loan_amount',
  INTEREST_RATE = 'interest_rate',
  MATURITY_DATE = 'maturity_date',
  GRADE = 'grade',
  RELATIVE_VALUE = 'percentile',
  TERM = 'term_original',
  LOAN_PURPOSE = 'loan_purpose',
  SECTOR = 'sector'
}

export enum LoanSortTypes {
  ORIGINATION_DATE = 'origination_date',
  GRADE = 'grade',
  INTEREST_RATE = 'interest_rate',
  TERM = 'term',
  INVESTED_AMOUNT = 'loan_amount',
  MATURITY_DATE = 'maturity_date',
  RELATIVE_VALUE = 'percentile'
}

export const GET_OPPORTUNITIES_DEFAULT_PARAMS = (): GetOpportunitiesParams => ({
  page: 1,
  limit: 100,
  sort: {
    type: LoanSortTypes.INVESTED_AMOUNT,
    order: SortOrders.DESC
  },
  relative: []
});

export const OPPORTUNITIES_TABLE_HEADINGS_LIST = (loanType?: LoanTypes): TableHeaderColumns => {
  const list: TableHeaderColumns = [
    {
      label: '',
      id: OpportunitiesColIDs.CHECK_BOX,
      isSortable: false,
      isOpen: false,
      sortOptions: null,
      isCheckbox: true,
      width: 3,
      align: ColumnAlignOptions.LEFT
    },
    {
      label: 'Loan Originator',
      id: OpportunitiesColIDs.ORIGINATOR,
      isSortable: false,
      isOpen: false,
      sortOptions: null,
      align: ColumnAlignOptions.LEFT
    },
    {
      label: 'Opportunity Date',
      id: OpportunitiesColIDs.MATCHED_DATE,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'Newest', order: SortOrders.DESC },
        { title: 'Oldest', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Loan Amount',
      id: OpportunitiesColIDs.LOAN_AMOUNT,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'High - Low', order: SortOrders.DESC },
        { title: 'Low - High', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Interest Rate',
      id: OpportunitiesColIDs.INTEREST_RATE,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'High - Low', order: SortOrders.DESC },
        { title: 'Low - High', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Maturity Date',
      id: OpportunitiesColIDs.MATURITY_DATE,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'Newest', order: SortOrders.DESC },
        { title: 'Oldest', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Exaloan Rating',
      id: LoanSortTypes.GRADE,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'High - Low', order: SortOrders.ASC },
        { title: 'Low - High', order: SortOrders.DESC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Relative Value',
      id: LoanSortTypes.RELATIVE_VALUE,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'High - Low', order: SortOrders.DESC },
        { title: 'Low - High', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Term',
      id: OpportunitiesColIDs.TERM,
      isSortable: true,
      isOpen: false,
      sortOptions: [
        { title: 'High - Low', order: SortOrders.DESC },
        { title: 'Low - High', order: SortOrders.ASC }
      ],
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'Loan Purpose',
      id: OpportunitiesColIDs.LOAN_PURPOSE,
      isSortable: false,
      isOpen: false,
      sortOptions: null,
      align: ColumnAlignOptions.CENTER
    },
    {
      label: 'SME Sector',
      id: OpportunitiesColIDs.SECTOR,
      isSortable: false,
      isOpen: false,
      sortOptions: null,
      align: ColumnAlignOptions.CENTER
    }
  ];

  if (loanType === LoanTypes.CONSUMER) return list.filter(({ id }) => id !== OpportunitiesColIDs.SECTOR);

  return list;
};
