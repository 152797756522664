export default {
  GET_LOANS: (query: string): string => `loans?${query}`,
  GET_MY_INVESTMENTS: (query): string => `investments?${query}`,
  GET_OUTSTANDING_BIDS: (query): string => `investments/outstanding?${query}`,
  GET_PORTFOLIO_BUILDER_ALLOCATIONS: (query?: string): string => `allocations${query ? `?${query}` : ''}`,
  GET_ALLOCATION_DETAILS: (id: string): string => `/allocations/${id}`,
  LOAN_TYPES: 'loan-purposes/consumer',
  SME_PURPOSES: 'loan-purposes/sme',
  SME_LOAN_SECTORS: 'loan-sectors/sme',
  SME_LOAN_PURPOSES: '/loan-purposes/sme',
  LOAN_SECTORS: '/loan-sectors/sme',
  LOAN_FILTERS_SUMMARY: (query: string): string => `loans/summary?${query}`,
  LOAN_FILTERS_COUNTRY: 'country',
  LOAN_FILTERS_PLATFORM: (type?: string): string => (type ? `platforms?type=${type}` : 'platforms'),
  LOAN_FILTERS_GRADE: 'grades',
  CURRENCIES_URL: 'platform/currency',
  GET_ALL_ALLOCATIONS: 'allocations?limit=100',
  ADD_ALLOCATION: 'allocations',
  GET_OPPORTUNITIES_ALLOCATIONS: 'investments/opportunities/allocations',
  EDIT_ALLOCATION: (id: string): string => `/allocations/${id}`,
  DELETE_ALLOCATION: (id: string): string => `allocations/${id}`,
  DELETE_ALLOCATION_GROUP: 'allocations',
  START_ALLOCATION: (id: string): string => `allocations/start/${id}`,
  STOP_ALLOCATION: (id: string): string => `allocations/stop/${id}`,
  REGIONS_URL: 'country/region',
  GET_LOAN_CONSTRAINTS: 'loan-sizes',
  GET_LOAN_CONSTRAINTS_TYPE_CURRENCY: (currencies: string, type: string): string =>
    `loan-sizes?currencies=${currencies}&type=${type}`,
  GET_STATISTICS: (currency: string, allocationId: string): string =>
    `/statistic/report${allocationId ? `/${allocationId}` : ''}${currency ? `?currency=${currency}` : ''}`,
  CHECK_NAME: `/allocations/check`,
  GET_TOTAL_OPPORTUNITIES: 'investments/opportunities/total',
  GET_OPPORTUNITIES: (allocationId: string, query?): string => `/investments/opportunities/${allocationId}?${query}`,
  BID_OPPORTUNITIES: 'investments/opportunities/bid',
  GET_INVESTMENT_HISTORY: (id: string): string => `/investments/updates/${id}`,
  GET_INVESTMENT_REPAYMENTS: (investmentId: string, queryStr: string): string =>
    `/investments/repayments/${investmentId}?${queryStr}`,
  REFRESH_STATISTICS: '/statistic/refresh',
  GET_LOAN_DETAILS: (id: string, currency?: string): string =>
    currency ? `/loans/${id}?currency=${currency}` : `/loans/${id}`,
  GET_INVESTMENT_DETAILS: (id: string, currency?: string): string =>
    currency ? `/investments/${id}?currency=${currency}` : `/investments/${id}`,
  GET_REPORTS: (query: string): string => `/investments/cashflow?${query}`,
  GET_TRANSACTIONS: (query: string): string => `/investments/cashflow/transactions?${query}`,
  ADD_TRANSACTION: '/investments/cashflow/transactions',
  DELETE_TRANSACTION: (id: string): string => `/investments/cashflow/transactions/${id}`
};
