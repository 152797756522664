import { statisticsTypes } from '../types';
import * as Shapes from 'src/interfaces/statisticsReduxShapes';
import { InvestmentStatistic } from 'src/interfaces/InvestmentShapes';

export const getStatistics = (
  reqParams: Shapes.GetStatisticsParams,
  onSuccess?: () => void,
  onError?: () => void
): Shapes.GetStatisticsDataAction => ({
  type: statisticsTypes.GET_STATISTICS,
  payload: { reqParams, onSuccess, onError }
});

export const putStatistics = (data: InvestmentStatistic): Shapes.PutStatisticsDataAction => ({
  type: statisticsTypes.PUT_STATISTICS,
  payload: data
});

export const refreshStatistics = (onSuccess?: () => void, onError?: (err) => void): Shapes.RefreshStatisticsAction => ({
  type: statisticsTypes.REFRESH_STATISTICS,
  payload: { onSuccess, onError }
});
