import React from 'react';
import { header } from 'src/assets/locales/en/common.json';
import styles from '../NavAccountBlock.module.scss';

interface ProfileNameProps {
  profileName: string | null;
}

const ProfileName = ({ profileName = header.defaultProfileTitle }: ProfileNameProps): JSX.Element => {
  return (
    <div className={styles.profileTitleBlock}>
      <span className={styles.profileTitle}>{profileName}</span>
    </div>
  );
};

export default ProfileName;
