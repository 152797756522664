import React, { FC } from 'react';
import { Typography } from 'src/components';
import styles from './styles.module.scss';

type ConstraintsTitleProps = {
  title: string;
};

export const ConstraintsTitle: FC<ConstraintsTitleProps> = ({ title }) => {
  return <Typography className={styles.titleClass}>{title}</Typography>;
};
