import { useCallback, useMemo, useState } from 'react';
import { getScrollElements, onScrollTableBottom } from './helpers';

type TGetScrollElements = (payload: { platform: string; key: string }) => {
  elementId: string;
  containerId: string;
  onScrollToBottom: () => void;
};

export const useScrollToBottom: TGetScrollElements = ({ platform, key }) => {
  const { elementId, containerId } = useMemo(() => getScrollElements({ platform, key }), [platform, key]);

  const onScrollToBottom = useCallback(() => onScrollTableBottom({ elementId, containerId }), [elementId, containerId]);

  return { elementId, containerId, onScrollToBottom };
};

type TUseLoadMorePayload<T> = {
  data: T;
  onLoadMore: (data: T, callback: () => void) => void;
  onScrollBottom: () => void;
};

type TUseLoadMoreReturn = {
  isLoading: boolean;
  onMore: () => void;
};

export const useOnLoadMore = <T>({ data, onLoadMore, onScrollBottom }: TUseLoadMorePayload<T>): TUseLoadMoreReturn => {
  const [isLoading, setLoading] = useState(false);

  const onMore = useCallback(() => {
    setLoading(true);
    onLoadMore(data, () => {
      setLoading(false);
      onScrollBottom();
    });
  }, [data, onLoadMore, onScrollBottom]);

  return {
    isLoading,
    onMore
  };
};
