import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getApiTokens } from 'src/redux/actions/apiActions';
import { apiTokensSelector } from 'src/redux/selectors';
import { GreenBoxButton, CustomizedTable, FormikCustomField, MainLayout, StatementBox } from 'src/components';
import { createNewToken, deleteToken } from 'src/helpers';
import { ApiModal } from './ApiModal/ApiModal';
import { API_SETTINGS_TABLE_COLUMNS } from './constants';
import { renderApiTableContent } from './helpers';
import { ApiColIDs } from './model';
import styles from './ApiSettingsPage.module.scss';

const ApiSettingsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const tokens = useSelector(apiTokensSelector);

  const [data, setData] = useState({
    secretAccessKey: '',
    accessKeyId: ''
  });

  const [secretModal, setSecretModal] = useState(false);
  const toggleModal = () => setSecretModal((p) => !p);

  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState('');

  const onGenerate = async (name) => {
    try {
      const res = await createNewToken(name);
      setData({ secretAccessKey: res.data.key, accessKeyId: res.data.app_id });
      setSecretModal(true);
    } catch (e) {
      console.error('ApiSettingsPage onGenerate error: ', e);
    }
  };

  const setTokens = useCallback(() => dispatch(getApiTokens()), [dispatch]);

  useEffect(() => {
    setTokens();
  }, [setTokens]);

  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteToken = useCallback(
    async (appID: string) => {
      try {
        setDeleteLoading(true);

        await deleteToken(appID);
        dispatch(getApiTokens());

        setDeleteLoading(false);
      } catch (e) {
        setDeleteLoading(false);
      }
    },
    [dispatch]
  );

  const getApiRowContent = useCallback(
    (colId: string, row) => {
      return renderApiTableContent(colId as ApiColIDs, row, isDeleteLoading, handleDeleteToken);
    },
    [handleDeleteToken, isDeleteLoading]
  );

  const rows = useMemo(() => tokens?.map((token) => ({ ...token, id: token.app_id })), [tokens]);

  return (
    <MainLayout isPrivate isEmptySkeleton>
      <StatementBox className={styles.settingsHeaderWrapper}>
        <h2>API Settings</h2>
        <h3>API token name</h3>

        <div className={cn(styles.settingsFormWrapper, styles.contentWrapper)}>
          <Formik
            initialValues={{
              name: ''
            }}
            onSubmit={async ({ name }, { setSubmitting, resetForm }) => {
              try {
                setSubmitting(true);
                setLoading(true);
                await onGenerate(name);
                setTokens();
                resetForm();
                setSubmitting(false);
                setLoading(false);
                setSecretModal(true);
              } catch (error) {
                console.error('forgotPasswordSubmitHelper error', error);
                setResponseError('error');
                setLoading(false);
                setSubmitting(false);
              }
            }}
          >
            {({ values, handleSubmit }) => {
              return (
                <React.Fragment>
                  <FormikCustomField
                    placeholder="Name of API token"
                    id="name"
                    name="name"
                    classes={{
                      inputClassName: styles.inputClass,
                      labelClassName: styles.fieldLabelClass,
                      wrapperClassName: styles.amountWrapperClass,
                      errorClass: styles.errorClass
                    }}
                  />
                  <GreenBoxButton
                    type="submit"
                    btnClass={styles.activeTypeButton}
                    disabled={!values.name.length || loading}
                    onClick={handleSubmit}
                  >
                    Generate
                  </GreenBoxButton>
                </React.Fragment>
              );
            }}
          </Formik>

          {responseError && <p className={styles.error}>{responseError}</p>}
        </div>
      </StatementBox>

      <StatementBox>
        <CustomizedTable
          columns={API_SETTINGS_TABLE_COLUMNS}
          rows={rows}
          shouldShowList={!!rows.length}
          tContainerClass={styles.tableContainer}
          getSummaryContent={getApiRowContent}
          isLoading={false}
          hasDetails={false}
          emptyRowsMsg={null}
          onSort={null}
          guttersLeft={16}
          guttersRight={16}
        />
      </StatementBox>

      <ApiModal
        isOpen={secretModal}
        onToggle={toggleModal}
        accessKeyId={data.accessKeyId}
        secretAccessKey={data.secretAccessKey}
      />
    </MainLayout>
  );
};

export default ApiSettingsPage;
