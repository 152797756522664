import React, { useMemo, memo, FC } from 'react';
import { renderToString } from 'react-dom/server';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { LOAN_TABLE_DATE_FORMAT } from 'src/constants/globalConstants';
import { hexToRgba } from 'src/helpers/colorHelpers';
import { formatMoney, formatDateFromJSON } from 'src/helpers';
import styles from './StatisticsColumnChart.module.scss';
import { GroupBy } from 'src/constants/statisticsConstants';

export type IGraphData = {
  name: string;
  percentage?: number;
  date: string;
  y: number;
};

interface IGraphsProps {
  data: Array<IGraphData>;
  type: GroupBy;
  xLabelType?: 'date' | 'direct' | 'money';
  yLabelType?: 'money' | 'percent';
  yAxisType?: Highcharts.AxisTypeValue;
  currency?: string;
  id?: string;
  externalChartOptions?: Highcharts.Options;
}

const StatisticsColumnChart: FC<IGraphsProps> = memo(
  ({ id, data, type, xLabelType, yLabelType, yAxisType, currency, externalChartOptions }): JSX.Element => {
    const options: Highcharts.Options = useMemo(
      () =>
        isEmpty(externalChartOptions)
          ? getChartConfig({ data, xLabelType, yLabelType, yAxisType, currency, id })
          : merge({}, getChartConfig({ data, xLabelType, yLabelType, yAxisType, currency, id }), externalChartOptions),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currency, data, xLabelType, yLabelType, yAxisType, externalChartOptions]
    );

    return (
      <div className={styles.graphsWrapper}>
        {/* //* current usage of conditional rendering is needed to redraw chart correctly, shouldn't change it. */}
        {type === GroupBy.DAY && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.MONTH && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.WEEK && <HighchartsReact options={options} highcharts={Highcharts} />}
        {type === GroupBy.YEAR && <HighchartsReact options={options} highcharts={Highcharts} />}
        {typeof type === 'undefined' && <HighchartsReact options={options} highcharts={Highcharts} />}
      </div>
    );
  }
);

StatisticsColumnChart.displayName = 'StatisticsColumnChart';

type TGetChartConfigPayload = {
  data: IGraphData[];
  currency: string;
  xLabelType?: 'date' | 'direct' | 'money';
  yLabelType?: 'money' | 'percent';
  yAxisType?: Highcharts.AxisTypeValue;
  id?: string;
};

const getChartConfig = ({
  data,
  xLabelType,
  yLabelType,
  yAxisType = 'linear',
  currency
}: TGetChartConfigPayload): Highcharts.Options => {
  const plotOptions = {
    series: {
      borderWidth: 0,
      minPointLength: 0
    }
  } as Highcharts.PlotOptions;

  return {
    series: [
      {
        type: 'column',
        colorByPoint: true,
        data
      }
    ],

    chart: {
      type: 'column'
    },

    colors: [hexToRgba('#077186', 0.9)],

    xAxis: {
      tickLength: 5,

      labels: {
        enabled: true,
        formatter: function () {
          if (this.pos > data.length) return '';

          const currentObject = data[this.pos];

          if (xLabelType === 'money') return `${formatMoney(this.value, currency)}`;
          if (xLabelType === 'direct') return `${currentObject?.date ?? ''}`;

          return currentObject?.date
            ? `${formatDateFromJSON(currentObject?.date, LOAN_TABLE_DATE_FORMAT)}`
            : `${this.value}`;
        }
      }
    },

    yAxis: {
      type: yAxisType,

      labels: {
        enabled: true,
        formatter: function () {
          if (yLabelType === 'percent') return `${this.value}%`;

          return `${formatMoney(this?.value, currency)}`;
        }
      },

      title: { text: '' }
    },

    tooltip: {
      useHTML: true,
      backgroundColor: '#fff',

      formatter: function () {
        const point = this.point as unknown as Highcharts.Point & {
          date: string;
          options: Highcharts.PointOptionsObject & { date: string };
        };

        return renderToString(
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3px' }}>
            <span style={{ fontSize: 16, fontWeight: 500, lineHeight: 1 }}>{point?.date ?? point.options?.date}</span>

            <span style={{ fontSize: 16, fontWeight: 600, lineHeight: 1, color: point.color as string }}>
              {yLabelType === 'percent' ? `${point.name}%` : formatMoney(point.name, currency)}
            </span>

            {!isNil(point?.percentage) && (
              <span style={{ fontSize: 16, fontWeight: 500, lineHeight: 1 }}>
                <span style={{ fontSize: 16, fontWeight: 600, lineHeight: 1 }}>{point?.percentage.toFixed(2)}%</span> of
                total
              </span>
            )}
          </div>
        );
      }
    },

    plotOptions: plotOptions,

    title: { text: '' },
    subtitle: { text: '' },
    credits: { enabled: false },
    accessibility: { announceNewData: { enabled: true } },
    legend: { enabled: false }
  };
};

export default StatisticsColumnChart;
