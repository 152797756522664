import React, { FC } from 'react';
import { DatePickerBlock, ExportAllBlock } from 'src/components';
import Filter, { IFilter } from 'src/components/Filter/Filter';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { SummaryShape } from 'src/interfaces/baseReduxShapes';
import { RangeSlidersBlock, IRangeFilter } from 'src/components';
import styles from './FiltersBlock.module.scss';

type Props = {
  onClearFilters: () => void;
  onConfirmExport: (fileType: ExportFileTypes, onSuccess: () => void) => void;
  pickerProps: {
    dates: { startDate: Date; endDate: Date };
    selectedMonths: { startDate: number; endDate: number };
    onSetSelectedMonth: (id, value: number) => void;
    onDateChange: (id, date) => void;
  };
  rangeProps: {
    onValueChange: (id, value: number[]) => void;
    onSliderChange: (id, value: number[]) => void;
    rangesData: SummaryShape;
    rangeFilters: SummaryShape;
    list: IRangeFilter[];
    isWider: (id) => boolean;
  };
  filterProps: {
    filters: Array<IFilter>;
    onChangeFilters: (blockId: string, id: string, isChecked: boolean, name: string) => void;
    onSelectAllFilters: (name: string, isChecked: boolean) => void;
  };
};

const FiltersBlock: FC<Props> = ({ onClearFilters, onConfirmExport, pickerProps, rangeProps, filterProps }) => {
  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.titleWrapper}>
        <DatePickerBlock {...pickerProps} />

        <div className={styles.dateAndFilterWrapper}>
          <button type="button" className={styles.clearFiltersBtn} onClick={onClearFilters}>
            Clear All Filters
          </button>

          <ExportAllBlock onConfirmExport={onConfirmExport} selectWrapperClass={styles.fileTypeSelectBlock} />

          <Filter classes={{ btnClass: styles.filterBtn }} {...filterProps} />
        </div>
      </div>

      <RangeSlidersBlock {...rangeProps} />
    </div>
  );
};

export default FiltersBlock;
