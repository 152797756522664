import { useSelector } from 'react-redux';
import { UserRoles } from 'src/constants/userConstants';
import { userSelector } from 'src/redux/selectors';

type UseUserValuesReturnType = {
  userRole: UserRoles;
  firstName: string;
  lastName: string;
  profileName: string;
  companyName: string;
  accessToken: string;
};

export const useUserValues = (): UseUserValuesReturnType => {
  const user = useSelector(userSelector);

  const userRole = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
    ?.filter((item) => item.startsWith('Role:'))
    .map((item) => item.replace('Role:', ''))
    .join(', ') as UserRoles;

  const firstName = user?.attributes?.['custom:first_name'];
  const lastName = user?.attributes?.['custom:last_name'];
  const profileName = firstName && lastName ? `${firstName} ${lastName}` : null;

  const companyName = user?.attributes?.['custom:company_name'];

  const accessToken = user?.signInUserSession?.accessToken?.jwtToken;

  return { userRole, firstName, lastName, profileName, companyName, accessToken };
};
