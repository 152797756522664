import { startOfDay, endOfDay, formatISO } from 'date-fns';
import { formatDateFromJSON } from 'src/helpers';
import { stringify } from 'query-string';
import { PlatformShape } from 'src/interfaces';
import { Provider } from 'src/constants/marketplaceConstants';
import { ExportFileTypes } from 'src/constants/exportFilesConstants';
import { ReportsRequestParams } from 'src/interfaces/reportsReduxShapes';

enum ReportsFilterNames {
  PLATFORMS = 'platforms'
}

export type IFilterBlock = {
  id: string;
  blockLabel: string;
  isAllChecked: boolean;
  list: { id: string; name: string; isChecked: boolean }[];
};

export const createReportsFiltersArray = (platforms: PlatformShape[]): IFilterBlock[] => {
  return [
    {
      id: ReportsFilterNames.PLATFORMS,
      blockLabel: 'Platforms',
      list: platforms.map((item) => ({ ...item, name: Provider[item.name], isChecked: true })),
      isAllChecked: true
    }
  ];
};

export const getReportsFiltersRequestObject = (filtersArray: IFilterBlock[]): Record<ReportsFilterNames, string[]> =>
  filtersArray.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.list.reduce((acc, listItem) => {
        switch (item.id) {
          case ReportsFilterNames.PLATFORMS:
            return listItem.isChecked ? [...acc, listItem?.name?.toUpperCase()] : acc;

          default:
            return acc;
        }
      }, [])
    }),
    {} as Record<ReportsFilterNames, string[]>
  );

export const getExportReportsUrl = (
  reqParams: ReportsRequestParams,
  currency: string,
  fileType: ExportFileTypes
): string => {
  const query = {
    currency,
    platforms: reqParams.platforms,
    transaction_date_from: formatISO(startOfDay(reqParams.date.transaction_date_from)),
    transaction_date_to: formatISO(endOfDay(reqParams.date.transaction_date_to)),
    file_type: fileType
  };

  return `export/cashflow?${stringify(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true
  })}`;
};

export const getExportReportsFileName = (): string => {
  return `Exaloan reports ${formatDateFromJSON(new Date(), 'MM/dd/yyyy')}`;
};
