import React, { useState } from 'react';
import ModalLayout from 'src/atoms/modals/ModalLayout';
import Modal from 'src/atoms/modals/Modal';
import styles from './ApiModal.module.scss';

interface IApiModalProps {
  accessKeyId: string;
  isOpen: boolean;
  secretAccessKey: string;
  onToggle: () => void;
}

export const ApiModal = ({ accessKeyId, secretAccessKey, isOpen, onToggle }: IApiModalProps): JSX.Element => {
  const [isSecretShown, setIsSecretShown] = useState(false);

  const parseKey = (isSecretShown, secretAccessKey) => {
    if (isSecretShown) {
      return secretAccessKey;
    }

    return secretAccessKey
      .split('')
      .map(() => '*')
      .join('');
  };

  return (
    <Modal isOpen={isOpen} onToggle={onToggle}>
      <ModalLayout wrapperClass={styles.modalWrapper}>
        <div className={styles.modalHeader}>
          <h2>Warning</h2>
          <p>
            This is the only time that the secret access keys can be viewed or downloaded. You cannot recover them
            later. However you can create new access keys at any time.
          </p>
        </div>
        <div className={styles.modalTable}>
          <div className={styles.modalTableHeader}>
            <p>Acces Key ID</p>
            <p>Secret Access Key</p>
          </div>
          <div className={styles.modalTableRow}>
            <p>{accessKeyId}</p>
            <p className={styles.itemWithBtn}>
              <span className={styles.secretKey}>{parseKey(isSecretShown, secretAccessKey)}</span>
              <button type="button" className={styles.clearFiltersBtn} onClick={() => setIsSecretShown((p) => !p)}>
                {isSecretShown ? 'Hide' : 'Show'}
              </button>
            </p>
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
