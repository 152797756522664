import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { ROUTE_OPTIONS } from 'src/constants/routes';

const INITIAL_LOGOUT_COUNTER = 0;
const LOGOUT_AFTER = 60 * 10;
const INCREMENT = 10;
const TIMEOUT = INCREMENT * 1000;
const MOUSE_MOVE_TIMEOUT = 1000;

const useLogoutByTimer = (onLogoutWithRedirect: () => void): void => {
  const location = useLocation();

  const logoutIntervalRef = useRef<NodeJS.Timeout>();

  const activeRoute = useMemo(() => ROUTE_OPTIONS.find((route) => route.path.includes(location.pathname)), [location]);

  const [logoutCounter, setLogoutCounter] = useState(INITIAL_LOGOUT_COUNTER);

  const onClearLogoutTimer = useCallback(() => {
    if (!logoutIntervalRef.current) return;

    clearInterval(logoutIntervalRef.current);
    logoutIntervalRef.current = null;
    setLogoutCounter(INITIAL_LOGOUT_COUNTER);
  }, []);

  const onStartLogoutTimer = useCallback(() => {
    if (logoutIntervalRef.current) return;

    logoutIntervalRef.current = setInterval(() => setLogoutCounter((prev) => (prev += INCREMENT)), TIMEOUT);
  }, []);

  const onMoveUpdateLogoutTimer = useCallback(
    throttle(() => {
      if (!activeRoute?.isPrivate) return;

      onClearLogoutTimer();
      onStartLogoutTimer();
    }, MOUSE_MOVE_TIMEOUT),
    [activeRoute, onClearLogoutTimer]
  );

  useEffect(() => {
    if (!activeRoute?.isPrivate) return;

    onStartLogoutTimer();

    if (logoutCounter >= LOGOUT_AFTER) onLogoutWithRedirect();
  }, [activeRoute, logoutCounter, onLogoutWithRedirect, onStartLogoutTimer]);

  useEffect(() => {
    if (activeRoute?.isPrivate) {
      document.addEventListener('mousemove', onMoveUpdateLogoutTimer);
    }

    return () => {
      if (activeRoute?.isPrivate) {
        document.removeEventListener('mousemove', onMoveUpdateLogoutTimer);
      }

      onClearLogoutTimer();
    };
  }, [onMoveUpdateLogoutTimer, activeRoute, onClearLogoutTimer]);
};

export default useLogoutByTimer;
