import { useCallback, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { getMonth } from 'date-fns';
import { PlatformShape } from 'src/interfaces';
import { ReportsRequestParams } from 'src/interfaces/reportsReduxShapes';
import { DatePickersNames } from 'src/components/DatePickerBlock/DatePickerBlock';
import { createReportsFiltersArray, getReportsFiltersRequestObject, IFilterBlock } from './helpers';
import { DATE_PICKER_KEY_MAP, DEFAULT_REPORTS_PAGE, INITIAL_SELECTED_DATES, INITIAL_SELECTED_MONTH } from './constants';
import { SelectedDates, SelectedMonths } from './model';
import { PlatformsIDs } from 'src/constants/globalConstants';

type UseFiltersArrayReturnValue = [
  filtersArray: IFilterBlock[],
  setFiltersArray: React.Dispatch<React.SetStateAction<IFilterBlock[]>>
];

export const useFiltersArrayWithInitialFetch = (platforms: PlatformShape[]): UseFiltersArrayReturnValue => {
  const [filtersArray, setFiltersArray] = useState<IFilterBlock[]>([]);

  useEffect(() => {
    if (isEmpty(platforms)) return;
    setFiltersArray(createReportsFiltersArray(platforms));
  }, [platforms]);

  return [filtersArray, setFiltersArray];
};

export const useHandleFiltersChange = (
  filtersArray: IFilterBlock[],
  onChangeCheckboxFilters: (filtersArray: IFilterBlock[]) => void
): ((name: string, id: string) => void) => {
  return useCallback(
    (name: string, id: string) => {
      const filters = filtersArray.map((block) => {
        if (block.id !== name) return block;

        const list = block.list.map((item) => ({
          ...item,
          isChecked: item.id === id ? !item.isChecked : item.isChecked
        }));

        return {
          ...block,
          isAllChecked: list.every(({ isChecked }) => isChecked),
          list
        };
      });

      onChangeCheckboxFilters(filters);
    },
    [filtersArray, onChangeCheckboxFilters]
  );
};

export const useHandleFiltersSelectAll = (
  filtersArray: IFilterBlock[],
  onChangeCheckboxFilters: (filtersArray: IFilterBlock[]) => void
): ((name: string) => void) => {
  return useCallback(
    (name: string) => {
      const filters = filtersArray.map((block) => {
        if (block.id !== name) return block;

        return {
          ...block,
          isAllChecked: !block.isAllChecked,
          list: block.list.map((item) => ({ ...item, isChecked: !block.isAllChecked }))
        };
      });

      onChangeCheckboxFilters(filters);
    },
    [onChangeCheckboxFilters, filtersArray]
  );
};

export const useOnChangeFilters = (
  reqParams: ReportsRequestParams,
  setFiltersArray: React.Dispatch<React.SetStateAction<IFilterBlock[]>>,
  setReqParams: React.Dispatch<React.SetStateAction<ReportsRequestParams>>
): ((filtersArray: IFilterBlock[]) => void) => {
  return useCallback(
    (filtersArray: IFilterBlock[]) => {
      setFiltersArray(filtersArray);

      const { platforms } = getReportsFiltersRequestObject(filtersArray);
      const { platforms: platform } = reqParams;

      if (isEqual(platform, platforms)) return;

      setReqParams((state) => ({
        ...state,
        platforms: platforms as PlatformsIDs[],
        page: DEFAULT_REPORTS_PAGE
      }));
    },
    [reqParams, setFiltersArray, setReqParams]
  );
};

type UseDatesReturnValue = {
  selectedDates: SelectedDates;
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDates>>;
  selectedMonths: SelectedMonths;
  setSelectedMonths: React.Dispatch<React.SetStateAction<SelectedMonths>>;
  handleSetSelectedMonth: (id: DatePickersNames, value: number) => void;
  handleDateChange: (id: DatePickersNames, date: Date) => void;
  onSetInitialDates: () => void;
};

export const usePickerDates = (
  setReqParams: React.Dispatch<React.SetStateAction<ReportsRequestParams>>
): UseDatesReturnValue => {
  const [selectedDates, setSelectedDates] = useState<SelectedDates>(INITIAL_SELECTED_DATES);
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonths>(INITIAL_SELECTED_MONTH);

  const handleSetSelectedMonth = useCallback((id: DatePickersNames, value: number) => {
    setSelectedMonths((prev) => ({ ...prev, [id]: value }));
  }, []);

  const handleDateChange = useCallback(
    (id: DatePickersNames, date: Date) => {
      setSelectedDates((prevDates) => ({ ...prevDates, [id]: date }));

      const shouldUpdateMonths = getMonth(date) !== selectedMonths[id];
      if (shouldUpdateMonths) setSelectedMonths((prevMonth) => ({ ...prevMonth, [id]: getMonth(date) }));

      setReqParams((prevParams) => ({ ...prevParams, date: { ...prevParams.date, [DATE_PICKER_KEY_MAP[id]]: date } }));
    },
    [selectedMonths, setReqParams]
  );

  const onSetInitialDates = useCallback(() => {
    setSelectedDates(INITIAL_SELECTED_DATES);
    setSelectedMonths(INITIAL_SELECTED_MONTH);
  }, []);

  return {
    selectedDates,
    setSelectedDates,
    selectedMonths,
    setSelectedMonths,
    handleSetSelectedMonth,
    handleDateChange,
    onSetInitialDates
  };
};

export const useReportsCurrencyChanged = (
  currencyValue: string,
  reqCurrency: string,
  setReqParams: React.Dispatch<React.SetStateAction<ReportsRequestParams>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  useEffect(() => {
    if (currencyValue === reqCurrency) return;

    setLoading(true);
    setReqParams((prev) => ({ ...prev, currency: currencyValue }));
  }, [currencyValue, reqCurrency, setReqParams, setLoading]);
};
