import { TableHeaderColumns, ColumnAlignOptions } from 'src/interfaces';
import { ApiColIDs } from './model';

export const API_SETTINGS_TABLE_COLUMNS: TableHeaderColumns = [
  {
    label: 'Token Name',
    id: ApiColIDs.name,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.LEFT
  },
  {
    label: 'Access Key ID',
    id: ApiColIDs.app_id,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Created At',
    id: ApiColIDs.created_at,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Expires At',
    id: ApiColIDs.expires_at,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: '',
    id: ApiColIDs.delete,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];
