import React, { FC } from 'react';
import cn from 'classnames';
import styles from './ContentBodyBox.module.scss';

type ContentBodyBoxProps = {
  children: React.ReactNode;
  wrapperClass?: string;
};

const ContentBodyBox: FC<ContentBodyBoxProps> = ({ children, wrapperClass }) => {
  return <div className={cn(styles.wrapper, { [wrapperClass]: wrapperClass })}>{children}</div>;
};

export default ContentBodyBox;
