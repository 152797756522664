import React, { FC, useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { CustomizedTable } from 'src/components';
import { currencySymbols } from 'src/constants/globalConstants';
import { CASHFLOW_COLUMNS, CASHFLOW_TITLE_COLUMNS, CashflowColIds, PLUG_ROWS } from './constants';
import { renderRepaymentsRowContent, getRepaymentsRowData } from './helpers';
import { Box } from '../components';
import { ICashflowData } from 'src/interfaces';
import styles from './Cashflow.module.scss';

const MAX_TABLE_HEIGHT = '50vh';
const SCROLL_AFTER_LENGTH = 6;

type CashflowProps = {
  isLoading: boolean;
  cashflowData: ICashflowData[];
  currency: string;
};

const Cashflow: FC<CashflowProps> = ({ isLoading, cashflowData, currency }) => {
  const getRepaymentsRowContent = useCallback(
    (colId: string, row) => {
      return renderRepaymentsRowContent(colId as CashflowColIds, row, currencySymbols[currency]);
    },
    [currency]
  );

  const rows = useMemo(() => {
    if (!isLoading && isEmpty(cashflowData)) return PLUG_ROWS;

    return getRepaymentsRowData(cashflowData);
  }, [cashflowData, isLoading]);

  const isStickyHeader = rows.length > SCROLL_AFTER_LENGTH;
  return (
    <Box style={{ padding: '18px 24px 24px' }}>
      <div className={styles.wrapper}>
        <Fade in={!isLoading}>
          <div className={styles.content}>
            <CustomizedTable
              stickyHeader={isStickyHeader}
              maxTableContainerHeight={MAX_TABLE_HEIGHT}
              columns={CASHFLOW_COLUMNS}
              topHeadingColumns={CASHFLOW_TITLE_COLUMNS}
              rows={rows}
              getSummaryContent={getRepaymentsRowContent}
              tContainerClass={cn(styles.tableContainer, { [styles.tableContainerSticky]: isStickyHeader })}
              tHeadClasses={{
                tHeadClass: styles.tHeadClass,
                tHeadCellClass: cn(styles.tHeadCellClass, { [styles.tHeadCellClassSticky]: isStickyHeader }),
                tHeadLabelClass: styles.tHeadLabelClass,
                tHeadTopColumnCellClass: styles.tHeadTopColumnCellClass
              }}
              tBodyClasses={{
                tBodyCellClass: styles.tBodyCellClass,
                tBodyRowClass: styles.tBodyRowClass
              }}
              guttersLeft={20}
              guttersRight={20}
              hasDetails={false}
              isLoading={false}
              emptyRowsMsg={null}
              onSort={null}
            />
          </div>
        </Fade>
      </div>
    </Box>
  );
};

export default Cashflow;
