import { authActionTypes, statisticsTypes } from '../types';
import { StatisticsReducer } from 'src/interfaces/statisticsReduxShapes';

export default (state = null, { type, payload }: { type: string; payload }): StatisticsReducer => {
  switch (type) {
    case statisticsTypes.PUT_STATISTICS:
      return payload;

    case authActionTypes.SIGN_OUT:
      return null;

    default:
      return state;
  }
};
