import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { PieChart } from 'react-minimal-pie-chart';
import allocation_type_info from 'src/assets/images/icons/info.svg';
import { PercentageDropdown, CustomizedCheckbox, PercentageInput } from 'src/components';
import { setDefaultPieChartPercentage } from 'src/helpers/portfolioBuilderHelpers';
import { pieCharColors } from 'src/constants/colors';
import styles from './PercentagePie.module.scss';

export interface PercentagePieItemShape {
  id: string;
  title: string;
  percentage?: number;
  value?: number;
  isPreset?: boolean;
  isSelected?: boolean;
  childrenList?: Array<{ title: string }>;
}

interface PercentagePieShape {
  title: string;
  data: PercentagePieItemShape[];
  isViewCase?: boolean;
  classes?: {
    inputClass?: string;
    pieChartWrapperClass?: string;
    wrapperClass?: string;
  };
  onDataChanged?: (data: PercentagePieItemShape[]) => void;
  onDataCollect?: (data: PercentagePieItemShape[]) => void;
  isDefault?: boolean;
  readonly?: boolean;
  onError?: (isError: boolean) => void;
  displayParagraph?: boolean;
  bottomHint?: string;
  topHint?: string;
}

const PercentagePie = ({
  isViewCase,
  title,
  data,
  classes,
  onDataChanged,
  isDefault = false,
  readonly = false,
  onError = () => false,
  displayParagraph,
  bottomHint = null,
  topHint = null
}: PercentagePieShape): JSX.Element => {
  const [dataList, setDataList] = useState([]);
  const [isError, setIsError] = useState(false);

  const [showTopHint, setShowTopHint] = useState(false);

  const toggleShowHint = () => setShowTopHint((prevState) => !prevState);

  useMemo(() => {
    const array = data.map((item, index) => {
      return {
        ...item,
        title: item.title,
        color: pieCharColors[index % pieCharColors.length],
        percentage: item.percentage,
        isSelected: item.isSelected,
        isPreset: !!item.isPreset,
        childrenList: item?.childrenList ?? []
      };
    });

    setDataList(array);
  }, [data]);

  const onItemSelected = (selected) => {
    setDataList((state) => {
      const newState = [...state].map((item) =>
        item.title === selected.title ? { ...item, isPreset: item.isSelected, isSelected: !item.isSelected } : item
      );

      if (!setDefaultPieChartPercentage(newState, true)) {
        setIsError(true);
        onError(true);
      } else {
        setIsError(false);
        onError(false);
      }
      onDataChanged(newState);

      return newState;
    });
  };

  const onItemChanged = (item, percentage) => {
    setDataList((state) => {
      const newState = [...state].map((newListItem) =>
        newListItem.title === item.title ? { ...newListItem, percentage } : newListItem
      );

      if (!setDefaultPieChartPercentage(newState, true)) {
        setIsError(true);
        onError(true);
        return newState;
      }

      setIsError(false);
      onError(false);
      onDataChanged(newState);

      return newState;
    });
  };

  const onDropdownChanged = (base, data) => {
    setDataList((state) => {
      const array = [...state];
      const findBase = array.find((item) => item.title === base.title);

      if (findBase) {
        findBase.child = data;
      }

      setDefaultPieChartPercentage(data, true);
      onDataChanged(array);

      return array;
    });
  };

  return (
    <div className={cn(styles.wrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      <div className={styles.titleBlock}>
        <div>
          {title}
          {topHint && (
            <img
              className={styles.hintIcon}
              src={allocation_type_info}
              onMouseEnter={toggleShowHint}
              onMouseLeave={toggleShowHint}
            />
          )}
        </div>
        <div className={cn(styles.hintBlock, { [styles.shownHint]: showTopHint })} style={{ margin: 0 }}>
          {topHint ? `${topHint}` : '&nbsp;'}
        </div>
      </div>

      <div className={cn(styles.pieChartWrapper, { [classes?.pieChartWrapperClass]: classes?.pieChartWrapperClass })}>
        <PieChart
          data={dataList.map((val) => ({ ...val, value: val.percentage }))}
          lineWidth={isDefault ? undefined : 50}
          animate
          startAngle={270}
          className={styles.pieChart}
          label={
            isDefault
              ? ({ dataEntry }) => (dataEntry.percentage > 9.5 ? Math.round(dataEntry.percentage) + '%' : undefined)
              : undefined
          }
          labelStyle={{
            fontSize: '0.5em',
            filter: 'invert(1)'
          }}
        />
      </div>

      {bottomHint && (
        <div className={styles.hintWrapper}>
          <img className={styles.hintIcon} src={allocation_type_info} />
          <div className={styles.hintBlock}>{bottomHint}</div>
        </div>
      )}

      <div className={styles.listWrapper}>
        {dataList.map((item) => {
          return (
            <div key={item.title} className={styles.listItem}>
              {readonly ? null : (
                <CustomizedCheckbox
                  isDisabled={isViewCase}
                  classes={{ checkboxRootClass: styles.checkboxRoot }}
                  checkedIconProps={{ checkedBoxClass: styles.checkedBox, checkedIconClass: styles.checkedIcon }}
                  uncheckedIconProps={{ uncheckedBoxClass: styles.uncheckedBox }}
                  isChecked={item.isSelected}
                  onChange={() => onItemSelected(item)}
                />
              )}

              {!isEmpty(item.childrenList) && item.percentage > 0 ? (
                <PercentageDropdown
                  isViewCase={isViewCase}
                  onError={onError}
                  data={item.childrenList}
                  onChange={(data) => onDropdownChanged(item, data)}
                  customClasses={{ baseWrapperClass: styles.dropdown }}
                >
                  <div className={styles.styleDot} style={{ backgroundColor: item.color }}></div>
                  <div className={styles.listItemTitle}>{item.title}</div>
                </PercentageDropdown>
              ) : (
                <>
                  <div className={styles.styleDot} style={{ backgroundColor: item.color }}></div>
                  <div className={styles.listItemTitle}>{item.title}</div>
                </>
              )}

              <div className={cn(styles.inputWrapper, { [styles.readonlyPercentage]: readonly })}>
                {displayParagraph ? (
                  <p className={cn(styles.input, { [classes?.inputClass]: classes?.inputClass })}>
                    {Number(item.percentage).toFixed(2)}%
                  </p>
                ) : (
                  <PercentageInput
                    isDisabled={isViewCase}
                    className={cn(styles.input, { [classes?.inputClass]: classes?.inputClass })}
                    value={item.percentage}
                    onChanged={(value) => onItemChanged(item, value)}
                    isAllowed={!item.isSelected}
                    isError={isError}
                    suffix={'%'}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PercentagePie;
