import React, { useMemo } from 'react';
import { ExaloanRatings, LoanTypes } from 'src/constants/globalConstants';
import { CriteriaNames, Platform } from '../../model';
import { LoanSizeConstraints, ExaloanGrades, LoanPurposes, RelativeValues, SMESectors, SMEPurposes } from '..';

type CriteriaColumnItem = {
  criteriaName: CriteriaNames;
  criteriaComponent: React.ReactElement;
};

type LayoutCriteria = {
  columnLeft: CriteriaColumnItem[];
  columnRight: CriteriaColumnItem[];
};

enum ColumnNames {
  COLUMN_LEFT = 'columnLeft',
  COLUMN_RIGHT = 'columnRight'
}

export default (
  layoutCriteriaNames: CriteriaNames[],
  selectedPlatform: Platform,
  onCriteriaChange: (criteriaName: CriteriaNames, id: string | number, percentage: number) => void,
  isViewPage: boolean,
  loanType: LoanTypes
): LayoutCriteria => {
  return useMemo(() => {
    return layoutCriteriaNames.reduce(
      (acc, criteriaName, idx) => {
        const isOdd = !!((idx + 1) % 2);
        const columnKey = isOdd ? ColumnNames.COLUMN_LEFT : ColumnNames.COLUMN_RIGHT;

        let criteriaComponent: React.ReactNode;

        switch (criteriaName) {
          case CriteriaNames.LOAN_SIZE:
            criteriaComponent = (
              <LoanSizeConstraints
                isViewPage={isViewPage}
                constraints={selectedPlatform?.constraints}
                onChange={(id: number, value: number) => onCriteriaChange(CriteriaNames.LOAN_SIZE, id, value)}
              />
            );
            break;

          case CriteriaNames.GRADE:
            criteriaComponent = (
              <ExaloanGrades
                isViewPage={isViewPage}
                grades={selectedPlatform?.grades}
                onChange={(name: ExaloanRatings, value: number) => onCriteriaChange(CriteriaNames.GRADE, name, value)}
              />
            );
            break;
          case CriteriaNames.LOAN_PURPOSES:
            criteriaComponent =
              loanType === LoanTypes.SME ? (
                <SMEPurposes
                  isViewPage={isViewPage}
                  smePurposes={selectedPlatform?.smePurposes}
                  onChange={(id: string, value: number) => onCriteriaChange(CriteriaNames.SME_PURPOSES, id, value)}
                />
              ) : (
                <LoanPurposes
                  isViewPage={isViewPage}
                  purposes={selectedPlatform?.loanTypes}
                  onChange={(id: string, value: number) => onCriteriaChange(CriteriaNames.LOAN_PURPOSES, id, value)}
                />
              );
            break;

          case CriteriaNames.RELATIVE_VALUE:
            criteriaComponent = (
              <RelativeValues
                isViewPage={isViewPage}
                relative={selectedPlatform?.relative}
                onChange={(name: string, value: number) => onCriteriaChange(CriteriaNames.RELATIVE_VALUE, name, value)}
              />
            );
            break;
          case CriteriaNames.SME_SECTORS:
            criteriaComponent = (
              <SMESectors
                isViewPage={isViewPage}
                sectors={selectedPlatform?.sectors}
                onChange={(id: string, value: number) => onCriteriaChange(CriteriaNames.SME_SECTORS, id, value)}
              />
            );
            break;

          default:
            criteriaComponent = <></>;
            break;
        }

        return { ...acc, [columnKey]: [...acc[columnKey], { criteriaName, criteriaComponent }] };
      },
      { columnLeft: [], columnRight: [] }
    );
  }, [loanType, isViewPage, onCriteriaChange, selectedPlatform, layoutCriteriaNames]);
};
