import { LoanConstraintShape, LoanTypeShape, GradeShape } from 'src/interfaces';
import { AllocationPayload, InvestmentReinvestmentStrategy } from 'src/interfaces/AllocationTypes';
import { PlatformsIDs } from 'src/constants/globalConstants';
import { RangeType } from 'src/components/RangeBar/RangeBar';
import { RelativeValueFilter } from 'src/constants/relativeValueFilters';
import { Currencies, LoanTypes } from 'src/constants/globalConstants';

export enum FieldNames {
  NAME = 'name',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  LOAN_TYPE = 'loanType',
  MAX_BID_SIZE = 'maxBidSize',
  REINVESTMENT_STRATEGY = 'reinvestmentStrategy',
  STRUCTURE_END_DATE = 'structureEndDate'
}

export interface TypeBlockValues {
  name: string;
  currency: SelectOption;
  loanType: { value: LoanTypes; label: string };
  amount: string;
  maxBidSize: string;
  reinvestmentStrategy: { value: InvestmentReinvestmentStrategy; label: string };
  structureEndDate: Date;
}

export type TypeBlockErrors = {
  name?: string | undefined;
  currency?: string | undefined;
  amount?: string | undefined;
};

export type SelectOption = {
  value: string;
  label: string;
};

export type AllocationTypeData = {
  name: string;
  currency: SelectOption;
  amount: string;
  loanType: { value: LoanTypes; label: string };
  maxBidSize: string;
  reinvestmentStrategy: { value: InvestmentReinvestmentStrategy; label: string };
  structureEndDate: Date;
};

export interface PortfolioLoanType extends LoanTypeShape {
  percentage: number;
}

export interface PortfolioGrade extends GradeShape {
  percentage: number;
}

export interface PortfolioRelativeValue extends Omit<RelativeValueFilter, 'id'> {
  percentage: number;
}

export type Constraint = {
  currency: Currencies;
  max: number;
  min: number;
  type: LoanTypes;
  value: number;
  percentage: number;
};

export type ExtendedCriteriaFilters = {
  constraints: Constraint[];
  loanTypes: PortfolioLoanType[];
  relative: PortfolioRelativeValue[];
  grades: PortfolioGrade[];
  smePurposes: PortfolioLoanType[];
  sectors: PortfolioLoanType[];
};

export type Platform = {
  id: PlatformsIDs;
  label: string;
  logo?: string;
  percentage: number;
  amount: number;
  criteriaSelected: string[];
  investmentPercentage: number;
  general: RangeFilters;
  constraints: Constraint[];
  loanTypes: PortfolioLoanType[];
  relative: PortfolioRelativeValue[];
  grades: PortfolioGrade[];
  smePurposes: PortfolioLoanType[];
  sectors: PortfolioLoanType[];
  currency: Currencies;
};

export enum CriteriaNames {
  GENERAL = 'general',
  GRADE = 'grades',
  LOAN_SIZE = 'constraints',
  LOAN_PURPOSES = 'loanTypes',
  RELATIVE_VALUE = 'relative',
  SME_SECTORS = 'sectors',
  SME_PURPOSES = 'smePurposes'
}

export enum CriteriaNamesServer {
  GENERAL = 'general',
  GRADE = 'grades',
  LOAN_SIZE = 'loan_size_constraints',
  LOAN_PURPOSES = 'purposes',
  RELATIVE_VALUE = 'relative_value',
  SME_SECTORS = 'sectors'
}

export const CRITERIA_SERVER_TO_VIEW_KEYS = {
  [CriteriaNamesServer.GENERAL]: CriteriaNames.GENERAL,
  [CriteriaNamesServer.GRADE]: CriteriaNames.GRADE,
  [CriteriaNamesServer.LOAN_SIZE]: CriteriaNames.LOAN_SIZE,
  [CriteriaNamesServer.RELATIVE_VALUE]: CriteriaNames.RELATIVE_VALUE,
  [CriteriaNamesServer.LOAN_PURPOSES]: CriteriaNames.LOAN_PURPOSES,
  [CriteriaNamesServer.SME_SECTORS]: CriteriaNames.SME_SECTORS
};

export type CriteriaOption = {
  id: CriteriaNames;
  name: string;
  loanTypes: LoanTypes[];
};

export type RangeFilters = {
  borrowerRate: RangeType;
  term: RangeType;
  loanAmount: RangeType;
};

export enum RangeFiltersTypes {
  LOAN_AMOUNT = 'loanAmount',
  INTEREST_RATE = 'borrowerRate',
  TERM = 'term'
}

export type AllocationSummary = {
  currency: string[];
  type: LoanTypes;
};

export type AllocationSummaryReq = {
  currency: string;
  type: LoanTypes;
};

export interface CriteriaFilters {
  grades: Array<GradeShape>;
  constraints: LoanConstraintShape[];
  relative: Omit<RelativeValueFilter, 'id'>[];
  loanTypes?: Array<LoanTypeShape>;
  sectors?: Array<LoanTypeShape>;
  smePurposes?: Array<LoanTypeShape>;
}

export interface ServerAllocationPayload extends AllocationPayload {
  created_at: string;
  status: number;
  user: string;
}

export type ServerToViewAllocation = {
  name: string;
  amount: string;
  currency: { label: Currencies; value: Currencies };
  loanType: { value: LoanTypes; label: string };
  autoApproval: boolean;
  investedPlatforms: Partial<Platform>[];
  status: number;
  maxBidSize: string;
  reinvestmentStrategy: { value: InvestmentReinvestmentStrategy; label: string };
  structureEndDate: Date;
};
