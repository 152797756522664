import React, { FC, useState, useMemo, useEffect } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { CSSTransition } from 'react-transition-group';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ColumnAlignOptions, TableHeadProps } from 'src/interfaces/CustomTableShapes';
import { CustomizedCheckbox } from 'src/components';
import sortArrow from 'src/assets/images/icons/sort_table_arrow.svg';
import Fade from 'src/components/reactTransitions/FadeTransition.module.css';
import { DEFAULT_TRANSITION_TIMEOUT } from 'src/constants/globalConstants';
import styles from './styles.module.scss';

interface SortDropdownProps {
  id: string;
  isOpen: boolean;
  sortOptions: Array<{ title: string; order: string }> | null;
  onClickAway: (id: string) => void;
  onSort: (type, order) => void;
  align: ColumnAlignOptions;
  dropdownClass?: () => string | null;
}

const SortTableDropdown = ({
  id,
  isOpen,
  sortOptions,
  onClickAway,
  onSort,
  align,
  dropdownClass
}: SortDropdownProps) => {
  return (
    <CSSTransition in={isOpen} timeout={DEFAULT_TRANSITION_TIMEOUT} classNames={{ ...Fade }} unmountOnExit>
      <ClickAwayListener onClickAway={() => onClickAway(id)}>
        <div
          className={cn(styles.dropdownContent, styles[`dropdownContent${capitalize(align)}`], {
            [dropdownClass()]: dropdownClass && dropdownClass()
          })}
        >
          <ul className={styles.optionsList}>
            {sortOptions.map(({ title, order }) => (
              <li key={title} className={styles.optionListItem} onClick={() => onSort(id, order)}>
                <span className={styles.optionLabel}>{title}</span>
              </li>
            ))}
          </ul>
        </div>
      </ClickAwayListener>
    </CSSTransition>
  );
};

const alignOptions = {
  [ColumnAlignOptions.CENTER]: 'center',
  [ColumnAlignOptions.LEFT]: 'flex-start',
  [ColumnAlignOptions.RIGHT]: 'flex-end'
};

const THead: FC<TableHeadProps> = ({
  columns,
  topHeadingColumns,
  onSelectAllChange,
  isSelectAllDisabled = false,
  isSelectAllChecked = false,
  onSort,
  classes,
  guttersLeft,
  guttersRight,
  onSetDropdownClass
}) => {
  const [headerColumnsList, setHeaderColumnsList] = useState(columns);

  useEffect(() => {
    setHeaderColumnsList(columns);
  }, [columns]);

  const handleOpenSortDropdown = (id: string) => {
    setHeaderColumnsList((state) =>
      state.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : { ...item, isOpen: false }))
    );
  };

  const onSortDropdownClickAway = (id: string) => {
    setHeaderColumnsList((state) => state.map((item) => (item.id === id ? { ...item, isOpen: false } : item)));
  };

  const { count, totalWidth } = useMemo(() => {
    const initialAcc = {
      count: 0,
      totalWidth: 0
    };

    return headerColumnsList.reduce(
      (acc, { width }) => (width ? { count: acc.count++, totalWidth: acc.totalWidth + width } : acc),
      initialAcc
    );
  }, [headerColumnsList]);

  return (
    <TableHead classes={{ root: cn(styles.headerBlock, { [classes?.tHeadClass]: classes?.tHeadClass }) }}>
      {!isEmpty(topHeadingColumns) && (
        <TableRow>
          {topHeadingColumns.map(({ label, align, colSpan, ...rest }, idx, arr) => {
            const firstCol = idx === 0;
            const lastCol = idx === arr?.length - 1;

            const leftGutter = guttersLeft && firstCol && `${guttersLeft}px`;
            const rightGutter = guttersRight && lastCol && `${guttersRight}px`;

            return (
              <TableCell
                key={rest?.id ?? label}
                align={align}
                colSpan={colSpan}
                style={{
                  paddingLeft: leftGutter,
                  paddingRight: rightGutter
                }}
                className={cn(styles.tHeadTopColumnCell, {
                  [classes?.tHeadTopColumnCellClass]: classes?.tHeadTopColumnCellClass
                })}
              >
                {label}
              </TableCell>
            );
          })}
        </TableRow>
      )}

      <TableRow>
        {headerColumnsList.map(({ id, label, isSortable, align, width, ...rest }, idx, arr) => {
          const firstCol = idx === 0;
          const lastCol = idx === arr?.length - 1;

          const leftGutter = guttersLeft && firstCol && `${guttersLeft}px`;
          const rightGutter = guttersRight && lastCol && `${guttersRight}px`;
          const currentWidth = width ? `${width}%` : `calc(${100 - totalWidth}% / ${arr?.length - count})`;

          return (
            <TableCell
              key={id}
              align={align}
              className={cn(styles.tHeadCell, {
                [styles.tHeadCellSortable]: isSortable,
                [classes?.tHeadCellClass]: classes?.tHeadCellClass
              })}
              onClick={isSortable ? () => handleOpenSortDropdown(id) : null}
              style={{
                width: currentWidth,
                paddingLeft: leftGutter,
                paddingRight: rightGutter
              }}
            >
              <div className={styles.tableCellContent} style={{ justifyContent: alignOptions[align] }}>
                {rest?.isCheckbox ? (
                  <CustomizedCheckbox
                    isChecked={isSelectAllChecked}
                    onChange={onSelectAllChange}
                    classes={{
                      checkboxRootClass: cn(styles.checkboxRoot, {
                        [classes?.checkboxRootClass]: classes?.checkboxRootClass
                      })
                    }}
                    checkedIconProps={{ checkedBoxClass: styles.checkedBox, checkedIconClass: styles.checkedIcon }}
                    uncheckedIconProps={{ uncheckedBoxClass: styles.uncheckedBox }}
                    isDisabled={isSelectAllDisabled}
                  />
                ) : (
                  <>
                    <span
                      className={cn(styles.label, {
                        [styles.labelSortable]: isSortable,
                        [classes?.tHeadLabelClass]: classes?.tHeadLabelClass
                      })}
                    >
                      {label}
                    </span>
                    {isSortable && (
                      <>
                        <div className={styles.sortArrowWrapper}>
                          <img src={sortArrow} alt={label} className={cn(styles.sortArrow, 'img-fluid')} />
                        </div>
                        <SortTableDropdown
                          id={id}
                          onClickAway={onSortDropdownClickAway}
                          onSort={onSort}
                          align={align}
                          dropdownClass={() => (onSetDropdownClass ? onSetDropdownClass(id) : null)}
                          {...rest}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default THead;
