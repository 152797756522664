import React, { FC } from 'react';
import { StatementBox } from 'src/components';
import { ExaloanRatings } from 'src/constants/globalConstants';
import { ConstraintsTitle } from '../atoms';
import { PortfolioGrade } from '../../../model';
import { CriteriaRow } from '../..';
import styles from './ExaloanGrades.module.scss';

type Props = {
  isViewPage: boolean;
  grades: PortfolioGrade[];
  onChange: (name: ExaloanRatings, value: number) => void;
};

const ExaloanGrades: FC<Props> = ({ isViewPage, grades, onChange }) => {
  return (
    <StatementBox className={styles.box}>
      <ConstraintsTitle title="Exaloan Score" />

      <div className={styles.content}>
        {grades?.map(({ id, name, percentage }) => (
          <CriteriaRow
            key={id}
            label={name}
            value={percentage}
            onChange={(value) => onChange(name, value)}
            readOnly={isViewPage}
          />
        ))}
      </div>
    </StatementBox>
  );
};

export default ExaloanGrades;
