import React from 'react';
import { NavLogo, NavAccountBlock } from './components';
import styles from './Header.module.scss';

interface HeaderProps {
  isPrivatePage?: boolean;
  onToggleAside: () => void;
}

const Header = ({ isPrivatePage, onToggleAside }: HeaderProps): JSX.Element => (
  <header className={styles.header}>
    {isPrivatePage ? (
      <div className={styles.headerPrivateContent}>
        <NavLogo onToggleAside={onToggleAside} isPrivatePage={isPrivatePage} />
        <NavAccountBlock />
      </div>
    ) : (
      <NavLogo isPrivatePage={isPrivatePage} />
    )}
  </header>
);

export default Header;
