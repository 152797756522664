import { call, put, all, takeLatest, ForkEffect } from 'redux-saga/effects';
import { GetLoansSummary, GetPlatforms } from 'src/interfaces/baseReduxShapes';
import { callbackResolver } from 'src/utils/reduxHelpers';
import { formatNumber } from 'src/helpers';
import { baseTypes } from '../types';
import * as actions from '../actions/baseActions';
import * as apiHelpers from './sagaHelpers/baseApiHelpers';

const LOAN_AMOUNT_DECIMAL = 2;

function* getFiltersSummaryWorker({ payload }: GetLoansSummary) {
  try {
    const getSummary = () => apiHelpers.getFiltersSummary(`type=${payload.type}&currency=${payload.currency}`);
    const { data } = yield call(getSummary);

    data.borrowerRate.min *= 100;
    data.borrowerRate.max *= 100;
    data.borrowerRate.min = Number(data.borrowerRate.min.toFixed(1));
    data.borrowerRate.max = Number(data.borrowerRate.max.toFixed(1));
    data.loanAmount.min = Math.floor(data.loanAmount.min * 100) / 100;
    data.loanAmount.max = Math.floor(data.loanAmount.max * 100) / 100;

    const preparedData = {
      borrowerRate: {
        min: formatNumber(data.borrowerRate.min),
        max: formatNumber(data.borrowerRate.max)
      },
      term: {
        min: formatNumber(data.term.min),
        max: formatNumber(data.term.max)
      },
      loanAmount: {
        min: formatNumber(data.loanAmount.min, LOAN_AMOUNT_DECIMAL),
        max: formatNumber(data.loanAmount.max, LOAN_AMOUNT_DECIMAL)
      }
    };

    if (payload.onSuccess) payload.onSuccess(preparedData);

    yield put(actions.putLoansSummary(preparedData));
  } catch (error) {
    console.error('getFiltersSummaryWorker', error);
  }
}

function* getLoansFiltersWorker() {
  try {
    const [consumerPurposes, countryRes, platformsRes, gradesRes, sectorsRes, smePurposes] = yield all([
      call(apiHelpers.getLoanTypes),
      call(apiHelpers.getCountries),
      call(apiHelpers.getFiltersPlatform),
      call(apiHelpers.getFiltersGrade),
      call(apiHelpers.getLoanSectors),
      call(apiHelpers.getSmePurposes)
    ]);

    yield put(
      actions.putLoanFilters({
        loanTypes: consumerPurposes?.data?.data ?? [],
        countries: countryRes?.data?.data ?? [],
        platforms: platformsRes?.data?.data ?? [],
        grades: gradesRes?.data?.data ?? [],
        sectors: sectorsRes.data.data ?? [],
        smePurposes: smePurposes.data.data ?? []
      })
    );
  } catch (error) {
    console.error('getLoansFiltersWorker', error);
  }
}

function* getCurrenciesWorker() {
  try {
    const { data } = yield call(apiHelpers.getCurrenciesHelper);
    yield put(actions.putCurrencies(data?.data));
  } catch (error) {
    console.error('getCurrenciesWorker', error);
  }
}

function* getRegionsWorker() {
  try {
    const { data } = yield call(apiHelpers.getRegionsHelper);
    yield put(actions.putRegions(data?.data));
  } catch (error) {
    console.error('getRegionsWorker', error);
  }
}

function* getGradesWorker() {
  try {
    const { data } = yield call(apiHelpers.getFiltersGrade);
    yield put(actions.putGrades(data?.data));
  } catch (error) {
    console.error('getGradesWorker', error);
  }
}

function* getConsumerPurposesWorker() {
  try {
    const { data } = yield call(apiHelpers.getLoanTypes);
    yield put(actions.putConsumerPurposes(data?.data));
  } catch (error) {
    console.error('getConsumerPurposesWorker', error);
  }
}

function* getSmePurposesWorker() {
  try {
    const { data } = yield call(apiHelpers.getSmePurposes);
    yield put(actions.putSmePurposes(data?.data));
  } catch (error) {
    console.error('getSmePurposesWorker', error);
  }
}

function* getSmeSectorsWorker() {
  try {
    const { data } = yield call(apiHelpers.getLoanSectors);
    yield put(actions.putSmeSectors(data?.data));
  } catch (error) {
    console.error('getSmeSectorsWorker', error);
  }
}

function* getPlatformsWorker({ payload }: GetPlatforms) {
  try {
    const { data } = yield call(apiHelpers.getFiltersPlatform, payload?.loanType);
    yield put(actions.putPlatforms(data.data));

    callbackResolver(payload?.onSuccess, data.data);
  } catch (error) {
    console.error('getPlatformsWorker', error);
  }
}

export function* baseWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(baseTypes.GET_LOANS_SUMMARY, getFiltersSummaryWorker);
  yield takeLatest(baseTypes.GET_ALL_LOAN_FILTERS, getLoansFiltersWorker);
  yield takeLatest(baseTypes.GET_CURRENCIES, getCurrenciesWorker);
  yield takeLatest(baseTypes.GET_REGIONS, getRegionsWorker);
  yield takeLatest(baseTypes.GET_GRADES, getGradesWorker);
  yield takeLatest(baseTypes.GET_PLATFORMS, getPlatformsWorker);
  yield takeLatest(baseTypes.GET_CONSUMER_PURPOSES, getConsumerPurposesWorker);
  yield takeLatest(baseTypes.GET_SME_PURPOSES, getSmePurposesWorker);
  yield takeLatest(baseTypes.GET_SME_SECTORS, getSmeSectorsWorker);
}
