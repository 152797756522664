import Axios from 'axios';

export const createNewToken = async (name: string) => {
  const res = await Axios.post('/api-keys', { name });
  return res.data;
};

export const getTokens = async () => {
  const res = await Axios.get('/api-keys?limit=100');
  return res.data;
};

export const deleteToken = async (id: string) => {
  const res = await Axios.delete(`/api-keys/${id}`);
  return res.data;
};
