import React, { FC } from 'react';
import styles from './UserAgreement.module.scss';

interface UserAgreementProps {
  onAccept: () => void;
  onCancel: () => void;
}

const UserAgreement: FC<UserAgreementProps> = ({ onAccept, onCancel }) => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.detailsBlockWrapper}>
        <div className={styles.detailsBlock}>
          <p className={styles.detailsText}>User Agreement Details...</p>
        </div>
      </div>
      <div className={styles.btnBlock}>
        <button className={styles.btn} type="button" onClick={onAccept}>
          Accept
        </button>
        <button className={styles.btn} type="button" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserAgreement;
