import React, { FC, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

type TabLinkProps = {
  href: string;
};

const TabLink: FC<TabLinkProps> = forwardRef(({ href, ...rest }, ref: React.Ref<HTMLAnchorElement>) => (
  <NavLink ref={ref} to={href} {...rest} />
));

TabLink.displayName = 'TabLink';

export default TabLink;
