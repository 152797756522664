export enum InvestmentsByNames {
  BY_PURPOSE = 'loan_investment_by_purpose',
  BY_RATING = 'loan_investment_by_rating',
  BY_COUNTRY = 'loan_investment_by_country',
  BY_PLATFORM = 'loan_investment_by_platform',
  BY_TERM = 'loan_investment_by_term',
  BY_STATUS = 'loan_investment_by_status',
  BY_SECTOR = 'loan_investment_by_sector',
  BY_CURRENT_SECURITY = 'current_invested_by_security'
}

export type IStructureOption = {
  label: string;
  value: InvestmentsByNames;
};

export const structureOptions: IStructureOption[] = [
  {
    label: 'By Loan Purposes',
    value: InvestmentsByNames.BY_PURPOSE
  },
  {
    label: 'By Exaloan Score',
    value: InvestmentsByNames.BY_RATING
  },
  {
    label: 'By Country',
    value: InvestmentsByNames.BY_COUNTRY
  },
  {
    label: 'By Platform',
    value: InvestmentsByNames.BY_PLATFORM
  },
  {
    label: 'By Term',
    value: InvestmentsByNames.BY_TERM
  },
  {
    label: 'By Loan Status',
    value: InvestmentsByNames.BY_STATUS
  },
  {
    label: 'By Security Type',
    value: InvestmentsByNames.BY_CURRENT_SECURITY
  }
];

export const sectorStructureOption = {
  label: 'By Sector',
  value: InvestmentsByNames.BY_SECTOR
};

export enum GroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export enum GroupByFormatsFns {
  day = 'dd MMM yyyy',
  week = 'dd MMM YYYY',
  month = 'MMM yyyy',
  year = 'YYYY'
}

export enum Purposes {
  other = 'Other/Unspecified',
  education = 'Education',
  housing = 'Housing/Real Estate',
  car = 'Car',
  consumer = 'Consumer',
  self_employment = 'Self-employment/Small Business',
  debt_refinancing = 'Debt Refinancing/Consolidation',
  medical_purpose = 'Medical Purpose',
  refinancing = 'Refinancing',
  working_capital_finance = 'Working Capital Finance',
  growth_finance = 'Growth Finance',
  asset_finance = 'Asset Finance',
  other_finance = 'Other Finance'
}

export const NET_RETURN_BY_KEYS = ['net_return_by_week', 'net_return_by_month', 'net_return_by_year'];
