import React, { FC } from 'react';
import Slider, { SliderThumb, SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const StylesSlider = styled(Slider)(({ theme }) => {
  return {
    color: '#077186',
    height: 10,
    padding: '13px 0',

    '& .MuiSlider-thumb': {
      height: 31,
      width: 15,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      boxShadow: 'none',
      borderRadius: '5px',

      '&:hover': {
        boxShadow: '0 0 0 4px rgba(8, 114, 135, 0.16)'
      }
    },

    '& .MuiSlider-track': {
      height: 10
    },

    '& .MuiSlider-rail': {
      color: theme.palette.mode === 'dark' ? '#f3f3f3' : '#f3f3f3',
      opacity: 1,
      height: 10
    }
  };
});

function Thumb({ children, ...other }: React.HTMLAttributes<unknown>) {
  return <SliderThumb {...other}>{children}</SliderThumb>;
}

const SingleSlider: FC<SliderProps> = ({ defaultValue = 0, ...rest }) => {
  return <StylesSlider components={{ Thumb }} defaultValue={defaultValue} {...rest} />;
};

export default SingleSlider;
