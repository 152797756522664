import React, { forwardRef } from 'react';
import cn from 'classnames';
import ReactDatePicker from 'react-datepicker';
import isNil from 'lodash/isNil';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import range from 'lodash/range';
import NumberFormat from 'react-number-format';
import MonthSelect from './components/MonthSelect';
import YearSelect from './components/YearSelect';
import styles from './DatePicker.module.scss';
import './DatePicker.scss';

const DATE_FORMAT = 'dd/MM/yyyy';
const YEAR_DROPDOWN_NUMBER = 15;
const YEARS = range(2010, getYear(new Date()) + 50, 1).map((year) => ({ value: year, label: year.toString() }));
const MONTHS = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' }
];

interface CustomDateInputProps {
  isCalendarOpen: boolean;
  onChange?: (e) => void;
  onClick?: (e) => void;
  value?: string;
  placeholder?: string;
  dateInputClassName?: string;
}

const CustomDateInput = forwardRef(
  ({ onChange, placeholder, value, onClick, isCalendarOpen, dateInputClassName }: CustomDateInputProps, ref) => {
    return (
      <NumberFormat
        readOnly
        format="##/##/####"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        className={cn(styles.datePicker, {
          [styles.datePickerOpen]: isCalendarOpen,
          [dateInputClassName]: dateInputClassName
        })}
        onChange={onChange}
        getInputRef={ref}
      />
    );
  }
);

CustomDateInput.displayName = 'CustomDateInput';

const renderDayContents = (day: number, date: Date, month: number) => {
  const isSelectedMonth = getMonth(date) === month || isNil(month);

  return <span className={cn(styles.innerDay, { [styles.innerDaySelectedMonth]: isSelectedMonth })}>{day}</span>;
};

export enum PopperPlacementEnum {
  AUTO_START = 'auto-start',
  AUTO = 'auto',
  AUTO_END = 'auto-end',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end'
}

interface DatePickerProps {
  id;
  value: Date;
  selectedMonth: number;
  calendarLabel?: string;
  isCalendarOpen: boolean;
  popperPlacement?: PopperPlacementEnum;
  popperOffset?: { x?: number; y?: number };
  onOpenCalendar: () => void;
  onCloseCalendar: (date?: Date) => void;
  onSetSelectedMonth?: (id, value: number) => void;
  onDateChange: (id, date) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  getClassNames?: (date: Date) => Array<string>;
  onBlur?: (e) => void;
  dateInputClassName?: string;
  className?: string;
  clearButtonLabel?: string;
  onClearClick?: () => void;
  clearButtonClassName?: string;
}

const DatePicker = ({
  id,
  value,
  selectedMonth,
  calendarLabel,
  isCalendarOpen,
  popperPlacement,
  popperOffset,
  onOpenCalendar,
  onCloseCalendar,
  onSetSelectedMonth,
  onDateChange,
  minDate,
  maxDate,
  getClassNames,
  onBlur,
  dateInputClassName,
  className,
  clearButtonLabel,
  onClearClick,
  clearButtonClassName
}: DatePickerProps): JSX.Element => {
  const handleDateChange = (id, date: Date) => {
    onDateChange(id, date);
    onCloseCalendar(date);
  };

  const classNames = (date: Date) => (getClassNames ? cn([...getClassNames(date), styles.day]) : styles.day);

  return (
    <ReactDatePicker
      calendarClassName={styles.calendar}
      popperClassName={styles.popper}
      dayClassName={classNames}
      weekDayClassName={() => styles.weekDay}
      open={isCalendarOpen}
      selected={value}
      onInputClick={isCalendarOpen ? onCloseCalendar : onOpenCalendar}
      onClickOutside={() => onCloseCalendar()}
      onMonthChange={(date) => (onSetSelectedMonth ? onSetSelectedMonth(id, getMonth(date)) : null)}
      popperPlacement={popperPlacement ?? 'top'}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: `${popperOffset?.x ?? 0}px, ${popperOffset?.y ?? 13}px`
        }
      }}
      dateFormat={DATE_FORMAT}
      placeholderText="DD/MM/YYYY"
      onChange={(date) => handleDateChange(id, date as Date)}
      onBlur={onBlur}
      wrapperClassName={className}
      customInput={<CustomDateInput isCalendarOpen={isCalendarOpen} dateInputClassName={dateInputClassName} />}
      dateFormatCalendar="MMMM"
      showYearDropdown
      minDate={minDate}
      maxDate={maxDate}
      scrollableYearDropdown
      yearDropdownItemNumber={YEAR_DROPDOWN_NUMBER}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => {
        return (
          <div className={styles.header}>
            {calendarLabel && <span className={styles.title}>{calendarLabel}</span>}

            {onClearClick && clearButtonLabel && (
              <button
                type="button"
                className={cn(styles.clearDateButton, { [clearButtonClassName]: clearButtonClassName })}
                onClick={onClearClick}
              >
                {clearButtonLabel}
              </button>
            )}

            <div className={styles.dateNavigationBlock}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
                className={styles.dateNavButton}
              >
                {'<'}
              </button>

              <div className={styles.selectBlock}>
                <MonthSelect
                  options={MONTHS}
                  value={MONTHS[getMonth(date)]}
                  onChange={(value) => changeMonth(MONTHS.findIndex((month) => month.value === value?.value))}
                />

                <YearSelect
                  options={YEARS}
                  value={{ value: getYear(date), label: getYear(date).toString() }}
                  onChange={(year) => changeYear(year.value)}
                />
              </div>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  increaseMonth();
                }}
                disabled={nextMonthButtonDisabled}
                className={styles.dateNavButton}
              >
                {'>'}
              </button>
            </div>
          </div>
        );
      }}
      renderDayContents={(day, date) => renderDayContents(day, date, selectedMonth)}
    />
  );
};

export default DatePicker;
