import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './StatementBox.module.scss';

interface StatementBoxProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const StatementBox = forwardRef(
  ({ children, className, style }: StatementBoxProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
      <div ref={ref} style={style} className={cn(styles.wrapper, { [className]: className })}>
        {children}
      </div>
    );
  }
);

StatementBox.displayName = 'StatementBox';

export default StatementBox;
