import { TableHeaderColumns, ColumnAlignOptions, TableHeaderHeadingColumns } from 'src/interfaces';

export enum CashflowColIds {
  planned_payment_date = 'planned_payment_date',
  planned_principal_payment = 'planned_principal_payment',
  planned_interest_payment = 'planned_interest_payment',
  planned_total_payment = 'planned_total_payment',

  actual_payment_date = 'actual_payment_date',
  actual_principal_payment = 'actual_principal_payment',
  actual_interest_payment = 'actual_interest_payment',
  actual_total_payment = 'actual_total_payment'
}

export const PLUG_ROWS = [
  {
    id: '0',
    planned_payment_date: '-',
    planned_principal_payment: 0,
    planned_interest_payment: 0,
    planned_total_payment: 0,
    actual_payment_date: '-',
    actual_principal_payment: 0,
    actual_interest_payment: 0,
    actual_total_payment: 0
  }
];

const {
  planned_payment_date,
  planned_principal_payment,
  planned_interest_payment,
  planned_total_payment,
  actual_payment_date,
  actual_principal_payment,
  actual_interest_payment,
  actual_total_payment
} = CashflowColIds;

export type CashflowRow = {
  id?: string;
  [planned_payment_date]: string;
  [planned_principal_payment]: number | string;
  [planned_interest_payment]: number | string;
  [planned_total_payment]: number | string;
  [actual_payment_date]: string;
  [actual_total_payment]: number | string;
};

export const CASHFLOW_COLUMNS: TableHeaderColumns = [
  {
    label: 'Payment Date',
    id: planned_payment_date,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.LEFT
  },
  {
    label: 'Principal payment',
    id: planned_principal_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Interest payment',
    id: planned_interest_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Total payment',
    id: planned_total_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Payment Date',
    id: actual_payment_date,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Principal payment',
    id: actual_principal_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Interest payment',
    id: actual_interest_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    label: 'Total payment',
    id: actual_total_payment,
    isSortable: false,
    isOpen: false,
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export const CASHFLOW_TITLE_COLUMNS: TableHeaderHeadingColumns = [
  { id: '0', label: 'Planned', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '1', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '2', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '3', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '4', label: 'Actual', align: ColumnAlignOptions.CENTER, colSpan: 1 },
  { id: '5', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '6', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 },
  { id: '7', label: '', align: ColumnAlignOptions.LEFT, colSpan: 1 }
];
