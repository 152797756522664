import { useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { putUser, refreshAuthError, setAuthenticated } from 'src/redux/actions/authActions';
import { userSelector, authSelector } from 'src/redux/selectors';
import { getCurrentSession, getCurrentAuthenticatedUser, setAuthToken } from 'src/helpers/authHelpers';
import { ROUTE_OPTIONS } from 'src/constants/routes';

const BLOCK_REFRESH_TIMEOUT = 1000 * 60 * 7;
const REFRESH_ON_MOVE_AFTER = 60 * 7;

const INITIAL_COUNTER = 0;
let COUNTER = INITIAL_COUNTER;

const INCREMENT = 10;
const TIMEOUT = INCREMENT * 1000;
const MOUSE_MOVE_TIMEOUT = 1000;

const useRefreshAuthentication = (onLogoutWithRedirect: () => void): void => {
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { isAuthenticated, unauthorizedError } = useSelector(authSelector);

  const intervalRef = useRef<NodeJS.Timeout>();
  const refreshGuardRef = useRef<boolean>();

  const activeRoute = useMemo(
    () => ROUTE_OPTIONS.find((route) => route.path.includes(location.pathname)),
    [location.pathname]
  );

  const refreshAuthSession = useCallback(async () => {
    if (refreshGuardRef?.current) return;

    if (activeRoute?.isPrivate) {
      refreshGuardRef.current = true;
      setTimeout(() => (refreshGuardRef.current = false), BLOCK_REFRESH_TIMEOUT);
    }

    try {
      const session = await getCurrentSession();
      const token = session.getAccessToken().getJwtToken();

      setAuthToken(token);
      dispatch(setAuthenticated(!!token));
    } catch (error) {
      dispatch(refreshAuthError());
      if (activeRoute?.isPrivate) onLogoutWithRedirect();
    }
  }, [dispatch, onLogoutWithRedirect, activeRoute]);

  const refreshAuthUser = useCallback(async () => {
    try {
      const user = await getCurrentAuthenticatedUser();
      dispatch(putUser(user));
    } catch (error) {
      dispatch(refreshAuthError());
    }
  }, [dispatch]);

  const onMoveRefreshSession = useCallback(
    throttle(() => {
      if (!activeRoute?.isPrivate) return;

      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          COUNTER += INCREMENT;
        }, TIMEOUT);
      }

      if (COUNTER >= REFRESH_ON_MOVE_AFTER) {
        COUNTER = INITIAL_COUNTER;
        clearInterval(intervalRef.current);
        intervalRef.current = null;

        refreshAuthSession();
      }
    }, MOUSE_MOVE_TIMEOUT),
    [activeRoute]
  );

  useEffect(() => {
    const shouldRefreshSession = !isAuthenticated && !unauthorizedError;
    const shouldRefreshUser = isAuthenticated && !user && !unauthorizedError;

    if (shouldRefreshSession) refreshAuthSession();
    if (shouldRefreshUser) refreshAuthUser();
  }, [user, isAuthenticated, refreshAuthSession, refreshAuthUser, unauthorizedError]);

  useEffect(() => {
    if (activeRoute?.isPrivate) {
      document.addEventListener('mousemove', onMoveRefreshSession);
    }

    return () => {
      if (activeRoute?.isPrivate) {
        document.removeEventListener('mousemove', onMoveRefreshSession);
      }
    };
  }, [onMoveRefreshSession, activeRoute]);
};

export default useRefreshAuthentication;
