import React, { FC } from 'react';
import { StatementBox } from 'src/components';
import { ConstraintsTitle } from '../atoms';
import { CriteriaRow } from '../..';
import { PortfolioLoanType as LoanPurpose } from '../../../model';
import styles from './LoanPurposes.module.scss';

type Props = {
  isViewPage: boolean;
  purposes: LoanPurpose[];
  onChange: (id: string, value: number) => void;
};

const LoanPurposes: FC<Props> = ({ isViewPage, purposes, onChange }) => {
  return (
    <StatementBox className={styles.box}>
      <ConstraintsTitle title="Loan Purposes - Consumer" />

      <div className={styles.content}>
        {purposes?.map(({ id, name, percentage }) => (
          <CriteriaRow
            key={id}
            label={name}
            value={percentage}
            onChange={(value: number) => onChange(id, value)}
            readOnly={isViewPage}
          />
        ))}
      </div>
    </StatementBox>
  );
};

export default LoanPurposes;
