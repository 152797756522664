import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthTitleBlock, ButtonUnderlined as ButtonBack } from 'src/components';
import { PAGES } from 'src/constants/pages';
import styles from '../RegisterPage.module.scss';

const ThanksForSignUp = (): JSX.Element => {
  const { push } = useHistory();

  return (
    <React.Fragment>
      <AuthTitleBlock
        title="Thank You for Signing up!"
        description="You have successfully signed up! Please check your email to confirm your account."
        classes={{ blockClass: styles.thanksTitleBlock, titleClass: styles.thanksTitle }}
      />

      <ButtonBack onClick={() => push(PAGES.SIGN_IN)} label="Back to sign in" />
    </React.Fragment>
  );
};

export default ThanksForSignUp;
