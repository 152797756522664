import React from 'react';
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';

interface YearSelectProps {
  options: Array<{ value: number; label: string }>;
  value: { value: number; label: string };
  onChange: (value) => void;
}

const YearSelect = ({ options, value, onChange }: YearSelectProps): JSX.Element => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      minMenuHeight={140}
      maxMenuHeight={175}
      components={{ DropdownIndicator }}
      styles={{
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none'
        }),
        container: (base) => ({
          ...base,
          minWidth: 55,
          minHeight: 30,
          margin: '0px 1px'
        }),
        control: (base) => ({
          ...base,
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          justifyContent: 'flex-start',
          minHeight: 30
        }),

        singleValue: (base) => ({
          ...base,
          fontFamily: 'Verlag, sanserif',
          fontSize: 15,
          lineHeight: '18px',
          color: '#000'
        }),
        input: (base) => ({
          ...base,
          fontSize: 15,
          lineHeight: '18px',
          fontFamily: 'Verlag, sanserif',
          color: '#000'
        }),

        menu: (base) => ({
          ...base,
          zIndex: 10,
          marginTop: 1,
          marginBottom: 0,
          left: 0,
          minWidth: 80
        }),

        menuList: (base) => ({
          ...base,
          overflowX: 'hidden',
          padding: '0px 4px',

          '&::-webkit-scrollbar': {
            width: '4px'
          },

          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F2F3F6',
            borderRadius: '50px',
            width: '4px'
          },

          '&::-webkit-scrollbar-thumb': {
            background: '#D5D5D5',
            borderRadius: '3px',
            width: '6px'
          }
        }),

        option: (base, state) => ({
          ...base,
          textAlign: 'center',
          fontFamily: 'Verlag, sanserif',
          transitionProperty: 'all',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
          border: state.isSelected ? '1px solid rgba(7, 113, 134, 0.9)' : '1px solid #fff',
          borderRadius: '5px',
          backgroundColor: '#fff',
          color: '#111a3f',
          fontSize: 15,
          lineHeight: '18px',
          letterSpacing: '-0.777778px',

          '&:hover': { border: '1px solid rgba(7, 113, 134, 0.9)' },
          '&:active': { backgroundColor: 'rgba(7, 113, 134, 0.6)' }
        }),

        placeholder: (base: React.CSSProperties) => ({
          ...base,
          fontFamily: 'Verlag, sanserif',
          fontSize: 15,
          lineHeight: '18px',
          color: '#9dafbd'
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '0px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap'
        })
      }}
    />
  );
};

export default YearSelect;
