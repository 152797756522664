/* eslint-disable no-case-declarations */
import { investmentsTypes, authActionTypes } from '../types';
import uniqBy from 'lodash/uniqBy';
import { InvestmentsReducer } from 'src/interfaces/investmentsReduxShapes';
import { GET_INVESTMENT_LOANS_DEFAULT_PARAMS } from 'src/pages/InvestmentsPage/InvestmentContent/helpers';
import { loanHandler, outstandingBidHandler } from '../../utils/reduxHelpers';

const { limit: LIMIT_PER_PAGE, page } = GET_INVESTMENT_LOANS_DEFAULT_PARAMS();

const initialState: InvestmentsReducer = {
  investmentsData: { investments: [], total: 0, loans: [], hasMoreInvestments: true, page: page },
  outstandingBidsData: {
    outstandingBids: [],
    hasMoreBids: true,
    page: page
  },
  loansFilters: {
    loanTypes: [],
    allocations: undefined,
    allocationsWithOpportunities: undefined
  },
  totalOpportunities: 0,
  opportunities: {
    data: null,
    page: 1,
    pagesCount: 1,
    size: 0,
    total: 0
  },
  cachedParams: null
};

export default (state = initialState, { type, payload }: { type: string; payload }): InvestmentsReducer => {
  switch (type) {
    case investmentsTypes.PUT_INVESTMENTS_LOANS_DATA:
      const investments = uniqBy([...loanHandler(payload.data)], 'id');

      return {
        ...state,
        investmentsData: {
          investments: investments,
          total: payload.total,
          loans: uniqBy([...state.investmentsData.loans, ...loanHandler(payload.data)], 'id'),
          hasMoreInvestments: investments.length % LIMIT_PER_PAGE === 0,
          page: payload?.page
        }
      };

    case investmentsTypes.PUT_MORE_INVESTMENTS_LOANS_DATA:
      const investmentsMore = uniqBy([...state.investmentsData.investments, ...loanHandler(payload.data)], 'id');

      return {
        ...state,
        investmentsData: {
          investments: investmentsMore,
          total: payload.total,
          loans: uniqBy([...state.investmentsData.loans, ...loanHandler(payload.data)], 'id'),
          hasMoreInvestments: investmentsMore.length % LIMIT_PER_PAGE === 0,
          page: payload?.page
        }
      };

    case investmentsTypes.PUT_OUTSTANDING_BIDS:
      const outstandingBids = uniqBy([...outstandingBidHandler(payload.data)], 'id');

      return {
        ...state,
        outstandingBidsData: {
          outstandingBids: outstandingBids,
          hasMoreBids: outstandingBids.length % LIMIT_PER_PAGE === 0,
          page: payload?.page
        }
      };

    case investmentsTypes.PUT_MORE_OUTSTANDING_BIDS:
      const outstandingBidsMore = uniqBy(
        [...state.outstandingBidsData.outstandingBids, ...outstandingBidHandler(payload.data)],
        'id'
      );

      return {
        ...state,
        outstandingBidsData: {
          outstandingBids: outstandingBidsMore,
          hasMoreBids: outstandingBidsMore.length % LIMIT_PER_PAGE === 0,
          page: payload?.page
        }
      };

    case investmentsTypes.PUT_INVESTMENTS_CACHED_PARAMS:
      return { ...state, cachedParams: payload };

    case investmentsTypes.CLEAR_INVESTMENTS:
      return {
        ...state,
        investmentsData: initialState.investmentsData
      };

    case investmentsTypes.PUT_INVESTMENTS_FILTERS:
      return {
        ...state,
        loansFilters: { ...payload }
      };

    case investmentsTypes.PUT_TOTAL_OPPORTUNITIES:
      return {
        ...state,
        totalOpportunities: payload
      };

    case investmentsTypes.PUT_OPPORTUNITIES:
      return {
        ...state,
        opportunities: {
          ...payload,
          data: loanHandler(payload.data)
        }
      };

    case investmentsTypes.PUT_MORE_OPPORTUNITIES:
      return {
        ...state,
        opportunities: {
          ...payload,
          data: [...state.opportunities.data, ...loanHandler(payload.data)]
        }
      };

    case investmentsTypes.CLEAR_OPPORTUNITIES:
      return {
        ...state,
        opportunities: {
          ...initialState.opportunities,
          data: []
        },
        cachedParams: null
      };

    case investmentsTypes.CLEAR_CACHED_REQ_PARAMS:
      return {
        ...state,
        cachedParams: null
      };

    case authActionTypes.SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
