import { call, all, put, takeEvery, ForkEffect, takeLeading } from 'redux-saga/effects';
import { callbackResolver } from 'src/utils/reduxHelpers';
import {
  GetReports,
  GetMoreReportsByPlatform,
  GetTransactionsAndReportsAction,
  PutTransactionsAndReportsPayload,
  GetMoreTransactionsAction,
  TAddTransactionAction,
  TDeleteTransactionAction
} from 'src/interfaces/reportsReduxShapes';
import { DEFAULT_REPORTS_REQ_PARAMS } from 'src/constants/reportsConstants';
import { PlatformsIDs } from 'src/constants/globalConstants';
import {
  addTransaction,
  deleteTransaction,
  getReportsHelper,
  getTransactionsHelper
} from 'src/redux/sagas/sagaHelpers/reportsApiHelpers';
import {
  putReports,
  putMoreReportsByPlatform,
  putTransactionsAndReports,
  putMoreTransactionsByPlatform,
  putTransactions
} from '../actions/reportsActions';
import { reportTypes } from '../types';

function* getReportsWorker({ payload }: GetReports) {
  try {
    const res = yield all(
      payload.reqParams.platforms.map((platform: PlatformsIDs) => {
        return call(getReportsHelper, { ...payload.reqParams, platforms: [platform] });
      })
    );

    const data = res.map((item, idx) => ({
      platform: payload.reqParams.platforms[idx],
      data: item.data.data,
      clientSidePagination: DEFAULT_REPORTS_REQ_PARAMS
    }));

    yield put(putReports(data));

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('getReportsWorker', error);
  }
}

function* getTransactionsWorker({ payload }: GetTransactionsAndReportsAction) {
  try {
    const res = yield all(
      payload.reqParams.platforms.map((platform: PlatformsIDs) => {
        return call(getTransactionsHelper, { ...payload.reqParams, platforms: [platform] }, payload.transactionType);
      })
    );

    const data = res.map((item, idx) => ({
      platform: payload.reqParams.platforms[idx],
      data: item.data.data,
      actual_balance_original: item.data.actual_balance_original,
      actual_balance_reporting: item.data.actual_balance_reporting,
      clientSidePagination: DEFAULT_REPORTS_REQ_PARAMS
    }));

    yield put(putTransactions(data));

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('getTransactionsWorker', error);
  }
}

function* getMoreReportsByPlatformWorker({ payload }: GetMoreReportsByPlatform) {
  try {
    const { data } = yield call(getReportsHelper, payload.reqParams);

    yield put(
      putMoreReportsByPlatform({
        data: data.data,
        platform: payload.reqParams.platforms,
        page: payload.reqParams.page
      })
    );

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('getMoreReportsByPlatformWorker', error);
  }
}

function* getMoreTransactionsByPlatformWorker({ payload }: GetMoreTransactionsAction) {
  try {
    const { data } = yield call(getTransactionsHelper, payload.reqParams, payload.transactionType);

    yield put(
      putMoreTransactionsByPlatform({
        data: data.data,
        actual_balance_original: data?.actual_balance_original,
        actual_balance_reporting: data?.actual_balance_reporting,
        platform: payload.reqParams.platforms,
        page: payload.reqParams.page
      })
    );

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('getMoreTransactionsByPlatformWorker', error);
  }
}

function* getTransactionAndReportsWorker({ payload }: GetTransactionsAndReportsAction) {
  try {
    const cashflow = yield all(
      payload.reqParams.platforms.map((platform: PlatformsIDs) => {
        return call(getReportsHelper, { ...payload.reqParams, platforms: [platform] });
      })
    );

    const transactions = yield all(
      payload.reqParams.platforms.map((platform: PlatformsIDs) => {
        return call(getTransactionsHelper, { ...payload.reqParams, platforms: [platform] }, payload.transactionType);
      })
    );

    const data: PutTransactionsAndReportsPayload = {
      transactions: transactions.map((item, idx) => ({
        platform: payload.reqParams.platforms[idx],
        data: item.data.data,
        actual_balance_original: item.data.actual_balance_original,
        actual_balance_reporting: item.data.actual_balance_reporting,
        clientSidePagination: DEFAULT_REPORTS_REQ_PARAMS
      })),

      cashflow: cashflow.map((item, idx) => ({
        platform: payload.reqParams.platforms[idx],
        data: item.data.data,
        clientSidePagination: DEFAULT_REPORTS_REQ_PARAMS
      }))
    };

    yield put(putTransactionsAndReports(data));

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('getTransactionAndReportsWorker', error);
  }
}

function* addTransactionWorker({ payload }: TAddTransactionAction) {
  try {
    yield call(addTransaction, payload.body);

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('addTransactionWorker', error);
  }
}

function* deleteTransactionWorker({ payload }: TDeleteTransactionAction) {
  try {
    yield call(deleteTransaction, payload.id);

    callbackResolver(payload?.onSuccess);
  } catch (error) {
    callbackResolver(payload?.onError);
    console.error('deleteTransactionWorker', error);
  }
}

export function* transactionsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(reportTypes.GET_REPORTS, getReportsWorker);
  yield takeEvery(reportTypes.GET_MORE_REPORTS_BY_PLATFORM, getMoreReportsByPlatformWorker);
  yield takeEvery(reportTypes.GET_TRANSACTIONS, getTransactionsWorker);
  yield takeEvery(reportTypes.GET_MORE_TRANSACTIONS_BY_PLATFORM, getMoreTransactionsByPlatformWorker);
  yield takeEvery(reportTypes.GET_TRANSACTIONS_AND_REPORTS, getTransactionAndReportsWorker);
  yield takeLeading(reportTypes.ADD_TRANSACTION, addTransactionWorker);
  yield takeLeading(reportTypes.DELETE_TRANSACTION, deleteTransactionWorker);
}
