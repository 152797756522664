import { Steps } from 'src/interfaces/RandomShapes';

export enum SignUpFields {
  COUNTRY_OF_RESIDENCE = 'country',
  COMPANY_NAME = 'companyName',
  COMPANY_NUMBER = 'companyNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  USER_AGREEMENT = 'agreement',
  NEWS_OFFERS = 'offers'
}

export interface ConfirmInformationStep {
  steps: Steps;
  handlePrev: () => void;
  handleNext: () => void;
  onSetFormState: (values: ConfirmInformationFormValues) => void;
  formState: ConfirmInformationFormValues;
}

export interface ConfirmInformationFormValues {
  country: { value: string; label: string };
  companyName: string;
  companyNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreement: boolean;
  offers: boolean;
}
