import React, { memo } from 'react';
import { FieldNames, SelectOption } from 'src/pages/PortfolioBuilderPage/AllocationPage/model';
import { CustomizedSelect } from 'src/components';
import { REINVESTMENT_STRATEGY_OPTIONS } from 'src/pages/PortfolioBuilderPage/AllocationPage/constants';
import styles from '../../TypeBlock.module.scss';

type Props = {
  isViewMode: boolean;
  value: SelectOption;
  onChange: (value) => void;
  onBlur: () => void;
};

const ReinvestingStrategy = memo<Props>(({ isViewMode, value, onChange, onBlur }) => {
  return (
    <div className={styles.typeBlock}>
      {isViewMode ? (
        <div className={styles.allocationViewInfo}>
          <span className={styles.allocationViewLabel}>Reinvesting Strategy</span>
          <span className={styles.allocationViewValue}>{value?.label ?? null}</span>
        </div>
      ) : (
        <React.Fragment>
          <h3 className={styles.fieldLabelClass}>Reinvesting Strategy</h3>
          <CustomizedSelect
            isDisabled={isViewMode}
            name={FieldNames.REINVESTMENT_STRATEGY}
            value={value}
            options={REINVESTMENT_STRATEGY_OPTIONS}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="Reinvesting Strategy"
            isSearchable={false}
            customStyles={{
              container: { width: 323, padding: '0 12px' },
              valueContainer: {
                display: 'flex',
                justifyContent: 'flex-start'
              }
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
});

ReinvestingStrategy.displayName = 'ReinvestingStrategy';

export default ReinvestingStrategy;
