import axios from 'axios';
import { all, AllEffect, ForkEffect } from 'redux-saga/effects';
import { marketplaceWatcher } from './marketplaceSagas';
import { investmentsWatcher } from './investmentsSagas';
import { portfolioBuilderWatcher } from './portfolioBuilderSagas';
import { statisticsWatcher } from './statisticsSagas';
import { baseWatcher } from './baseSagas';
import { apiTokensWatcher } from './apiSagas';
import { transactionsWatcher } from './transactionsSagas';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    marketplaceWatcher(),
    investmentsWatcher(),
    portfolioBuilderWatcher(),
    baseWatcher(),
    statisticsWatcher(),
    apiTokensWatcher(),
    transactionsWatcher()
  ]);
}
