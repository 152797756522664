import { createStyles, makeStyles } from '@mui/styles';

export const usePaginationStyles = makeStyles(() =>
  createStyles({
    paginationRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    paginationItemPage: {
      fontSize: '15px',
      fontFamily: 'Verlag, sans-serif',
      color: '#000',

      '&:hover': {
        backgroundColor: '#F1F5F6'
      },

      '&.Mui-selected': {
        backgroundColor: 'rgba(7, 113, 134, 0.14)',
        color: '#000',
        '&:hover': {
          backgroundColor: 'rgba(7, 113, 134, 0.14)'
        },
        '&:focus': {
          backgroundColor: 'rgba(7, 113, 134, 0.14)'
        }
      }
    },
    paginationIcon: {
      width: '1.25em',
      height: '1.25em',
      fill: '#0F1942'
    }
  })
);

export const paginationSelectStyles = (selectStyles: {
  menuMargin?: string;
  menuBoxShadow?: string;
}): Record<string, unknown> => ({
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  container: (base) => ({
    ...base,
    minWidth: 70,
    minHeight: 24,
    padding: '0px 10px 0px 14px',
    background: ' #F3F3F3',
    borderRadius: '5px',
    marginRight: 10
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    minHeight: 24
  }),
  singleValue: (base) => ({
    ...base,
    fontFamily: 'Verlag, sanserif',
    fontSize: 15,
    lineHeight: '15px',
    color: '#000'
  }),
  input: (base) => ({
    ...base,
    width: '100%',
    fontSize: 15,
    lineHeight: '15px',
    fontFamily: 'Verlag, sanserif',
    color: '#000',
    margin: '0px 2px'
  }),

  menu: (base) => ({
    ...base,
    zIndex: 1250,
    margin: selectStyles?.menuMargin ?? '2px 0 0',
    left: 0,
    minWidth: 70,
    background: '#fff',
    boxShadow: selectStyles?.menuBoxShadow ?? '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 5px 5px'
  }),

  menuList: (base) => ({
    ...base,
    overflowX: 'auto',
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px'
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F2F3F6',
      borderRadius: '50px',
      width: '4px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#D5D5D5',
      borderRadius: '3px',
      width: '6px'
    }
  }),

  option: (base, state) => ({
    ...base,
    maxHeight: 33,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Verlag, sanserif',
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    border: state.isSelected ? '1px solid rgba(7, 113, 134, 0.03)' : '1px solid #fff',
    backgroundColor: state.isSelected ? 'rgba(7, 113, 134, 0.14)' : '#fff',
    color: '#111a3f',
    fontSize: 15,
    letterSpacing: '-0.777778px',

    '&:last-child': {
      borderRadius: '0px 0px 5px 5px'
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: state.isSelected ? 'rgba(7, 113, 134, 0.01)' : '#fff',
      boxShadow: ' 0px 3px 10px 0.7px rgba(0, 0, 0, 0.10)',
      transitionProperty: 'all',
      transitionDuration: '0.3s',
      transitionTimingFunction: 'ease-in-out'
    },

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-1px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '1px',
      background: state.isSelected ? 'rgba(7, 113, 134, 0.01)' : '#fff',
      boxShadow: ' 0px 4px 10px 0.3px rgba(0, 0, 0, 0.03)',
      transitionProperty: 'all',
      transitionDuration: '0.3s',
      transitionTimingFunction: 'ease-in-out'
    },

    '&:hover': {
      border: '1px solid rgba(7, 113, 134, 0.03)',
      backgroundColor: 'rgba(7, 113, 134, 0.14)',

      '&::after': {
        background: 'rgba(7, 113, 134, 0.01)'
      },

      '&::before': {
        background: 'rgba(7, 113, 134, 0.03)'
      }
    },
    '&:active': {
      backgroundColor: 'rgba(7, 113, 134, 0.14)'
    }
  }),

  placeholder: (base: React.CSSProperties) => ({
    ...base,
    fontFamily: 'Verlag, sanserif',
    fontSize: 15,
    lineHeight: '18px',
    color: '#979797',
    letterSpacing: '-0.9px'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap'
  })
});
