import { SortOrders } from 'src/constants/globalConstants';
import { TableHeaderColumns, ColumnAlignOptions } from 'src/interfaces/CustomTableShapes';

export enum PortfolioProfitabilityColIds {
  SCORE = 'score',
  INVESTED_AMOUNT = 'invested_amount',
  INTEREST_RATE = 'interest_rate',
  DEFAULT_AMOUNT = 'default_amount',
  DEFAULT_RATE = 'default_rate',
  NET_RETURN = 'net_return',
  NET_RETURN_PERCENTAGE = 'net_return_in_%'
}

const { SCORE, INVESTED_AMOUNT, INTEREST_RATE, DEFAULT_AMOUNT, DEFAULT_RATE, NET_RETURN, NET_RETURN_PERCENTAGE } =
  PortfolioProfitabilityColIds;

export const portfolioProfitabilityCols: TableHeaderColumns = [
  {
    id: SCORE,
    label: 'Exaloan Score Classes',
    isSortable: true,
    isOpen: false,
    sortOptions: [
      { title: 'High - Low', order: SortOrders.ASC },
      { title: 'Low - High', order: SortOrders.DESC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: INVESTED_AMOUNT,
    isSortable: true,
    isOpen: false,
    label: 'Invested Amount',
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: INTEREST_RATE,
    isSortable: true,
    isOpen: false,
    label: 'Interest Rate',
    sortOptions: [
      { title: 'High - Low', order: SortOrders.DESC },
      { title: 'Low - High', order: SortOrders.ASC }
    ],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: DEFAULT_AMOUNT,
    isSortable: false,
    isOpen: false,
    label: 'Default Amount',
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: DEFAULT_RATE,
    isSortable: false,
    isOpen: false,
    label: 'Default Rate',
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: NET_RETURN,
    isSortable: false,
    isOpen: false,
    label: 'Net Return',
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  },
  {
    id: NET_RETURN_PERCENTAGE,
    isSortable: false,
    isOpen: false,
    label: 'Net Return In %',
    sortOptions: null,
    align: ColumnAlignOptions.CENTER
  }
];

export enum StatisticSortTypes {
  RATING = 'rating',
  INTEREST_RATE = 'interesetRate',
  INVESTED = 'invested'
}
