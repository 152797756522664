import { authActionTypes } from '../types';
import { AuthReducer, IAction } from 'src/interfaces';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  unauthorizedError: false
};

export default (state = initialState, { type, payload }: IAction): AuthReducer => {
  switch (type) {
    case authActionTypes.PUT_USER:
      return { unauthorizedError: false, isAuthenticated: true, isAuthenticating: false };

    case authActionTypes.SET_AUTHENTICATED:
      return { ...state, unauthorizedError: false, isAuthenticated: payload };

    case authActionTypes.SIGN_OUT:
      return { ...state, isAuthenticated: false, isAuthenticating: false };

    case authActionTypes.REFRESH_ERROR:
      return { ...state, isAuthenticated: false, isAuthenticating: false };

    case authActionTypes.UNAUTHORIZED_ERROR:
      return { ...state, unauthorizedError: true };

    default:
      return state;
  }
};
