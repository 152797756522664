import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import cn from 'classnames';
import { Formik, FormikHelpers, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import {
  FormikCustomField as CustomField,
  ButtonNext,
  ButtonUnderlined as ButtonBack,
  RequestErrorMessage,
  MainLayout,
  AuthTitleBlock
} from 'src/components';
import { authSelector } from 'src/redux/selectors';
import { PAGES } from 'src/constants/pages';
import { forgotPasswordHelper, forgotPasswordSubmitHelper } from 'src/helpers/authHelpers';
import { InputEnums } from 'src/interfaces/RandomShapes';
import { validationSchema, formFields } from './constants';
import styles from './ConfirmPasswordRecoveryPage.module.scss';

interface FormValues {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

type ConfirmPasswordRecoveryLocation = {
  email: string;
};

const ConfirmPasswordRecoveryPage = (): JSX.Element => {
  const { push, location } = useHistory<ConfirmPasswordRecoveryLocation>();

  const { isAuthenticated } = useSelector(authSelector);

  const [isCodeResend, setCodeResend] = useState(true);
  const toggleCodeResend = () => setCodeResend((prev) => !prev);

  const [isResponseError, setResponseError] = useState(false);
  const onClearResponseError = () => setResponseError(false);

  const onResendCode = async () => {
    try {
      await forgotPasswordHelper(location?.state?.email);
      toggleCodeResend();
    } catch (error) {
      console.error('forgotPasswordHelper error', error);
    }
  };

  const countdownEndDate = Date.now() + 60000;

  const description = `We have sent a verification code to the Email address: ${
    location?.state?.email ?? ''
  }. Enter the code below to reset your password.`;

  if (isAuthenticated) return <Redirect to={PAGES.DASHBOARD} />;
  return (
    <MainLayout isPrivate={false}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <AuthTitleBlock
            title="Identity Verification"
            description={description}
            classes={{ blockClass: styles.titleBlock, titleClass: styles.title }}
          />

          {isResponseError && <RequestErrorMessage msg="The code you entered is incorrect, Please check your email" />}

          <button
            type="button"
            onClick={onResendCode}
            disabled={isCodeResend}
            className={cn(styles.resendBtn, { [styles.resendDisabled]: isCodeResend })}
          >
            Resend Code
            {isCodeResend && (
              <Countdown
                date={countdownEndDate}
                onComplete={toggleCodeResend}
                renderer={({ minutes, seconds }) => (
                  <span className={styles.timer}>
                    &nbsp;In {zeroPad(minutes, 1)}:{zeroPad(seconds, 2)}
                  </span>
                )}
              />
            )}
          </button>
        </div>

        <Formik
          initialValues={{
            email: location?.state?.email ?? '',
            code: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ email, code, password }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
            try {
              await forgotPasswordSubmitHelper(email, code, password);
              push(PAGES.CHANGE_PASSWORD_SUCCESS);
            } catch (error) {
              console.error('forgotPasswordSubmitHelper error', error);
              setResponseError(true);
            }

            setSubmitting(false);
          }}
        >
          {({ errors, values }) => {
            const isSomeValuesFalsy = some(values, (value) => !value);

            return (
              <Form className={styles.form}>
                {formFields.map(({ name, id, type, placeholder }) => (
                  <CustomField
                    key={name}
                    name={name}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    onClearRequestError={onClearResponseError}
                    isPassword={type === InputEnums.PASSWORD}
                    classes={{ wrapperClassName: styles.fieldWrapper }}
                    readOnly={name === 'email'}
                  />
                ))}

                <ButtonNext type="submit" isDisabled={isSomeValuesFalsy || !isEmpty(errors)} />
              </Form>
            );
          }}
        </Formik>
        <ButtonBack onClick={() => push(PAGES.SIGN_IN)} />
      </div>
    </MainLayout>
  );
};

export default ConfirmPasswordRecoveryPage;
