import React from 'react';
import cn from 'classnames';
import { CheckboxWithLabel, ShouldRender } from 'src/components';
import styles from './CheckboxBlock.module.scss';

enum VariantType {
  noBorders = 'no-borders',
  normal = 'normal'
}

interface CheckboxBlockProps {
  isDisabled?: boolean;
  blockId: string;
  blockLabel: string;
  isAllChecked: boolean;
  withTruncate?: boolean;
  truncateWidth?: number;
  onChange: (blockId: string, id: string, isChecked: boolean, name: string) => void;
  onSelectAll: (blockId: string, isChecked: boolean) => void;
  list: Array<{
    id: string;
    name: string;
    isChecked: boolean;
  }>;
  variant?: 'no-borders' | 'normal';
  displaySelectAll?: boolean;
  className?: string;
  checkboxRootClass?: string;
  labelRootClass?: string;
}

const CheckboxBlock = ({
  isDisabled,
  blockId,
  blockLabel,
  list,
  onChange,
  onSelectAll,
  isAllChecked,
  truncateWidth,
  withTruncate,
  variant = 'normal',
  displaySelectAll = true,
  className,
  checkboxRootClass,
  labelRootClass
}: CheckboxBlockProps): JSX.Element => {
  return (
    <div
      className={cn(styles.contentWrapper, {
        [styles.noBorders]: variant === VariantType.noBorders,
        [className]: className
      })}
    >
      <div className={cn(styles.labelWrapper, { [styles.whiteBg]: variant === VariantType.noBorders })}>
        <span className={styles.label}>{blockLabel}</span>
      </div>

      <ShouldRender should={displaySelectAll}>
        <div className={styles.selectAllWrapper}>
          <CheckboxWithLabel
            isDisabled={isDisabled}
            name={blockId}
            title={isAllChecked ? 'Clear All' : 'Select All'}
            isChecked={isAllChecked}
            onChange={() => (onSelectAll ? onSelectAll(blockId, isAllChecked) : null)}
            classes={{
              labelTextClass: styles.labelTextClass,
              labelRootClass: cn(styles.labelRootClass, { [labelRootClass]: labelRootClass }),
              checkboxRootClass: cn(styles.checkboxRootClass, { [checkboxRootClass]: checkboxRootClass })
            }}
            uncheckedIconProps={{ uncheckedBoxClass: styles.unCheckedIcon }}
            checkedIconProps={{
              checkedBoxClass: styles.checkedIconBox,
              checkedIconClass: styles.checkedIconClass
            }}
          />
        </div>
      </ShouldRender>

      <ul className={styles.checkboxList}>
        {list?.map(({ id, name, isChecked }) => (
          <li key={id} className={styles.checkboxListItem}>
            <CheckboxWithLabel
              withTruncate={withTruncate}
              truncateWidth={truncateWidth}
              isDisabled={isDisabled}
              name={id}
              title={name}
              isChecked={isChecked}
              onChange={() => (onChange ? onChange(blockId, id, isChecked, name) : null)}
              classes={{
                labelTextClass: styles.labelTextClass,
                labelRootClass: cn(styles.labelRootClass, { [labelRootClass]: labelRootClass }),
                checkboxRootClass: cn(styles.checkboxRootClass, { [checkboxRootClass]: checkboxRootClass })
              }}
              uncheckedIconProps={{ uncheckedBoxClass: styles.unCheckedIcon }}
              checkedIconProps={{
                checkedBoxClass: styles.checkedIconBox,
                checkedIconClass: styles.checkedIconClass
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CheckboxBlock;
