import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { baseReducerSelector } from 'src/redux/selectors';
import { Modal } from 'src/components';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  children?: React.ReactNode;
};

const ModalWithAside: FC<Props> = ({ children, isOpen, onToggle }) => {
  const { isAsideMenuOpen } = useSelector(baseReducerSelector);

  return children ? (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      stylesProps={{
        modal: {
          padding: isAsideMenuOpen ? '10% 0 10% 215px' : '10% 0 10% 84px',
          alignItems: 'center',
          transition: 'all 0.15s ease-in-out'
        }
      }}
    >
      {children}
    </Modal>
  ) : (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      stylesProps={{
        modal: {
          padding: isAsideMenuOpen ? '10% 0 10% 215px' : '10% 0 10% 84px',
          alignItems: 'center',
          transition: 'all 0.15s ease-in-out'
        }
      }}
    />
  );
};

export default ModalWithAside;
